import { HiArrowUpRight } from "react-icons/hi2";
import { CgIfDesign } from "react-icons/cg";
import { ImFilePicture } from "react-icons/im";
import { BsFillPinMapFill } from "react-icons/bs";
import { TfiThought } from "react-icons/tfi";
import { MdPrecisionManufacturing,MdOutlineDeveloperMode } from "react-icons/md";
import { SiBmcsoftware } from "react-icons/si"
import { MdSpatialTracking } from "react-icons/md"
import { MdDraw } from "react-icons/md"
import { TbPhotoSensor2 } from "react-icons/tb"
import { AiOutlineSafety } from "react-icons/ai"
import { MdAssistantNavigation } from "react-icons/md"
import { SiPaloaltosoftware } from "react-icons/si"
import { FaDatabase } from "react-icons/fa"
import { BiCodeAlt } from "react-icons/bi"
import { HiDesktopComputer } from "react-icons/hi"
import { IoIosPeople } from "react-icons/io"
import { AiFillAppstore } from "react-icons/ai"
import { TbSettingsAutomation } from "react-icons/tb"
import { RiGpsFill } from "react-icons/ri"
import { PiComputerTowerBold } from "react-icons/pi"
import { FaTools } from "react-icons/fa"
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { BiRightArrowAlt } from 'react-icons/bi';
import { SiAntdesign,SiChase } from 'react-icons/si';
import { MdOutlineDeveloperBoard } from 'react-icons/md';
import { TfiJoomla } from 'react-icons/tfi';
import { PiVirtualRealityBold } from 'react-icons/pi';
import ContactNew from "../../Assets/images/icon/Automotive/Automotive-contact.webp"


function Automotive() {
    return (
        <>
            <div className="automotive-banner-section">
                <section className="automotive-img back-posi-img">
                    <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid top-banner-section'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1 className="top-text-one">At Upstrox, we are passionate about revolutionizing the <span className="text-color">automobile industry</span> through our innovative software solutions and services.</h1>
                                    <div className="top-text-two">At Upstrox, we offer a comprehensive range of software and services tailored specifically for the automotive industry. With our expertise and cutting-edge technology, we strive to revolutionize the way you design, develop, prototype, market, and manufacture vehicles</div>
                                </div>
                            </div>
                            <div className="pt-3">
                               <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="top-banner-section-two pb-4 pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h5 className="text-one">Our solutions cover everything from concept design to manufacturing and assembly, ensuring a seamless and efficient process. Additionally, we provide software development services for various automotive-related applications. Read on to explore our wide range of offerings.</h5>
                            </div>
                        </div>
                    </div>
                </section>



                <section className="automotive-new-design-2 pb-4 pt-5">
                    <div className="container-fluid col-lg-10 mx-auto">
                        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 justify-content-center">
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <SiAntdesign className="automotive-new-text-2" />
                                        <h5 class="automotive-new-text-1">Concept Design</h5>
                                        <p class="automotive-new-text-3">Our concept design services provide automotive designers with the tools and expertise to bring their ideas to life. Using advanced 3D modeling techniques, we help you create digital representations of your vehicle concepts. These models enable you to visualize the overall shape, proportions, and aesthetics of the vehicle before proceeding to the next stages of development.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <MdOutlineDeveloperBoard className="automotive-new-text-2" />
                                        <h5 class="automotive-new-text-1">Product Development</h5>
                                        <p class="automotive-new-text-3">With our product development solutions, we assist engineers in creating detailed virtual models of individual vehicle components and systems. By leveraging 3D modeling and simulation, we help optimize designs, identify potential issues, and make necessary adjustments. This iterative process ensures that your vehicles meet the highest standards of performance, safety, and efficiency.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <SiChase className="automotive-new-text-2" />
                                        <h5 class="automotive-new-text-1">Prototyping</h5>
                                        <p class="automotive-new-text-3">Our prototyping services enable you to transform your digital designs into physical prototypes. Through advanced techniques like 3D printing, we help you bring your concepts to life and evaluate the design's functionality. Prototyping allows you to test and refine your ideas, ensuring that your final product meets your expectations and customer demands.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <TfiJoomla className="automotive-new-text-2" />
                                        <h5 class="automotive-new-text-1">Visualization and Marketing</h5>
                                        <p class="automotive-new-text-3">At Upstrox, we understand the importance of captivating visuals in marketing your vehicles. Our visualization and marketing solutions leverage 3D modeling and rendering techniques to create high-quality visual representations of your vehicles. These realistic renderings and animations showcase the vehicle's features, highlight design elements, and engage potential customers, ultimately driving sales and brand recognition.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <PiVirtualRealityBold className="automotive-new-text-2" />
                                        <h5 class="automotive-new-text-1">Virtual Reality (VR) and Augmented Reality (AR)</h5>
                                        <p class="automotive-new-text-3">Immerse yourself in the world of automotive design with our VR and AR solutions. Using 3D models, we create interactive experiences that allow designers, engineers, and customers to explore virtual vehicles. Our VR and AR technologies enable you to examine vehicle interiors, experience functionalities, and make informed decisions before physical production.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <MdPrecisionManufacturing className="automotive-new-text-2" />
                                        <h5 class="automotive-new-text-1">Manufacturing and Assembly</h5>
                                        <p class="automotive-new-text-3">Streamline your manufacturing process with our manufacturing and assembly solutions. We utilize 3D modeling to create digital representations of tools, molds, and assembly lines. These models optimize production efficiency, identify potential conflicts, and ensure smooth assembly and integration of various vehicle components.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <MdOutlineDeveloperMode className="automotive-new-text-2" />
                                        <h5 class="automotive-new-text-1">Software Development</h5>
                                        <p class="automotive-new-text-3">Our software enables customers to book appointments with vehicle consultants effortlessly. The system provides a user-friendly interface and integrates seamlessly with your existing business operations.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <BsFillPinMapFill className="automotive-new-text-2" />
                                        <h5 class="automotive-new-text-1">Real-Time Status Tracking</h5>
                                        <p class="automotive-new-text-3">Track the progress of your vehicle's production in real-time with our software. Stay informed about each stage of manufacturing, ensuring transparency and efficient communication within your organization.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <MdDraw className="automotive-new-text-2" />
                                        <h5 class="automotive-new-text-1">Designing The Model Of Vehicle</h5>
                                        <p class="automotive-new-text-3">Our software assists designers in creating vehicle models with precision and ease. Utilize advanced design tools, customization options, and interactive features to develop the perfect vehicle model that meets your specific requirements.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <TbPhotoSensor2 className="automotive-new-text-2" />
                                        <h5 class="automotive-new-text-1">Photography Of Vehicles</h5>
                                        <p class="automotive-new-text-3">Capture stunning photographs of your vehicles with our photography software. Enhance and edit images, showcase your vehicles from different angles, and create a visually appealing portfolio for marketing and sales purposes.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <AiOutlineSafety className="automotive-new-text-2" />
                                        <h5 class="automotive-new-text-1">Vehicle Safety Software</h5>
                                        <p class="automotive-new-text-3">Ensure the safety of your vehicles with our dedicated safety software. Monitor and analyze vehicle performance, detect potential risks, and implement preventive measures to enhance overall safety standards.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <MdAssistantNavigation className="automotive-new-text-2" />
                                        <h5 class="automotive-new-text-1">Vehicle Navigation Software</h5>
                                        <p class="automotive-new-text-3">Navigate through the roads with ease using our advanced navigation software. Enjoy accurate maps, real-time traffic updates, and intuitive features that enhance the driving experience for your customers.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <SiPaloaltosoftware className="automotive-new-text-2" />
                                        <h5 class="automotive-new-text-1">Vehicle Diagnostics Software</h5>
                                        <p class="automotive-new-text-3">Identify and resolve vehicle issues efficiently with our diagnostics software. Access real-time data, perform comprehensive diagnostics, and streamline maintenance and repair processes.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <FaDatabase className="automotive-new-text-2" />
                                        <h5 class="automotive-new-text-1">Garage Management Software</h5>
                                        <p class="automotive-new-text-3">Efficiently manage your garage operations with our comprehensive management software. From scheduling appointments to tracking inventory and managing customer records, our software streamlines your daily operations and improves overall efficiency.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <BiCodeAlt className="automotive-new-text-2" />
                                        <h5 class="automotive-new-text-1">Dealership Management Software</h5>
                                        <p class="automotive-new-text-3">Optimize your dealership's performance with our dealership management software. Streamline sales, inventory management, customer relationship management, and reporting to enhance productivity and maximize profitability.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <HiDesktopComputer className="automotive-new-text-2" />
                                        <h5 class="automotive-new-text-1">Automobile ERP Software</h5>
                                        <p class="automotive-new-text-3">Our enterprise resource planning (ERP) software is specifically designed for the automotive industry. It integrates various business functions such as finance, sales, procurement, and inventory management into a single, cohesive system, ensuring smooth operations and effective resource utilization.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <IoIosPeople className="automotive-new-text-2" />
                                        <h5 class="automotive-new-text-1">Automobile CRM Software</h5>
                                        <p class="automotive-new-text-3">Manage your customer relationships effectively with our customer relationship management (CRM) software. Track customer interactions, manage leads, and streamline sales processes to enhance customer satisfaction and drive sales growth.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <AiFillAppstore className="automotive-new-text-2" />
                                        <h5 class="automotive-new-text-1">OBD Application Development</h5>
                                        <p class="automotive-new-text-3">We specialize in developing on-board diagnostics (OBD) applications that enable real-time monitoring of vehicle performance, emission levels, and diagnostic trouble codes. Our applications provide valuable insights for vehicle owners and mechanics, facilitating proactive maintenance and efficient repairs.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <TbSettingsAutomation className="automotive-new-text-2" />
                                        <h5 class="automotive-new-text-1">Autonomous ADAS Solution</h5>
                                        <p class="automotive-new-text-3">Experience the future of automotive technology with our autonomous advanced driver-assistance systems (ADAS) solutions. Our software incorporates cutting-edge technologies such as sensors, cameras, and machine learning algorithms to enhance vehicle safety, collision avoidance, and automated driving capabilities.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <RiGpsFill className="automotive-new-text-2" />
                                        <h5 class="automotive-new-text-1">Fleet Management Software</h5>
                                        <p class="automotive-new-text-3">Efficiently manage your vehicle fleet with our fleet management software. Track vehicle locations, monitor fuel consumption, schedule maintenance, and optimize routes to improve fleet productivity, reduce costs, and ensure compliance with regulations.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <PiComputerTowerBold className="automotive-new-text-2" />
                                        <h5 class="automotive-new-text-1">Auto Insurance Claim Management Software</h5>
                                        <p class="automotive-new-text-3">Simplify the insurance claim process with our claim management software. From recording claim details to processing documentation and communication with insurers, our software streamlines the claim handling process, reducing paperwork and improving customer satisfaction.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <FaTools className="automotive-new-text-2" />
                                        <h5 class="automotive-new-text-1">Auto Parts Inventory Management</h5>
                                        <p class="automotive-new-text-3">Effectively manage your auto parts inventory with our inventory management software. Track stock levels, streamline procurement processes, and optimize inventory replenishment to ensure smooth operations and prevent stock outs or overstocking.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="aerospace-new-design-2 py-5">
                    <div className="container">
                        <div className="row g-4 flex-lg-row-reverse align-items-center">
                            <div className="col-lg-4">
                                <img src={ContactNew} alt="Autumotive" />
                            </div>
                            <div className="col-lg-8">
                                <p className="aerospace-new-text-5 mb-lg-4">At Upstrox, we are committed to providing innovative software solutions and services to meet the diverse needs of the automotive industry. With our expertise and cutting-edge technology, we empower automotive businesses to enhance their design capabilities, streamline operations, improve customer experiences, and stay ahead in a competitive market. </p>
                                <a href='/contact' className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="aerospace-new-design-2 py-5">
                    <div className="container">
                        <div className="row g-4 justify-content-center text-center ">
                            <div className="col-lg-12">
                                <p className="aerospace-new-text-6 mb-lg-4">Contact us today to explore how Upstrox can transform your automotive business.</p>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}
export default Automotive;