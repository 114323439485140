
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Healthcarebg from "../../Assets/images/servies-images/healthcare.webp"
import { FaArrowRightLong } from "react-icons/fa6";
import { FaArrowLeftLong } from "react-icons/fa6";
import Contactnew from "../../Assets/images/icon/healthcare image/healthcare 3rd image.webp";
import { BiRightArrowAlt } from 'react-icons/bi';
import img1 from "../../Assets/images/icon/healthcare image/healthcare.webp";
import img2 from "../../Assets/images/icon/healthcare image/untitled 3.webp";

function Healthcare() {
  return (
    <>
      <div className="healthcare-banner-section">
        <section className=" top-banner-section-overral-bg-section back-posi-img" style={{ backgroundImage: "url(" + Healthcarebg + ")" }}>
          <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid top-banner-section'>
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one">Upstrox - Empowering
                    <span className="text-color"> Healthcare</span> with Comprehensive IT Services
                  </h1>
                  <div className="top-text-two">
                    In the healthcare industry, technology plays a crucial role in improving patient care, streamlining operations, and ensuring data security.At Upstrox, we specialize in providing tailored IT services for healthcare organizations.
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
              </div>
            </div>
          </div>
        </section>

        <section className="datacard-section">
          <section className="datacard-section-one py-5">
            <div className="container">
              <h5 className="text-one">Our comprehensive solutions cater to the unique needs of the healthcare industry. Here are the key IT services we offer</h5>
              <div className="row pt-2 pt-lg-5 align-items-center">
                <div className="data-card-blur" style={{ background: "#00ffff75", opacity: "0.5" }}></div>
                <div className="col-lg-7 col-md-7">
                  <div className="container">
                    <div className="row pt-lg-3 py-2">
                      <div className="col">
                        <div className="card bg-transparent text-white">
                          <h4 className="card-text-one">Electronic Health Records (EHR) Systems</h4>
                          <h5 className="card-text-two text-ho">
                            We provide robust EHR systems that enable healthcare organizations to digitize patient records, securely store and access medical information, and streamline clinical workflows. Our EHR solutions enhance patient safety, facilitate information exchange, and support efficient healthcare delivery.
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-lg-3 py-2">
                      <div className="col">
                        <div className="card bg-transparent text-white">
                          <h4 className="card-text-one">Health Information Exchange (HIE)</h4>
                          <h5 className="card-text-two text-ho">
                            We assist in implementing HIE solutions that enable secure and seamless sharing of patient health information across different healthcare providers and organizations. Our solutions improve care coordination, reduce duplication of tests, and enhance the continuity of care.
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-lg-3 py-2">
                      <div className="col">
                        <div className="card bg-transparent text-white">
                          <h4 className="card-text-one">Telehealth and Remote Patient Monitoring</h4>
                          <h5 className="card-text-two text-ho">
                            We offer telehealth solutions that enable virtual consultations, remote patient monitoring, and patient engagement. Our solutions enhance accessibility to care, improve patient outcomes, and increase efficiency in healthcare delivery.
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5">
                  <div className=""><img src={img1} alt="healthcare" className="datacard-img" /></div>
                </div>
              </div>
            </div>
          </section>
        </section>

        <section className="customercard-section">

          <section className="customercard-section-two py-lg-5">
            <div className="container-fluid col-lg-10">
              <div className="row align-items-center">

                <div className="col-lg-4 col-md-4">
                  <div><img src={img2} className="healthcare" /></div>
                </div>
                <div className="col-lg-8 col-md-8">
                  <div className="container">
                    <div className="row pt-lg-5 py-2">
                      <div className="col-lg-6 col-md-6">
                        <div className="card bg-transparent text-white">
                          <h4 className="card-text-one">Picture Archiving and Communication Systems (PACS)</h4>
                          <h5 className="card-text-two text-ho">
                          We provide PACS solutions for efficient management, storage, and distribution of medical images such as X-rays, CT scans, and MRIs. Our PACS solutions enable healthcare providers to access and share images securely, enhancing diagnostic accuracy and treatment planning.
                          </h5>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="card bg-transparent text-white">
                          <h4 className="card-text-one">Healthcare Analytics and Business Intelligence</h4>
                          <h5 className="card-text-two text-ho">
                          Our healthcare analytics and business intelligence solutions help healthcare organizations derive actionable insights from their data. We assist in analyzing clinical, operational, and financial data to drive informed decision-making, optimize processes, and improve patient outcomes.
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-lg-5 py-2">
                      <div className="col-lg-6 col-md-6">
                        <div className="card bg-transparent text-white">
                          <h4 className="card-text-one">Revenue Cycle Management (RCM)</h4>
                          <h5 className="card-text-two text-ho">
                          We offer RCM solutions that streamline billing, coding, and claims management processes. Our solutions help healthcare organizations improve cash flow, reduce billing errors, and enhance reimbursement accuracy.
                          </h5>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="card bg-transparent text-white">
                          <h4 className="card-text-one">Patient Engagement and Experience</h4>
                          <h5 className="card-text-two text-ho">
                          We provide patient engagement solutions that enable healthcare organizations to communicate with patients effectively, facilitate appointment scheduling, and deliver personalized health information. Our solutions enhance patient satisfaction, adherence to treatment plans, and overall patient experience.
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="customercard-section-three py-5">
            <div className="container">
              <div className="row py-lg-5 pt-3 d-flex align-items-center">
                <div className="col-lg-3 col-md-3">
                  <h4 className="card-text-one customercard-heading">Health IT Security and Compliance</h4></div>
                <div className="col-lg-1 col-md-2 card-hide">
                  <div className=""><FaArrowRightLong className="customer-arrow" /></div>
                </div>
                <div className="col">
                  <h5 className="card-text-two text-ho">
                  We prioritize data security and compliance in the healthcare industry. We offer comprehensive cybersecurity services, including HIPAA compliance, risk assessments, data encryption, access controls, and security training. Our solutions safeguard patient information and protect against data breaches.
                  </h5>
                </div>
              </div>
              <div className="row py-lg-5 pt-3 d-flex align-items-center row-reverse">
                <div className="col">
                  <h5 className="card-text-two text-ho">
                  We provide end-to-end IT infrastructure management services, including network design, server management, data backup, and disaster recovery planning. Our solutions ensure secure and reliable IT infrastructure to support healthcare operations.
                  </h5>
                </div>
                <div className="col-lg-1 col-md-2 card-hide">
                  <div className=""><FaArrowLeftLong className="customer-arrow" /></div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <h4 className="card-text-one customercard-heading">IT Infrastructure Management</h4></div>
              </div>
              <div className="row py-lg-5 pt-3 d-flex align-items-center">
                <div className="col-lg-3 col-md-3">
                  <h4 className="card-text-one customercard-heading">Healthcare Consulting and Strategy</h4></div>
                <div className="col-lg-1 col-md-2 card-hide">
                  <div className=""><FaArrowRightLong className="customer-arrow" /></div>
                </div>
                <div className="col">
                  <h5 className="card-text-two text-ho">
                  Our experienced consultants offer healthcare IT consulting services to help organizations develop IT strategies, assess technology needs, and optimize workflows. We assist in technology selection, vendor management, and digital transformation initiatives.
                  </h5>
                </div>
              </div>
            </div>
          </section>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 flex-lg-row-reverse align-items-center">
              <div className="col-lg-4">
                <img src={Contactnew} alt="aerospace and defense" />
              </div>
              <div className="col-lg-8">
                <p className="aerospace-new-text-5 mb-lg-4">At Upstrox, we understand the unique challenges and requirements of the healthcare industry. Our comprehensive IT services empower healthcare organizations to enhance patient care, improve operational efficiency, and ensure data security and compliance. </p>
                <a href='/contact' className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="aerospace-new-text-6 mb-lg-4">Contact us today to discuss your healthcare IT needs and explore how we can support your organization's goals effectively.</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
export default Healthcare;