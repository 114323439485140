
import Errorimg from "./Assets/images/Error-404.webp"
import React, { useContext } from 'react';
import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router';

function Error({ setShowNavbar }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
};

  useLayoutEffect(() => {
    setShowNavbar(false);
}, [])

  return (
    <div className="App ">
      <section className="error-page-banner">
        <div className="container-fluid col-lg-11">
          <div className="row min-vh-100 align-items-center justify-content-center text-center">
            <div className="col">
              <img src={Errorimg} alt="Error" /><br />
              <a href="/" type="button" class="btn btn-light" onClick={handleClick} >Return Home</a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Error;
