import datatwo from "../../Assets/images/icon/education/education image.webp";
import { BiRightArrowAlt } from "react-icons/bi";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Educationbg from "../../Assets/images/servies-images/education.webp";
import eduicon1 from "../../Assets/images/icon/education/IT Infrastructure Management.webp";
import eduicon2 from "../../Assets/images/icon/education/Learning Management Systems.webp";
import eduicon3 from "../../Assets/images/icon/education/Student Information Systems.webp";
import eduicon4 from "../../Assets/images/icon/education/Classroom Technology Integration.webp";
import eduicon5 from "../../Assets/images/icon/education/E-Learning Solutions.webp";
import eduicon6 from "../../Assets/images/icon/education/Digital Libraries and Resource Management.webp";
import eduicon7 from "../../Assets/images/icon/education/Data Analytics and Reporting.webp";
import eduicon8 from "../../Assets/images/icon/education/Cybersecurity and Data Protection.webp";
import eduicon9 from "../../Assets/images/icon/education/IT Support and Help Desk Services.webp";
import eduicon10 from "../../Assets/images/icon/education/Technology Consulting and Planning.webp";

function Education() {
  return (
    <>
      <div className="education-banner-section">
        <section
          className="top-banner-section top-banner-section-overral-bg-section"
          style={{ backgroundImage: "url(" + Educationbg + ")" }}
        >
          <div className="mobile-new-ups-bg-shade-inner mx-auto container-fluid">
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one">
                    Upstrox - Empowering
                    <span className="text-color">
                      {" "}
                      Education with Comprehensive{" "}
                    </span>{" "}
                    IT Services
                  </h1>
                  <div className="top-text-two">
                    {" "}
                    In the modern era of education, technology plays a crucial
                    role in enhancing learning experiences, improving
                    administrative processes, and facilitating effective
                    communication.
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <a href="/contact">
                  <button className="top-button right-side">
                    Get More{" "}
                    <MdOutlineArrowForwardIos className="top-icon ms-lg-2" />
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="top-banner-section-two py-3">
          <div className="container">
            <div className="row">
              <div className="col">
                <h5 className="text-one">
                  At Upstrox, we are dedicated to providing tailored IT services
                  that cater to the unique needs of educational institutions.
                  Here are the key IT services we offer.
                </h5>
              </div>
            </div>
          </div>
        </section>

        <section className="chemicalmanufacturing-new-design-1 ">
          <div className="container-fluid col-lg-10 mx-auto py-lg-5">
            <div className="row row-cols-md-2 row-cols-1 row-cols-lg-2 g-4">
              <div className="col mt- card h-1000 chemicalmanufacturing-top-new-card-1">
                <div class="card bg-transparent text-white border-0 p-lg-3">
                  <div class="card-body">
                    <img
                      src={eduicon1}
                      alt="education"
                      className="aerospace-new-image-2"
                    />
                    <h5 className="aerospace-new-text-3">
                      IT Infrastructure Management
                    </h5>
                    <p className="aerospace-new-text-4 mb-2">
                      We provide end-to-end management of IT infrastructure for
                      educational institutions, including network design, server
                      management, data centers, and cloud integration. Our
                      services ensure reliable connectivity, scalability, and
                      security to support the digital ecosystem of schools and
                      universities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mt-0 chemicalmanufacturing-top-new-card-2">
                <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                  <div class="card-body">
                    <img
                      src={eduicon2}
                      alt="education"
                      className="aerospace-new-image-2"
                    />
                    <h5 className="aerospace-new-text-3">
                      Learning Management Systems (LMS)
                    </h5>
                    <p className="aerospace-new-text-4 mb-2">
                      We develop and implement robust LMS platforms that enable
                      educational institutions to deliver online courses, manage
                      curriculum, track student progress, and facilitate
                      communication between students and educators. Our LMS
                      solutions promote interactive and engaging learning
                      experiences.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mt-0 chemicalmanufacturing-top-new-card-3">
                <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                  <div class="card-body">
                    <img
                      src={eduicon3}
                      alt="education"
                      className="aerospace-new-image-2"
                    />
                    <h5 className="aerospace-new-text-3">
                      Student Information Systems (SIS)
                    </h5>
                    <p className="aerospace-new-text-4 mb-2">
                      {" "}
                      Our SIS solutions streamline administrative processes by
                      centralizing student data, managing admissions,
                      enrollment, grades, attendance, and generating academic
                      reports. We help educational institutions optimize data
                      management and enhance efficiency in student
                      administration.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mt-0 chemicalmanufacturing-top-new-card-4">
                <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                  <div class="card-body">
                    <img
                      src={eduicon4}
                      alt="education"
                      className="aerospace-new-image-2"
                    />
                    <h5 className="aerospace-new-text-3">
                      Classroom Technology Integration
                    </h5>
                    <p className="aerospace-new-text-4 mb-2">
                      We assist in integrating interactive whiteboards,
                      projectors, audio systems, and other classroom
                      technologies to create a dynamic and engaging learning
                      environment. Our solutions empower educators to deliver
                      impactful lessons and encourage collaborative learning
                      among students.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container mt-5 con-card-two">
            <div className="row row-cols-lg-3 row-cols-1 row-cols-md-2  pb-lg-4">
              <div className="col con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={eduicon5}
                      className="con-icon-top"
                      alt="education"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">E-Learning Solutions</h3>
                    <p className="card-text-two">
                      {" "}
                      We develop customized e-learning solutions that include
                      digital content creation, multimedia integration, and
                      interactive assessments. Our e-learning platforms
                      facilitate anytime, anywhere access to educational
                      resources, fostering self-paced learning and personalized
                      education.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={eduicon6}
                      className="con-icon-top"
                      alt="education"
                    />
                  </div>
                  <div className="pt-lg-4 ">
                    <h3 className="card-text-one">
                      Digital Libraries and Resource Management
                    </h3>
                    <p className="card-text-two">
                      We create digital library platforms that provide students
                      and faculty with access to a vast collection of digital
                      resources, e-books, research databases, and academic
                      journals. Our solutions enable efficient cataloging,
                      search capabilities, and secure access to educational
                      materials.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={eduicon7}
                      className="con-icon-top"
                      alt="education"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                      Data Analytics and Reporting
                    </h3>
                    <p className="card-text-two">
                      We help educational institutions leverage data analytics
                      and reporting tools to gain insights into student
                      performance, course effectiveness, and resource
                      allocation. Our solutions enable evidence-based
                      decision-making, identifying areas for improvement and
                      enhancing educational outcomes.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col con-top con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={eduicon8}
                      className="con-icon-top"
                      alt="education"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                      Cybersecurity and Data Protection
                    </h3>
                    <p className="card-text-two">
                      Protecting student data and ensuring cybersecurity is of
                      utmost importance in the education sector. We offer
                      comprehensive cybersecurity services, including network
                      security, data encryption, access control, and user
                      awareness training, to safeguard sensitive information and
                      maintain compliance with data protection regulations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={eduicon9}
                      className="con-icon-top"
                      alt="education"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                      IT Support and Help Desk Services
                    </h3>
                    <p className="card-text-two">
                      We provide dedicated IT support and help desk services to
                      address technical issues, troubleshoot software or
                      hardware problems, and provide timely assistance to
                      students, faculty, and staff. Our team ensures smooth
                      operations and minimal disruption to educational
                      activities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={eduicon10}
                      className="con-icon-top"
                      alt="education"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                      Technology Consulting and Planning
                    </h3>
                    <p className="card-text-two">
                      We offer expert technology consulting services to
                      educational institutions, assisting in IT strategic
                      planning, technology roadmap development, and vendor
                      selection. We help align technology initiatives with
                      educational goals and ensure optimal utilization of
                      resources.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="logistic-last-section my-lg-5">
          <div className="container py-3">
            <div className="row flex-row-reverse">
              <div className="col-lg-4 col-md-4">
                <img src={datatwo} alt="education" />
              </div>
              <div className="col-lg-8 col-md-8">
                <div className="pt-lg-5">
                  <p className="">
                    At Upstrox, we understand the evolving needs of educational
                    institutions in the digital age. Our IT services empower
                    schools and universities to leverage technology effectively,
                    enhance the learning experience, and streamline
                    administrative processes.
                  </p>
                </div>
                <div>
                  <a className="new-ups-button-2" href="/contact">
                    <span className="new-ups-button-icon-3">Read More</span>{" "}
                    <BiRightArrowAlt className="new-ups-button-icon-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-text-section py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="contact-text-last mb-lg-4">
                  Contact us today to explore how we can support your
                  institution's IT requirements and contribute to its success in
                  the education landscape.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Education;
