import { MdOutlineArrowForwardIos } from "react-icons/md";
import Dummybg from "../../Assets/images/servies-images/demo/dummy-2.webp"

import { BiRightArrowAlt } from "react-icons/bi";
import consumerlast from "../../Assets/images/servies-images/smart contract webp/images/last image.webp";

import smarticon1 from "../../Assets/images/servies-images/smart contract webp/icon/Smart Contract Design and Development.webp";
import smarticon2 from "../../Assets/images/servies-images/smart contract webp/icon/Token Creation and Crowdsale Contracts.webp";
import smarticon3 from "../../Assets/images/servies-images/smart contract webp/icon/Decentralized Finance (DeFi) Smart Contracts.webp";
import smarticon4 from "../../Assets/images/servies-images/smart contract webp/icon/Non-Fungible Token (NFT) Contracts.webp";
import smarticon5 from "../../Assets/images/servies-images/smart contract webp/icon 2/Escrow and Multi-signature Contracts.webp";
import smarticon6 from "../../Assets/images/servies-images/smart contract webp/icon 2/Supply Chain and Traceability Contracts.webp";
import smarticon7 from "../../Assets/images/servies-images/smart contract webp/icon 2/Oracle Integration.webp";
import smarticon8 from "../../Assets/images/servies-images/smart contract webp/icon 2/Audit and Security.webp";
import smarticon9 from "../../Assets/images/servies-images/smart contract webp/icon 2/Smart Contract Testing and Deployment.webp";
import smarticon10 from "../../Assets/images/servies-images/smart contract webp/icon 2/Smart Contract Consulting and Support.webp";
import { useEffect } from "react";

function SmartContractDevelopment() {
    
    return (
        <>
            <div className="smartcontract">
                <section className="top-banner-section top-banner-section-overral-bg-section back-posi-img smartcontract-background">
                    <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1 className="top-text-one"><span className="text-color">Smart Contract Development</span> by Upstrox.</h1>
                                    <div className="top-text-two">Upstrox specializes in smart contract development, offering customized and secure smart contract solutions to organizations across various industries. Smart contracts are self-executing agreements that run on blockchain networks, enabling automated and tamper-proof transactions without the need for intermediaries.</div>
                                </div>
                            </div>
                            <div className="pt-3">
                               <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="consumer-new-cards-section pt-5">
                <div className="container-fluid col-lg-10 position-relative pb-lg-5">
                    <div className="row">
                    {/* <div className="consumer-blur"></div> */}
                    <div className="col-lg-4">
                        <h5 className="aerospace-new-text-1">Smart Contract Development</h5>
                        <p className="aerospace-new-text-2">
                        Our team of blockchain experts and smart contract developers possess extensive knowledge in programming languages such as Solidity, enabling us to deliver robust and efficient smart contracts. Here's how Upstrox can assist you with smart contract development
                        </p>
                    </div>
                    <div className="col-lg-8">
                        <div className="d-lg-flex d-md-flex">
                        <div>
                            {" "}
                            <img
                            src={smarticon1}
                            className="smart-icon-img"
                            alt="smartcontract"
                            />
                        </div>
                        <div className="">
                            <h3 className="card-text-one">
                            Smart Contract Design and Development
                            </h3>
                            <p className="card-text-two">
                            Our experts collaborate closely with your organization to understand your specific requirements and design smart contracts that meet your business needs. We analyze the contract logic, define the contract terms, and develop the smart contract code using Solidity, ensuring accuracy and security.
                            </p>
                        </div>
                        </div>
                        <div className="d-lg-flex d-md-flex">
                        <div>
                            {" "}
                            <img
                            src={smarticon2}
                            className="smart-icon-img"
                            alt="smartcontract"
                            />
                        </div>
                        <div className="">
                            <h3 className="card-text-one">
                            Token Creation and Crowdsale Contracts
                            </h3>
                            <p className="card-text-two">
                            We assist organizations in creating their own tokens and developing crowdsale contracts for initial coin offerings (ICOs) or token sales. Our experts guide you through the token creation process, implement the necessary token functionality, and develop smart contracts to manage the token sale process securely.
                            </p>
                        </div>
                        </div>
                        <div className="d-lg-flex d-md-flex">
                        <div>
                            {" "}
                            <img
                            src={smarticon3}
                            className="smart-icon-img"
                            alt="smartcontract"
                            />
                        </div>
                        <div>
                            <h3 className="card-text-one">
                            Decentralized Finance (DeFi) Smart Contracts
                            </h3>
                            <p className="card-text-two">
                            We specialize in developing smart contracts for decentralized finance applications. Our experts create and deploy smart contracts for lending and borrowing protocols, decentralized exchanges (DEXs), liquidity pools, yield farming platforms, and other DeFi solutions, ensuring security and efficiency.
                            </p>
                        </div>
                        </div>
                        <div className="d-lg-flex d-md-flex">
                        <div>
                            {" "}
                            <img
                            src={smarticon4}
                            className="smart-icon-img"
                            alt="smartcontract"
                            />
                        </div>
                        <div>
                            <h3 className="card-text-one">
                            Non-Fungible Token (NFT) Contracts
                            </h3>
                            <p className="card-text-two">
                            We help organizations implement non-fungible token contracts for unique digital assets, such as collectibles, artwork, and virtual real estate. Our experts develop NFT smart contracts that enable the minting, transfer, and ownership tracking of NFTs on blockchain networks.
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="container mt-5 con-card-two">
                    <div className="row row-cols-lg-3 row-cols-1 row-cols-md-2  pb-lg-4">
                    <div className="col con-top-mobile">
                        <div className="card h-100 px-3">
                        <div className="ps-3">
                            <img
                            src={smarticon5}
                            className="con-icon-top"
                            alt="smartcontract"
                            />
                        </div>
                        <div className="pt-lg-4">
                            <h3 className="card-text-one">
                            Escrow and Multi-signature Contracts
                            </h3>
                            <p className="card-text-two">
                            {" "}
                            We design and develop escrow contracts and multi-signature contracts that facilitate secure and trustless transactions between multiple parties. Our experts create smart contracts that hold funds or assets in escrow until predefined conditions are met, ensuring transparency and dispute resolution.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="col con-top-mobile">
                        <div className="card h-100 px-3">
                        <div className="ps-3">
                            <img
                            src={smarticon6}
                            className="con-icon-top"
                            alt="smartcontract"
                            />
                        </div>
                        <div className="pt-lg-4 ">
                            <h3 className="card-text-one">
                            Supply Chain and Traceability Contracts
                            </h3>
                            <p className="card-text-two">
                            We develop smart contracts that enhance supply chain transparency and traceability by recording and verifying the movement of goods and assets on the blockchain. Our experts create contracts that enable secure tracking, authentication, and verification of supply chain events and transactions.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="col con-top-mobile">
                        <div className="card h-100 px-3">
                        <div className="ps-3">
                            <img
                            src={smarticon7}
                            className="con-icon-top"
                            alt="smartcontract"
                            />
                        </div>
                        <div className="pt-lg-4">
                            <h3 className="card-text-one">
                            Oracle Integration
                            </h3>
                            <p className="card-text-two">
                            We integrate oracles with smart contracts to enable the interaction between blockchain networks and external data sources. Our experts configure and connect smart contracts with trusted oracles, enabling access to real-world data and ensuring the execution of contract conditions based on external events.
                            </p>
                        </div>
                        </div>
                    </div>

                    <div className="col con-top con-top-mobile">
                        <div className="card h-100 px-3">
                        <div className="ps-3">
                            <img
                            src={smarticon8}
                            className="con-icon-top"
                            alt="smartcontract"
                            />
                        </div>
                        <div className="pt-lg-4">
                            <h3 className="card-text-one">
                            Audit and Security
                            </h3>
                            <p className="card-text-two">
                            We prioritize security in smart contract development. Our experts conduct thorough code audits, identify potential vulnerabilities, and recommend best practices to mitigate risks associated with smart contracts. We ensure that your smart contracts are robust, secure, and resilient against potential attacks.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="col con-top con-top-mobile">
                        <div className="card h-100 px-3">
                        <div className="ps-3">
                            <img
                            src={smarticon9}
                            className="con-icon-top"
                            alt="smartcontract"
                            />
                        </div>
                        <div className="pt-lg-4">
                            <h3 className="card-text-one">
                            Smart Contract Testing and Deployment
                            </h3>
                            <p className="card-text-two">
                            We perform comprehensive testing of smart contracts to ensure their functionality and security. Our experts conduct unit testing, integration testing, and simulated scenario testing to identify and resolve any issues. We also assist in the deployment of smart contracts on the appropriate blockchain network.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="col con-top con-top-mobile">
                        <div className="card h-100 px-3">
                        <div className="ps-3">
                            <img
                            src={smarticon10}
                            className="con-icon-top"
                            alt="smartcontract"
                            />
                        </div>
                        <div className="pt-lg-4">
                            <h3 className="card-text-one">
                            Smart Contract Consulting and Support
                            </h3>
                            <p className="card-text-two">
                            We provide expert guidance and support throughout the entire smart contract development lifecycle. Our consultants offer advice on smart contract implementation, best practices, regulatory compliance, and integration with existing systems. We are committed to assisting you in making informed decisions regarding your smart contract initiatives.
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>

                <section className="consumer-last-section my-lg-5">
                <div className="container py-5">
                    <div className="row flex-row-reverse">
                    <div className="col-lg-4 col-md-4">
                        <img src={consumerlast} alt="smartcontract" />
                    </div>
                    <div className="col-lg-8 col-md-8">
                        <div className="pt-lg-5 pt-md-5">
                        <p className="">
                        At Upstrox, we are dedicated to delivering secure, reliable, and efficient smart contract solutions. 
                        </p>
                        </div>
                        <div>
                        <a className="new-ups-button-2" href="/contact">
                            <span className="new-ups-button-icon-3">Read More</span>{" "}
                            <BiRightArrowAlt className="new-ups-button-icon-2" />
                        </a>
                        </div>
                    </div>
                    </div>
                </div>
                </section>

                <section className="py-5">
                <div className="container">
                    <div className="row g-4 justify-content-center text-center">
                    <div className="col-lg-12">
                        <p className="contact-text-last mb-lg-4">
                        Contact us today to discuss your smart contract development requirements and explore how Upstrox can assist your organization in leveraging the power of blockchain technology through our smart contract development services.
                        </p>
                    </div>
                    </div>
                </div>
                </section>

            </div>
        </>
    )
}
export default SmartContractDevelopment;