import { MdOutlineArrowForwardIos } from "react-icons/md";
import IconAero1 from "../../Assets/images/icon/aerospacedefence/operational.webp"
import IconAero2 from "../../Assets/images/icon/aerospacedefence/cyber-security.webp"
import IconAero3 from "../../Assets/images/icon/aerospacedefence/intelligence.webp"
import IconAero4 from "../../Assets/images/icon/aerospacedefence/testing-verified.webp"
import IconAero5 from "../../Assets/images/icon/aerospacedefence/collaboration.webp"
import IconAero6 from "../../Assets/images/icon/aerospacedefence/supply-chain.webp"
import IconAero7 from "../../Assets/images/icon/aerospacedefence/compliance.webp"
import ContactNew from "../../Assets/images/icon/aerospacedefence/aerospace-defence-contact.webp"
import { useEffect } from "react";
import { BiRightArrowAlt } from 'react-icons/bi';


function AerospaceDefence() {
  
  useEffect(() => {
    var ogurl = document.getElementById("og-url");
    ogurl.setAttribute("content", "https://assets.clarisco.com/clarisco+images/nft/market-places/rarible-like-nft-marketplace/rarible-nft-marketplace-services-4.webp");
    console.log(ogurl);
  }, []);

  return (
    <>
    
      <div className="aerospace-banner-section">
        <section className="top-banner-section aerospace-img back-posi-img">
          <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one">Propel Your <span className="text-color">Aerospace and Defense</span> Technologies to New Heights with Advanced IT Solutions</h1>
                  <p className="top-text-two"> In the highly competitive aerospace and defense industry, embracing cutting-edge technology is crucial for gaining a strategic advantage and achieving Tech growth. At Upstrox, we specialize in providing innovative IT solutions specifically tailored to meet the unique demands of the aerospace and defense sector. </p>
                </div>
              </div>
              <div className="pt-3">
                <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
              </div>
            </div>
          </div>
        </section>




        <section className="aerospace-new-design-1 py-5">
          <div className="container-fluid col-lg-11 mx-auto">
            <div className="row ">
              <div className="col-lg-4 ">
                <h2 className="aerospace-new-text-1 mb-lg-4 mb-3">Upstrox in Aerospace and Defense sector</h2>
                <p className="aerospace-new-text-2">Our comprehensive range of services empowers your Technological growth to roar above the competitions.</p>
              </div>
              <div className="col-lg-8">
                <div className="row row-cols-md-2 row-cols-1 row-cols-lg-2 g-4">
                  <div className="col">
                    <img src={IconAero1} alt="icon" className="aerospace-new-image-1" />
                    <h5 className="aerospace-new-text-3">Operational Excellence</h5>
                    <p className="aerospace-new-text-4">Achieving operational excellence is paramount in the aerospace and defense industry. Our advanced IT solutions optimize your operations, streamline processes, and enhance efficiency. From supply chain management to logistics and maintenance, our tailored solutions ensure seamless coordination, real-time visibility, and improved resource utilization, enabling you to deliver projects on time and within budget.</p>
                  </div>
                  <div className="col">
                    <img src={IconAero2} alt="icon" className="aerospace-new-image-1" />
                    <h5 className="aerospace-new-text-3">Cybersecurity and Data Protection</h5>
                    <p className="aerospace-new-text-4">Protecting sensitive information and safeguarding critical infrastructure are vital in the aerospace and defense sector. Our IT experts implement robust cybersecurity measures to defend against cyber threats, secure networks, and protect valuable data. With advanced encryption, intrusion detection systems, and vulnerability assessments, we ensure your systems remain secure and compliant with industry regulations.</p>
                  </div>
                  <div className="col">
                    <img src={IconAero3} alt="icon" className="aerospace-new-image-1" />
                    <h5 className="aerospace-new-text-3">Intelligent Analytics</h5>
                    <p className="aerospace-new-text-4">Unlock the power of data to make informed decisions and gain a competitive edge. Our IT solutions leverage advanced analytics, artificial intelligence, and machine learning algorithms to extract actionable insights from vast amounts of data. By analyzing trends, predicting outcomes, and identifying potential risks, our analytics solutions enable you to optimize performance, drive innovation, and enhance operational efficiency.</p>
                  </div>
                  <div className="col">
                    <img src={IconAero4} alt="icon" className="aerospace-new-image-1" />
                    <h5 className="aerospace-new-text-3">Simulation and Virtual Testing</h5>
                    <p className="aerospace-new-text-4">Accelerate product development and reduce costs by harnessing the capabilities of simulation and virtual testing. Our IT services provide sophisticated modeling tools and simulation platforms that allow you to design, test, and refine aerospace and defense systems in a virtual environment. This not only saves time and resources but also ensures superior product quality and performance.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="aerospace-new-design-2 py-5">
          <div className="container-fluid col-lg-11 mx-auto">
            <div className="row row-cols-md-1 row-cols-1 row-cols-lg-3 g-4 py-lg-5">
              <div className="col aerospace-new-design-2-inner-1">
                <img src={IconAero5} alt="icon" className="aerospace-new-image-2" />
                <h5 className="aerospace-new-text-3">Collaboration and Communication</h5>
                <p className="aerospace-new-text-4">Collaboration is key in the aerospace and defense industry, where seamless communication across teams and partners is essential. Our IT solutions facilitate efficient collaboration through integrated communication platforms, document sharing tools, and project management systems. By enabling real-time collaboration, remote access, and secure information exchange, we foster effective teamwork and streamlined communication channels.</p>
              </div>
              <div className="col aerospace-new-design-2-inner-2">
                <img src={IconAero6} alt="icon" className="aerospace-new-image-2" />
                <h5 className="aerospace-new-text-3">Supply Chain Optimization</h5>
                <p className="aerospace-new-text-4">Managing complex supply chains is a challenge in the aerospace and defense sector. Our IT expertise helps optimize your supply chain operations, reducing lead times, minimizing costs, and ensuring timely delivery of critical components. Through advanced inventory management, demand forecasting, and supplier relationship management systems, we enhance supply chain visibility and enable effective decision-making.</p>
              </div>
              <div className="col aerospace-new-design-2-inner-3">
                <img src={IconAero7} alt="icon" className="aerospace-new-image-2" />
                <h5 className="aerospace-new-text-3">Compliance and Regulatory Support</h5>
                <p className="aerospace-new-text-4">The aerospace and defense industry is subject to stringent regulatory requirements. Our IT solutions assist in maintaining compliance by implementing robust documentation management systems, traceability solutions, and audit trail capabilities. We help you stay ahead of regulatory changes, ensuring adherence to industry standards and mitigating compliance risks.</p>
              </div>
            </div>
          </div>
        </section>


        <section className="aerospace-new-design-2 py-3">
          <div className="container">
            <div className="row g-4 flex-lg-row-reverse">
              <div className="col-lg-4">
                <img src={ContactNew} alt="aerospace and defense" />
              </div>
              <div className="col-lg-8">
                <p className="aerospace-new-text-5 mb-lg-4">At Upstrox, we understand the intricate needs of the aerospace and defense industry. Our specialized IT solutions are designed to address your unique challenges, leverage emerging technologies, and propel your tech to new heights. By partnering with us, you gain a trusted ally committed to enhancing your operational capabilities, strengthening cybersecurity, and driving sustainable growth.</p>
                <a href='/contact' className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
              </div>
            </div>
          </div>
        </section>


        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="aerospace-new-text-6 mb-lg-4">Contact us today to explore how our tailored IT solutions can transform your aerospace and defense technologies to new unbeatable heights. Together, let's seize opportunities, overcome challenges, and redefine success in the dynamic world of aerospace and defense through the power of advanced IT solutions.</p>
              </div>
            </div>
          </div>
        </section>


      </div>
    </>
  )
}
export default AerospaceDefence;