
import retaillast from "../../Assets/images/servies-images/Oracle Services webp/images/first section copy.webp";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import logidis1ast from "../../Assets/images/servies-images/Oracle Services webp/images/last image copy.webp";
import { BiRightArrowAlt } from "react-icons/bi";
import oracleicon1 from "../../Assets/images/servies-images/Oracle Services webp/icon 1/Oracle Implementation and Upgrades.webp";
import oracleicon2 from "../../Assets/images/servies-images/Oracle Services webp/icon 1/Oracle Application Customization and Integration.webp";
import oracleicon3 from "../../Assets/images/servies-images/Oracle Services webp/icon 1/Oracle Database Management.webp";
import oracleicon4 from "../../Assets/images/servies-images/Oracle Services webp/icon 1/Oracle Cloud Services.webp";
import oracleicon5 from "../../Assets/images/servies-images/Oracle Services webp/icon 2/Oracle Business Intelligence and Analytics.webp";
import oracleicon6 from "../../Assets/images/servies-images/Oracle Services webp/icon 2/Oracle Fusion Middleware Services.webp";
import oracleicon7 from "../../Assets/images/servies-images/Oracle Services webp/icon 2/Oracle Business Intelligence and Analytics.webp";
import oracleicon8 from "../../Assets/images/servies-images/Oracle Services webp/icon 2/Oracle Application Support and Maintenance.webp";
import oracleicon9 from "../../Assets/images/servies-images/Oracle Services webp/icon 2/Oracle Training and Knowledge Transfer.webp";
import oracleicon10 from "../../Assets/images/servies-images/Oracle Services webp/icon 2/Oracle License and Asset Management.webp";
import { useEffect } from "react";


function Oracle() {

  
    return (
        <>
            <div className="oracle">
                <section className="top-banner-section top-banner-section-overral-bg-section oracle-background back-posi-img">
                    <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1 className="top-text-one"><span className="text-color">Oracle</span>  Services by Upstrox.</h1>
                                    <div className="top-text-two">As a leading provider of enterprise software and solutions, Upstrox offers a wide range of services centered around Oracle technologies.  </div>
                                </div>
                            </div>
                            <div className="pt-3">
                               <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="top-banner-section-two pt-5 py-2">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h5 className="text-one">Our team of Oracle-certified experts has deep expertise in Oracle's suite of products and can assist organizations in maximizing the value of their Oracle investments. Here are some key Oracle services provided by Upstrox</h5>
                            </div>
                        </div>
                    </div>
                </section>

               <section>
               <div className="container-fluid col-lg-10 mt-5 re-card-three ">
            <div className="row flex-row-reverse">
              <div className="col-lg-5 col-md-4 d-flex align-items-center">
                <img src={retaillast} alt="oracle" style={{height:"auto"}} />
              </div>
              <div className="col-lg-7 col-md-8">
                <div className=" card ">
                  <div className="card-body d-lg-flex d-md-flex">
                    <div className="">
                      {" "}
                      {/* <BsFillDatabaseFill className="re-icon-three th-icon-one" /> */}
                      <img src={oracleicon1} className="oracle-icon-img" alt="oracle"/>
                    </div>
                    <div className="px-lg-3 px-md-3">
                      <h4 className="card-text-one">
                      Oracle Implementation and Upgrades
                      </h4>
                      <h5 className="card-text-two">
                      We assist organizations in implementing new Oracle solutions or upgrading their existing Oracle systems. Our experts work closely with you to understand your business requirements, design the optimal solution architecture, configure Oracle applications, and ensure a smooth and successful implementation or upgrade.

                      </h5>
                    </div>
                  </div>
                </div>
                <div className=" card ">
                  <div className="card-body d-lg-flex d-md-flex">
                    <div className="">
                      {" "}
                      {/* <GiDigitalTrace className="re-icon-three th-icon-two" /> */}
                      <img src={oracleicon2} className="oracle-icon-img" alt="oracle"/>
                    </div>
                    <div className="px-lg-3 px-md-3">
                      <h4 className="card-text-one">
                      Oracle Application Customization and Integration
                      </h4>
                      <h5 className="card-text-two">
                      We provide customization and integration services to tailor Oracle applications to your specific business needs. Our experts leverage Oracle's application development tools, frameworks, and APIs to extend the functionality of Oracle applications and integrate them with other enterprise systems.
                      </h5>
                    </div>
                  </div>
                </div>
                <div className=" card ">
                  <div className="card-body d-lg-flex d-md-flex">
                    <div className="">
                      {" "}
                      {/* <MdOutlineSecurity className="re-icon-three th-icon-three" /> */}
                      <img src={oracleicon3} className="oracle-icon-img" alt="oracle"/>
                    </div>
                    <div className="px-lg-3 px-md-3">
                      <h4 className="card-text-one">
                      Oracle Database Management
                      </h4>
                      <h5 className="card-text-two">
                      We offer comprehensive Oracle database management services, including database design, installation, configuration, performance tuning, and ongoing maintenance. Our experts ensure that your Oracle databases are optimized, secure, and available, enabling efficient data management and supporting critical business operations.
                      </h5>
                    </div>
                  </div>
                </div>
                <div className=" card ">
                  <div className="card-body d-lg-flex d-md-flex">
                    <div className="">
                      {" "}
                      {/* <MdOutlineSecurity className="re-icon-three th-icon-three" /> */}
                      <img src={oracleicon4} className="oracle-icon-img" alt="oracle"/>
                    </div>
                    <div className="px-lg-3 px-md-3">
                      <h4 className="card-text-one">
                      Oracle Cloud Services
                      </h4>
                      <h5 className="card-text-two">
                      We assist organizations in adopting Oracle Cloud services, including Oracle Cloud Infrastructure (OCI) and Oracle Cloud Applications. Our experts help you migrate to the cloud, design cloud architectures, deploy and configure cloud services, and provide ongoing support for your Oracle cloud environment.

                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
               </section>

               <section className="industrialmanu-new-design-2 py-5">
          <div className="container-fluid col-lg-10 mx-auto">
            <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 g-4 justify-content-center">
              <div className="col industrialmanu-new-card-1">
                <div class="card h-100">
                  <div class="card-body">
                    <img className="oracle-icon-img" src={oracleicon5} alt="oracle" />
                    <h5 class="automotive-new-text-1">Oracle E-Business Suite Services</h5>
                    <p class="automotive-new-text-3">We provide end-to-end services for Oracle E-Business Suite, including implementation, customization, upgrades, and ongoing support. Our experts have extensive experience in deploying and managing the various modules of Oracle E-Business Suite, helping you leverage its capabilities for improved business processes and efficiency.
</p>
                  </div>
                </div>
              </div>
              <div className="col industrialmanu-new-card-2">
                <div class="card h-100">
                  <div class="card-body">
                    <img className="oracle-icon-img" src={oracleicon6} alt="oracle" />
                    <h5 class="automotive-new-text-1">Oracle Fusion Middleware Services</h5>
                    <p class="automotive-new-text-3">We offer services related to Oracle Fusion Middleware, a comprehensive suite of middleware technologies. Our experts assist organizations in implementing, configuring, and integrating Oracle Fusion Middleware components, such as Oracle WebLogic Server, Oracle SOA Suite, and Oracle Identity and Access Management.
</p>
                  </div>
                </div>
              </div>
              <div className="col industrialmanu-new-card-3">
                <div class="card h-100">
                  <div class="card-body">
                    <img className="oracle-icon-img" src={oracleicon7} alt="oracle" />
                    <h5 class="automotive-new-text-1">Oracle Business Intelligence and Analytics</h5>
                    <p class="automotive-new-text-3">We help organizations harness the power of Oracle Business Intelligence (BI) and Analytics solutions to gain valuable insights from their data. Our experts design and implement Oracle BI solutions, develop customized reports and dashboards, and enable self-service analytics capabilities to support data-driven decision-making.
</p>
                  </div>
                </div>
              </div>
              <div className="col industrialmanu-new-card-4 mt-0">
                <div class="card h-100">
                  <div class="card-body">
                    <img className="oracle-icon-img" src={oracleicon8} alt="oracle" />
                    <h5 class="automotive-new-text-1">Oracle Application Support and Maintenance</h5>
                    <p class="automotive-new-text-3">We provide comprehensive support and maintenance services for Oracle applications and systems. Our experts offer ongoing monitoring, troubleshooting, bug fixing, performance optimization, and application enhancements to ensure the stability, security, and reliability of your Oracle environment.</p>
                  </div>
                </div>
              </div>
              <div className="col industrialmanu-new-card-5 mt-0">
                <div class="card h-100">
                  <div class="card-body">
                    <img className="oracle-icon-img" src={oracleicon9} alt="oracle" />
                    <h5 class="automotive-new-text-1">Oracle Training and Knowledge Transfer</h5>
                    <p class="automotive-new-text-3">We offer customized Oracle training programs to equip your teams with the necessary skills and knowledge to effectively manage and utilize Oracle technologies. Our training sessions cover various Oracle products and solutions, empowering your employees to leverage Oracle's capabilities to their fullest extent.
</p>
                  </div>
                </div>
              </div>
              <div className="col industrialmanu-new-card-6 mt-0">
                <div class="card h-100">
                  <div class="card-body">
                    <img className="oracle-icon-img" src={oracleicon10} alt="oracle" />
                    <h5 class="automotive-new-text-1">Oracle License and Asset Management</h5>
                    <p class="automotive-new-text-3">We assist organizations in managing their Oracle licenses and assets effectively. Our experts provide guidance on license optimization, compliance management, and cost optimization strategies to ensure that you are maximizing the value of your Oracle investments.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="logistic-last-section my-lg-5">
          <div className="container py-3">
            <div className="row flex-row-reverse">
              <div className="col-lg-4 col-md-4">
                <img src={logidis1ast} alt="oracle" />
              </div>
              <div className="col-lg-8 col-md-8">
                <div className="pt-lg-5">
                  <p className="">
                  At Upstrox, we combine our Oracle expertise with industry knowledge and best practices to deliver exceptional services that help organizations leverage Oracle technologies for improved efficiency, productivity, and business outcomes. 
                  </p>
                </div>
                <div>
                  <a className="new-ups-button-2" href="/contact">
                    <span className="new-ups-button-icon-3">Read More</span>{" "}
                    <BiRightArrowAlt className="new-ups-button-icon-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-text-section py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="contact-text-last mb-lg-4">
                Contact us today to discuss your Oracle requirements and explore how Upstrox can assist your organization in maximizing the value of your Oracle investments.
                </p>
              </div>
            </div>
          </div>
        </section>
            
            </div>
        </>
    )
}
export default Oracle;