import { MdOutlineArrowForwardIos } from "react-icons/md";
import Dummybg from "../../Assets/images/icon/consumer\ packaged\ goods/consumer-packaged-goods landing banner.webp";
import { BiRightArrowAlt } from "react-icons/bi";
import consumerlast from "../../Assets/imgs/consumer-last.webp";
import customer from "../../Assets/imgs/customer.webp";
import supply from "../../Assets/imgs/supply-chain.webp";
import product from "../../Assets/imgs/product-chain.webp";
import enterprise from "../../Assets/imgs/enterprise.webp";
import business from "../../Assets/imgs/business.webp";
import ecom from "../../Assets/imgs/e comerce.webp";
import trade from "../../Assets/imgs/trade promotion.webp";
import quality from "../../Assets/imgs/quality management.webp";
import data from "../../Assets/imgs/data intergration.webp";
import cyber from "../../Assets/imgs/cyber-security.webp";

function ConsumerPackaged() {
  return (
    <>
      <div className="consumerpackaged-banner-section">
        <section
          className="top-banner-section top-banner-section-overral-bg-section consumer-background-image back-posi-img"
          style={{ backgroundImage: "url(" + Dummybg + ")" }}
        >
          <div className="mobile-new-ups-bg-shade-inner mx-auto container-fluid">
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one">
                    Welcome to Upstrox - Your Partner for
                    <span className="text-color">
                      {" "}
                      Customer Packaged Goods{" "}
                    </span>{" "}
                    Solutions
                  </h1>
                  <div className="top-text-two">
                    {" "}
                    At Upstrox, we understand the unique challenges that
                    companies in the Customer Packaged Goods (CPG) industry
                    face. With ever-evolving market trends, increasing
                    competition, and changing consumer demands, it is crucial
                    for CPG companies to have robust and efficient systems in
                    place.
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <a href="/contact"><button className="top-button right-side">
                  Get More{" "}
                  <MdOutlineArrowForwardIos className="top-icon ms-lg-2" />
                </button></a>
              </div>
            </div>
          </div>
        </section>

        <section className="consumer-new-cards-section pt-5">
          <div className="container-fluid col-lg-10 position-relative pb-lg-5">
            <div className="row">
              {/* <div className="consumer-blur"></div> */}
              <div className="col-lg-4">
                <h5 className="aerospace-new-text-1">Customer Packaged Goods</h5>
                <p className="aerospace-new-text-2">
                  That's where our comprehensive range of services comes in.
                </p>
              </div>
              <div className="col-lg-8">
                <div className="d-lg-flex d-md-flex">
                  <div>
                    {" "}
                    <img
                      src={enterprise}
                      className="consum-img"
                      alt="consumerpackaged"
                    />
                  </div>
                  <div className="">
                    <h3 className="card-text-one">
                      Enterprise Resource Planning (ERP)
                    </h3>
                    <p className="card-text-two">
                      Our ERP solutions are tailored specifically for the CPG
                      industry. We provide end-to-end ERP implementation and
                      customization services to help you streamline your
                      business processes. From financial management and
                      inventory control to production planning and order
                      management, our ERP system will centralize your
                      operations, increase efficiency, and improve
                      decision-making.
                    </p>
                  </div>
                </div>
                <div className="d-lg-flex d-md-flex">
                  <div>
                    {" "}
                    <img
                      src={customer}
                      className="consum-img"
                      alt="consumerpackaged"
                    />
                  </div>
                  <div className="">
                    <h3 className="card-text-one">
                      Customer Relationship Management (CRM)
                    </h3>
                    <p className="card-text-two">
                      Building strong relationships with your customers is vital
                      for success in the CPG industry. Our CRM solutions enable
                      you to manage customer interactions, track sales
                      opportunities, and automate marketing campaigns. By
                      leveraging our CRM system, you can enhance customer
                      satisfaction, drive sales growth, and gain valuable
                      insights into consumer behavior.
                    </p>
                  </div>
                </div>
                <div className="d-lg-flex d-md-flex">
                  <div>
                    {" "}
                    <img
                      src={supply}
                      className="consum-img"
                      alt="consumerpackaged"
                    />
                  </div>
                  <div>
                    <h3 className="card-text-one">
                      Supply Chain Management (SCM)
                    </h3>
                    <p className="card-text-two">
                      Efficient supply chain management is crucial for CPG
                      companies to meet customer demands and maintain
                      competitive advantage. Our SCM solutions optimize your
                      supply chain processes, from procurement and inventory
                      management to logistics and distribution. With improved
                      visibility, collaboration, and real-time data analytics,
                      you can reduce costs, streamline operations, and enhance
                      customer satisfaction.
                    </p>
                  </div>
                </div>
                <div className="d-lg-flex d-md-flex">
                  <div>
                    {" "}
                    <img
                      src={product}
                      className="consum-img"
                      alt="consumerpackaged"
                    />
                  </div>
                  <div>
                    <h3 className="card-text-one">
                      Product Lifecycle Management (PLM)
                    </h3>
                    <p className="card-text-two">
                      Managing product lifecycles is a complex task for CPG
                      companies. Our PLM solutions provide end-to-end support
                      for your product development processes. From ideation and
                      design to manufacturing and maintenance, our PLM system
                      ensures efficient collaboration, version control, and
                      documentation. By streamlining your product lifecycles,
                      you can accelerate time-to-market and stay ahead of the
                      competition.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-5 con-card-two">
            <div className="row row-cols-lg-3 row-cols-1 row-cols-md-2  pb-lg-4">
              <div className="col con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={business}
                      className="con-icon-top"
                      alt="consumerpackaged"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                      Business Intelligence (BI) and Analytics
                    </h3>
                    <p className="card-text-two">
                      {" "}
                      Data-driven decision-making is crucial in the fast-paced
                      CPG industry. Our BI and analytics solutions empower you
                      to gain valuable insights from your data. We help you
                      collect, analyze, and visualize data from various sources,
                      enabling you to identify trends, optimize operations, and
                      make informed business decisions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={ecom}
                      className="con-icon-top"
                      alt="consumerpackaged"
                    />
                  </div>
                  <div className="pt-lg-4 ">
                    <h3 className="card-text-one">
                      E-commerce and Digital Marketing
                    </h3>
                    <p className="card-text-two">
                      In the digital age, having a strong online presence is
                      essential for CPG companies. Our e-commerce and digital
                      marketing solutions enable you to reach your target
                      audience, drive online sales, and build brand loyalty.
                      From developing user-friendly e-commerce platforms to
                      implementing effective digital marketing strategies, we
                      help you maximize your online potential.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={trade}
                      className="con-icon-top"
                      alt="consumerpackaged"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                      Trade Promotion Management (TPM)
                    </h3>
                    <p className="card-text-two">
                      Effective trade promotion management is crucial for CPG
                      companies to drive sales and increase market share. Our
                      TPM solutions help you plan, execute, and analyze trade
                      promotions. With our system, you can optimize trade spend,
                      improve promotional effectiveness, and gain insights into
                      ROI, ultimately boosting your sales and profitability.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col con-top con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={quality}
                      className="con-icon-top"
                      alt="consumerpackaged"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                      Quality Management Systems (QMS)
                    </h3>
                    <p className="card-text-two">
                      Maintaining product quality and ensuring compliance with
                      industry standards is vital for CPG companies. Our QMS
                      solutions provide you with the tools and processes to
                      manage quality control, track regulatory compliance, and
                      ensure product safety. By implementing our QMS system, you
                      can enhance product quality, minimize risks, and safeguard
                      your brand reputation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={data}
                      className="con-icon-top"
                      alt="consumerpackaged"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                      Data Integration and Middleware
                    </h3>
                    <p className="card-text-two">
                      Efficient data integration is key to streamlining
                      processes and enabling real-time information flow across
                      your organization. Our data integration and middleware
                      solutions ensure seamless data exchange between different
                      systems and applications. By integrating your systems, you
                      can enhance operational efficiency, improve data accuracy,
                      and enable better decision-making.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={cyber}
                      className="con-icon-top"
                      alt="consumerpackaged"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                      Cybersecurity and Data Protection
                    </h3>
                    <p className="card-text-two">
                      In today's digital landscape, protecting your sensitive
                      data and ensuring cybersecurity is of utmost importance.
                      Our cybersecurity and data protection solutions help you
                      safeguard your critical information, mitigate cyber
                      threats, and comply with data privacy regulations. With
                      our comprehensive security measures, you can minimize the
                      risk of data breaches and protect your reputation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="consumer-last-section my-lg-5">
          <div className="container py-5">
            <div className="row flex-row-reverse">
              <div className="col-lg-4 col-md-4">
                <img src={consumerlast} alt="consumerpackaged" />
              </div>
              <div className="col-lg-8 col-md-8">
                <div className="pt-lg-5 pt-md-5">
                  <p className="">
                    At Upstrox, we are committed to providing tailored and
                    innovative solutions to meet the specific needs of CPG
                    companies. With our expertise and industry knowledge, we
                    empower you to optimize your operations, drive growth, and
                    stay ahead of the competition.
                  </p>
                </div>
                <div>
                  <a className="new-ups-button-2" href="/contact">
                    <span className="new-ups-button-icon-3">Read More</span>{" "}
                    <BiRightArrowAlt className="new-ups-button-icon-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-text-section py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="contact-text-last mb-lg-4">
                  Contact us today to learn more about how we can help your CPG
                  business thrive in the dynamic marketplace.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default ConsumerPackaged;
