import {MdOutlineArrowForwardIos} from "react-icons/md";
import Utilitiesbg from "../../Assets/images/servies-images/utilities-industry.webp";
import retaillast from "../../Assets/images/icon/utilities/utilities image 1.webp";
import { BiRightArrowAlt } from "react-icons/bi";
import ContactNew from "../../Assets/images/icon/utilities/utilities image 2.webp";
import uti1 from "../../Assets/images/icon/utilities/Workforce Management Systems.webp";
import uti2 from "../../Assets/images/icon/utilities/Geographic Information Systems.webp";
import uti3 from "../../Assets/images/icon/utilities/Data Analytics and Business Intelligence.webp";
import uti4 from "../../Assets/images/icon/utilities/Regulatory Compliance and reporting.webp";
import uti5 from "../../Assets/images/icon/utilities/Cybersecurity and Data Protection.webp";
import uti6 from "../../Assets/images/icon/utilities/IT Consulting and Strategy.webp";


function Utilities(){
    return(
        <>
       <div className="utilities-bammer-section">
       <section className="top-banner-section top-banner-section-overral-bg-section back-posi-img" style={{ backgroundImage: "url(" + Utilitiesbg + ")" }}>
          <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
          <div className="container">
            <div className="row align-items-center">
              <div className="col">
                <h1 className="top-text-one">
                  <span className="text-color"> Utilities  </span>  IT Solutions by Upstrox
                </h1>
                <div className="top-text-two"> Upstrox is your trusted partner for comprehensive IT solutions tailored specifically for the utilities industry. We understand the unique challenges and requirements of utility companies, including operational efficiency, customer engagement, asset management, and regulatory compliance.  
                </div>
              </div>
            </div>
            <div className="pt-3">
            <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2"/></button></a>
              </div>
          </div>
          </div>
        </section>

        <section className="top-banner-section-two py-3">
          <div className="container">
            <div className="row">
              <div className="col">
                <h5 className="text-one">
                Our expertise in technology and domain knowledge enables us to deliver innovative solutions that improve service delivery, optimize resource utilization, and drive operational excellence. Here are the key IT services we offer
                </h5>
              </div>
            </div>
          </div>
        </section>

        <section className="datacard-section-three py-5">
                        <div className="container">
                            <div className="row py-2 g-4 pt-lg-4">
                                <div className="col-lg-6 col-md-6">
                                    <div className=" card text-white p-lg-4 h-100" style={{ background: "rgba(46, 46, 46, 0.199)" }}>
                                        <h4 className="card-text-one ps-3">
                                        Customer Information Systems (CIS)
                                        </h4>
                                        <h5 className="card-text-two px-3 text-ho">
                                        We provide robust CIS solutions that streamline customer management, billing, and service requests. Our CIS systems enable efficient customer data management, meter reading integration, rate calculation, and billing automation, enhancing customer satisfaction and revenue management.

                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className=" card text-white p-lg-4 h-100" style={{ background: "rgb(132 107 249)" }}>
                                        <h4 className="card-text-one ps-3">
                                        Advanced Metering Infrastructure (AMI)
                                        </h4>
                                        <h5 className="card-text-two px-3 text-ho">
                                        We offer AMI solutions that enable smart metering and remote monitoring of utility consumption. Our AMI systems provide real-time data collection, meter data analytics, and demand response capabilities, improving metering accuracy, operational efficiency, and energy management.

                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="row py-2 align-items-center row-reverse g-4">
                                <div className="col-lg-6 col-md-6">
                                    <div className=" card text-white p-lg-4 h-100" style={{ background: "rgb(53 195 97 / 77%)" }}>
                                        <h4 className="card-text-one ps-3">
                                        Outage Management Systems (OMS)
                                        </h4>
                                        <h5 className="card-text-two px-3 text-ho">
                                        We provide OMS solutions that optimize outage detection, response, and restoration processes. Our OMS systems enable real-time outage tracking, fault analysis, crew dispatching, and customer communication, minimizing downtime and improving customer service.
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className=" card text-white p-lg-4 h-100" style={{ background: "rgba(46, 46, 46, 0.199)" }}>
                                        <h4 className="card-text-one ps-3">
                                        Asset Management and Maintenance
                                        </h4>
                                        <h5 className="card-text-two px-3 text-ho">
                                        We offer asset management and maintenance solutions that optimize the lifecycle management of utility assets. Our solutions include asset tracking, maintenance planning, predictive maintenance, and condition monitoring, maximizing asset performance and minimizing downtime.

                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
         </section>

         <section>
         <div className="container-fluid mt-5 re-card-three col-lg-10 customercard-section-one">
            <div className="row flex-row-reverse">
              <div className="col-lg-5 col-md-4 d-flex align-items-center">
                <img src={retaillast} alt="utilities" style={{height:"auto"}} />
              </div>
              <div className="col-lg-7 col-md-8">
                <div className=" card ">
                  <div className="card-body d-lg-flex d-md-flex">
                    <div className="">
                      {" "}
                      <img src={uti1} className="customer-icon utilities-img" alt="utilities" />
                    </div>
                    <div className="px-lg-3 px-md-3">
                      <h4 className="card-text-one">
                      Workforce Management Systems
                      </h4>
                      <h5 className="card-text-two">
                      We provide workforce management systems that optimize field service operations and scheduling. Our systems enable efficient job dispatching, field resource allocation, scheduling optimization, and mobile workforce enablement, improving field productivity and customer response times.

                      </h5>
                    </div>
                  </div>
                </div>
                <div className=" card ">
                  <div className="card-body d-lg-flex d-md-flex">
                    <div className="">
                      {" "}
                      <img src={uti2} className="customer-icon utilities-img" alt="utilities" />
                    </div>
                    <div className="px-lg-3 px-md-3">
                      <h4 className="card-text-one">
                      Geographic Information Systems (GIS)
                      </h4>
                      <h5 className="card-text-two">
                      We offer GIS solutions that enable effective mapping, analysis, and management of utility assets and networks. Our GIS systems assist in asset location tracking, network planning, outage management, and regulatory compliance, enhancing operational efficiency and data accuracy.

                      </h5>
                    </div>
                  </div>
                </div>
                <div className=" card ">
                  <div className="card-body d-lg-flex d-md-flex">
                    <div className="">
                      {" "}
                      <img src={uti3} className="customer-icon utilities-img" alt="utilities"/>
                    </div>
                    <div className="px-lg-3 px-md-3">
                      <h4 className="card-text-one">
                      Data Analytics and Business Intelligence
                      </h4>
                      <h5 className="card-text-two">
                      Our data analytics and business intelligence solutions help utilities derive valuable insights from their data. We assist in analyzing consumption patterns, grid performance, demand forecasting, and revenue analytics, enabling data-driven decision-making, load optimization, and improved customer service.

                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         </section>

         <section className="customercard-section-one pb-5">
                        <div className="container-fluid col-lg-10">
                            <div className="row pt-5 justify-content-between align-items-center g-4">
                                <div className="col-lg-4 col-md-4">
                                    <div className="card text-white">
                                        <div className=" px-3 pt-2"><img src={uti4} className="customer-icon utilities-img" alt="utilities"/></div>
                                        <div className="py-3 ">
                                            <h4 className="card-text-one  px-3">Regulatory Compliance and Reporting</h4>
                                            <h5 className="card-text-two px-3">
                                            We provide solutions to help utilities comply with regulatory requirements and reporting obligations. Our solutions cover compliance monitoring, data validation, regulatory reporting, and audit trail management, ensuring adherence to industry standards and regulatory frameworks.

                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4" style={{ background: "rgba(46, 46, 46, 0.199)" }}>
                                    <div className="card text-white p-lg-4 p-md-4" >
                                        <div className=" px-3 pt-2"><img src={uti5} className="customer-icon utilities-img" alt="utilities"/></div>
                                        <div className="py-3 ">
                                            <h4 className="card-text-one  px-3">Cybersecurity and Data Protection</h4>
                                            <h5 className="card-text-two px-3">
                                            Protecting critical infrastructure, customer data, and operational systems is crucial in the utilities industry. We provide comprehensive cybersecurity services, including network security, access controls, data encryption, and vulnerability assessments. Our solutions help safeguard your valuable assets and protect against cyber threats.

                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 " >
                                    <div className="card text-white">
                                        <div className=" px-3 pt-2"><img src={uti6} className="customer-icon utilities-img" alt="utilities"/></div>
                                        <div className="py-3 ">
                                            <h4 className="card-text-one  px-3">IT Consulting and Strategy</h4>
                                            <h5 className="card-text-two px-3">
                                            Our experienced consultants provide IT consulting services to utilities companies, helping develop technology strategies, optimize workflows, and implement digital transformation initiatives. We assist in technology selection, vendor management, and leveraging emerging technologies to drive operational efficiency and sustainability.

                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


        <section className="logistic-last-section my-lg-5">
          <div className="container py-3">
            <div className="row flex-row-reverse">
              <div className="col-lg-4 col-md-4">
                <img src={ContactNew} alt="utilities" />
              </div>
              <div className="col-lg-8 col-md-8">
                <div className="pt-lg-5">
                  <p className="">
                  At Upstrox, we are committed to supporting utilities companies with innovative and reliable IT solutions. 
                  </p>
                </div>
                <div>
                  <a className="new-ups-button-2" href="/contact">
                    <span className="new-ups-button-icon-3">Read More</span>{" "}
                    <BiRightArrowAlt className="new-ups-button-icon-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-text-section py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="contact-text-last mb-lg-4">
                Contact us today to discuss your specific requirements and explore how Upstrox can empower your organization's operations, customer service, and sustainability goals with our industry-leading IT services.

                </p>
              </div>
            </div>
          </div>
        </section>


        </div>
        </>
    )
}
export default Utilities;