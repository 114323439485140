import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import {
  Pagination,
  Autoplay,
  Navigation,
  Mousewheel,
  FreeMode,
} from "swiper/modules";
import { BiRightArrowAlt } from "react-icons/bi";
import { SiCommerzbank, SiNginxproxymanager } from "react-icons/si";
import { IoMdContacts } from "react-icons/io";
import { MdTravelExplore } from "react-icons/md";
import { GiHealing } from "react-icons/gi";
import { LuMonitorSpeaker } from "react-icons/lu";
import { RiShakeHandsFill, RiShieldStarFill } from "react-icons/ri";
import { FaPodcast, FaIndustry } from "react-icons/fa";
import { DiYii } from "react-icons/di";
import { VscCommentDiscussion } from "react-icons/vsc";
import banner1 from "./Assets/images/upstrox-banner-1.webp";
import banner2 from "./Assets/images/upstrox-banner-2.webp";
import { useEffect } from "react";
// import MetaTags from "./Metatag";

function App() {
  

  return (
    <div className="App home-section">
      {/* <MetaTags
        top="Upstrox: Your Gateway to Cutting-Edge Digital Transformation"
        title="Upstrox: Setting New Standards in IT Solutions"
        description="Open the doors to best IT solutions with Upstrox. From top-notch solutions to flawless services, we redefine success in the digital age."
        keywords="Software development company in madurai, Software development services, IT Consulting services, IT Services & Technology Solutions, IT Services and Digital Solutions in India, Software development company, Custom Software Development, Software consulting companies, Software company in usa, Business intelligence services, IT services companies in usa, Software development consulting companies, Software development and consulting services, IT services firms, Digital transformation company in chennai, Digital transformation services, Digital transformation companies in usa"
      /> */}
      <section className="top-banner-new-swiper">
        <Swiper
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loop={true}
          pagination={{
            clickable: true,
          }}
          // navigation={true}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide className="swiper-slide-1-bg">
            <div className="mobile-new-ups-bg-shade">
              <div className="top-new-ups-fixed-postion-1"></div>
              <div className="container-fluid col-lg-10">
                <div className="row align-items-center top-new-ups-height-1">
                  <div className="col">
                    <h1 className="new-ups-text-1 mb-lg-5">
                      Upstrox{" "}
                      <span className="new-ups-text-2">
                        Unleashing Potential, Streamlining Technology,
                        Revolutionizing Outcomes Exceptionally
                      </span>{" "}
                      has the expertise and experience to help businesses
                      achieve their goals and stay competitive in today's
                      rapidly evolving business landscape.
                    </h1>
                    <a href="/contact" className="new-ups-button-1">
                      Know More{" "}
                      <BiRightArrowAlt className="new-ups-button-icon-1" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide-2-bg">
            <div className="mobile-new-ups-bg-shade">
              <div className="top-new-ups-fixed-postion-1"></div>
              <div className="container-fluid col-lg-10">
                <div className="row align-items-center top-new-ups-height-1">
                  <div className="col">
                    <h1 className="new-ups-text-1 mb-lg-5">
                      <span className="new-ups-text-2">
                        Discover the power of{" "}
                      </span>
                      Unleashing Potential{" "}
                      <span className="new-ups-text-2">
                        with our cutting-edge solutions, designed to elevate
                        your business to new heights.
                      </span>
                    </h1>
                    <a href="/contact" className="new-ups-button-1">
                      Know More{" "}
                      <BiRightArrowAlt className="new-ups-button-icon-1" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide-3-bg">
            <div className="mobile-new-ups-bg-shade">
              <div className="top-new-ups-fixed-postion-1"></div>
              <div className="container-fluid col-lg-10">
                <div className="row align-items-center top-new-ups-height-1">
                  <div className="col">
                    <h1 className="new-ups-text-1 mb-lg-5">
                      <span className="new-ups-text-2">
                        Unlock the true efficiency of your business operations
                        with our industry-leading
                      </span>{" "}
                      Streamlining Technology{" "}
                      <span className="new-ups-text-2">
                        to drive growth and success.
                      </span>
                    </h1>
                    <a href="/contact" className="new-ups-button-1">
                      Know More{" "}
                      <BiRightArrowAlt className="new-ups-button-icon-1" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="swiper-slide-4-bg">
            <div className="mobile-new-ups-bg-shade">
              <div className="top-new-ups-fixed-postion-1"></div>
              <div className="container-fluid col-lg-10">
                <div className="row align-items-center top-new-ups-height-1">
                  <div className="col">
                    <h1 className="new-ups-text-1 mb-lg-5">
                      <span className="new-ups-text-2">
                        Unlock the true efficiency of your business operations
                        with our industry-leading
                      </span>{" "}
                      Streamlining Technology{" "}
                      <span className="new-ups-text-2">
                        to drive growth and success.
                      </span>
                    </h1>
                    <a href="/contact" className="new-ups-button-1">
                      Know More{" "}
                      <BiRightArrowAlt className="new-ups-button-icon-1" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>

      <section className="top-banner-new-section-2">
        <div className="container">
          <div className="row align-items-center flex-lg-row-reverse g-4 justify-content-center">
            <div className="col-lg-6 col-8">
              <img src={banner1} alt="images" />
            </div>
            <div className="col-lg-6">
              <p className="new-ups-text-3 mb-lg-5 mb-4">
                Upstrox is a leading provider of technology services, offering a
                range of solutions to businesses across various industries. From
                network management to analytics, Upstrox has the expertise and
                experience to help businesses achieve your technology goals.
              </p>
              <a href="/contact" className="animated-button">
                {" "}
                <BiRightArrowAlt className="new-ups-button-icon-2 arr-2" />
                <span className="text">Read More</span>{" "}
                <span class="circle"></span>{" "}
                <BiRightArrowAlt className="new-ups-button-icon-2 arr-1" />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="top-banner-new-section-3 d-lg-block d-none">
        <div className="container px-lg-3 position-relative">
          <p className="text-center new-ups-head-1 mb-lg-5">
            Our Range of Solutions
          </p>
          <Swiper
            direction={"vertical"}
            // mousewheel={true}
            mousewheel={{
              forceToAxis: true,
              sensitivity: 0.5,
              // releaseOnEdges: false,
            }}
            autoHeight={true}
            slidesPerView={"auto"}
            pagination={{
              clickable: true,
            }}
            parallax={true}
            onReachEnd={(swiperCore) => {
              swiperCore.params.mousewheel.releaseOnEdges = true;
              console.log(
                swiperCore.params.mousewheel.releaseOnEdges,
                "swiperCore"
              );
            }}
            onReachBeginning={(swiperCore) => {
              swiperCore.params.mousewheel.releaseOnEdges = false;
              console.log(
                swiperCore.params.mousewheel.releaseOnEdges,
                "swiperCore"
              );
            }}
            modules={[Pagination, Mousewheel]}
            className="mySwiper212 swiperslide-container"
          >
            <SwiperSlide>
              <div className="card px-lg-4  py-lg-5 services-new-top-card-1">
                <div className="container">
                  <div class="row row-cols-1 row-cols-lg-3 g-4">
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "1.4s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">
                            Infrastructure and Cloud Services
                          </h5>
                          <p class="new-ups-text-5">
                            Upstrox provides comprehensive infrastructure and
                            cloud services to help organizations design,
                            implement, and manage their IT infrastructure. This
                            includes cloud migration, infrastructure
                            optimization, virtualization, disaster recovery
                            planning, and cloud platform management.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "1.6s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">Network Services</h5>
                          <p class="new-ups-text-5">
                            Upstrox offers network services to optimize and
                            secure your organization's network infrastructure.
                            This includes network design and implementation,
                            network monitoring and management, network security
                            solutions, and network performance optimization.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "1.8s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">
                            Integrated Engineering Solutions
                          </h5>
                          <p class="new-ups-text-5">
                            Upstrox provides integrated engineering solutions to
                            help organizations streamline their engineering
                            processes, improve product development, and enhance
                            operational efficiency. This includes CAD/CAM/CAE
                            services, product lifecycle management, engineering
                            simulation, and manufacturing automation.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "2s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">SAP</h5>
                          <p class="new-ups-text-5">
                            Upstrox offers SAP services to help organizations
                            implement, customize, and optimize their SAP
                            systems. This includes SAP implementation and
                            upgrades, system integration, business process
                            reengineering, and SAP support and maintenance.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "2.2s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">Data Analytics</h5>
                          <p class="new-ups-text-5">
                            Upstrox provides data analytics services to help
                            organizations extract insights from their data and
                            make data-driven decisions. This includes data
                            visualization, predictive analytics, data mining,
                            big data analytics, and business intelligence
                            solutions.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "2.4s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">Customer Experience</h5>
                          <p class="new-ups-text-5">
                            {" "}
                            Upstrox focuses on enhancing customer experiences
                            through its customer experience services. This
                            includes customer journey mapping, user experience
                            design, customer analytics, omni-channel customer
                            engagement, and personalized customer experiences.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="card px-lg-4  py-lg-5 services-new-top-card-1">
                <div className="container">
                  <div class="row row-cols-1 row-cols-lg-3 g-4">
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "1.4s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">
                            Sustainability as a Service
                          </h5>
                          <p class="new-ups-text-5">
                            Upstrox offers sustainability as a service to help
                            organizations integrate sustainable practices into
                            their operations. This includes sustainability
                            strategy development, energy efficiency solutions,
                            carbon footprint measurement, and sustainable supply
                            chain management.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "1.6s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">
                            Experience Design Service
                          </h5>
                          <p class="new-ups-text-5">
                            Upstrox provides experience design services to help
                            organizations create intuitive and engaging
                            experiences for their customers. This includes user
                            research, user interface design, interaction design,
                            and usability testing.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "1.8s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">Testing Services</h5>
                          <p class="new-ups-text-5">
                            Upstrox offers comprehensive testing services to
                            ensure the quality and reliability of software
                            applications. This includes functional testing,
                            performance testing, security testing, test
                            automation, and test management.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "2s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">
                            Performance Engineering
                          </h5>
                          <p class="new-ups-text-5">
                            {" "}
                            Upstrox specializes in performance engineering
                            services to optimize the performance and scalability
                            of software applications. This includes performance
                            testing, load testing, capacity planning,
                            performance tuning, and infrastructure optimization.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "2.2s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">Oracle</h5>
                          <p class="new-ups-text-5">
                            {" "}
                            Upstrox provides a range of services related to
                            Oracle technologies, including implementation,
                            customization, upgrades, and support for Oracle
                            applications and systems.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "2.4s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">
                            Artificial Intelligence
                          </h5>
                          <p class="new-ups-text-5">
                            Upstrox offers artificial intelligence services to
                            help organizations leverage AI technologies such as
                            machine learning, natural language processing, and
                            computer vision. This includes AI strategy
                            consulting, AI model development, and AI
                            integration.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="card px-lg-4  py-lg-5 services-new-top-card-1">
                <div className="container">
                  <div class="row row-cols-1 row-cols-lg-3 g-4">
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "1.4s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">Digital Supply Chain</h5>
                          <p class="new-ups-text-5">
                            Upstrox provides digital supply chain services to
                            help organizations optimize their supply chain
                            operations using technologies such as IoT, AI, and
                            blockchain. This includes supply chain visibility,
                            demand forecasting, inventory optimization, and
                            supply chain analytics.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "1.6s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">
                            Business Process Services
                          </h5>
                          <p class="new-ups-text-5">
                            Upstrox offers business process services to
                            streamline and optimize core business processes.
                            This includes process consulting, process
                            automation, document management, finance and
                            accounting services, and HR outsourcing.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "1.8s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">
                            Business Excellence Services
                          </h5>
                          <p class="new-ups-text-5">
                            Upstrox helps organizations achieve business
                            excellence through services such as process
                            improvement, quality management, risk management,
                            and performance measurement
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "2s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">
                            Telecom Product Engineering
                          </h5>
                          <p class="new-ups-text-5">
                            Upstrox provides telecom product engineering
                            services to help organizations develop and enhance
                            their telecom products and solutions. This includes
                            product development, testing, network management,
                            and telecom software development.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "2.2s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">
                            Enterprise of the Future
                          </h5>
                          <p class="new-ups-text-5">
                            {" "}
                            Upstrox assists organizations in embracing the
                            Enterprise of the Future through services that
                            enable digital transformation, agility, innovation,
                            and sustainable growth.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "2.4s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">Cybersecurity</h5>
                          <p class="new-ups-text-5">
                            {" "}
                            Upstrox offers cybersecurity services to protect
                            organizations from cyber threats. This includes
                            vulnerability assessments, penetration testing,
                            security audits, and security incident response.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="card px-lg-4  py-lg-5 services-new-top-card-1">
                <div className="container">
                  <div class="row row-cols-1 row-cols-lg-3 g-4">
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "1.4s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">Intelligent Automation</h5>
                          <p class="new-ups-text-5">
                            Upstrox helps organizations implement intelligent
                            automation solutions using technologies such as
                            robotic process automation (RPA), cognitive
                            automation, and process automation.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "1.6s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">Metaverse Development</h5>
                          <p class="new-ups-text-5">
                            Upstrox provides services for metaverse development,
                            including the creation of virtual reality (VR) and
                            augmented reality (AR) experiences, immersive
                            content development, and metaverse platform
                            integration.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "1.8s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">dApp and DeFi</h5>
                          <p class="new-ups-text-5">
                            Upstrox assists organizations in developing
                            decentralized applications (dApps) and decentralized
                            finance (DeFi) solutions on blockchain platforms,
                            enabling secure and transparent transactions and
                            smart contract functionality.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "2s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">
                            Smart Contract Development
                          </h5>
                          <p class="new-ups-text-5">
                            Upstrox specializes in developing smart contracts,
                            self-executing contracts with predefined terms and
                            conditions, using blockchain technology to automate
                            and secure transactions.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="card h-100 services-new-top-inner-card-1"
                        style={{ animationDuration: "2.2s" }}
                      >
                        <div class="card-body">
                          <h5 class="new-ups-text-4">
                            Blockchain & Blockchain Application
                          </h5>
                          <p class="new-ups-text-5">
                            Upstrox offers services related to blockchain
                            technology, including blockchain development,
                            implementation, and integration into various
                            business applications to enhance transparency,
                            security, and trust.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      <section className="d-lg-none d-block py-4">
        <div className="container ">
          <p className="text-center new-ups-head-1 mb-lg-5">
            Our Range of Solutions
          </p>
          <div className="row row-cols-1 g-4">
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "1.4s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">
                    Infrastructure and Cloud Services
                  </h5>
                  <p class="new-ups-text-5">
                    Upstrox provides comprehensive infrastructure and cloud
                    services to help organizations design, implement, and manage
                    their IT infrastructure. This includes cloud migration,
                    infrastructure optimization, virtualization, disaster
                    recovery planning, and cloud platform management.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "1.6s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">Network Services</h5>
                  <p class="new-ups-text-5">
                    Upstrox offers network services to optimize and secure your
                    organization's network infrastructure. This includes network
                    design and implementation, network monitoring and
                    management, network security solutions, and network
                    performance optimization.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "1.8s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">
                    Integrated Engineering Solutions
                  </h5>
                  <p class="new-ups-text-5">
                    Upstrox provides integrated engineering solutions to help
                    organizations streamline their engineering processes,
                    improve product development, and enhance operational
                    efficiency. This includes CAD/CAM/CAE services, product
                    lifecycle management, engineering simulation, and
                    manufacturing automation.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "2s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">SAP</h5>
                  <p class="new-ups-text-5">
                    Upstrox offers SAP services to help organizations implement,
                    customize, and optimize their SAP systems. This includes SAP
                    implementation and upgrades, system integration, business
                    process reengineering, and SAP support and maintenance.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "2.2s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">Data Analytics</h5>
                  <p class="new-ups-text-5">
                    Upstrox provides data analytics services to help
                    organizations extract insights from their data and make
                    data-driven decisions. This includes data visualization,
                    predictive analytics, data mining, big data analytics, and
                    business intelligence solutions.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "2.4s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">Customer Experience</h5>
                  <p class="new-ups-text-5">
                    {" "}
                    Upstrox focuses on enhancing customer experiences through
                    its customer experience services. This includes customer
                    journey mapping, user experience design, customer analytics,
                    omni-channel customer engagement, and personalized customer
                    experiences.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "1.4s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">Sustainability as a Service</h5>
                  <p class="new-ups-text-5">
                    Upstrox offers sustainability as a service to help
                    organizations integrate sustainable practices into their
                    operations. This includes sustainability strategy
                    development, energy efficiency solutions, carbon footprint
                    measurement, and sustainable supply chain management.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "1.6s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">Experience Design Service</h5>
                  <p class="new-ups-text-5">
                    Upstrox provides experience design services to help
                    organizations create intuitive and engaging experiences for
                    their customers. This includes user research, user interface
                    design, interaction design, and usability testing.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "1.8s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">Testing Services</h5>
                  <p class="new-ups-text-5">
                    Upstrox offers comprehensive testing services to ensure the
                    quality and reliability of software applications. This
                    includes functional testing, performance testing, security
                    testing, test automation, and test management.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "2s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">Performance Engineering</h5>
                  <p class="new-ups-text-5">
                    {" "}
                    Upstrox specializes in performance engineering services to
                    optimize the performance and scalability of software
                    applications. This includes performance testing, load
                    testing, capacity planning, performance tuning, and
                    infrastructure optimization.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "2.2s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">Oracle</h5>
                  <p class="new-ups-text-5">
                    {" "}
                    Upstrox provides a range of services related to Oracle
                    technologies, including implementation, customization,
                    upgrades, and support for Oracle applications and systems.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "2.4s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">Artificial Intelligence</h5>
                  <p class="new-ups-text-5">
                    Upstrox offers artificial intelligence services to help
                    organizations leverage AI technologies such as machine
                    learning, natural language processing, and computer vision.
                    This includes AI strategy consulting, AI model development,
                    and AI integration.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "1.4s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">Digital Supply Chain</h5>
                  <p class="new-ups-text-5">
                    Upstrox provides digital supply chain services to help
                    organizations optimize their supply chain operations using
                    technologies such as IoT, AI, and blockchain. This includes
                    supply chain visibility, demand forecasting, inventory
                    optimization, and supply chain analytics.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "1.6s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">Business Process Services</h5>
                  <p class="new-ups-text-5">
                    Upstrox offers business process services to streamline and
                    optimize core business processes. This includes process
                    consulting, process automation, document management, finance
                    and accounting services, and HR outsourcing.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "1.8s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">Business Excellence Services</h5>
                  <p class="new-ups-text-5">
                    Upstrox helps organizations achieve business excellence
                    through services such as process improvement, quality
                    management, risk management, and performance measurement
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "2s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">Telecom Product Engineering</h5>
                  <p class="new-ups-text-5">
                    Upstrox provides telecom product engineering services to
                    help organizations develop and enhance their telecom
                    products and solutions. This includes product development,
                    testing, network management, and telecom software
                    development.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "2.2s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">Enterprise of the Future</h5>
                  <p class="new-ups-text-5">
                    {" "}
                    Upstrox assists organizations in embracing the Enterprise of
                    the Future through services that enable digital
                    transformation, agility, innovation, and sustainable growth.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "2.4s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">Cybersecurity</h5>
                  <p class="new-ups-text-5">
                    {" "}
                    Upstrox offers cybersecurity services to protect
                    organizations from cyber threats. This includes
                    vulnerability assessments, penetration testing, security
                    audits, and security incident response.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "1.4s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">Intelligent Automation</h5>
                  <p class="new-ups-text-5">
                    Upstrox helps organizations implement intelligent automation
                    solutions using technologies such as robotic process
                    automation (RPA), cognitive automation, and process
                    automation.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "1.6s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">Metaverse Development</h5>
                  <p class="new-ups-text-5">
                    Upstrox provides services for metaverse development,
                    including the creation of virtual reality (VR) and augmented
                    reality (AR) experiences, immersive content development, and
                    metaverse platform integration.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "1.8s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">dApp and DeFi</h5>
                  <p class="new-ups-text-5">
                    Upstrox assists organizations in developing decentralized
                    applications (dApps) and decentralized finance (DeFi)
                    solutions on blockchain platforms, enabling secure and
                    transparent transactions and smart contract functionality.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "2s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">Smart Contract Development</h5>
                  <p class="new-ups-text-5">
                    Upstrox specializes in developing smart contracts,
                    self-executing contracts with predefined terms and
                    conditions, using blockchain technology to automate and
                    secure transactions.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="card h-100 services-new-top-inner-card-1"
                style={{ animationDuration: "2.2s" }}
              >
                <div class="card-body">
                  <h5 class="new-ups-text-4">
                    Blockchain & Blockchain Application
                  </h5>
                  <p class="new-ups-text-5">
                    Upstrox offers services related to blockchain technology,
                    including blockchain development, implementation, and
                    integration into various business applications to enhance
                    transparency, security, and trust.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="top-banner-new-section-4 py-5">
        <div className="container">
          <div className="row">
            <p className="text-center new-ups-head-1 mb-lg-5">
              Serving Sectors
            </p>
            <div className="col">
              <Swiper
                navigation={true}
                pagination={{
                  clickable: true,
                }}
                keyboard={true}
                breakpoints={{
                  920: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                modules={[FreeMode, Navigation]}
                className="mySwiper"
              >
                <SwiperSlide className="">
                  <div class="card h-100 services-new-top-inner-card-2">
                    <div class="card-body">
                      <VscCommentDiscussion className="services-new-top-inner-card-2-icon-1 mb-3" />
                      <h5 class="new-ups-text-6">Communications</h5>
                      <p class="new-ups-text-7">
                        Upstrox can help communications companies manage their
                        networks, optimize performance, and improve customer
                        experience. With Upstrox's network management and
                        performance management services, communications
                        companies can ensure their networks are running smoothly
                        and efficiently.{" "}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="">
                  <div class="card h-100 services-new-top-inner-card-2">
                    <div class="card-body">
                      <SiCommerzbank className="services-new-top-inner-card-2-icon-1 mb-3" />
                      <h5 class="new-ups-text-6">
                        Banking | Financial services & Insurance
                      </h5>
                      <p class="new-ups-text-7">
                        {" "}
                        Upstrox assists financial institutions and insurance
                        companies manage their IT infrastructure, maintain data
                        security, and improve customer experience. With
                        Upstrox's BSS/OSS managed services and analytics
                        solutions, financial institutions can streamline their
                        operations and improve their bottom line.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="">
                  <div class="card h-100 services-new-top-inner-card-2">
                    <div class="card-body">
                      <DiYii className="services-new-top-inner-card-2-icon-1 mb-3" />
                      <h5 class="new-ups-text-6">Energy and Utilities</h5>
                      <p class="new-ups-text-7">
                        {" "}
                        Upstrox can help energy and utility companies manage
                        their assets, optimize resource usage, and reduce
                        downtime. With Upstrox's industrial IoT and analytics
                        solutions, energy and utility companies can monitor
                        their infrastructure and predict failures before they
                        occur.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="">
                  <div class="card h-100 services-new-top-inner-card-2">
                    <div class="card-body">
                      <FaPodcast className="services-new-top-inner-card-2-icon-1 mb-3" />
                      <h5 class="new-ups-text-6">
                        Public sector and Government
                      </h5>
                      <p class="new-ups-text-7">
                        Upstrox collaborates government agencies and public
                        sector organizations to deliver better services,
                        increase transparency, and engage citizens. With
                        Upstrox's e-government and digital identity solutions,
                        government agencies can improve service delivery and
                        enhance citizen engagement.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="">
                  <div class="card h-100 services-new-top-inner-card-2">
                    <div class="card-body">
                      <GiHealing className="services-new-top-inner-card-2-icon-1 mb-3" />
                      <h5 class="new-ups-text-6">Healthcare | Life Science</h5>
                      <p class="new-ups-text-7">
                        Upstrox works with healthcare and life science companies
                        to improve patient outcomes, reduce costs, and comply
                        with regulations. With Upstrox's analytics and content
                        distribution solutions, healthcare and life science
                        companies can analyze patient data and deliver
                        personalized medicine.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="">
                  <div class="card h-100 services-new-top-inner-card-2">
                    <div class="card-body">
                      <LuMonitorSpeaker className="services-new-top-inner-card-2-icon-1 mb-3" />
                      <h5 class="new-ups-text-6">High Tech</h5>
                      <p class="new-ups-text-7">
                        {" "}
                        Upstrox empowers high tech companies to stay ahead of
                        the curve with innovative technology solutions. With
                        Upstrox's network-as-a-service and content monetization
                        services, high tech companies can deliver cutting-edge
                        services and stay competitive.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="">
                  <div class="card h-100 services-new-top-inner-card-2">
                    <div class="card-body">
                      <RiShakeHandsFill className="services-new-top-inner-card-2-icon-1 mb-3" />
                      <h5 class="new-ups-text-6">Professional services</h5>
                      <p class="new-ups-text-7">
                        Upstrox enables professional services companies
                        streamline their operations, manage projects, and
                        improve customer experience. With Upstrox's workforce
                        management and service fulfillment solutions,
                        professional services companies can improve efficiency
                        and deliver better services to their clients.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="">
                  <div class="card h-100 services-new-top-inner-card-2">
                    <div class="card-body">
                      <SiNginxproxymanager className="services-new-top-inner-card-2-icon-1 mb-3" />
                      <h5 class="new-ups-text-6">Manufacturing</h5>
                      <p class="new-ups-text-7">
                        Upstrox offers solutions for manufacturing companies to
                        optimize production processes, reduce costs, and improve
                        quality. With Upstrox's network inventory management and
                        performance management solutions, manufacturing
                        companies can monitor their production processes and
                        ensure they are running efficiently.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="">
                  <div class="card h-100 services-new-top-inner-card-2">
                    <div class="card-body">
                      <RiShieldStarFill className="services-new-top-inner-card-2-icon-1 mb-3" />
                      <h5 class="new-ups-text-6">Private Equity</h5>
                      <p class="new-ups-text-7">
                        Upstrox can help private equity firms analyze investment
                        opportunities, manage portfolio companies, and drive
                        value. With Upstrox's analytics and performance
                        management solutions, private equity firms can identify
                        value drivers and optimize operations.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="">
                  <div class="card h-100 services-new-top-inner-card-2">
                    <div class="card-body">
                      <IoMdContacts className="services-new-top-inner-card-2-icon-1 mb-3" />
                      <h5 class="new-ups-text-6">Retail and consumer goods</h5>
                      <p class="new-ups-text-7">
                        Upstrox provides support for retail and consumer goods
                        companies to improve customer experience and supply
                        chain management. With Upstrox's content distribution
                        and analytics solutions, retail and consumer goods
                        companies can analyze customer data and deliver
                        personalized experiences.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="">
                  <div class="card h-100 services-new-top-inner-card-2">
                    <div class="card-body">
                      <MdTravelExplore className="services-new-top-inner-card-2-icon-1 mb-3" />
                      <h5 class="new-ups-text-6">
                        Travel, Transportation, Hospitality and Logistics
                      </h5>
                      <p class="new-ups-text-7">
                        {" "}
                        Upstrox works with travel, transportation, hospitality,
                        and logistics companies to manage their operations,
                        reduce costs, and improve customer experience. With
                        Upstrox's live operations and order management
                        solutions, these companies can improve operational
                        efficiency and deliver better services to their
                        customers.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="">
                  <div class="card h-100 services-new-top-inner-card-2">
                    <div class="card-body">
                      <FaIndustry className="services-new-top-inner-card-2-icon-1 mb-3" />
                      <h5 class="new-ups-text-6">Oil & Gas</h5>
                      <p class="new-ups-text-7">
                        {" "}
                        Upstrox empowers oil and gas companies to manage their
                        exploration and production operations, optimize resource
                        usage, and reduce downtime. With Upstrox's industrial
                        IoT and performance management solutions, oil and gas
                        companies can monitor their infrastructure and predict
                        failures before they occur.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>

      <section className="top-banner-new-section-2">
        <div className="container">
          <div className="row align-items-center justify-content-center ">
            <div className="col-lg-6 col-8 ms-lg-auto">
              <img src={banner2} alt="images" />
            </div>
            <div className="col-lg-6 text-lg-end">
              <p className="new-ups-text-3 mb-lg-5 mb-4 ms-auto">
                No matter what industry your business is in, Upstrox has the
                expertise and technology solutions to help you achieve your
                business goals.
              </p>
              <div className="justify-content-end d-flex">
                {" "}
                <a href="/contact" className="animated-button">
                  {" "}
                  <BiRightArrowAlt className="new-ups-button-icon-2 arr-2" />
                  <span className="text">Read More</span>{" "}
                  <span class="circle"></span>{" "}
                  <BiRightArrowAlt className="new-ups-button-icon-2 arr-1" />
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
