import { MdOutlineArrowForwardIos } from "react-icons/md";
import Dummybg from "../../Assets/images/servies-images/demo/dummy-1.webp";

import ContactNew from "../../Assets/images/servies-images/metaverse development webp/images/last image copy.webp"


import { BiRightArrowAlt } from 'react-icons/bi';
import metaicon1 from "../../Assets/images/servies-images/metaverse development webp/icon 1/Metaverse Strategy and Consulting.webp";
import metaicon2 from "../../Assets/images/servies-images/metaverse development webp/icon 1/Virtual Reality (VR) Development.webp";
import metaicon3 from "../../Assets/images/servies-images/metaverse development webp/icon 1/Augmented Reality (AR) Development.webp";
import metaicon4 from "../../Assets/images/servies-images/metaverse development webp/icon 1/Mixed Reality (MR) Development.webp";

import metaicon5 from "../../Assets/images/servies-images/metaverse development webp/icon 2/3D Modeling and Animation.webp";
import metaicon6 from "../../Assets/images/servies-images/metaverse development webp/icon 2/Metaverse Integration.webp";
import metaicon7 from "../../Assets/images/servies-images/metaverse development webp/icon 2/Metaverse Analytics and Insights.webp";

import metaicon8 from "../../Assets/images/servies-images/metaverse development webp/icon 3/Social and Community Experiences.webp";
import metaicon9 from "../../Assets/images/servies-images/metaverse development webp/icon 3/Metaverse Security and Privacy.webp";
import metaicon10 from "../../Assets/images/servies-images/metaverse development webp/icon 3/Metaverse Analytics and Insights.webp";
import { useEffect } from "react";


function MetaverseDevelopment() {
   
    return (
        <>
            <div className="metaversedevelopment">
                <section className="top-banner-section top-banner-section-overral-bg-section back-posi-img metaverse-background">
                    <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1 className="top-text-one"><span className="text-color">Metaverse Development</span> by Upstrox.</h1>
                                    <div className="top-text-two">Upstrox is at the forefront of Metaverse development, providing innovative solutions that enable organizations to create immersive and interactive digital experiences within the Metaverse. The Metaverse represents a virtual shared space where users can interact with each other and digital content in real-time.</div>
                                </div>
                            </div>
                            <div className="pt-3">
                               <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="aerospace-new-design-1 py-5">
                <div className="container-fluid col-lg-11 mx-auto">
                    <div className="row">
                    <div className="col-lg-4">
                        <h2 className="aerospace-new-text-1 mb-lg-4 mb-3">Metaverse Development by Upstrox</h2>
                        <p className="aerospace-new-text-2">Our Metaverse development services encompass a range of areas, including virtual reality (VR), augmented reality (AR), mixed reality (MR), and 3D modeling. Here's how Upstrox can assist you in Metaverse development</p>
                    </div>
                    <div className="col-lg-8">
                        <div className="row row-cols-md-2 row-cols-1 row-cols-lg-2 g-4">
                        <div className="col">
                            <img src={metaicon1} alt="metaversedevelopment" className="aerospace-new-image-1" />
                            <h5 className="aerospace-new-text-3">Metaverse Strategy and Consulting</h5>
                            <p className="aerospace-new-text-4">Our experienced consultants work closely with your organization to develop a Metaverse strategy aligned with your business objectives. We assess your needs, identify Metaverse use cases, and provide guidance on Metaverse development, integration, and monetization opportunities.</p>
                        </div>
                        <div className="col">
                            <img src={metaicon2} alt="metaversedevelopment" className="aerospace-new-image-1" />
                            <h5 className="aerospace-new-text-3">Virtual Reality (VR) Development</h5>
                            <p className="aerospace-new-text-4">We specialize in VR development to create immersive and engaging experiences within the Metaverse. Our experts design and develop VR applications, simulations, and training experiences that leverage the power of VR technology to transport users to virtual worlds.</p>
                        </div>
                        <div className="col">
                            <img src={metaicon3} alt="metaversedevelopment" className="aerospace-new-image-1" />
                            <h5 className="aerospace-new-text-3">Augmented Reality (AR) Development</h5>
                            <p className="aerospace-new-text-4">We offer AR development services to enhance the real world with digital overlays and interactive elements within the Metaverse. Our experts create AR applications, mobile AR experiences, and AR-enabled marketing campaigns that blend physical and digital environments seamlessly.</p>
                        </div>
                        <div className="col">
                            <img src={metaicon4} alt="metaversedevelopment" className="aerospace-new-image-1" />
                            <h5 className="aerospace-new-text-3">Mixed Reality (MR) Development</h5>
                            <p className="aerospace-new-text-4">We provide MR development services that combine virtual and real-world elements to create immersive experiences within the Metaverse. Our experts leverage MR technologies, such as Microsoft HoloLens, to develop MR applications for training, visualization, and collaboration.</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>

                <section className="industrialmanu-new-design-2 py-5">
                <div className="container-fluid col-lg-10 mx-auto">
                    <div className="row row-cols-1 row-cols-lg-3 g-4 justify-content-center">
                    
                    <div className="col industrialmanu-new-card-4">
                        <div class="card h-100">
                        <div class="card-body">
                            <img className="industrialmanu-icon-1" src={metaicon5} alt="metaversedevelopment" />
                            <h5 class="automotive-new-text-1">3D Modeling and Animation</h5>
                            <p class="automotive-new-text-3">We specialize in 3D modeling and animation to create realistic and immersive virtual environments within the Metaverse. Our experts design and develop 3D models, textures, and animations that bring digital content to life, enhancing user experiences and interactions.</p>
                        </div>
                        </div>
                    </div>
                    <div className="col industrialmanu-new-card-5">
                        <div class="card h-100">
                        <div class="card-body">
                            <img className="industrialmanu-icon-1" src={metaicon6} alt="metaversedevelopment" />
                            <h5 class="automotive-new-text-1">Metaverse Integration</h5>
                            <p class="automotive-new-text-3">We assist organizations in integrating their existing digital assets, platforms, and applications with the Metaverse. Our experts ensure seamless data flow, interoperability, and user experiences across different Metaverse platforms, social networks, and virtual worlds.</p>
                        </div>
                        </div>
                    </div>
                    <div className="col industrialmanu-new-card-6">
                        <div class="card h-100">
                        <div class="card-body">
                            <img className="industrialmanu-icon-1" src={metaicon7} alt="metaversedevelopment" />
                            <h5 class="automotive-new-text-1">Virtual Commerce and Transactions</h5>
                            <p class="automotive-new-text-3">We help organizations establish virtual commerce capabilities within the Metaverse. Our experts assist in developing secure and user-friendly virtual marketplaces, in-app purchases, and virtual currency systems to enable transactions and monetization opportunities.</p>
                        </div>
                        </div>
                    </div>

                    </div>
                </div>
                </section>

                <section className="aerospace-new-design-2 py-5">
                <div className="container-fluid col-lg-11 mx-auto">
                    <div className="row row-cols-md-2 row-cols-1 row-cols-lg-3 g-4 py-lg-3 justify-content-center">
                    <div className="col aerospace-new-design-2-inner-1">
                        <img src={metaicon8} alt="metaversedevelopment" className="aerospace-new-image-2" />
                        <h5 className="aerospace-new-text-3">Social and Community Experiences</h5>
                        <p className="aerospace-new-text-4">We create social and community experiences within the Metaverse, allowing users to connect, communicate, and collaborate. Our experts develop virtual social networks, multiplayer environments, and shared spaces that foster interaction and engagement.</p>
                    </div>
                    <div className="col aerospace-new-design-2-inner-2">
                        <img src={metaicon9} alt="metaversedevelopment" className="aerospace-new-image-2" />
                        <h5 className="aerospace-new-text-3">Metaverse Security and Privacy</h5>
                        <p className="aerospace-new-text-4">We prioritize security and privacy within the Metaverse. Our experts implement robust security measures, identity management systems, and data protection protocols to ensure user trust and safeguard sensitive information within virtual environments.</p>
                    </div>
                    <div className="col aerospace-new-design-2-inner-3">
                        <img src={metaicon10} alt="metaversedevelopment" className="aerospace-new-image-2" />
                        <h5 className="aerospace-new-text-3">Metaverse Analytics and Insights</h5>
                        <p className="aerospace-new-text-4">We provide analytics and insights within the Metaverse to help organizations understand user behavior, engagement patterns, and content performance. Our experts utilize data analytics tools and metrics to provide actionable insights for continuous optimization and user-driven innovation.</p>
                    </div>
                    </div>
                </div>
                </section>

                <section className="aerospace-new-design-2 py-5">
                <div className="container">
                    <div className="row g-4 flex-lg-row-reverse">
                    <div className="col-lg-4">
                        <img src={ContactNew} alt="metaversedevelopment" />
                    </div>
                    <div className="col-lg-8">
                        <p className="aerospace-new-text-5 mb-lg-4">At Upstrox, we are committed to pushing the boundaries of Metaverse development and helping organizations unlock the full potential of this immersive digital realm.</p>
                        <a href='/contact' className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
                    </div>
                    </div>
                </div>
                </section>

                <section className="aerospace-new-design-2 py-5">
                <div className="container">
                    <div className="row g-4 justify-content-center text-center">
                    <div className="col-lg-12">
                        <p className="aerospace-new-text-6 mb-lg-4">Contact us today to discuss your Metaverse development requirements and explore how Upstrox can empower your organization with our industry-leading Metaverse development services.</p>
                    </div>
                    </div>
                </div>
                </section>
                    </div>
        </>
    )
}
export default MetaverseDevelopment;