import { MdOutlineArrowForwardIos } from "react-icons/md";
import React, { useRef, useState, useEffect } from 'react';
import { Virtual, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import ContactNew from "../../Assets/images/servies-images/Cybersecurity Solutions webp/image/last image copy.webp"

import { BiRightArrowAlt } from 'react-icons/bi';
import cybericon1 from "../../Assets/images/servies-images/Cybersecurity Solutions webp/icon 1/Cybersecurity Strategy and Consulting.webp";
import cybericon2 from "../../Assets/images/servies-images/Cybersecurity Solutions webp/icon 1/Risk Assessment and Vulnerability Management.webp";
import cybericon3 from "../../Assets/images/servies-images/Cybersecurity Solutions webp/icon 1/Security Architecture and Design.webp";
import cybericon4 from "../../Assets/images/servies-images/Cybersecurity Solutions webp/icon 1/Threat Intelligence and Monitoring.webp";

import cybericon5 from "../../Assets/images/servies-images/Cybersecurity Solutions webp/icon2/Security Operations Center.webp";
import cybericon6 from "../../Assets/images/servies-images/Cybersecurity Solutions webp/icon2/Identity and Access Management (IAM) 2.webp";
import cybericon7 from "../../Assets/images/servies-images/Cybersecurity Solutions webp/icon2/Data Loss Prevention (DLP).webp";
import cybericon8 from "../../Assets/images/servies-images/Cybersecurity Solutions webp/icon2/Incident Response and Forensics.webp";
import cybericon9 from "../../Assets/images/servies-images/Cybersecurity Solutions webp/icon2/Employee Security Awareness Training.webp";
import cybericon10 from "../../Assets/images/servies-images/Cybersecurity Solutions webp/icon2/Compliance and Regulatory Support.webp";
import cybericon11 from "../../Assets/images/servies-images/Cybersecurity Solutions webp/icon2/Network Security.webp";
import cybericon12 from "../../Assets/images/servies-images/Cybersecurity Solutions webp/icon2/Endpoint Protection.webp";
import cybericon13 from "../../Assets/images/servies-images/Cybersecurity Solutions webp/icon2/Cloud Security.webp";
import cybericon14 from "../../Assets/images/servies-images/Cybersecurity Solutions webp/icon2/Identity and Access Management (IAM).webp";
import cybericon15 from "../../Assets/images/servies-images/Cybersecurity Solutions webp/icon2/Data Security and Encryption.webp";
import cybericon16 from "../../Assets/images/servies-images/Cybersecurity Solutions webp/icon2/Security Incident and Event Management (SIEM).webp";

import cybericon17 from "../../Assets/images/servies-images/Cybersecurity Solutions webp/icon 3/Security Awareness Training.webp";
import cybericon18 from "../../Assets/images/servies-images/Cybersecurity Solutions webp/icon 3/Vulnerability Management.webp";
import cybericon19 from "../../Assets/images/servies-images/Cybersecurity Solutions webp/icon 3/Security Governance and Compliance.webp";
import cybericon20 from "../../Assets/images/servies-images/Cybersecurity Solutions webp/icon 3/Incident Response and Cybersecurity Consulting.webp";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import $ from "jquery"
import Dummybg from "../../Assets/images/servies-images/demo/dummy-2.webp"
import MetaTags from "../../Metatag";


function CyberSecurity() {
    const [swiperRef, setSwiperRef] = useState(null);


    useEffect(() => {
        let totalTabsCount = 0;
        let activeTabIndex = 1;
        let tabChangeTimeout = 4000;

        totalTabsCount = $("#v-pills-tab .nav-link").length;

        function tabChangeHandler() {
            if (activeTabIndex == totalTabsCount) {
                activeTabIndex = 1;
            } else {
                activeTabIndex++;
            }
            console.log(activeTabIndex, "activeTabIndex")

            $("#v-pills-tab .nav-link")
                .eq(parseInt(activeTabIndex - 1))
                .trigger("click");
        }

        let AUTO_CHANGE_TIMER = setInterval(tabChangeHandler, tabChangeTimeout);

        $(".chemicalmanufacturing-new-design-2-inner-2 .card-body").click(function () {
            clearInterval(AUTO_CHANGE_TIMER);
        });

    });

    const slideTo = (index) => {
        swiperRef.slideTo(index - 1, 0);
    };

    
      
    return (
        <>
          {/* <MetaTags
        top="Maximize Your Safety with Upstrox Cybersecurity Solutions"
        title="Upstrox Cybersecurity Solutions for Ultimate Protection"
        description="Upstrox's advanced cybersecurity solutions defend your online presence with top-notch protection and provide guard against threats. "
      /> */}
         
            <div className="cybersecurity">
                <section className="top-banner-section top-banner-section-overral-bg-section cybersecurity-background back-posi-img" >
                    <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1 className="top-text-one"><span className="text-color">Cybersecurity</span> Solutions by Upstrox.</h1>
                                    <div className="top-text-two">At Upstrox, we understand the importance of cybersecurity in today's digital landscape. We offer a wide range of cybersecurity solutions designed to protect organizations from evolving cyber threats and ensure the confidentiality, integrity, and availability of their critical assets.</div>
                                </div>
                            </div>
                            <div className="pt-3">
                                <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="top-banner-section-two pb-4 pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h5 className="text-one">
                                    Our team of cybersecurity experts brings extensive knowledge and expertise to help organizations build robust security postures. Here are the key cybersecurity solutions we provide
                                </h5>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="chemicalmanufacturing-new-design-1 py-5">
                    <div className="container-fluid col-lg-11 mx-auto py-lg-5">
                        <div className="row row-cols-md-2 row-cols-1 row-cols-lg-2 g-4">
                            <div className="col mt- card h-1000 chemicalmanufacturing-top-new-card-1">
                                <div class="card bg-transparent text-white border-0 p-lg-3">
                                    <div class="card-body">
                                        <img src={cybericon1} alt="cybersecurity" className="aerospace-new-image-2" />
                                        <h5 className="aerospace-new-text-3">Cybersecurity Strategy and Consulting</h5>
                                        <p className="aerospace-new-text-4">Our cybersecurity consultants work closely with your organization to develop a cybersecurity strategy tailored to your specific needs. We assess your current security posture, identify potential risks and threats, and provide strategic recommendations to enhance your overall cybersecurity resilience.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col mt-0 chemicalmanufacturing-top-new-card-2">
                                <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                                    <div class="card-body">
                                        <img src={cybericon2} alt="cybersecurity" className="aerospace-new-image-2" />
                                        <h5 className="aerospace-new-text-3">Risk Assessment and Vulnerability Management</h5>
                                        <p className="aerospace-new-text-4">We conduct comprehensive risk assessments and vulnerability scans to identify potential security gaps and weaknesses in your systems, networks, and applications. Our experts analyze the results and provide prioritized recommendations for remediation and risk mitigation.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col mt-0 chemicalmanufacturing-top-new-card-3">
                                <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                                    <div class="card-body">
                                        <img src={cybericon3} alt="cybersecurity" className="aerospace-new-image-2" />
                                        <h5 className="aerospace-new-text-3">Security Architecture and Design</h5>
                                        <p className="aerospace-new-text-4">We assist organizations in designing and implementing robust security architectures that align with industry best practices and regulatory requirements. Our experts develop secure network designs, implement firewalls, intrusion detection systems, and access controls to safeguard your infrastructure.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col mt-0 chemicalmanufacturing-top-new-card-4">
                                <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                                    <div class="card-body">
                                        <img src={cybericon4} alt="cybersecurity" className="aerospace-new-image-2" />
                                        <h5 className="aerospace-new-text-3">Threat Intelligence and Monitoring</h5>
                                        <p className="aerospace-new-text-4">We provide threat intelligence services that involve monitoring and analyzing the threat landscape to identify potential risks and emerging threats. Our experts utilize advanced threat intelligence tools and techniques to proactively detect and respond to security incidents.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="chemicalmanufacturing-new-design-2 py-5 d-lg-none d-block">
                    <div className="container-fluid col-lg-11 mx-auto py-lg-5">

                        <div className="row">
                            <div className="col-lg-12 ">
                                <Swiper
                                    loop={true}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    onSwiper={setSwiperRef}
                                    keyboard={true}
                                    breakpoints={{
                                        920: {
                                            slidesPerView: 1,
                                            spaceBetween: 10,
                                        },
                                        768: {
                                            slidesPerView: 1,
                                            spaceBetween: 40,
                                        },
                                        1024: {
                                            slidesPerView: 1,
                                            spaceBetween: 30,
                                        },
                                    }}

                                    modules={[Autoplay]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide className="swiper-slide-card swiper-slide-card-1">
                                        <div className="card h-lg-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon5} alt="cybersecurity" className="chemicalmanu-new-image-2" />
                                                <h3 className="aerospace-new-text-3">Security Operations Center (SOC) Services</h3>
                                                <p className="aerospace-new-text-4 ">We offer managed security services through our Security Operations Center (SOC). Our SOC experts monitor your networks, systems, and applications 24/7, detect and respond to security incidents, and provide timely threat intelligence and incident reporting.</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="swiper-slide-card swiper-slide-card-2">
                                        <div className="card h-lg-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon6} alt="cybersecurity" className="chemicalmanu-new-image-2" />
                                                <h3 className="aerospace-new-text-3">Identity and Access Management (IAM)</h3>
                                                <p className="aerospace-new-text-4 ">We help organizations implement robust identity and access management solutions to control user access and privileges. Our experts design and deploy IAM frameworks, including user provisioning, authentication, single sign-on (SSO), and role-based access controls.</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="swiper-slide-card swiper-slide-card-3">
                                        <div className="card h-lg-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon7} alt="cybersecurity" className="chemicalmanu-new-image-2" />
                                                <h3 className="aerospace-new-text-3">Data Loss Prevention (DLP)</h3>
                                                <p className="aerospace-new-text-4 ">We assist organizations in protecting sensitive data and preventing data breaches through data loss prevention solutions. Our experts develop and implement DLP strategies that involve data classification, encryption, access controls, and data leakage detection and prevention mechanisms.</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="swiper-slide-card swiper-slide-card-4">
                                        <div className="card h-lg-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon8} alt="cybersecurity" className="chemicalmanu-new-image-2" />
                                                <h3 className="aerospace-new-text-3">Incident Response and Forensics</h3>
                                                <p className="aerospace-new-text-4 ">We provide incident response services to help organizations effectively respond to security incidents and mitigate their impact. Our experts perform incident investigations, conduct digital forensics, and develop incident response plans to minimize downtime and ensure business continuity.</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="swiper-slide-card swiper-slide-card-5">
                                        <div className="card h-lg-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon9} alt="cybersecurity" className="chemicalmanu-new-image-2" />
                                                <h3 className="aerospace-new-text-3">Employee Security Awareness Training</h3>
                                                <p className="aerospace-new-text-4 ">We offer comprehensive security awareness training programs to educate and empower your employees in recognizing and responding to potential security threats. Our training modules cover topics such as phishing attacks, social engineering, password hygiene, and safe browsing practices.</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="swiper-slide-card swiper-slide-card-6">
                                        <div className="card h-lg-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon10} alt="cybersecurity" className="chemicalmanu-new-image-2" />
                                                <h3 className="aerospace-new-text-3">Compliance and Regulatory Support</h3>
                                                <p className="aerospace-new-text-4 ">We assist organizations in achieving and maintaining compliance with industry-specific regulations, such as GDPR, HIPAA, PCI DSS, and others. Our experts help you navigate regulatory requirements, conduct security audits, and implement necessary controls to meet compliance obligations.</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="swiper-slide-card swiper-slide-card-7">
                                        <div className="card h-lg-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon11} alt="cybersecurity" className="chemicalmanu-new-image-2" />
                                                <h3 className="aerospace-new-text-3">Network Security</h3>
                                                <p className="aerospace-new-text-4 ">We implement comprehensive network security measures to safeguard your organization's network infrastructure from unauthorized access, malware, and other threats. Our solutions include firewalls, intrusion detection and prevention systems (IDS/IPS), virtual private networks (VPNs), and secure network architecture design.</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="swiper-slide-card swiper-slide-card-8">
                                        <div className="card h-lg-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon12} alt="cybersecurity" className="chemicalmanu-new-image-2" />
                                                <h3 className="aerospace-new-text-3">Endpoint Protection</h3>
                                                <p className="aerospace-new-text-4 ">In today's mobile-centric world, empowering your workforce with real-time information and collaboration tools is essential for optimizing productivity and efficiency. Upstrox's Mobile Applications provide intuitive and user-friendly solutions designed to enhance communication and streamline operations in the chemical manufacturing industry.Our mobile applications enable remote access to critical information, facilitate real-time collaboration, and provide on-the-go capabilities for tasks such as inventory management, field inspections, and reporting. By empowering your workforce with mobility, you can improve response times, reduce errors, and enhance overall operational efficiency.Partner with Upstrox and harness the power of Mobile Applications to transform your chemical manufacturing operations and drive productivity and collaboration wherever your employees are.</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="swiper-slide-card swiper-slide-card-9">
                                        <div className="card h-lg-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon13} alt="cybersecurity" className="chemicalmanu-new-image-2" />
                                                <h3 className="aerospace-new-text-3">Cloud Security</h3>
                                                <p className="aerospace-new-text-4 ">We help organizations secure their cloud environments, ensuring the confidentiality, integrity, and availability of data stored in the cloud. Our experts assess cloud security risks, design secure cloud architectures, and implement security controls for cloud platforms such as Amazon Web Services (AWS), Microsoft Azure, and Google Cloud Platform (GCP).</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="swiper-slide-card swiper-slide-card-10">
                                        <div className="card h-lg-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon14} alt="cybersecurity" className="chemicalmanu-new-image-2" />
                                                <h3 className="aerospace-new-text-3">Identity and Access Management (IAM)</h3>
                                                <p className="aerospace-new-text-4 ">We implement robust IAM solutions to control user access, manage identities, and prevent unauthorized account usage. Our solutions include multi-factor authentication (MFA), single sign-on (SSO), privileged access management (PAM), and user identity governance.</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="swiper-slide-card swiper-slide-card-9">
                                        <div className="card h-lg-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon15} alt="cybersecurity" className="chemicalmanu-new-image-2-1" />
                                                <h3 className="aerospace-new-text-3">Data Security and Encryption</h3>
                                                <p className="aerospace-new-text-4 ">We assist organizations in protecting sensitive data through data security and encryption solutions. Our experts help you classify data, implement encryption technologies, and develop data loss prevention (DLP) strategies to prevent unauthorized data disclosure or theft.</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="swiper-slide-card swiper-slide-card-10">
                                        <div className="card h-lg-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon16} alt="cybersecurity" className="chemicalmanu-new-image-2-1" />
                                                <h3 className="aerospace-new-text-3">Security Incident and Event Management (SIEM)</h3>
                                                <p className="aerospace-new-text-4 ">We deploy SIEM solutions to monitor and analyze security events across your organization's infrastructure. Our experts utilize advanced SIEM tools and techniques to detect, investigate, and respond to security incidents in real-time, ensuring rapid incident response.</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>



                                </Swiper>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="chemicalmanufacturing-new-design-2 py-5 d-lg-block d-none">
                    <div className="container-fluid col-lg-11 mx-auto py-lg-5">

                        <div className="row">
                            <div className="col-lg-5 ">


                                <div class="tab-content swiper-slide-card" id="v-pills-tabContent">
                                    <div class="tab-pane fade show active" id="v-manu-tabs-1" role="tabpanel" aria-labelledby="v-manu-tabs-1-tab">
                                        <div className="card h-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon5} alt="cybersecurity" className="chemicalmanu-new-image-2-1" />
                                                <h3 className="aerospace-new-text-3">Security Operations Center (SOC) Services</h3>
                                                <p className="aerospace-new-text-4 ">We offer managed security services through our Security Operations Center (SOC). Our SOC experts monitor your networks, systems, and applications 24/7, detect and respond to security incidents, and provide timely threat intelligence and incident reporting.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="v-manu-tabs-2" role="tabpanel" aria-labelledby="v-manu-tabs-2-tab">
                                        <div className="card h-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon6} alt="cybersecurity" className="chemicalmanu-new-image-2-1" />
                                                <h3 className="aerospace-new-text-3">Identity and Access Management (IAM)</h3>
                                                <p className="aerospace-new-text-4 ">We help organizations implement robust identity and access management solutions to control user access and privileges. Our experts design and deploy IAM frameworks, including user provisioning, authentication, single sign-on (SSO), and role-based access controls.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="v-manu-tabs-3" role="tabpanel" aria-labelledby="v-manu-tabs-3-tab">
                                        <div className="card h-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon7} alt="cybersecurity" className="chemicalmanu-new-image-2-1" />
                                                <h3 className="aerospace-new-text-3">Data Loss Prevention (DLP)</h3>
                                                <p className="aerospace-new-text-4 ">We assist organizations in protecting sensitive data and preventing data breaches through data loss prevention solutions. Our experts develop and implement DLP strategies that involve data classification, encryption, access controls, and data leakage detection and prevention mechanisms.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="v-manu-tabs-4" role="tabpanel" aria-labelledby="v-manu-tabs-4-tab">
                                        <div className="card h-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon8} alt="cybersecurity" className="chemicalmanu-new-image-2-1" />
                                                <h3 className="aerospace-new-text-3">Incident Response and Forensics</h3>
                                                <p className="aerospace-new-text-4 ">We provide incident response services to help organizations effectively respond to security incidents and mitigate their impact. Our experts perform incident investigations, conduct digital forensics, and develop incident response plans to minimize downtime and ensure business continuity.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="v-manu-tabs-5" role="tabpanel" aria-labelledby="v-manu-tabs-5-tab">
                                        <div className="card h-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon9} alt="cybersecurity" className="chemicalmanu-new-image-2-1" />
                                                <h3 className="aerospace-new-text-3">Employee Security Awareness Training</h3>
                                                <p className="aerospace-new-text-4 ">We offer comprehensive security awareness training programs to educate and empower your employees in recognizing and responding to potential security threats. Our training modules cover topics such as phishing attacks, social engineering, password hygiene, and safe browsing practices.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="v-manu-tabs-6" role="tabpanel" aria-labelledby="v-manu-tabs-6-tab">
                                        <div className="card h-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon10} alt="cybersecurity" className="chemicalmanu-new-image-2-1" />
                                                <h3 className="aerospace-new-text-3">Compliance and Regulatory Support</h3>
                                                <p className="aerospace-new-text-4 ">We assist organizations in achieving and maintaining compliance with industry-specific regulations, such as GDPR, HIPAA, PCI DSS, and others. Our experts help you navigate regulatory requirements, conduct security audits, and implement necessary controls to meet compliance obligations.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="v-manu-tabs-7" role="tabpanel" aria-labelledby="v-manu-tabs-7-tab">
                                        <div className="card h-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon11} alt="cybersecurity" className="chemicalmanu-new-image-2-1" />
                                                <h3 className="aerospace-new-text-3">Network Security</h3>
                                                <p className="aerospace-new-text-4 ">We implement comprehensive network security measures to safeguard your organization's network infrastructure from unauthorized access, malware, and other threats. Our solutions include firewalls, intrusion detection and prevention systems (IDS/IPS), virtual private networks (VPNs), and secure network architecture design.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="v-manu-tabs-8" role="tabpanel" aria-labelledby="v-manu-tabs-8-tab">
                                        <div className="card h-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon12} alt="cybersecurity" className="chemicalmanu-new-image-2-1" />
                                                <h3 className="aerospace-new-text-3">Endpoint Protection</h3>
                                                <p className="aerospace-new-text-4 ">We deploy advanced endpoint protection solutions to secure your devices and endpoints from malware, ransomware, and other malicious activities. Our solutions include next-generation antivirus (NGAV), endpoint detection and response (EDR), and device encryption.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="v-manu-tabs-9" role="tabpanel" aria-labelledby="v-manu-tabs-9-tab">
                                        <div className="card h-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon13} alt="cybersecurity" className="chemicalmanu-new-image-2-1" />
                                                <h3 className="aerospace-new-text-3">Cloud Security</h3>
                                                <p className="aerospace-new-text-4 ">We help organizations secure their cloud environments, ensuring the confidentiality, integrity, and availability of data stored in the cloud. Our experts assess cloud security risks, design secure cloud architectures, and implement security controls for cloud platforms such as Amazon Web Services (AWS), Microsoft Azure, and Google Cloud Platform (GCP).</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="v-manu-tabs-10" role="tabpanel" aria-labelledby="v-manu-tabs-10-tab">
                                        <div className="card h-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon14} alt="cybersecurity" className="chemicalmanu-new-image-2-1" />
                                                <h3 className="aerospace-new-text-3">Identity and Access Management (IAM)</h3>
                                                <p className="aerospace-new-text-4 ">We implement robust IAM solutions to control user access, manage identities, and prevent unauthorized account usage. Our solutions include multi-factor authentication (MFA), single sign-on (SSO), privileged access management (PAM), and user identity governance.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="v-manu-tabs-11" role="tabpanel" aria-labelledby="v-manu-tabs-11-tab">
                                        <div className="card h-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon15} alt="cybersecurity" className="chemicalmanu-new-image-2-1" />
                                                <h3 className="aerospace-new-text-3">Data Security and Encryption</h3>
                                                <p className="aerospace-new-text-4 ">We assist organizations in protecting sensitive data through data security and encryption solutions. Our experts help you classify data, implement encryption technologies, and develop data loss prevention (DLP) strategies to prevent unauthorized data disclosure or theft.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="v-manu-tabs-12" role="tabpanel" aria-labelledby="v-manu-tabs-12-tab">
                                        <div className="card h-100 p-2">
                                            <div className="card-body">
                                                <img src={cybericon16} alt="cybersecurity" className="chemicalmanu-new-image-2-1" />
                                                <h3 className="aerospace-new-text-3">Security Incident and Event Management (SIEM)</h3>
                                                <p className="aerospace-new-text-4 ">We deploy SIEM solutions to monitor and analyze security events across your organization's infrastructure. Our experts utilize advanced SIEM tools and techniques to detect, investigate, and respond to security incidents in real-time, ensuring rapid incident response.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div class="nav nav-pills row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 chemicalmanufacturing-new-design-2-inner-2" id="v-pills-tab" role="tablist">
                                    <div className="col">
                                        <div class="card h-100 nav-link active" id="v-manu-tabs-1-tab" data-bs-toggle="pill" data-bs-target="#v-manu-tabs-1" type="button" role="tab" aria-controls="v-manu-tabs-1" aria-selected="true">
                                            <div class="card-body">
                                                <img src={cybericon5} alt="cybersecurity" className="chemicalmanu-new-image-2" />
                                                <h5 class="chemicalmanu-new-text-1">Security Operations Center (SOC) Services</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div class="nav-link card h-100" id="v-manu-tabs-2-tab" data-bs-toggle="pill" data-bs-target="#v-manu-tabs-2" type="button" role="tab" aria-controls="v-manu-tabs-2" aria-selected="false">
                                            <div class="card-body">
                                                <img src={cybericon6} alt="cybersecurity" className="chemicalmanu-new-image-2" />
                                                <h5 class="chemicalmanu-new-text-1">Identity and Access Management (IAM)</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div class="nav-link card h-100" id="v-manu-tabs-3-tab" data-bs-toggle="pill" data-bs-target="#v-manu-tabs-3" type="button" role="tab" aria-controls="v-manu-tabs-3" aria-selected="false">
                                            <div class="card-body">
                                                <img src={cybericon7} alt="cybersecurity" className="chemicalmanu-new-image-2" />
                                                <h5 class="chemicalmanu-new-text-1">Data Loss Prevention (DLP)</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div class="nav-link card h-100" id="v-manu-tabs-4-tab" data-bs-toggle="pill" data-bs-target="#v-manu-tabs-4" type="button" role="tab" aria-controls="v-manu-tabs-4" aria-selected="false">
                                            <div class="card-body">
                                                <img src={cybericon8} alt="cybersecurity" className="chemicalmanu-new-image-2" />
                                                <h5 class="chemicalmanu-new-text-1">Incident Response and Forensics</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div class="nav-link card h-100" id="v-manu-tabs-5-tab" data-bs-toggle="pill" data-bs-target="#v-manu-tabs-5" type="button" role="tab" aria-controls="v-manu-tabs-5" aria-selected="false">
                                            <div class="card-body">
                                                <img src={cybericon9} alt="cybersecurity" className="chemicalmanu-new-image-2" />
                                                <h5 class="chemicalmanu-new-text-1">Employee Security Awareness Training</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div class="nav-link card h-100" id="v-manu-tabs-6-tab" data-bs-toggle="pill" data-bs-target="#v-manu-tabs-6" type="button" role="tab" aria-controls="v-manu-tabs-6" aria-selected="false">
                                            <div class="card-body">
                                                <img src={cybericon10} alt="cybersecurity" className="chemicalmanu-new-image-2" />
                                                <h5 class="chemicalmanu-new-text-1">Compliance and Regulatory Support</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div class="nav-link card h-100" id="v-manu-tabs-7-tab" data-bs-toggle="pill" data-bs-target="#v-manu-tabs-7" type="button" role="tab" aria-controls="v-manu-tabs-7" aria-selected="false">
                                            <div class="card-body">
                                                <img src={cybericon11} alt="cybersecurity" className="chemicalmanu-new-image-2" />
                                                <h5 class="chemicalmanu-new-text-1">Network Security</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div class="nav-link card h-100" id="v-manu-tabs-8-tab" data-bs-toggle="pill" data-bs-target="#v-manu-tabs-8" type="button" role="tab" aria-controls="v-manu-tabs-8" aria-selected="false">
                                            <div class="card-body">
                                                <img src={cybericon12} alt="cybersecurity" className="chemicalmanu-new-image-2" />
                                                <h5 class="chemicalmanu-new-text-1">Endpoint Protection</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div class="nav-link card h-100" id="v-manu-tabs-9-tab" data-bs-toggle="pill" data-bs-target="#v-manu-tabs-9" type="button" role="tab" aria-controls="v-manu-tabs-9" aria-selected="false">
                                            <div class="card-body">
                                                <img src={cybericon13} alt="cybersecurity" className="chemicalmanu-new-image-2" />
                                                <h5 class="chemicalmanu-new-text-1">Cloud Security</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div class="nav-link card h-100" id="v-manu-tabs-10-tab" data-bs-toggle="pill" data-bs-target="#v-manu-tabs-10" type="button" role="tab" aria-controls="v-manu-tabs-10" aria-selected="false">
                                            <div class="card-body">
                                                <img src={cybericon14} alt="cybersecurity" className="chemicalmanu-new-image-2" />
                                                <h5 class="chemicalmanu-new-text-1">Identity and Access Management (IAM)</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div class="nav-link card h-100" id="v-manu-tabs-11-tab" data-bs-toggle="pill" data-bs-target="#v-manu-tabs-11" type="button" role="tab" aria-controls="v-manu-tabs-11" aria-selected="false">
                                            <div class="card-body">
                                                <img src={cybericon15} alt="cybersecurity" className="chemicalmanu-new-image-2" />
                                                <h5 class="chemicalmanu-new-text-1">Data Security and Encryption</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div class="nav-link card h-100" id="v-manu-tabs-12-tab" data-bs-toggle="pill" data-bs-target="#v-manu-tabs-12" type="button" role="tab" aria-controls="v-manu-tabs-12" aria-selected="false">
                                            <div class="card-body">
                                                <img src={cybericon16} alt="cybersecurity" className="chemicalmanu-new-image-2" />
                                                <h5 class="chemicalmanu-new-text-1">Security Incident and Event Management (SIEM)</h5>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="chemicalmanufacturing-new-design-1 py-5">
                    <div className="container-fluid col-lg-11 mx-auto py-lg-5">
                        <div className="row row-cols-md-2 row-cols-1 row-cols-lg-2 g-4">
                            <div className="col mt- card h-1000 chemicalmanufacturing-top-new-card-1">
                                <div class="card bg-transparent text-white border-0 p-lg-3">
                                    <div class="card-body">
                                        <img src={cybericon17} alt="cybersecurity" className="aerospace-new-image-2" />
                                        <h5 className="aerospace-new-text-3">Security Awareness Training</h5>
                                        <p className="aerospace-new-text-4">We provide comprehensive security awareness training programs to educate your employees about cybersecurity best practices. Our training modules cover topics such as phishing awareness, social engineering, password hygiene, and safe internet browsing, empowering your employees to be the first line of defense against cyber threats.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col mt-0 chemicalmanufacturing-top-new-card-2">
                                <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                                    <div class="card-body">
                                        <img src={cybericon18} alt="cybersecurity" className="aerospace-new-image-2" />
                                        <h5 className="aerospace-new-text-3">Vulnerability Management</h5>
                                        <p className="aerospace-new-text-4">We conduct regular vulnerability assessments and penetration testing to identify weaknesses in your systems and applications. Our experts provide remediation recommendations and assist in patch management, ensuring that your organization stays protected against known vulnerabilities.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col mt-0 chemicalmanufacturing-top-new-card-3">
                                <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                                    <div class="card-body">
                                        <img src={cybericon19} alt="cybersecurity" className="aerospace-new-image-2" />
                                        <h5 className="aerospace-new-text-3">Security Governance and Compliance</h5>
                                        <p className="aerospace-new-text-4">We help organizations establish security governance frameworks and comply with industry regulations and standards. Our experts assist in developing security policies and procedures, conducting security audits, and achieving compliance with regulations such as GDPR, HIPAA, and PCI DSS.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col mt-0 chemicalmanufacturing-top-new-card-4">
                                <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                                    <div class="card-body">
                                        <img src={cybericon20} alt="cybersecurity" className="aerospace-new-image-2" />
                                        <h5 className="aerospace-new-text-3">Incident Response and Cybersecurity Consulting</h5>
                                        <p className="aerospace-new-text-4">In the event of a security incident, our incident response team is ready to assist you with incident investigation, containment, and recovery. We also provide cybersecurity consulting services to assess your organization's security posture, develop cybersecurity roadmaps, and align security strategies with your business objectives.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="aerospace-new-design-2 py-5">
                    <div className="container">
                        <div className="row g-4 flex-lg-row-reverse">
                            <div className="col-lg-4">
                                <img src={ContactNew} alt="cybersecurity" />
                            </div>
                            <div className="col-lg-8">
                                <p className="aerospace-new-text-5 mb-lg-4">At Upstrox, we prioritize the security of your organization and work diligently to mitigate cyber risks. we are committed to protecting your organization's digital assets and ensuring the confidentiality, integrity, and availability of your systems and data. </p>
                                <a href='/contact' className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="aerospace-new-design-2 py-5">
                    <div className="container">
                        <div className="row g-4 justify-content-center text-center">
                            <div className="col-lg-12">
                                <p className="aerospace-new-text-6 mb-lg-4">Contact us today to discuss your cybersecurity requirements and explore how Upstrox can empower your organization to defenses and protect your valuable assets from cyber threats.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default CyberSecurity;