import experience from "../../Assets/images/icon/waste management/waste management image.webp";
import { BiRightArrowAlt } from "react-icons/bi";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import WasteManagementbg from "../../Assets/images/icon/waste management/waste management.webp";
import wasteicon1 from "../../Assets/images/icon/waste management/Waste Management Systems.webp";
import wasteicon2 from "../../Assets/images/icon/waste management/Recycling Management Solutions.webp";
import wasteicon3 from "../../Assets/images/icon/waste management/Landfill Management Systems.webp";
import wasteicon4 from "../../Assets/images/icon/waste management/Asset and Equipment Management.webp";
import wasteicon5 from "../../Assets/images/icon/waste management/Customer Service and Billing.webp";
import wasteicon6 from "../../Assets/images/icon/waste management/Regulatory Compliance and Reporting.webp";
import wasteicon7 from "../../Assets/images/icon/waste management/Data Analytics and Business Intelligence.webp";

function WasteManagement() {
  return (
    <>
      <div className="wastemanagement-section">
        <section className="top-banner-section top-banner-section-overral-bg-section back-posi-img waste-management-background" style={{ backgroundImage: "url(" + WasteManagementbg + ")" }}>
          <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one">
                    <span className="text-color"> Waste Management </span> IT Solutions by Upstrox
                  </h1>
                  <div className="top-text-two">Upstrox is your trusted partner for comprehensive IT solutions tailored specifically for the waste management industry. We understand the unique challenges and requirements of waste management companies, including collection, recycling, disposal, and regulatory compliance.
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
              </div>
            </div>
          </div>
        </section>


        <section className="pt-5">
          <div className="container-fluid col-lg-10 position-relative pb-lg-5">
            <div className="row">
              {/* <div className="consumer-blur"></div> */}
              <div className="col-lg-4">
                <h5 className="aerospace-new-text-1">Waste Management</h5>
                <p className="aerospace-new-text-2">
                  Our expertise in technology and domain knowledge enables us to deliver innovative solutions that optimize waste management processes, improve operational efficiency, and promote sustainability. Here are the key IT services we offer
                </p>
              </div>
              <div className="col-lg-8">
                <div className="d-lg-flex d-md-flex">
                  <div>
                    {" "}
                    <img
                      src={wasteicon1}
                      className="waste-img-iconone p-3"
                      alt="wastemanagement"
                    />
                  </div>
                  <div className="">
                    <h3 className="card-text-one">
                      Waste Management Systems
                    </h3>
                    <p className="card-text-two">
                      We provide waste management systems that streamline and automate waste collection, tracking, and disposal processes. Our systems enable efficient route planning, container management, work order tracking, and real-time data collection, improving operational efficiency and service quality.

                    </p>
                  </div>
                </div>
                <div className="d-lg-flex d-md-flex">
                  <div>
                    {" "}
                    <img
                      src={wasteicon2}
                      className="waste-img-iconone p-3"
                      alt="wastemanagement"
                    />
                  </div>
                  <div className="">
                    <h3 className="card-text-one">
                      Recycling Management Solutions
                    </h3>
                    <p className="card-text-two">
                      We offer recycling management solutions that facilitate the tracking, sorting, and processing of recyclable materials. Our solutions include material identification, recycling program management, and reporting, helping waste management companies meet recycling goals and comply with environmental regulations.

                    </p>
                  </div>
                </div>
                <div className="d-lg-flex d-md-flex">
                  <div>
                    {" "}
                    <img
                      src={wasteicon3}
                      className="waste-img-iconone p-3"
                      alt="wastemanagement"
                    />
                  </div>
                  <div>
                    <h3 className="card-text-one">
                      Landfill Management Systems
                    </h3>
                    <p className="card-text-two">
                      We provide landfill management systems that optimize the operation and monitoring of landfill sites. Our systems assist in waste tracking, inventory management, methane gas monitoring, and compliance reporting, ensuring efficient landfill operations and regulatory compliance.

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="industrialmanu-new-design-1">
          <div className="container-fluid col-lg-10 mx-auto py-5">
            <div className="row">
              <div className="col-lg-12">
                <div className="row row-cols-md-2 row-cols-1 row-cols-lg-2 g-4">
                  <div className="col mt-0 industrialmanu-top-new-card-1">
                    <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                      <div class="card-body">
                        <img className="industrialmanu-icon-1" src={wasteicon4} alt="wastemanagement" />
                        <h5 className="aerospace-new-text-3">Asset and Equipment Management</h5>
                        <p className="aerospace-new-text-4">We offer asset and equipment management solutions that optimize the utilization and maintenance of waste management equipment. Our solutions include asset tracking, preventive maintenance scheduling, inventory control, and performance monitoring, minimizing downtime and maximizing equipment efficiency.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col mt-0 industrialmanu-top-new-card-2">
                    <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                      <div class="card-body">
                        <img className="industrialmanu-icon-1" src={wasteicon5} alt="wastemanagement" />
                        <h5 className="aerospace-new-text-3">Customer Service and Billing</h5>
                        <p className="aerospace-new-text-4">We provide customer service and billing solutions that enhance the customer experience and streamline billing processes. Our solutions enable customer self-service, service request management, invoice generation, and payment processing, improving customer satisfaction and revenue management.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col mt-0 industrialmanu-top-new-card-3">
                    <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                      <div class="card-body">
                        <img className="industrialmanu-icon-1" src={wasteicon6} alt="wastemanagement" />
                        <h5 className="aerospace-new-text-3">Regulatory Compliance and Reporting</h5>
                        <p className="aerospace-new-text-4">We offer solutions to help waste management companies comply with environmental regulations and reporting requirements. Our solutions cover compliance monitoring, data validation, regulatory reporting, and audit trail management, ensuring adherence to industry standards and regulatory frameworks.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col mt-0 industrialmanu-top-new-card-4">
                    <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                      <div class="card-body">
                        <img className="industrialmanu-icon-1" src={wasteicon7} alt="wastemanagement" />
                        <h5 className="aerospace-new-text-3">Data Analytics and Business Intelligence</h5>
                        <p className="aerospace-new-text-4">Our data analytics and business intelligence solutions help waste management companies derive valuable insights from their data. We assist in analyzing operational data, waste composition, recycling rates, and performance metrics to drive data-driven decision-making, optimize processes, and identify areas for improvement.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sustaincard-section sustaincard-section-two py-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 py-2">
                <div className="card h-100 p-4 waste-border-card">

                  <h4 className="card-text-one">Route Optimization and Fleet Management</h4>
                  <h5 className="card-text-two">
                    We provide route optimization and fleet management solutions that maximize efficiency in waste collection and transportation. Our solutions consider factors such as bin capacity, vehicle capacity, traffic conditions, and optimized routing, reducing travel time, fuel consumption, and carbon emissions.

                  </h5>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 py-2">
                <div className="card h-100 p-4" style={{ backgroundColor: "rgba(46, 46, 46, 0.199)" }}>

                  <h4 className="card-text-one">Mobile Applications and Field Data Collection</h4>
                  <h5 className="card-text-two">
                    We offer mobile applications and field data collection solutions to streamline data collection and improve field operations. Our solutions enable field technicians to capture data on waste collection, inspections, and work orders in real-time, enhancing accuracy and efficiency.

                  </h5>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 py-2 ">
                <div className="card h-100 p-4 waste-border-card">

                  <h4 className="card-text-one">Cybersecurity and Data Protection</h4>
                  <h5 className="card-text-two">
                    Protecting sensitive customer data, operational systems, and infrastructure is crucial in the waste management industry. We provide comprehensive cybersecurity services, including network security, access controls, data encryption, and vulnerability assessments. Our solutions help safeguard your valuable assets and protect against cyber threats.

                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="logistic-last-section my-lg-5">
          <div className="container py-3">
            <div className="row flex-row-reverse">
              <div className="col-lg-4 col-md-4">
                <img src={experience} alt="wastemanagement" />
              </div>
              <div className="col-lg-8 col-md-8">
                <div className="pt-lg-5">
                  <p className="">
                    At Upstrox, we are committed to supporting waste management companies with innovative and reliable IT solutions.
                  </p>
                </div>
                <div>
                  <a className="new-ups-button-2" href="/contact">
                    <span className="new-ups-button-icon-3">Read More</span>{" "}
                    <BiRightArrowAlt className="new-ups-button-icon-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-text-section py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="contact-text-last mb-lg-4">
                  Contact us today to discuss your specific requirements and explore how Upstrox can empower your organization's waste management operations, sustainability goals, and customer satisfaction with our industry-leading IT services.
                </p>
              </div>
            </div>
          </div>
        </section>

      </div>
    </>
  )
}
export default WasteManagement;