import {MdOutlineArrowForwardIos} from "react-icons/md";
import Dummybg from "../../Assets/images/icon/financial services/financial services landing banner.webp";
import ContactNew from "../../Assets/images/icon/financial services/financial services image.webp"
import { BiRightArrowAlt } from 'react-icons/bi';
import finicon1 from "../../Assets/images/icon/financial services/Core Banking Systems.webp";
import finicon2 from "../../Assets/images/icon/financial services/Digital Banking Solutions.webp";
import finicon3 from "../../Assets/images/icon/financial services/Risk Management Solutions.webp";
import finicon4 from "../../Assets/images/icon/financial services/Wealth Management Systems.webp";
import finicon5 from "../../Assets/images/icon/financial services/Payment Processing Solutions.webp";
import finicon6 from "../../Assets/images/icon/financial services/Regulatory Compliance Solutions.webp";
import finicon7 from "../../Assets/images/icon/financial services/Data Analytics and Business Intelligence.webp";
import finicon8 from "../../Assets/images/icon/financial services/Cybersecurity and Data Protection.webp";
import finicon9 from "../../Assets/images/icon/financial services/IT Infrastructure Management.webp";
import finicon10 from "../../Assets/images/icon/financial services/IT Consulting and Strategy.webp";

function FinancialService(){
    return(
        <>
        <div className="financial-banner-section">
        <section className="top-banner-section top-banner-section-overral-bg-section financial-background back-posi-img" style={{ backgroundImage: "url(" + Dummybg + ")" }}>
          <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
          <div className="container">
            <div className="row align-items-center">
              <div className="col">
                <h1 className="top-text-one">Upstrox - Your Trusted Partner for
                  <span className="text-color">  Financial Services  </span>   IT Solutions
                </h1>
                <div className="top-text-two">In the fast-paced and highly regulated world of financial services, technology plays a vital role in driving efficiency, ensuring compliance, and delivering exceptional customer experiences. 
                </div>
              </div>
            </div>
            <div className="pt-3">
            <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2"/></button></a>
              </div>
          </div>
          </div>
        </section>

        <section className="sustaincard-section  py-5">
                <div className="container pb-5">
                <h5 className="text-one">At Upstrox, we specialize in providing comprehensive IT services tailored specifically for the financial services industry. Here are the key IT services we offer</h5>
                <div className="row pt-lg-5 pt-3 d-flex align-items-center">
                    <div className="col-lg-1 col-md-2">
                    <div className=""> <img src={finicon1} alt="financial" className="fin-icon-img" /></div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                    <h4 className="card-text-one">Core Banking Systems</h4></div>
                    <div className="col">
                    <h5 className="card-text-two">
                    We provide robust and scalable core banking systems that streamline essential banking processes, including account management, transaction processing, and loan servicing. Our solutions enable seamless integration with various banking channels and support multi-currency transactions.
                    </h5>
                    </div>
                </div>
                <div className="row pt-lg-5 pt-3 d-flex align-items-center">
                    <div className="col-lg-1 col-md-2">
                    <div className=""> <img src={finicon2} alt="financial" className="fin-icon-img" /></div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                    <h4 className="card-text-one">Digital Banking Solutions</h4></div>
                    <div className="col">
                    <h5 className="card-text-two">
                    We help financial institutions develop and implement digital banking platforms that enhance customer experiences. Our solutions cover internet banking, mobile banking applications, digital wallets, and personalized financial management tools, enabling customers to perform transactions conveniently and securely.

                    </h5>
                    </div>
                </div>
                <div className="row pt-lg-5 pt-3 d-flex align-items-center">
                    <div className="col-lg-1 col-md-2">
                    <div className=""> <img src={finicon3} alt="financial" className="fin-icon-img" /></div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                    <h4 className="card-text-one">Risk Management Solutions</h4></div>
                    <div className="col">
                    <h5 className="card-text-two">
                    We offer risk management solutions that help financial institutions identify, assess, and mitigate risks effectively. Our solutions cover enterprise risk management, credit risk assessment, fraud detection and prevention, and regulatory compliance, ensuring adherence to industry standards and regulations.

                    </h5>
                    </div>
                </div>
                </div>   
            </section>
 <section className="pt-5">
 <div className="container-fluid card-re-one col-lg-10">
            <div className="row row-cols-lg-2 row-cols-1 row-cols-md-2 justify-content-md-end">
              <div className="col pt-3 mt-lg-3 ">
                <div className="card-re-border-top"></div>
                <div
                  className=" card card-back-re1"
                  style={{ borderColor: "rgb(53 58 45)" }}
                >
                  <div
                    className="card-body d-lg-flex"
                    style={{ background: "rgb(53 58 45 / 25%)" }}
                  >
                    <div className="re-icon-one">
                      {" "}
                      <img src={finicon4} className="fin-icon-img" alt="financial" />
                    </div>
                    <div>
                      <h4 className="card-text-one">
                      Wealth Management Systems
                      </h4>
                      <h5 className="card-text-two">
                      We provide wealth management systems that streamline investment portfolio management, financial planning, and client relationship management for wealth management firms. Our solutions enable advisors to offer personalized investment strategies, track performance, and deliver exceptional client service.

                      </h5>
                    </div>
                  </div>
                </div>
                <div
                  className="card-re-border-bottom"
                  style={{ borderColor: "rgb(53 58 45)" }}
                ></div>
              </div>
              <div className="col pt-3 pt-lg-0 retail-card-one">
                <div className="card-re-border-top"></div>
                <div
                  className=" card card-back-re2"
                  style={{ borderColor: "rgb(53 58 45)" }}
                >
                  <div
                    className="card-body d-lg-flex"
                    style={{ background: "rgb(53 58 45 / 25%)" }}
                  >
                    <div className="re-icon-one">
                      {" "}
                      <img src={finicon5} alt="financial" className="fin-icon-img" />
                    </div>
                    <div>
                      <h4 className="card-text-one">
                      Payment Processing Solutions
                      </h4>
                      <h5 className="card-text-two">
                      We offer secure and efficient payment processing solutions, including payment gateways, merchant services, and automated clearinghouse (ACH) integration. Our solutions ensure seamless payment transactions, fraud prevention, and compliance with payment industry standards.

                      </h5>
                    </div>
                  </div>
                </div>
                <div
                  className="card-re-border-bottom"
                  style={{ borderColor: "rgb(53 58 45)" }}
                ></div>
              </div>
              <div className="col retail-card-one pt-md-5 pt-4">
                <div className="card-re-border-top"></div>
                <div
                  className=" card card-back-re3"
                  style={{ borderColor: "rgb(53 58 45)" }}
                >
                  <div
                    className="card-body d-lg-flex"
                    style={{ background: "rgb(53 58 45 / 25%)" }}
                  >
                    <div className="re-icon-one">
                      <img src={finicon6} alt="financial" className="fin-icon-img"/>
                    </div>
                    <div>
                      <h4 className="card-text-one">
                      Regulatory Compliance Solutions
                      </h4>
                      <h5 className="card-text-two">
                      We assist financial institutions in achieving and maintaining regulatory compliance. Our solutions include Anti-Money Laundering (AML) systems, Know Your Customer (KYC) solutions, and data privacy compliance tools. We help you navigate complex regulatory requirements and protect sensitive customer information.

                      </h5>
                    </div>
                  </div>
                </div>
                <div
                  className="card-re-border-bottom"
                  style={{ borderColor: "rgb(53 58 45)" }}
                ></div>
              </div>
            </div>
          </div>
 </section>

 <section className="aerospace-new-design-1 py-5">
          <div className="container-fluid col-lg-11 mx-auto">
            <div className="row">
              <div className="col-lg-12">
                <div className="row row-cols-md-2 row-cols-1 row-cols-lg-2 g-4">
                <div className="col">
                    <img src={finicon7} alt="financial" className="fin-icon-img" />
                    <h5 className="aerospace-new-text-3">Data Analytics and Business Intelligence</h5>
                    <p className="aerospace-new-text-4">Our data analytics and business intelligence solutions empower financial institutions to derive actionable insights from vast amounts of data. We help you analyze customer behavior, optimize product offerings, identify market trends, and make data-driven decisions to drive business growth.</p>
                  </div>
                  <div className="col">
                    <img src={finicon8} alt="financial" className="fin-icon-img" />
                    <h5 className="aerospace-new-text-3">Cybersecurity and Data Protection</h5>
                    <p className="aerospace-new-text-4">Protecting sensitive financial data and ensuring cybersecurity is paramount in the financial services industry. We provide comprehensive cybersecurity services, including network security, data encryption, identity and access management, and security audits. Our solutions help safeguard your systems and customer information from evolving cyber threats.</p>
                  </div>
                  <div className="col">
                    <img src={finicon9} alt="financial" className="fin-icon-img" />
                    <h5 className="aerospace-new-text-3">IT Infrastructure Management</h5>
                    <p className="aerospace-new-text-4">We offer end-to-end IT infrastructure management services, including network design, server management, data center optimization, and cloud integration. Our solutions ensure high availability, scalability, and security to support the demanding requirements of financial services organizations.
</p>
                  </div>
                  <div className="col">
                    <img src={finicon10} alt="financial" className="fin-icon-img" />
                    <h5 className="aerospace-new-text-3">IT Consulting and Strategy</h5>
                    <p className="aerospace-new-text-4">Our experienced consultants provide expert guidance on IT strategy, technology roadmap development, and digital transformation initiatives. We help financial institutions align technology investments with business goals, optimize processes, and stay ahead in the digital era.
</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section className="logistic-last-section my-lg-5">
          <div className="container py-3">
            <div className="row flex-row-reverse">
              <div className="col-lg-4 col-md-4">
                <img src={ContactNew} alt="financial" />
              </div>
              <div className="col-lg-8 col-md-8">
                <div className="pt-lg-5">
                  <p className="">
                  At Upstrox, we understand the critical role of technology in the financial services industry. Our comprehensive IT services empower financial institutions to drive operational efficiency, deliver superior customer experiences, and ensure compliance in a rapidly changing landscape. 

                  </p>
                </div>
                <div>
                  <a className="new-ups-button-2" href="/contact">
                    <span className="new-ups-button-icon-3">Read More</span>{" "}
                    <BiRightArrowAlt className="new-ups-button-icon-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="aerospace-new-text-6 mb-lg-4">
                Contact us today to discuss your financial services IT requirements and explore how we can help you achieve your business objectives.

                </p>
              </div>
            </div>
          </div>
        </section>

        </div>
        </>
        )
}
export default FinancialService;