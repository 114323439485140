import { HiArrowUpRight } from "react-icons/hi2";
import agrione from "../../Assets/images/servies-images/agrione.webp";
import agritwo from "../../Assets/images/servies-images/agritwo.webp";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Agriculturebg from "../../Assets/images/servies-images/agriculture.webp";
import { SiAntdesign, SiChase } from 'react-icons/si';
import { MdOutlineDeveloperBoard } from 'react-icons/md';
import { TfiJoomla } from 'react-icons/tfi';
import { PiVirtualRealityBold } from 'react-icons/pi';
import { MdPrecisionManufacturing, MdOutlineDeveloperMode } from "react-icons/md";
import agriicon1 from "../../Assets/images/icon/agriculture/Precision Farming.webp";
import agriicon2 from "../../Assets/images/icon/agriculture/Smart Farming and IoT.webp";
import agriicon3 from "../../Assets/images/icon/agriculture/Data Analytics and Predictive Modeling.webp";
import agriicon4 from "../../Assets/images/icon/agriculture/Supply Chain Optimization.webp";
import agriicon5 from "../../Assets/images/icon/agriculture/Farm Management Systems.webp";
import agriicon6 from "../../Assets/images/icon/agriculture/Remote Monitoring and Automation.webp";
import agriicon7 from "../../Assets/images/icon/agriculture/Emerging Technologies and Innovations.webp";
import agriicon8 from "../../Assets/images/icon/agriculture/Our Approach.webp";
import MetaTags from "../../Metatag";

function Agriculture() {
    return (
        <>
           <MetaTags
        title="agriculture"
        description="Description of agriculture"
        // Add more props for additional meta tags
      />
            <div className="agriculture-banner-section">
                <section className="top-banner-section top-banner-section-overral-bg-section" style={{ backgroundImage: "url(" + Agriculturebg + ")" }}>
                    <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1 className="top-text-one">Empowering Your
                                        <span className="text-color">  Agriculture Business </span>  with Digital Solutions for Enhanced Productivity and Profitability
                                    </h1>
                                </div>
                            </div>
                            <div className="pt-3">
                                <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="top-banner-section-two py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h5 className="text-one">
                                    UPSTROX empowering agriculture through IT Services and Solutions
                                </h5>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="agriculture-banner-two">
                    <div className="container">
                        <div className="row py-3">
                            <div className="col-lg-6">
                                <img src={agrione} className="img-size" alt="agriculture"></img>
                            </div>
                            <div className="col-lg-6">
                                <p className="card-text-two">The agriculture industry has undergone a remarkable transformation with the integration of technology. Traditional practices have given way to the era of digital agriculture, revolutionizing the way farmers operate. At Upstrox, we are committed to driving the digitalization of agriculture through the latest technological trends.</p>
                                <p className="card-text-two">Our agriculture IT solutions are specifically designed to address the unique challenges faced by farmers. Whether it's optimizing manual irrigation, improving soil fertility, streamlining distribution chains, or navigating fluctuating crop prices, our solutions empower farmers to achieve higher yields and improve financial performance. From consulting services to comprehensive management solutions, Upstrox provides a one-stop-shop for all your agriculture IT needs.</p>
                            </div>
                        </div>
                        <div className="row flex-lg-row-reverse py-3">
                            <div className="col-lg-6">
                                <img src={agritwo} className="img-size" alt="agriculture"></img>
                            </div>
                            <div className="col-lg-6">
                                <p className="card-text-two">At Upstrox, we nurture agriculture through technology. Our dedicated team is tirelessly working to redefine the journey from field to fork by leveraging the latest technological trends tailored specifically for the agriculture industry. Real-time information, augmented reality, data-driven recommendations, computer image analysis, and advanced data analytics are just a few examples of the cutting-edge trends our experts follow.</p>
                                <p className="card-text-two">By harnessing the power of real-time information, farmers can make informed decisions, optimize resource allocation, and enhance operational efficiency. Augmented reality empowers farmers with immersive experiences, enabling them to visualize and plan their agricultural activities more effectively. Our data-based recommendations provide farmers with comprehensive insights, enabling them to optimize crop management practices and maximize yields. Computer image analysis helps identify crop diseases and pests at an early stage, allowing for targeted interventions and improved crop health. Advanced data analytics unlocks valuable insights from vast amounts of agricultural data, aiding in strategic decision-making and performance optimization.</p>
                                <p className="card-text-two">Upstrox is dedicated to helping farmers thrive in the digital age by providing innovative IT solutions that address their evolving needs.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="top-banner-section-two pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h5 className="text-one">Our Services</h5>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="automotive-new-design-2 pb-4 ">
                    <div className="container-fluid col-lg-10 mx-auto">
                        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-2 g-4 justify-content-center">
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <img src={agriicon1} className="automotive-new-text-2 agriicon" alt="agriculture"/>
                                        <h5 class="automotive-new-text-1">Precision Farming</h5>
                                        <p class="automotive-new-text-3">Precision farming techniques leverage state-of-the-art technologies such as GPS, sensors, and remote sensing to optimize crop production. Our IT solutions enable precise field mapping, soil analysis, and crop monitoring, empowering you to make data-driven decisions about irrigation, fertilization, and pest control. By implementing precision farming practices, you can enhance resource utilization, reduce costs, and achieve higher crop yields.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                    <img src={agriicon2} className="automotive-new-text-2 agriicon" alt="agriculture"/>
                                        <h5 class="automotive-new-text-1">Smart Farming and IoT</h5>
                                        <p class="automotive-new-text-3">The Internet of Things (IoT) has transformed the agriculture industry by connecting devices and systems for intelligent farming. Our IT expertise enables the integration of IoT technologies into your operations. By deploying sensors, automated irrigation systems, and smart monitoring solutions, you can remotely monitor crop health, soil moisture levels, and weather conditions in real-time. This empowers you to take proactive measures, optimize resource allocation, and achieve sustainable farming practices.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                    <img src={agriicon3} className="automotive-new-text-2 agriicon" alt="agriculture"/>
                                        <h5 class="automotive-new-text-1">Data Analytics and Predictive Modeling</h5>
                                        <p class="automotive-new-text-3">Unlock the power of data through advanced analytics and predictive modeling. Our IT solutions leverage machine learning algorithms and big data analytics to analyze historical and real-time data, identifying patterns and predicting future outcomes. By gaining insights into market trends, crop performance, and yield forecasts, you can optimize production planning, make informed decisions, and adapt strategies for maximum profitability.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                    <img src={agriicon4} className="automotive-new-text-2 agriicon" alt="agriculture"/>
                                        <h5 class="automotive-new-text-1">Supply Chain Optimization</h5>
                                        <p class="automotive-new-text-3"> Efficient supply chain management is critical in the agriculture industry, where timely delivery of produce is crucial. Our IT solutions optimize supply chain operations, providing end-to-end visibility, improving logistics, and streamlining inventory management. By implementing robust supply chain systems, you can minimize waste, reduce transportation costs, and ensure freshness and quality from farm to market.

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                    <img src={agriicon5} className="automotive-new-text-2 agriicon" alt="agriculture" />
                                        <h5 class="automotive-new-text-1">Farm Management Systems</h5>
                                        <p class="automotive-new-text-3">Simplify and streamline farm management processes with tailored IT solutions. Our services include the development and implementation of farm management systems that centralize data, automate workflows, and facilitate collaboration among team members. These systems enable efficient planning, scheduling, and tracking of farm activities, ensuring optimal resource allocation and improved productivity.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                    <img src={agriicon6} className="automotive-new-text-2 agriicon" alt="agriculture"/>
                                        <h5 class="automotive-new-text-1">Remote Monitoring and Automation</h5>
                                        <p class="automotive-new-text-3">Embrace remote monitoring and automation technologies to increase operational efficiency. Our IT solutions enable real-time monitoring of equipment, environmental conditions, and livestock health. By implementing automated processes such as robotic farming, drones, and smart machinery, you can reduce labor costs, improve accuracy, and achieve higher productivity.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                    <img src={agriicon7} className="automotive-new-text-2 agriicon" alt="agriculture"/>
                                        <h5 class="automotive-new-text-1">Emerging Technologies and Innovations</h5>
                                        <p class="automotive-new-text-3">Stay ahead of the curve by adopting emerging technologies and innovations in agriculture. Our IT experts closely monitor the latest trends, including vertical farming, hydroponics, and blockchain applications in supply chain management. We help you evaluate and implement these innovations, unlocking new opportunities for growth, sustainability, and market differentiation.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                    <img src={agriicon8} className="automotive-new-text-2 agriicon" alt="agriculture"/>
                                        <h5 class="automotive-new-text-1">Our Approach </h5>
                                        <p class="automotive-new-text-3">At Upstrox, we understand the unique challenges and opportunities within the agriculture industry. Our specialized IT solutions are designed to address these challenges and harness the potential of technology to transform your business. By partnering with us, you gain access to a team of experts dedicated to delivering innovative solutions that optimize your operations, enhance productivity, and drive business success.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="contact-text-section py-5">
                    <div className="container">
                        <div className="row g-4 justify-content-center text-center">
                            <div className="col-lg-12">
                                <p className="contact-text-last mb-lg-4">
                                    Contact us today to explore how our tailored IT solutions can revolutionize your agriculture business. Together, let's leverage technology, implement innovations, and achieve sustainable growth in the dynamic world of agriculture. Join us on this transformative journey as we reshape agriculture through the power of technology.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default Agriculture;