import TravelHospitalitybg from "../../Assets/images/servies-images/travel-and-hospitality.webp";

import { MdOutlineArrowForwardIos } from "react-icons/md";
import React, { useRef, useState, useEffect } from "react";
import { Virtual, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import IconChem1 from "../../Assets/images/icon/travel and hospitality/Property Management Systems.webp";
import IconChem2 from "../../Assets/images/icon/travel and hospitality/Online Booking Engines.webp";
import IconChem3 from "../../Assets/images/icon/travel and hospitality/Central Reservation Systems.webp";
import IconChem4 from "../../Assets/images/icon/travel and hospitality/Customer Relationship Management.webp";
import IconChem5 from "../../Assets/images/icon/travel and hospitality/Revenue Management Systems.webp";
import IconChem6 from "../../Assets/images/icon/travel and hospitality/Channel Management Solutions.webp";
import IconChem7 from "../../Assets/images/icon/travel and hospitality/Mobile Applications and Guest Engagement.webp";
import IconChem8 from "../../Assets/images/icon/travel and hospitality/Data Analytics and Business Intelligence.webp";
import IconChem9 from "../../Assets/images/icon/travel and hospitality/Integrated Accounting and Financial Systems.webp";
import IconChem10 from "../../Assets/images/icon/travel and hospitality/Cybersecurity and Data Protection.webp";
import contactnew from "../../Assets/images/icon/travel and hospitality/travel and hospitality image.webp";
import { BiRightArrowAlt } from "react-icons/bi";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import $ from "jquery";

function TravelHospitality() {
  const [swiperRef, setSwiperRef] = useState(null);

  useEffect(() => {
    let totalTabsCount = 0;
    let activeTabIndex = 1;
    let tabChangeTimeout = 4000;

    totalTabsCount = $("#v-pills-tab .nav-link").length;

    function tabChangeHandler() {
      if (activeTabIndex == totalTabsCount) {
        activeTabIndex = 1;
      } else {
        activeTabIndex++;
      }
      console.log(activeTabIndex, "activeTabIndex");

      $("#v-pills-tab .nav-link")
        .eq(parseInt(activeTabIndex - 1))
        .trigger("click");
    }

    let AUTO_CHANGE_TIMER = setInterval(tabChangeHandler, tabChangeTimeout);

    $(".chemicalmanufacturing-new-design-2-inner-2 .card-body").click(
      function () {
        clearInterval(AUTO_CHANGE_TIMER);
      }
    );
  });

  const slideTo = (index) => {
    swiperRef.slideTo(index - 1, 0);
  };
  return (
    <>
      <div className="travalhospitality-banner-section">
        <section
          className="top-banner-section top-banner-section-overral-bg-section back-posi-img"
          style={{ backgroundImage: "url(" + TravelHospitalitybg + ")" }}
        >
          <div className="mobile-new-ups-bg-shade-inner mx-auto container-fluid">
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one">
                    <span className="text-color">Travel and Hospitality </span>{" "}
                    IT Solutions by Upstrox
                  </h1>
                  <div className="top-text-two">
                    Upstrox is your trusted partner for comprehensive IT
                    solutions tailored specifically for the travel and
                    hospitality industry. We understand the unique challenges
                    and requirements of businesses in this sector, including
                    reservation management, guest experience enhancement,
                    revenue optimization, and operational efficiency.
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <a href="/contact">
                  <button className="top-button right-side">
                    Get More{" "}
                    <MdOutlineArrowForwardIos className="top-icon ms-lg-2" />
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="top-banner-section-two pb-4 pt-5">
          <div className="container">
            <div className="row">
              <div className="col">
                <h5 className="text-one">
                  Our expertise in technology and domain knowledge enables us to
                  deliver innovative solutions that improve customer
                  satisfaction, streamline operations, and drive business
                  growth. Here are the key IT services we offer
                </h5>
              </div>
            </div>
          </div>
        </section>

        <section className="chemicalmanufacturing-new-design-1 py-5">
          <div className="container-fluid col-lg-11 mx-auto py-lg-5">
            <div className="row row-cols-md-1 row-cols-1 row-cols-lg-2 g-4">
              <div className="col mt- card h-1000 chemicalmanufacturing-top-new-card-1">
                <div class="card bg-transparent text-white border-0 p-lg-3">
                  <div class="card-body">
                    <img
                      src={IconChem1}
                      alt="travalhospitality"
                      className="aerospace-new-image-2"
                    />
                    <h5 className="aerospace-new-text-3">
                      Property Management Systems (PMS)
                    </h5>
                    <p className="aerospace-new-text-4 mb-2">
                      We provide robust PMS solutions that streamline
                      reservation management, check-in/check-out processes, and
                      guest communications. Our PMS systems enable efficient
                      room inventory management, rate optimization, guest
                      profile tracking, and integrated billing, ensuring smooth
                      operations and exceptional guest experiences.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mt-0 chemicalmanufacturing-top-new-card-2">
                <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                  <div class="card-body">
                    <img
                      src={IconChem2}
                      alt="travalhospitality"
                      className="aerospace-new-image-2"
                    />
                    <h5 className="aerospace-new-text-3">
                      Online Booking Engines
                    </h5>
                    <p className="aerospace-new-text-4 mb-2">
                      We offer online booking engine solutions that enable
                      seamless and secure online reservations for hotels,
                      resorts, and other accommodations. Our solutions provide
                      real-time availability, dynamic pricing, and integrated
                      payment gateways, enhancing the booking experience and
                      increasing direct bookings.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mt-0 chemicalmanufacturing-top-new-card-3">
                <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                  <div class="card-body">
                    <img
                      src={IconChem3}
                      alt="travalhospitality"
                      className="aerospace-new-image-2"
                    />
                    <h5 className="aerospace-new-text-3">
                      Central Reservation Systems (CRS)
                    </h5>
                    <p className="aerospace-new-text-4 mb-2">
                      We provide CRS solutions that centralize and optimize
                      distribution across multiple channels, including online
                      travel agencies (OTAs), global distribution systems (GDS),
                      and direct channels. Our CRS systems facilitate real-time
                      inventory updates, rate parity management, and reservation
                      synchronization, maximizing revenue opportunities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mt-0 chemicalmanufacturing-top-new-card-4">
                <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                  <div class="card-body">
                    <img
                      src={IconChem4}
                      alt="travalhospitality"
                      className="aerospace-new-image-2"
                    />
                    <h5 className="aerospace-new-text-3">
                      Customer Relationship Management (CRM)
                    </h5>
                    <p className="aerospace-new-text-4 mb-2">
                      We offer CRM solutions tailored for the travel and
                      hospitality industry. Our CRM systems help businesses
                      manage guest data, preferences, and communication history.
                      With our CRM solutions, you can personalize guest
                      interactions, track loyalty programs, and create targeted
                      marketing campaigns to enhance guest satisfaction and
                      loyalty.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="chemicalmanufacturing-new-design-2 py-5 d-lg-none d-block">
          <div className="container-fluid col-lg-11 mx-auto py-lg-5">
            <div className="row">
              <div className="col-lg-12 ">
                <Swiper
                  loop={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  onSwiper={setSwiperRef}
                  keyboard={true}
                  breakpoints={{
                    920: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 1,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 1,
                      spaceBetween: 30,
                    },
                  }}
                  modules={[Autoplay]}
                  className="mySwiper"
                >
                  <SwiperSlide className="swiper-slide-card swiper-slide-card-1">
                    <div className="card h-100 p-2">
                      <div className="card-body">
                        <img
                          src={IconChem5}
                          alt="travalhospitality"
                          className="chemicalmanu-new-image-2"
                        />
                        <h3 className="aerospace-new-text-3">
                          Revenue Management Systems
                        </h3>
                        <p className="aerospace-new-text-4 ">
                          We provide revenue management systems that optimize
                          pricing, inventory allocation, and revenue strategies.
                          Our solutions utilize advanced analytics and demand
                          forecasting to maximize revenue and profitability
                          across different channels and market segments.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide-card swiper-slide-card-2">
                    <div className="card h-100 p-2">
                      <div className="card-body">
                        <img
                          src={IconChem6}
                          alt="travalhospitality"
                          className="chemicalmanu-new-image-2"
                        />
                        <h3 className="aerospace-new-text-3">
                          Channel Management Solutions
                        </h3>
                        <p className="aerospace-new-text-4 ">
                          {" "}
                          We offer channel management solutions that simplify
                          the management and distribution of rates,
                          availability, and content across various online
                          channels. Our solutions enable seamless connectivity
                          with OTAs, GDS, metasearch engines, and direct
                          channels, improving visibility and maximizing booking
                          opportunities.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide-card swiper-slide-card-3">
                    <div className="card h-100 p-2">
                      <div className="card-body">
                        <img
                          src={IconChem7}
                          alt="travalhospitality"
                          className="chemicalmanu-new-image-2"
                        />
                        <h3 className="aerospace-new-text-3">
                          Mobile Applications and Guest Engagement
                        </h3>
                        <p className="aerospace-new-text-4 ">
                          We provide mobile application development and guest
                          engagement solutions that enhance the guest
                          experience. Our solutions include mobile
                          check-in/check-out, room service ordering, loyalty
                          program integration, and personalized guest
                          communication, increasing guest satisfaction and
                          loyalty.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide-card swiper-slide-card-4">
                    <div className="card h-100 p-2">
                      <div className="card-body">
                        <img
                          src={IconChem8}
                          alt="travalhospitality"
                          className="chemicalmanu-new-image-2"
                        />
                        <h3 className="aerospace-new-text-3">
                          Data Analytics and Business Intelligence
                        </h3>
                        <p className="aerospace-new-text-4 ">
                          {" "}
                          Our data analytics and business intelligence solutions
                          help businesses derive valuable insights from their
                          data. We assist in analyzing guest data, booking
                          patterns, market trends, and competitor analysis to
                          drive data-driven decision-making, optimize pricing
                          strategies, and identify revenue growth opportunities.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide-card swiper-slide-card-5">
                    <div className="card h-100 p-2">
                      <div className="card-body">
                        <img
                          src={IconChem9}
                          alt="travalhospitality"
                          className="chemicalmanu-new-image-2"
                        />
                        <h3 className="aerospace-new-text-3">
                          Integrated Accounting and Financial Systems
                        </h3>
                        <p className="aerospace-new-text-4 ">
                          We offer integrated accounting and financial systems
                          that streamline financial operations, including
                          invoicing, accounts receivable, and financial
                          reporting. Our solutions integrate with PMS and other
                          systems, ensuring accurate financial management and
                          reporting.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide-card swiper-slide-card-6">
                    <div className="card h-100 p-2">
                      <div className="card-body">
                        <img
                          src={IconChem10}
                          alt="travalhospitality"
                          className="chemicalmanu-new-image-2"
                        />
                        <h3 className="aerospace-new-text-3">
                          Cybersecurity and Data Protection
                        </h3>
                        <p className="aerospace-new-text-4 ">
                          {" "}
                          Protecting guest data, payment information, and
                          business operations is paramount in the travel and
                          hospitality industry. We provide comprehensive
                          cybersecurity services, including network security,
                          access controls, data encryption, and vulnerability
                          assessments. Our solutions help safeguard your
                          valuable assets and protect against data breaches.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </section>

        <section className="chemicalmanufacturing-new-design-2 py-5 d-lg-block d-none">
          <div className="container-fluid col-lg-11 mx-auto py-lg-5">
            <div className="row">
              <div className="col-lg-5 ">
                <div
                  class="tab-content swiper-slide-card"
                  id="v-pills-tabContent"
                >
                  <div
                    class="tab-pane fade show active"
                    id="v-manu-tabs-1"
                    role="tabpanel"
                    aria-labelledby="v-manu-tabs-1-tab"
                  >
                    <div className="card h-100 p-2">
                      <div className="card-body">
                        <img
                          src={IconChem5}
                          alt="travalhospitality"
                          className="chemicalmanu-new-image-2-1"
                        />
                        <h3 className="aerospace-new-text-3">
                          Revenue Management Systems
                        </h3>
                        <p className="aerospace-new-text-4 ">
                          We provide revenue management systems that optimize
                          pricing, inventory allocation, and revenue strategies.
                          Our solutions utilize advanced analytics and demand
                          forecasting to maximize revenue and profitability
                          across different channels and market segments.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="v-manu-tabs-2"
                    role="tabpanel"
                    aria-labelledby="v-manu-tabs-2-tab"
                  >
                    <div className="card h-100 p-2">
                      <div className="card-body">
                        <img
                          src={IconChem6}
                          alt="travalhospitality"
                          className="chemicalmanu-new-image-2-1"
                        />
                        <h3 className="aerospace-new-text-3">
                          Channel Management Solutions
                        </h3>
                        <p className="aerospace-new-text-4 ">
                          {" "}
                          We offer channel management solutions that simplify
                          the management and distribution of rates,
                          availability, and content across various online
                          channels. Our solutions enable seamless connectivity
                          with OTAs, GDS, metasearch engines, and direct
                          channels, improving visibility and maximizing booking
                          opportunities.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="v-manu-tabs-3"
                    role="tabpanel"
                    aria-labelledby="v-manu-tabs-3-tab"
                  >
                    <div className="card h-100 p-2">
                      <div className="card-body">
                        <img
                          src={IconChem7}
                          alt="travalhospitality"
                          className="chemicalmanu-new-image-2-1"
                        />
                        <h3 className="aerospace-new-text-3">
                          Mobile Applications and Guest Engagement
                        </h3>
                        <p className="aerospace-new-text-4 ">
                          We provide mobile application development and guest
                          engagement solutions that enhance the guest
                          experience. Our solutions include mobile
                          check-in/check-out, room service ordering, loyalty
                          program integration, and personalized guest
                          communication, increasing guest satisfaction and
                          loyalty.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="v-manu-tabs-4"
                    role="tabpanel"
                    aria-labelledby="v-manu-tabs-4-tab"
                  >
                    <div className="card h-100 p-2">
                      <div className="card-body">
                        <img
                          src={IconChem8}
                          alt="travalhospitality"
                          className="chemicalmanu-new-image-2-1"
                        />
                        <h3 className="aerospace-new-text-3">
                          Data Analytics and Business Intelligence
                        </h3>
                        <p className="aerospace-new-text-4 ">
                          {" "}
                          Our data analytics and business intelligence solutions
                          help businesses derive valuable insights from their
                          data. We assist in analyzing guest data, booking
                          patterns, market trends, and competitor analysis to
                          drive data-driven decision-making, optimize pricing
                          strategies, and identify revenue growth opportunities.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="v-manu-tabs-5"
                    role="tabpanel"
                    aria-labelledby="v-manu-tabs-5-tab"
                  >
                    <div className="card h-100 p-2">
                      <div className="card-body">
                        <img
                          src={IconChem9}
                          alt="travalhospitality"
                          className="chemicalmanu-new-image-2-1"
                        />
                        <h3 className="aerospace-new-text-3">
                          Integrated Accounting and Financial Systems
                        </h3>
                        <p className="aerospace-new-text-4 ">
                          We offer integrated accounting and financial systems
                          that streamline financial operations, including
                          invoicing, accounts receivable, and financial
                          reporting. Our solutions integrate with PMS and other
                          systems, ensuring accurate financial management and
                          reporting.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="v-manu-tabs-6"
                    role="tabpanel"
                    aria-labelledby="v-manu-tabs-6-tab"
                  >
                    <div className="card h-100 p-2">
                      <div className="card-body">
                        <img
                          src={IconChem10}
                          alt="travalhospitality"
                          className="chemicalmanu-new-image-2-1"
                        />
                        <h3 className="aerospace-new-text-3">
                          Cybersecurity and Data Protection
                        </h3>
                        <p className="aerospace-new-text-4 ">
                          {" "}
                          Protecting guest data, payment information, and
                          business operations is paramount in the travel and
                          hospitality industry. We provide comprehensive
                          cybersecurity services, including network security,
                          access controls, data encryption, and vulnerability
                          assessments. Our solutions help safeguard your
                          valuable assets and protect against data breaches.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div
                  class="nav nav-pills row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 chemicalmanufacturing-new-design-2-inner-2"
                  id="v-pills-tab"
                  role="tablist"
                >
                  <div className="col">
                    <div
                      class="card h-100 nav-link active"
                      id="v-manu-tabs-1-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-manu-tabs-1"
                      type="button"
                      role="tab"
                      aria-controls="v-manu-tabs-1"
                      aria-selected="true"
                    >
                      <div class="card-body">
                        <img
                          src={IconChem5}
                          alt="travalhospitality"
                          className="chemicalmanu-new-image-2"
                        />
                        <h5 class="chemicalmanu-new-text-1">
                          Revenue Management Systems
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div
                      class="nav-link card h-100"
                      id="v-manu-tabs-2-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-manu-tabs-2"
                      type="button"
                      role="tab"
                      aria-controls="v-manu-tabs-2"
                      aria-selected="false"
                    >
                      <div class="card-body">
                        <img
                          src={IconChem6}
                          alt="travalhospitality"
                          className="chemicalmanu-new-image-2"
                        />
                        <h5 class="chemicalmanu-new-text-1">
                          Channel Management Solutions
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div
                      class="nav-link card h-100"
                      id="v-manu-tabs-3-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-manu-tabs-3"
                      type="button"
                      role="tab"
                      aria-controls="v-manu-tabs-3"
                      aria-selected="false"
                    >
                      <div class="card-body">
                        <img
                          src={IconChem7}
                          alt="travalhospitality"
                          className="chemicalmanu-new-image-2"
                        />
                        <h5 class="chemicalmanu-new-text-1">
                          Mobile Applications and Guest Engagement
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div
                      class="nav-link card h-100"
                      id="v-manu-tabs-4-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-manu-tabs-4"
                      type="button"
                      role="tab"
                      aria-controls="v-manu-tabs-4"
                      aria-selected="false"
                    >
                      <div class="card-body">
                        <img
                          src={IconChem8}
                          alt="travalhospitality"
                          className="chemicalmanu-new-image-2"
                        />
                        <h5 class="chemicalmanu-new-text-1">
                          Data Analytics and Business Intelligence
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div
                      class="nav-link card h-100"
                      id="v-manu-tabs-5-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-manu-tabs-5"
                      type="button"
                      role="tab"
                      aria-controls="v-manu-tabs-5"
                      aria-selected="false"
                    >
                      <div class="card-body">
                        <img
                          src={IconChem9}
                          alt="travalhospitality"
                          className="chemicalmanu-new-image-2"
                        />
                        <h5 class="chemicalmanu-new-text-1">
                          Integrated Accounting and Financial Systems
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div
                      class="nav-link card h-100"
                      id="v-manu-tabs-6-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-manu-tabs-6"
                      type="button"
                      role="tab"
                      aria-controls="v-manu-tabs-6"
                      aria-selected="false"
                    >
                      <div class="card-body">
                        <img
                          src={IconChem10}
                          alt="travalhospitality"
                          className="chemicalmanu-new-image-2"
                        />
                        <h5 class="chemicalmanu-new-text-1">
                          Cybersecurity and Data Protection
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 flex-lg-row-reverse align-items-center">
              <div className="col-lg-4">
                <img src={contactnew} alt="travalhospitality" />
              </div>
              <div className="col-lg-8">
                <p className="aerospace-new-text-5 mb-lg-4">
                  At Upstrox, we are committed to supporting the travel and
                  hospitality industry with innovative and reliable IT
                  solutions.{" "}
                </p>
                <a href="/contact" className="new-ups-button-2">
                  <span className="new-ups-button-icon-3">Read More</span>{" "}
                  <BiRightArrowAlt className="new-ups-button-icon-2" />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="aerospace-new-text-6 mb-lg-4">
                  {" "}
                  Contact us today to discuss your specific requirements and
                  explore how Upstrox can empower your organization's
                  operations, guest experiences, and business growth with our
                  industry-leading IT services.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default TravelHospitality;
