import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate

} from "react-router-dom";
import './Assets/css/Style.css';
import './Assets/css/Styleone.css';
import './Assets/css/New.css';


import Navbar from "./Separate/Navbar"
import Footer from "./Separate/Footer"
import AerospaceDefence from "./Pages/Industries/AerospaceDefence";
import Automotive from "./Pages/Industries/Automotive";
import IndustrialManufacturing from "./Pages/Industries/IndustrialManufacturing";
import ChemicalManufacturing from "./Pages/Industries/ChemicalManufacturing";
import Mining from "./Pages/Industries/Mining";
import OilGas from "./Pages/Industries/OilGas";
import Healthcare from "./Pages/Industries/Healthcare";
import Insurance from "./Pages/Industries/Insurance";
import Lifescience from "./Pages/Industries/Lifescience";
import TravelHospitality from "./Pages/Industries/TravelHospitality";
import Informationservice from "./Pages/Industries/Informationservice";
import EngineeringProcurement from "./Pages/Industries/EngineeringProcurement";
import Communicationservice from "./Pages/Industries/Communicationservice";
import AdvancedDriverAssistanceSystems from "./Pages/Industries/AdvancedDriverAssistanceSystems";
import HighTechnology from "./Pages/Industries/HighTechnology";
import Agriculture from "./Pages/Industries/Agriculture";
import PublicSector from "./Pages/Industries/PublicSector";
import Education from "./Pages/Industries/Education";
import WasteManagement from "./Pages/Industries/WasteManagement";
import MediaEntertainment from "./Pages/Industries/MediaEntertainment";
import Utilities from "./Pages/Industries/Utilities";
import FinancialService from "./Pages/Industries/FinancialService";
import PrivateEquity from "./Pages/Industries/PrivateEquity";
import ProfessionalServices from "./Pages/Industries/ProfessionalServices";
import Retail from "./Pages/Industries/Retail";
import ConsumerPackaged from "./Pages/Industries/ConsumerPackaged";
import LogisticsDistribution from "./Pages/Industries/LogisticsDistribution";
import Career from "./Pages/career/Career";
import AboutUs from "./Pages/About/Aboutus";
import Sustainability from "./Pages/About/sustainability";
import InfrastructureandCloudServices from "./Pages/Services/InfrastructureandCloudServices";
import Networkservices from "./Pages/Services/Networkservices";
import TelecomProductDevelopment from "./Pages/Services/TelecomProductDevelopment";
import CyberSecurity from "./Pages/Services/CyberSecurity";
import EnterpriseFutureServices from "./Pages/Services/EnterpriseFutureServices";
import IntelligentAutomation from "./Pages/Services/IntelligentAutomation";
import Oracle from "./Pages/Services/Oracle";
import MetaverseDevelopment from "./Pages/Services/MetaverseDevelopment";
import DAppandDeFi from "./Pages/Services/DAppandDeFi";
import SmartContractDevelopment from "./Pages/Services/SmartContractDevelopment";
import Blockchain from "./Pages/Services/Blockchain";
import ArtificialIntelligence from "./Pages/Services/ArtificialIntelligence";
import DigitalSupplyChain from "./Pages/Services/DigitalSupplyChain";
import BusinessProcessServices from "./Pages/Services/BusinessProcessServices";
import BusinessExcellenceServices from "./Pages/Services/BusinessExcellenceServices";
import TestingServices from "./Pages/Services/TestingServices";
import IntegratedEngineeringSolutions from "./Pages/Services/IntegratedEngineeringSolutions";
import PerformanceEngineering from "./Pages/Services/PerformanceEngineering";
import ExperienceDesignService from "./Pages/Services/ExperienceDesignService";
import SAP from "./Pages/Services/SAP";
import DataAnalytics from "./Pages/Services/DataAnalytics";
import CustomerExperience from "./Pages/Services/CustomerExperience";
import SustainabilityasaService from "./Pages/Services/SustainabilityasaService";
import Contact from "./Pages/Contact/Contactus";
import Error from "./Error";
import Thankyou from "./Thankyou";
import Home from "./Home";
import { useState } from 'react';
import Blog from "./Pages/Insights/Blog";

function App() {

  const [showNavbar, setShowNavbar] = useState(true);

  return (
    <div className="App">
           {showNavbar && <Navbar />}
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/aerospace-service-offering" element={<AerospaceDefence />} />
          <Route exact path="/automotive-services-offering" element={<Automotive />} />
          <Route exact path="/industrial-manufacturing" element={<IndustrialManufacturing />} />
          <Route exact path="/chemical-manufacturing" element={<ChemicalManufacturing />} />
          <Route exact path="/mining" element={<Mining />} />
          <Route exact path="/oil-and-gas" element={<OilGas />} />
          <Route exact path="/healthcare" element={<Healthcare />} />
          <Route exact path="/insurance" element={<Insurance />} />
          <Route exact path="/life-science" element={<Lifescience />} />
          <Route exact path="/travel-hospitality" element={<TravelHospitality />} />
          <Route exact path="/information-service" element={<Informationservice />} />
          <Route exact path="/engineering-procurement" element={<EngineeringProcurement />} />
          <Route exact path="/communication-service" element={<Communicationservice />} />
          <Route exact path="/advanced-driver-assistance-systems" element={<AdvancedDriverAssistanceSystems />} />
          <Route exact path="/high-technology" element={<HighTechnology />} />
          <Route exact path="/agriculture" element={<Agriculture />} />
          <Route exact path="/public-sector" element={<PublicSector />} />
          <Route exact path="/education" element={<Education />} />
          <Route exact path="/waste-management" element={<WasteManagement />} />
          <Route exact path="/media-and-entertainment" element={<MediaEntertainment />} />
          <Route exact path="/utilities" element={<Utilities />} />
          <Route exact path="/financial-service" element={<FinancialService />} />
          <Route exact path="/private-equity" element={<PrivateEquity />} />
          <Route exact path="/professional-services" element={<ProfessionalServices />} />
          <Route exact path="/retail" element={<Retail />} />
          <Route exact path="/consumer-packaged" element={<ConsumerPackaged />} />
          <Route exact path="/logistics-distribution" element={<LogisticsDistribution />} />
          <Route exact path="/career" element={<Career />} />
          <Route exact path="/aboutus" element={<AboutUs />} />
          <Route exact path="/sustainability" element={<Sustainability />} />
          <Route exact path="/infrastructure-and-cloud-services" element={<InfrastructureandCloudServices/>} />
          <Route exact path="/network-services" element={<Networkservices/>} />
          <Route exact path="/telecom-product-development" element={<TelecomProductDevelopment/>} />
          <Route exact path="/cyber-security" element={<CyberSecurity/>} />
          <Route exact path="/enterprise-future-services" element={<EnterpriseFutureServices/>} />
          <Route exact path="/intelligent-automation" element={<IntelligentAutomation/>} />
          <Route exact path="/oracle-cloud-services" element={<Oracle/>} />
          <Route exact path="/metaverse-development-services" element={<MetaverseDevelopment/>} />
          <Route exact path="/dapp-and-defi" element={<DAppandDeFi/>} />
          <Route exact path="/smart-contract-development" element={<SmartContractDevelopment/>} />
          <Route exact path="/blockchain-development-services" element={<Blockchain/>} />
          <Route exact path="/ai-development-companies" element={<ArtificialIntelligence/>} />
          <Route exact path="/digital-supply-chain" element={<DigitalSupplyChain/>} />
          <Route exact path="/business-process-services" element={<BusinessProcessServices/>} />
          <Route exact path="/business-excellence-services" element={<BusinessExcellenceServices/>} />
          <Route exact path="/qa-and-software-testing-company" element={<TestingServices/>} />
          <Route exact path="/integrated-engineering-solutions" element={<IntegratedEngineeringSolutions/>} />
          <Route exact path="/performance-engineering" element={<PerformanceEngineering/>} />
          <Route exact path="/experience-design-service" element={<ExperienceDesignService/>} />
          <Route exact path="/sap" element={<SAP/>} />
          <Route exact path="/data-analytics-companies" element={<DataAnalytics/>} />
          <Route exact path="/customer-experience" element={<CustomerExperience/>} />
          <Route exact path="/sustainability-as-a-service" element={<SustainabilityasaService/>} />
          <Route exact path="/contact" element={<Contact/>} />
          <Route exact path="*"  element={<Error setShowNavbar={setShowNavbar} /> }/>
          <Route exact path="/thankyou" element={<Thankyou/>} />
          <Route exact path="/blog" element={<Blog />} />
          <Route path="/aerospacedefence" element={<Navigate replace to="/aerospace-service-offering" />} />
          <Route path="/industrialmanufacturing" element={<Navigate replace to="/industrial-manufacturing" />} />
          <Route path="/dappanddefi" element={<Navigate replace to="/dapp-and-defi" />} />
          <Route path="/oilgas" element={<Navigate replace to="/oil-and-gas" />} />
          <Route path="/privateequity" element={<Navigate replace to="/private-equity" />} />
          <Route path="/lifescience" element={<Navigate replace to="/life-science" />} />
          <Route path="/experiencedesignservice" element={<Navigate replace to="/experience-design-service" />} />
          <Route path="/hightechnology" element={<Navigate replace to="/high-technology" />} />
          <Route path="/financialservice" element={<Navigate replace to="/financial-service" />} />
          <Route path="/artificialintelligence" element={<Navigate replace to="/ai-development-companies" />} />
          <Route path="/businessexcellenceservices" element={<Navigate replace to="/business-excellence-services" />} />
          <Route path="/communicationservice" element={<Navigate replace to="/communication-service" />} />
          <Route path="/blockchain" element={<Navigate replace to="/blockchain-development-services" />} />
          <Route path="/cybersecurity" element={<Navigate replace to="/cyber-security" />} />
          <Route path="/networkservices" element={<Navigate replace to="/network-services" />} />
          <Route path="/oracle" element={<Navigate replace to="/oracle-cloud-services" />} />
          <Route path="/engineeringprocurement" element={<Navigate replace to="/engineering-procurement" />} />
          <Route path="/testingservices" element={<Navigate replace to="/qa-and-software-testing-company" />} />
          <Route path="/chemicalmanufacturing" element={<Navigate replace to="/chemical-manufacturing" />} />
          <Route path="/customerexperience" element={<Navigate replace to="/customer-experience" />} />
          <Route path="/wastemanagement" element={<Navigate replace to="/waste-management" />} />
        </Routes>
      </Router>
      {showNavbar && <Footer />}
    </div>
  );
}

export default App;
