import { BiRightArrowAlt } from 'react-icons/bi';
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Dummybg from "../../Assets/images/icon/professional services/professional-services landing banner.webp"
import contactnew from "../../Assets/images/icon/professional services/professional services image.webp"
import proicon1 from "../../Assets/images/icon/professional services/Collaboration and Communication Tools.webp"
import proicon2 from "../../Assets/images/icon/professional services/Data Analytics and Business Intelligence.webp"
import proicon3 from "../../Assets/images/icon/professional services/Cybersecurity and Data Protection.webp"
import proicon4 from "../../Assets/images/icon/professional services/Data Analytics and Business Intelligence copy.webp"

function ProfessionalServices() {
  return (
    <>
      <div className="professionalservice-banner-section">
        <section className="top-banner-section top-banner-section-overral-bg-section professional-background back-posi-img" style={{ backgroundImage: "url(" + Dummybg + ")" }}>
          <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one">
                    <span className="text-color"> Professional Services </span>  IT Solutions by Upstrox
                  </h1>
                  <div className="top-text-two">Upstrox is your trusted partner for comprehensive IT solutions tailored specifically for professional services firms. We understand the unique challenges and requirements of consulting, legal, accounting, and other professional services organizations.
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
              </div>
            </div>
          </div>
        </section>

        <section className="top-banner-section-two py-3">
          <div className="container">
            <div className="row">
              <div className="col">
                <h5 className="text-one">
                  Our expertise in technology and industry knowledge enables us to deliver innovative solutions that enhance operational efficiency, client management, and collaboration. Here are the key IT services we offer
                </h5>
              </div>
            </div>
          </div>
        </section>
        <section className="sustaincard-section-two py-5 sustaincard-section">
          <div className="container">
            <div className="row">

              <div className="col-lg-4 col-md-4 py-2">
                <div className="card h-100 p-4 top-border-pro" >

                  <h4 className="card-text-one">Project and Engagement Management</h4>
                  <h5 className="card-text-two">
                    We offer project and engagement management solutions that streamline project planning, resource allocation, and time tracking. Our solutions facilitate efficient project collaboration, task management, and budget control, ensuring successful project delivery and client satisfaction.
                  </h5>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 py-2">
                <div className="card h-100 p-4" style={{ backgroundColor: "rgba(46, 46, 46, 0.199)" }}>

                  <h4 className="card-text-one">Time and Expense Tracking</h4>
                  <h5 className="card-text-two">
                    We offer time and expense tracking solutions that automate the capture, tracking, and reporting of billable hours and expenses. Our systems provide real-time visibility into project costs, facilitate accurate invoicing, and support financial analysis.

                  </h5>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 py-2">
                <div className="card h-100 p-4  top-border-pro">

                  <h4 className="card-text-one">Document Management Systems</h4>
                  <h5 className="card-text-two">
                    We provide document management systems that centralize and streamline document creation, storage, and retrieval. Our solutions enable version control, document sharing, collaboration, and secure access, improving efficiency, and ensuring data security.

                  </h5>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 py-2">
                <div className="card h-100 p-4  " style={{ backgroundColor: "rgba(46, 46, 46, 0.199)" }}>

                  <h4 className="card-text-one">Client Relationship Management (CRM)</h4>
                  <h5 className="card-text-two">
                    We provide CRM solutions that help professional services firms manage client relationships, track opportunities, and improve sales effectiveness. Our CRM systems enable contact management, pipeline tracking, client communication, and marketing automation, enhancing client engagement and business development efforts.

                  </h5>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 py-2">
                <div className="card h-100 p-4 top-border-pro">

                  <h4 className="card-text-one">Financial Management Systems</h4>
                  <h5 className="card-text-two">
                    We provide financial management systems that optimize financial operations for professional services firms. Our solutions cover accounting, budgeting, invoicing, and financial reporting, ensuring accurate financial management and compliance with regulatory requirements.
                  </h5>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 py-2">
                <div className="card h-100 p-4" style={{ backgroundColor: "rgba(46, 46, 46, 0.199)" }}>

                  <h4 className="card-text-one">Resource Management and Scheduling</h4>
                  <h5 className="card-text-two">
                    We offer resource management and scheduling solutions that help professional services firms efficiently allocate and schedule resources. Our solutions provide visibility into resource availability, skills matching, and project staffing, optimizing resource utilization and project profitability.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="chemicalmanufacturing-new-design-1 pb-5">
          <div className="container-fluid col-lg-10 mx-auto py-lg-5">
            <div className="row row-cols-md-1 row-cols-1 row-cols-lg-2 g-4">
              <div className="col mt- card h-1000 chemicalmanufacturing-top-new-card-1">
                <div class="card bg-transparent text-white border-0 p-lg-3">
                  <div class="card-body">
                    <img src={proicon1} alt="professionalservice" className="aerospace-new-image-2" />
                    <h5 className="aerospace-new-text-3">Collaboration and Communication Tools</h5>
                    <p className="aerospace-new-text-4 mb-2"> We provide collaboration and communication tools that enable seamless collaboration among teams and clients. Our solutions include document sharing, team collaboration platforms, video conferencing, and secure client portals, fostering effective communication and collaboration.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mt-0 chemicalmanufacturing-top-new-card-2">
                <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                  <div class="card-body">
                    <img src={proicon2} alt="professionalservice" className="aerospace-new-image-2" />
                    <h5 className="aerospace-new-text-3">Data Analytics and Business Intelligence</h5>
                    <p className="aerospace-new-text-4 mb-2">Our data analytics and business intelligence solutions help professional services firms derive valuable insights from their data. We assist in analyzing client data, project performance, and financial metrics to drive data-driven decision-making, identify trends, and optimize business operations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mt-0 chemicalmanufacturing-top-new-card-3">
                <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                  <div class="card-body">
                    <img src={proicon3} alt="professionalservice" className="aerospace-new-image-2" />
                    <h5 className="aerospace-new-text-3">Cybersecurity and Data Protection</h5>
                    <p className="aerospace-new-text-4 mb-2">Protecting sensitive client data, intellectual property, and confidential information is crucial in professional services firms. We provide comprehensive cybersecurity services, including network security, access controls, data encryption, and vulnerability assessments. Our solutions help safeguard your valuable assets and protect against cyber threats.</p>
                  </div>
                </div>
              </div>
              <div className="col mt-0 chemicalmanufacturing-top-new-card-4">
                <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                  <div class="card-body">
                    <img src={proicon4} alt="professionalservice" className="aerospace-new-image-2" />
                    <h5 className="aerospace-new-text-3">IT Consulting and Strategy</h5>
                    <p className="aerospace-new-text-4 mb-2">Our experienced consultants provide IT consulting services to professional services firms, helping develop technology strategies, optimize workflows, and implement digital transformation initiatives. We assist in technology selection, vendor management, and leveraging emerging technologies to drive operational efficiency and growth.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 flex-lg-row-reverse">
              <div className="col-lg-4">
                <img src={contactnew} alt="professionalservice" />
              </div>
              <div className="col-lg-8">
                <p className="aerospace-new-text-5 mb-lg-4">At Upstrox, we are committed to supporting professional services firms with innovative and reliable IT solutions. </p>
                <a href='/contact' className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="aerospace-new-text-6 mb-lg-4">Contact us today to discuss your specific requirements and explore how Upstrox can empower your organization's operations, client management, and growth with our industry-leading IT services.
                </p>
              </div>
            </div>
          </div>
        </section>

      </div>
    </>
  )
}
export default ProfessionalServices;