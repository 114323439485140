import { IoMdAnalytics } from "react-icons/io";
import { AiOutlineAudit } from "react-icons/ai";
import { GiGreenhouse } from "react-icons/gi";
import { SlEnergy } from "react-icons/sl";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Dummybg from "../../Assets/images/servies-images/demo/dummy-1.webp"
import { BiRightArrowAlt } from "react-icons/bi";
import sustain from "../../Assets/images/sustain-footer-img.jpg";
import sustainemployee from "../../Assets/images/servies-images/sustainability/sustainemployee.webp";
import sustainabilityreporting from "../../Assets/images/servies-images/sustainability/sustainabilityreporting.webp";
import technologysolution from "../../Assets/images/servies-images/sustainability/technologysolution.webp";
import { useEffect } from "react";

function SustainabilityasaService() {
    
    return (
        <>
            <div className="sustainability-section">
                <section className="top-banner-section top-banner-section-overral-bg-section sustainability-background back-posi-img">
                    <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1 className="top-text-one"><span className="text-color">Sustainability as a Service</span> by Upstrox</h1>
                                    <div className="top-text-two">Upstrox is committed to helping businesses achieve their sustainability goals and promote environmentally responsible practices. We offer Sustainability as a Service, providing comprehensive solutions to support organizations in their sustainability initiatives.</div>
                                </div>
                            </div>
                            <div className="pt-3">
                                <a href="/Contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="sustaincard-section">
                    <section className="sustaincard-section-one py-5">
                        <div className="container">
                            <h5 className="text-one">Our services encompass sustainability strategy development, implementation, monitoring, and reporting. Here's how Upstrox can assist your organization</h5>
                            <div className="row pt-lg-5 pt-3 d-flex align-items-center">
                                <div className="col-lg-1 col-md-2">
                                    <div className=""><IoMdAnalytics className="sustain-icon" /></div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <h4 className="card-text-one">Sustainability Strategy Development</h4></div>
                                <div className="col">
                                    <h5 className="card-text-two">
                                        Our experts work closely with your organization to develop a tailored sustainability strategy aligned with your business objectives. We conduct a comprehensive assessment of your current practices, identify opportunities for improvement, and define key sustainability goals and targets.
                                    </h5>
                                </div>
                            </div>
                            <div className="row pt-lg-5 pt-3 d-flex align-items-center">
                                <div className="col-lg-1 col-md-2">
                                    <div className=""><AiOutlineAudit className="sustain-icon" /></div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <h4 className="card-text-one">Sustainability Audits and Assessments</h4></div>
                                <div className="col">
                                    <h5 className="card-text-two">
                                        We conduct sustainability audits and assessments to evaluate your organization's environmental impact, resource consumption, waste management, and carbon footprint. Our audits help identify areas for improvement and guide the development of sustainability action plans.
                                    </h5>
                                </div>
                            </div>
                            <div className="row pt-lg-5 pt-3 d-flex align-items-center">
                                <div className="col-lg-1 col-md-2">
                                    <div className=""><GiGreenhouse className="sustain-icon" /></div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <h4 className="card-text-one">Greenhouse Gas (GHG) Emissions Management</h4></div>
                                <div className="col">
                                    <h5 className="card-text-two">
                                        We assist organizations in managing and reducing their greenhouse gas emissions. Our experts analyze your operations, calculate emissions, and develop strategies to mitigate environmental impact. We also support carbon offsetting initiatives and help organizations transition to renewable energy sources.
                                    </h5>
                                </div>
                            </div>
                            <div className="row pt-lg-5 pt-3 d-flex align-items-center">
                                <div className="col-lg-1 col-md-2">
                                    <div className=""><SlEnergy className="sustain-icon" /></div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <h4 className="card-text-one">Energy Efficiency Solutions</h4></div>
                                <div className="col">
                                    <h5 className="card-text-two">
                                        We provide energy efficiency solutions to optimize energy consumption and reduce costs. Our experts conduct energy audits, identify energy-saving opportunities, recommend energy-efficient technologies, and assist in the implementation of energy management systems.
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sustaincard-section-two py-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 py-2">
                                    <div className="card h-100 p-4 top-border">

                                        <h4 className="card-text-one">Waste Management and Circular Economy</h4>
                                        <h5 className="card-text-two">
                                            We help organizations develop effective waste management strategies and promote the principles of the circular economy. Our solutions include waste audits, waste reduction programs, recycling initiatives, and the implementation of circular supply chain practices.
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 py-2">
                                    <div className="card h-100 p-4" style={{ backgroundColor: "rgba(46, 46, 46, 0.199)" }}>

                                        <h4 className="card-text-one">Water Management and Conservation</h4>
                                        <h5 className="card-text-two">
                                            We support organizations in managing water resources sustainably. Our experts analyze water usage, identify areas for conservation, implement water-efficient technologies, and develop water management strategies to reduce consumption and minimize environmental impact.
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 py-2">
                                    <div className="card h-100 p-4 top-border">

                                        <h4 className="card-text-one">Sustainable Supply Chain Management</h4>
                                        <h5 className="card-text-two">
                                            We assist organizations in implementing sustainable practices across their supply chains. Our experts help identify suppliers with strong sustainability credentials, develop supplier codes of conduct, and implement responsible sourcing practices to promote social and environmental responsibility.
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sustaincard-section-three py-3">
                        <div className="container">
                            <div className="row d-flex justify-content-center pt-lg-5">
                                <div className="col">
                                    <div className="card h-100 p-3 text-center">
                                        <div className="pb-3"><img src={sustainabilityreporting} alt="sustainabilityreporting" className="sustain-card-img" /></div>
                                        <h4 className="card-text-one">Sustainability Reporting and Compliance</h4>
                                        <h5 className="card-text-two">
                                            We help organizations meet sustainability reporting requirements and comply with relevant regulations and frameworks. Our experts assist in developing sustainability reports, tracking key performance indicators (KPIs), and ensuring transparency in environmental, social, and governance (ESG) disclosures.
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center pb-lg-5">
                                <div className="col-lg-6 col-md-6">
                                    <div className="card h-100 p-3 text-center">
                                        <div className="pb-3"><img src={sustainemployee} alt="sustainemployee" className="sustain-card-img" /></div>
                                        <h4 className="card-text-one">Employee Engagement and Training</h4>
                                        <h5 className="card-text-two">
                                            We provide employee engagement programs and training sessions to foster a culture of sustainability within your organization. Our initiatives include awareness campaigns, training on sustainable practices, and employee engagement platforms to encourage active participation in sustainability efforts.
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="card h-100 p-3 text-center">
                                        <div className="pb-3"><img src={technologysolution} alt="technologysolution" className="sustain-card-img" /></div>
                                        <h4 className="card-text-one">Sustainability Technology Solutions</h4>
                                        <h5 className="card-text-two">
                                            We offer technology solutions to support sustainability initiatives, including energy management systems, environmental monitoring platforms, and sustainability data management tools. Our experts help organizations leverage these technologies to streamline data collection, analysis, and reporting for enhanced sustainability performance.
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sustaincard-section-four py-3 py-lg-5">
                        <div className="container">
                            <div className="row d-flex align-items-center g-4">
                                <div className="col-lg-8 col-md-8"><p className="service-footer-text mb-4">At Upstrox, we are dedicated to supporting organizations in their journey towards sustainability.</p>
                                    <a href="contact" className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
                                </div>
                                <div className="col-lg-4 col-md-4"><img src={sustain} alt="sustain" className="service-footer-img"></img></div>
                            </div>
                        </div>
                    </section>
                    <section className="sustaincard-section-five pt-5">
                        <div className="container">
                            <div className="row g-4 justify-content-center text-center">
                                <div className="col-lg-12">
                                    <p className="contact-text mb-lg-4">Contact us today to discuss your specific sustainability goals and explore how Upstrox can assist your organization with our comprehensive Sustainability as a Service offering.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

            </div>
        </>
    )
}
export default SustainabilityasaService;