import { MdOutlineArrowForwardIos } from "react-icons/md";
import point from "../../Assets/images/icon/mining/Mining Operation Management Systems.webp";
import ecom from "../../Assets/images/icon/mining/Geographical Information Systems.webp";
import inventory from "../../Assets/images/icon/mining/Mine Safety and Monitoring Solutions.webp";
import ContactNew from "../../Assets/images/icon/mining/image 2.webp";
import { BiRightArrowAlt } from "react-icons/bi";
import Equip from "../../Assets/images/icon/mining/Asset and Equipment Management.webp";
import mine from "../../Assets/images/icon/mining/Mine Planning and Design.webp";
import remote from "../../Assets/images/icon/mining/Remote Sensing and Drone Technology.webp";
import data from "../../Assets/images/icon/mining/Data Analytics and Business Intelligence.webp";
import environ from "../../Assets/images/icon/mining/Environmental Management Systems.webp";
import cyber from "../../Assets/images/icon/mining/Cybersecurity and Data Protection.webp";
import it from "../../Assets/images/icon/mining/IT Consulting and Strategy.webp";

function Mining() {
  return (
    <>
      <div className="mining-banner-section">
        <section className=" mining-img back-posi-img">
          <div className="mobile-new-ups-bg-shade-inner mx-auto container-fluid top-banner-section">
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one">
                    <span className="text-color">Mining </span> IT Solutions by
                    Upstrox
                  </h1>
                  <div className="top-text-two">
                    Upstrox is your trusted partner for comprehensive IT
                    solutions tailored specifically for the mining industry. We
                    understand the unique challenges and requirements of mining
                    operations, including safety, productivity, and operational
                    efficiency.
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <a href="/contact">
                  <button className="top-button right-side">
                    Get More{" "}
                    <MdOutlineArrowForwardIos className="top-icon ms-lg-2" />
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="retail-banner-section-two pt-5">
          <div className="container">
            <div className="row">
              <div className="col">
                <h5 className="retail-tw-text">
                  Our expertise in technology and domain knowledge enables us to
                  deliver innovative solutions that enhance mining processes,
                  optimize resource utilization, and drive operational
                  excellence. Here are the key IT services we offer:
                </h5>
              </div>
            </div>
          </div>
        </section>

        <section className="retail-banner-section-three py-5">
          <div className="container-fluid card-re-one col-lg-10">
            <div className="row row-cols-lg-2 row-cols-1 row-cols-md-2 justify-content-md-end">
              <div className="col pt-3 mt-lg-3 ">
                <div className="card-re-border-top"></div>
                <div
                  className=" card card-back-re1"
                  style={{ borderColor: "rgb(83 83 83)" }}
                >
                  <div
                    className="card-body d-lg-flex"
                    style={{ background: "rgb(75 81 81 / 23%)" }}
                  >
                    <div className="re-icon-one">
                      {" "}
                      <img src={point} className="img-card-one" alt="mining" />
                    </div>
                    <div>
                      <h4 className="card-text-one">
                        Mining Operation Management Systems
                      </h4>
                      <h5 className="card-text-two">
                        We provide robust mining operation management systems
                        that streamline and optimize various aspects of mining
                        operations. Our systems cover mine planning, fleet
                        management, production scheduling, asset tracking, and
                        real-time monitoring, improving productivity and
                        resource utilization.
                      </h5>
                    </div>
                  </div>
                </div>
                <div
                  className="card-re-border-bottom"
                  style={{ borderColor: "rgb(83 83 83 / 34%)" }}
                ></div>
              </div>
              <div className="col pt-3 pt-lg-0 retail-card-one">
                <div className="card-re-border-top"></div>
                <div
                  className=" card card-back-re2"
                  style={{ borderColor: "rgb(255 255 255 / 40%)" }}
                >
                  <div
                    className="card-body d-lg-flex"
                    style={{
                      background: "rgb(255 255 255 / 11%)",
                      borderColor: "black",
                    }}
                  >
                    <div className="re-icon-one">
                      {" "}
                      <img src={ecom} alt="mining" className="img-card-one" />
                    </div>
                    <div>
                      <h4 className="card-text-one">
                        Geographical Information Systems (GIS)
                      </h4>
                      <h5 className="card-text-two">
                        We offer GIS solutions that enable effective spatial
                        analysis, visualization, and management of mining assets
                        and resources. Our GIS systems assist in exploration,
                        land management, environmental monitoring, and mine
                        mapping, supporting informed decision-making and
                        compliance with regulatory requirements.
                      </h5>
                    </div>
                  </div>
                </div>
                <div
                  className="card-re-border-bottom"
                  style={{ borderColor: "rgb(255 255 255 / 8%)" }}
                ></div>
              </div>
              <div className="col retail-card-one pt-md-5 pt-4">
                <div className="card-re-border-top"></div>
                <div
                  className=" card card-back-re3"
                  style={{ borderColor: "rgb(255 255 255 / 40%)" }}
                >
                  <div
                    className="card-body d-lg-flex"
                    style={{ background: "rgb(255 255 255 / 11%)" }}
                  >
                    <div className="re-icon-one">
                      <img
                        src={inventory}
                        alt="mining"
                        className="img-card-one"
                      />
                    </div>
                    <div>
                      <h4 className="card-text-one">
                        Mine Safety and Monitoring Solutions
                      </h4>
                      <h5 className="card-text-two">
                        We provide mine safety and monitoring solutions that
                        help ensure a safe working environment for mining
                        personnel. Our solutions include real-time monitoring of
                        gas levels, ventilation systems, worker location
                        tracking, and emergency response systems, enhancing
                        safety and reducing risks.
                      </h5>
                    </div>
                  </div>
                </div>
                <div
                  className="card-re-border-bottom"
                  style={{ borderColor: "rgb(255 255 255 / 8%)" }}
                ></div>
              </div>
            </div>
          </div>
        </section>

        <section className="sustaincard-section">
          <section className="sustaincard-section-one py-5">
            <div className="container">
              <div className="row pt-lg-5 pt-3 d-flex align-items-center">
                <div className="col-lg-1 col-md-2">
                  <div className="">
                    {" "}
                    <img src={Equip} className="img-card-one" alt="mining" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <h4 className="card-text-one">
                    Asset and Equipment Management
                  </h4>
                </div>
                <div className="col">
                  <h5 className="card-text-two">
                    We offer asset and equipment management solutions to
                    optimize the utilization, maintenance, and lifecycle of
                    mining equipment. Our systems enable predictive maintenance,
                    inventory control, and performance tracking, minimizing
                    downtime and maximizing equipment efficiency.
                  </h5>
                </div>
              </div>
              <div className="row pt-lg-5 pt-3 d-flex align-items-center">
                <div className="col-lg-1 col-md-2">
                  <div className="">
                    <img src={mine} className="img-card-one" alt="mining" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <h4 className="card-text-one">Mine Planning and Design</h4>
                </div>
                <div className="col">
                  <h5 className="card-text-two">
                    We provide mine planning and design solutions that
                    facilitate efficient extraction of mineral resources. Our
                    solutions cover geological modeling, mine optimization,
                    production forecasting, and reserve estimation, enabling
                    effective resource management and optimized mine plans.
                  </h5>
                </div>
              </div>
              <div className="row pt-lg-5 pt-3 d-flex align-items-center">
                <div className="col-lg-1 col-md-2">
                  <div className="">
                    <img src={remote} className="img-card-one" alt="mining" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <h4 className="card-text-one">
                    Remote Sensing and Drone Technology
                  </h4>
                </div>
                <div className="col">
                  <h5 className="card-text-two">
                    We leverage remote sensing and drone technology to collect
                    accurate and real-time data for mining operations. Our
                    solutions enable aerial surveys, topographic mapping,
                    stockpile volume calculations, and environmental monitoring,
                    improving efficiency and accuracy of data collection.
                  </h5>
                </div>
              </div>
              <div className="row pt-lg-5 pt-3 d-flex align-items-center">
                <div className="col-lg-1 col-md-2">
                  <div className="">
                    <img src={data} className="img-card-one" alt="mining" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <h4 className="card-text-one">
                    Data Analytics and Business Intelligence
                  </h4>
                </div>
                <div className="col">
                  <h5 className="card-text-two">
                    Our data analytics and business intelligence solutions help
                    mining companies derive valuable insights from their data.
                    We assist in analyzing operational data, production metrics,
                    and cost analysis to drive informed decision-making,
                    optimize processes, and identify areas for improvement.
                  </h5>
                </div>
              </div>
            </div>
          </section>
        </section>

        <section className="datacard-section-two py-5">
          <div className="container">
            <div className="row  g-4">
              <div className="col-lg-4 col-md-4">
                <div className="card p-lg-3  h-100">
                  <div className="text-center">
                    <img src={environ} alt="mining" className="datacard-img" />
                  </div>
                  <h4 className="card-text-one">
                    Environmental Management Systems
                  </h4>
                  <h5 className="card-text-two text-ho">
                    We offer environmental management systems that help mining
                    companies monitor and manage environmental impacts. Our
                    solutions cover environmental data tracking, regulatory
                    compliance monitoring, and reporting, ensuring adherence to
                    environmental regulations and sustainability goals.
                  </h5>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="card p-lg-3   h-100">
                  <div className="text-center">
                    <img src={cyber} alt="mining" className="datacard-img" />
                  </div>
                  <h4 className="card-text-one">
                    Cybersecurity and Data Protection
                  </h4>
                  <h5 className="card-text-two text-ho">
                    Protecting sensitive mining data, operational systems, and
                    infrastructure is critical in the mining industry. We
                    provide comprehensive cybersecurity services, including
                    network security, access controls, data encryption, and
                    vulnerability assessments. Our solutions help safeguard your
                    valuable assets and protect against cyber threats.
                  </h5>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="card p-lg-3 h-100">
                  <div className="text-center">
                    <img src={it} alt="mining" className="datacard-img" />
                  </div>
                  <h4 className="card-text-one">IT Consulting and Strategy</h4>
                  <h5 className="card-text-two text-ho">
                    Our experienced consultants provide IT consulting services
                    to mining organizations, helping develop technology
                    strategies, optimize workflows, and implement digital
                    transformation initiatives. We assist in technology
                    selection, vendor management, and leveraging emerging
                    technologies to drive operational efficiency and
                    cost-effectiveness.{" "}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 flex-lg-row-reverse align-items-center">
              <div className="col-lg-4">
                <img src={ContactNew} alt="mining" />
              </div>
              <div className="col-lg-8">
                <p className="aerospace-new-text-5 mb-lg-4">
                  At Upstrox, we are committed to supporting the mining industry
                  by providing innovative and reliable IT solutions. Contact us
                  today to discuss your mining IT needs and explore how Upstrox
                  can empower your organization's operations, safety, and
                  productivity with our industry-leading IT services.
                </p>
                <a href="/contact" className="new-ups-button-2">
                  <span className="new-ups-button-icon-3">Read More</span>{" "}
                  <BiRightArrowAlt className="new-ups-button-icon-2" />
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Mining;
