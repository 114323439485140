import { MdOutlineArrowForwardIos } from "react-icons/md";
import contactnew from "../../Assets/images/servies-images/Blockchain And Blockchain Application webp/image/last image.webp"
import { BiRightArrowAlt } from 'react-icons/bi';
import blockicon1 from "../../Assets/images/servies-images/Blockchain And Blockchain Application webp/icon 1/Blockchain Consulting.webp";
import blockicon2 from "../../Assets/images/servies-images/Blockchain And Blockchain Application webp/icon 1/Blockchain Development.webp";
import blockicon3 from "../../Assets/images/servies-images/Blockchain And Blockchain Application webp/icon 1/Blockchain Integration.webp";
import blockicon4 from "../../Assets/images/servies-images/Blockchain And Blockchain Application webp/icon 1/Cryptocurrency and Token Development.webp";

import blockicon5 from "../../Assets/images/servies-images/Blockchain And Blockchain Application webp/icon 2/Smart Contract Development.webp";
import blockicon6 from "../../Assets/images/servies-images/Blockchain And Blockchain Application webp/icon 2/Blockchain Security and Auditing.webp";
import blockicon7 from "../../Assets/images/servies-images/Blockchain And Blockchain Application webp/icon 2/Supply Chain Blockchain Solutions.webp";
import blockicon8 from "../../Assets/images/servies-images/Blockchain And Blockchain Application webp/icon 2/Blockchain Identity Solutions.webp";
import blockicon9 from "../../Assets/images/servies-images/Blockchain And Blockchain Application webp/icon 2/Blockchain for Financial Services.webp";
import blockicon10 from "../../Assets/images/servies-images/Blockchain And Blockchain Application webp/icon 2/Blockchain for Healthcare.webp";
import { useEffect } from "react";


function Blockchain() {

    
    return (
        <>
            <div className="blockchain">
                <section className="top-banner-section top-banner-section-overral-bg-section blockchain-background back-posi-img">
                    <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1 className="top-text-one"><span className="text-color">Blockchain and Blockchain Application</span> Services by Upstrox</h1>
                                    <div className="top-text-two">Upstrox offers comprehensive blockchain solutions and services to help organizations harness the power of blockchain technology for various applications. Blockchain is a decentralized and distributed ledger technology that enables secure, transparent, and tamper-proof record-keeping and transaction processing.</div>
                                </div>
                            </div>
                            <div className="pt-3">
                               <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="industrialmanu-new-design-1 py-5">
                <div className="container-fluid col-lg-11 mx-auto py-lg-5">
                <div className="row">
                    <div className="col-lg-4">
                    <h2 className="aerospace-new-text-1 mb-lg-4 mb-3">Blockchain and Blockchain Application Services</h2>
                    <p className="aerospace-new-text-2">Our blockchain services cover a wide range of areas, including blockchain consulting, development, implementation, and integration. Here's how Upstrox can assist you with blockchain and blockchain application services</p>
                    </div>
                    <div className="col-lg-8">
                    <div className="row row-cols-md-1 row-cols-1 row-cols-lg-2 g-4">
                        <div className="col mt-0 industrialmanu-top-new-card-1">
                        <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                            <div class="card-body">
                            <img className="block-icon-img" src={blockicon1} alt="blockchain" />
                            <h5 className="aerospace-new-text-3">Blockchain Consulting</h5>
                            <p className="aerospace-new-text-4">Our experienced blockchain consultants work closely with your organization to understand your business needs and identify potential blockchain use cases. We assess the feasibility and suitability of blockchain technology for your specific requirements and provide strategic recommendations on blockchain adoption.</p>
                            </div>
                        </div>
                        </div>
                        <div className="col mt-0 industrialmanu-top-new-card-2">
                        <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                            <div class="card-body">
                            <img className="block-icon-img" src={blockicon2} alt="blockchain" />
                            <h5 className="aerospace-new-text-3">Blockchain Development</h5>
                            <p className="aerospace-new-text-4">We provide end-to-end blockchain development services, including smart contract development, consensus mechanism implementation, and decentralized application (DApp) development. Our experts leverage various blockchain platforms such as Ethereum, Hyperledger, and Corda to build secure and scalable blockchain solutions.</p>
                            </div>
                        </div>
                        </div>
                        <div className="col mt-0 industrialmanu-top-new-card-3">
                        <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                            <div class="card-body">
                            <img className="block-icon-img" src={blockicon3} alt="blockchain" />
                            <h5 className="aerospace-new-text-3">Blockchain Integration</h5>
                            <p className="aerospace-new-text-4">We specialize in integrating blockchain solutions with your existing systems and applications. Whether it's integrating blockchain with your supply chain management system, CRM platform, or financial systems, our experts ensure seamless data flow and interoperability between blockchain and other systems.</p>
                            </div>
                        </div>
                        </div>
                        <div className="col mt-0 industrialmanu-top-new-card-4">
                        <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                            <div class="card-body">
                            <img className="block-icon-img" src={blockicon4} alt="blockchain" />
                            <h5 className="aerospace-new-text-3">Cryptocurrency and Token Development</h5>
                            <p className="aerospace-new-text-4">We assist organizations in developing their own cryptocurrencies or tokens based on blockchain technology. Our experts guide you through the process of token creation, ICO/STO (Initial Coin Offering/Security Token Offering) development, and compliance with regulatory requirements.</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                </section>

                <section className="industrialmanu-new-design-2 py-5">
                    <div className="container-fluid col-lg-10 mx-auto">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 justify-content-center">
                        <div className="col industrialmanu-new-card-1">
                        <div class="card h-100">
                            <div class="card-body">
                            <img className="block-icon-img" src={blockicon5} alt="blockchain" />
                            <h5 class="automotive-new-text-1">Smart Contract Development</h5>
                            <p class="automotive-new-text-3">We offer smart contract development services to automate business processes and enable self-executing agreements. Our experts design and develop secure and auditable smart contracts using programming languages such as Solidity and provide ongoing support for smart contract maintenance and upgrades.</p>
                            </div>
                        </div>
                        </div>
                        <div className="col industrialmanu-new-card-2">
                        <div class="card h-100">
                            <div class="card-body">
                            <img className="block-icon-img" src={blockicon6} alt="blockchain" />
                            <h5 class="automotive-new-text-1">Blockchain Security and Auditing</h5>
                            <p class="automotive-new-text-3">We prioritize blockchain security and provide comprehensive auditing and security assessment services. Our experts conduct security audits, identify vulnerabilities, and recommend best practices for ensuring the integrity and confidentiality of your blockchain solutions.</p>
                            </div>
                        </div>
                        </div>
                        <div className="col industrialmanu-new-card-3">
                        <div class="card h-100">
                            <div class="card-body">
                            <img className="block-icon-img" src={blockicon7} alt="blockchain" />
                            <h5 class="automotive-new-text-1">Supply Chain Blockchain Solutions</h5>
                            <p class="automotive-new-text-3">We help organizations implement blockchain solutions for supply chain management, enabling end-to-end traceability, transparency, and efficiency. Our experts assist in tracking goods, verifying product authenticity, and ensuring compliance with regulatory requirements using blockchain technology.</p>
                            </div>
                        </div>
                        </div>
                        <div className="col industrialmanu-new-card-4 mt-0">
                        <div class="card h-100">
                            <div class="card-body">
                            <img className="block-icon-img" src={blockicon8} alt="blockchain" />
                            <h5 class="automotive-new-text-1">Blockchain Identity Solutions</h5>
                            <p class="automotive-new-text-3">We offer blockchain-based identity solutions that provide secure and decentralized identity management. Our solutions enable individuals to have control over their personal data and establish trust in digital interactions, enhancing security and privacy.</p>
                            </div>
                        </div>
                        </div>
                        <div className="col industrialmanu-new-card-5 mt-0">
                        <div class="card h-100">
                            <div class="card-body">
                            <img className="block-icon-img" src={blockicon9} alt="blockchain" />
                            <h5 class="automotive-new-text-1">Blockchain for Financial Services</h5>
                            <p class="automotive-new-text-3">We assist financial institutions in leveraging blockchain technology for applications such as cross-border payments, trade finance, and asset tokenization. Our experts help streamline financial transactions, enhance transparency, and reduce costs using blockchain solutions.</p>
                            </div>
                        </div>
                        </div>
                        <div className="col industrialmanu-new-card-6 mt-0">
                        <div class="card h-100">
                            <div class="card-body">
                            <img className="block-icon-img" src={blockicon10} alt="blockchain" />
                            <h5 class="automotive-new-text-1">Blockchain for Healthcare</h5>
                            <p class="automotive-new-text-3">We provide blockchain solutions for healthcare organizations to enhance data security, interoperability, and patient privacy. Our experts assist in implementing blockchain-based electronic health records (EHR), medical data sharing platforms, and clinical trial management systems.</p>
                            </div>
                        </div>
                        </div>

                    </div>
                    </div>
                </section>

                <section className="aerospace-new-design-2 py-5">
                    <div className="container">
                    <div className="row g-4 flex-lg-row-reverse">
                        <div className="col-lg-4">
                        <img src={contactnew} alt="blockchain" />
                        </div>
                        <div className="col-lg-8">
                        <p className="aerospace-new-text-5 mb-lg-4">At Upstrox, we are committed to delivering innovative and scalable blockchain solutions tailored to your specific needs.</p>
                        <a href='/contact' className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
                        </div>
                    </div>
                    </div>
                </section>

                <section className="aerospace-new-design-2 py-5">
                    <div className="container">
                    <div className="row g-4 justify-content-center text-center">
                        <div className="col-lg-12">
                        <p className="aerospace-new-text-6 mb-lg-4">Contact us today to discuss your blockchain requirements and explore how Upstrox can help your organization harness the potential of blockchain technology for enhanced security, transparency, and efficiency.</p>
                        </div>
                    </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default Blockchain;