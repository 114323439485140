import { MdOutlineArrowForwardIos } from "react-icons/md";
import { BiRightArrowAlt } from 'react-icons/bi';
import Icon1 from "../../Assets/images/icon/images.webp"
import IconIndu1 from "../../Assets/images/icon/industrial-manufacturing/Enterprise-Resource.webp"
import IconIndu2 from "../../Assets/images/icon/industrial-manufacturing/Product-Lifecycle.webp"
import IconIndu3 from "../../Assets/images/icon/industrial-manufacturing/Manufacturing-Execution.webp"
import IconIndu4 from "../../Assets/images/icon/industrial-manufacturing/Internet-of-Things.webp"
import IconIndu5 from "../../Assets/images/icon/industrial-manufacturing/Supply-Chain-Management.webp"
import IconIndu6 from "../../Assets/images/icon/industrial-manufacturing/Warehouse-Management.webp"
import IconIndu7 from "../../Assets/images/icon/industrial-manufacturing/Data-Analytics.webp"
import IconIndu8 from "../../Assets/images/icon/industrial-manufacturing/Cybersecurity.webp"
import IconIndu9 from "../../Assets/images/icon/industrial-manufacturing/IT-Infrastructure.webp"
import IconIndu10 from "../../Assets/images/icon/industrial-manufacturing/IT-Consulting.webp"

import contactnew from "../../Assets/images/icon/industrial-manufacturing/industrial-manufacturing-contact.webp"


function IndustrialManufacturing() {
  return (
    <>
      <div className="industrial-bammer-section">
        <section className=" industrial-img back-posi-img">
          <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid top-banner-section'>
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one">
                    Upstrox - Your Trusted Partner for{" "}
                    <span className="text-color">Industrial Manufacturing</span>{" "}
                    IT Solutions
                  </h1>
                  <div className="top-text-two">
                    {" "}
                    In the competitive landscape of industrial manufacturing,
                    leveraging technology is essential for optimizing operations,
                    increasing productivity, and driving innovation. At Upstrox,
                    we specialize in providing comprehensive IT services tailored
                    specifically for the industrial manufacturing sector.{" "}
                  </div>
                </div>
              </div>
              <div className="pt-3">
               <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
              </div>
            </div>
          </div>
        </section>


        <section className="industrialmanu-new-design-1 py-5">
          <div className="container-fluid col-lg-11 mx-auto py-lg-5">
            <div className="row">
              <div className="col-lg-4">
                <h2 className="aerospace-new-text-1 mb-lg-4 mb-3">Upstrox in Industrial Manufacturing sector</h2>
                <p className="aerospace-new-text-2">Our expertise spans various sub-sectors, including automotive, aerospace, machinery, and heavy equipment. Here are the key IT services we offer</p>
              </div>
              <div className="col-lg-8">
                <div className="row row-cols-md-1 row-cols-1 row-cols-lg-2 g-4">
                  <div className="col mt-0 industrialmanu-top-new-card-1">
                    <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                      <div class="card-body">
                        <img className="industrialmanu-icon-1" src={IconIndu1} alt="Industrial Manufacturing" />
                        <h5 className="aerospace-new-text-3">Enterprise Resource Planning (ERP)</h5>
                        <p className="aerospace-new-text-4">We provide tailored ERP solutions for industrial manufacturing, covering areas such as production planning, inventory management, supply chain optimization, and financials. Our ERP systems integrate various business functions, enabling real-time data sharing and enhancing operational efficiency.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col mt-0 industrialmanu-top-new-card-2">
                    <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                      <div class="card-body">
                        <img className="industrialmanu-icon-1" src={IconIndu2} alt="Industrial Manufacturing" />
                        <h5 className="aerospace-new-text-3">Product Lifecycle Management (PLM)</h5>
                        <p className="aerospace-new-text-4">We offer PLM solutions that manage the entire lifecycle of industrial products, from design and engineering to manufacturing, maintenance, and disposal. Our PLM systems enable collaboration, version control, and efficient change management, ensuring streamlined processes and improved product quality.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col mt-0 industrialmanu-top-new-card-3">
                    <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                      <div class="card-body">
                        <img className="industrialmanu-icon-1" src={IconIndu3} alt="Industrial Manufacturing" />
                        <h5 className="aerospace-new-text-3">Manufacturing Execution Systems (MES)</h5>
                        <p className="aerospace-new-text-4">We provide MES solutions that bridge the gap between enterprise systems and shop floor operations. Our solutions offer real-time visibility into manufacturing processes, enabling efficient production monitoring, inventory control, and quality management.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col mt-0 industrialmanu-top-new-card-4">
                    <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                      <div class="card-body">
                        <img className="industrialmanu-icon-1" src={IconIndu4} alt="Industrial Manufacturing" />
                        <h5 className="aerospace-new-text-3">Industrial Internet of Things (IIoT)</h5>
                        <p className="aerospace-new-text-4">We help industrial manufacturers leverage IIoT technologies to connect machines, devices, and sensors for real-time data collection and analysis. Our solutions enable predictive maintenance, remote monitoring, and improved overall equipment efficiency (OEE).</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="industrialmanu-new-design-2 py-5">
          <div className="container-fluid col-lg-10 mx-auto">
            <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 g-4 justify-content-center">
              <div className="col industrialmanu-new-card-1">
                <div class="card h-100">
                  <div class="card-body">
                    <img className="industrialmanu-icon-1" src={IconIndu5} alt="Industrial Manufacturing" />
                    <h5 class="automotive-new-text-1">Supply Chain Management (SCM)</h5>
                    <p class="automotive-new-text-3">We offer SCM solutions tailored for industrial manufacturing, optimizing processes such as demand planning, procurement, inventory management, and logistics. Our solutions enhance visibility, collaboration, and efficiency throughout the supply chain, reducing costs and ensuring timely delivery.</p>
                  </div>
                </div>
              </div>
              <div className="col industrialmanu-new-card-2">
                <div class="card h-100">
                  <div class="card-body">
                    <img className="industrialmanu-icon-1" src={IconIndu6} alt="Industrial Manufacturing" />
                    <h5 class="automotive-new-text-1">Warehouse Management Systems (WMS)</h5>
                    <p class="automotive-new-text-3">We provide WMS solutions that streamline warehouse operations, including inventory tracking, order fulfillment, and shipping. Our solutions optimize storage space, improve picking accuracy, and enhance overall warehouse productivity.</p>
                  </div>
                </div>
              </div>
              <div className="col industrialmanu-new-card-3">
                <div class="card h-100">
                  <div class="card-body">
                    <img className="industrialmanu-icon-1" src={IconIndu7} alt="Industrial Manufacturing" />
                    <h5 class="automotive-new-text-1">Data Analytics and Business Intelligence</h5>
                    <p class="automotive-new-text-3">Our data analytics and business intelligence solutions help industrial manufacturers gain valuable insights from their data. We assist in analyzing production data, optimizing processes, identifying trends, and making data-driven decisions to drive efficiency and profitability.</p>
                  </div>
                </div>
              </div>
              <div className="col industrialmanu-new-card-4 mt-0">
                <div class="card h-100">
                  <div class="card-body">
                    <img className="industrialmanu-icon-1" src={IconIndu8} alt="Industrial Manufacturing" />
                    <h5 class="automotive-new-text-1">Cybersecurity and Data Protection</h5>
                    <p class="automotive-new-text-3">Protecting sensitive data, intellectual property, and manufacturing processes is critical for industrial manufacturers. We provide comprehensive cybersecurity services, including network security, access controls, data encryption, and vulnerability assessments. Our solutions help safeguard your valuable assets and ensure compliance with industry regulations.</p>
                  </div>
                </div>
              </div>
              <div className="col industrialmanu-new-card-5 mt-0">
                <div class="card h-100">
                  <div class="card-body">
                    <img className="industrialmanu-icon-1" src={IconIndu9} alt="Industrial Manufacturing" />
                    <h5 class="automotive-new-text-1">IT Infrastructure and Network Design</h5>
                    <p class="automotive-new-text-3">We design and implement scalable and secure IT infrastructures for industrial manufacturing organizations. Our solutions cover network architecture, server management, data backup, and disaster recovery planning, ensuring reliable and resilient operations.</p>
                  </div>
                </div>
              </div>
              <div className="col industrialmanu-new-card-6 mt-0">
                <div class="card h-100">
                  <div class="card-body">
                    <img className="industrialmanu-icon-1" src={IconIndu10} alt="Industrial Manufacturing" />
                    <h5 class="automotive-new-text-1">IT Consulting and Strategy</h5>
                    <p class="automotive-new-text-3">Our experienced consultants offer IT consulting services to industrial manufacturing organizations, providing guidance on technology strategy, digital transformation, and process optimization. We help you align technology investments with business goals, leverage emerging technologies, and stay ahead of the competition.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 flex-lg-row-reverse align-items-center">
              <div className="col-lg-4">
                <img src={contactnew} alt="industrial" />
              </div>
              <div className="col-lg-8">
                <p className="aerospace-new-text-5 mb-lg-4">At Upstrox, we understand the unique challenges and opportunities in the industrial manufacturing sector. Our comprehensive IT services empower manufacturers to drive operational excellence, enhance productivity, and foster innovation. </p>
                <a href='/contact' className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="aerospace-new-text-6 mb-lg-4">Contact us today to discuss your industrial manufacturing IT needs and explore how we can support your organization's growth and success.</p>
              </div>
            </div>
          </div>
        </section>

      </div>
    </>
  );
}
export default IndustrialManufacturing;
