import { AiOutlineArrowRight } from "react-icons/ai";
import { HiArrowSmRight } from "react-icons/hi";
import { IoLogoWhatsapp } from "react-icons/io";
import { IoMdMail } from "react-icons/io";
import Carreerbanner1 from "../../Assets/images/about/carreer-banner-1.webp";


function Career() {
  return (
    <>
      <div className="career-banner-section">
        <section className="career-banner-section-one">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <h1 className="career-text-one">
                  We are thriving and racing towards Becoming a Technological
                  Giant Are you able to explain why?
                </h1>
                <div className="pt-3">
                  <button className="career-button">
                    Apply Now <AiOutlineArrowRight />
                  </button>
                </div>
              </div>
            </div>

          </div>
        </section>

        <section className="career-banner-section-two">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img src={Carreerbanner1} alt="team" />
              </div>
              <div className="col-lg-6">
                <h3 className="career-text-one">It's time to join our team!</h3>
                <div className="career-text-two">
                  Upstrox Solution, a rapidly growing company in blockchain
                  software development and crypto-software, is looking for
                  creative, ambitious and innovative individuals to join its
                  team in Blockchain Development, App Development and Game
                  Development. We are looking to recruit highly motivated,
                  goal-oriented people who are open to sharing their ideas and
                  learning from others.
                </div>
                <div className="career-text-two py-2">
                  We believe ambitions can be progressive and we appreciate your
                  desire for growth. Upstrox Solution offers opportunities for
                  professional development and learning, no matter your level of
                  experience. To foster mutual growth, we provide a platform
                  that encourages collaboration.
                </div>
                <h3 className="career-text-one">
                  Join us on our ever-expanding adventure!
                </h3>
                <div className="pt-lg-3">
                  <IoLogoWhatsapp className="career-icon-button" />
                  <IoMdMail className="career-icon-button mx-2" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="career-banner-section-three mt-lg-5 mt-3">
          <div className="container position-relative pb-lg-5">
            <div className="blur-container"></div>
            <div className="row align-items-center pt-lg-3 gap-3 gap-lg-0">
              <div className="col-lg-4">
                <h3 className="">Our Requirements</h3>
                <div className="">
                  You might be the perfect person to take our extra chair.
                </div>
              </div>
              <div className="col-lg-4">
                <div className=" card h-100 ">
                  <div className="p-lg-3 p-1 pb-lg-3">
                    <h4 className="card-text-one text-color">
                      Position: Sr. Blockchain Developer
                    </h4>
                    <h5 className="card-text-two px-lg-3">
                      Experience: 2-5 Years
                    </h5>
                    <h5 className="card-text-two px-lg-3">location: Madurai</h5>
                  </div>
                  <div className="card-text-two text-end p-lg-2">
                    click Here
                    <HiArrowSmRight className="text-color" />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className=" card h-100 ">
                  <div className="p-lg-3 p-1 pb-lg-3">
                    <h4 className="card-text-one text-color">
                      Position: Sr. Blockchain Developer
                    </h4>
                    <h5 className="card-text-two px-lg-3">
                      Experience: 2-5 Years
                    </h5>
                    <h5 className="card-text-two px-lg-3">location: Madurai</h5>
                  </div>
                  <div className="card-text-two text-end p-lg-2">
                    click Here
                    <HiArrowSmRight className="text-color" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-lg-3 gap-3 gap-lg-0">
              <div className="col-lg-4">
                <div className=" card h-100 ">
                  <div className="p-lg-3 p-1 pb-lg-3">
                    <h4 className="card-text-one text-color">
                      Position: Sr. Blockchain Developer
                    </h4>
                    <h5 className="card-text-two px-lg-3">
                      Experience: 2-5 Years
                    </h5>
                    <h5 className="card-text-two px-lg-3">location: Madurai</h5>
                  </div>
                  <div className="card-text-two text-end p-lg-2">
                    click Here
                    <HiArrowSmRight className="text-color" />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className=" card h-100 ">
                  <div className="p-lg-3 p-1 pb-lg-3">
                    <h4 className="card-text-one text-color">
                      Position: Sr. Blockchain Developer
                    </h4>
                    <h5 className="card-text-two px-lg-3">
                      Experience: 2-5 Years
                    </h5>
                    <h5 className="card-text-two px-lg-3">location: Madurai</h5>
                  </div>
                  <div className="card-text-two text-end p-lg-2">
                    click Here
                    <HiArrowSmRight className="text-color" />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className=" card h-100 ">
                  <div className="p-lg-3 p-1 pb-lg-3">
                    <h4 className="card-text-one text-color">
                      Position: Sr. Blockchain Developer
                    </h4>
                    <h5 className="card-text-two px-lg-3">
                      Experience: 2-5 Years
                    </h5>
                    <h5 className="card-text-two px-lg-3">location: Madurai</h5>
                  </div>
                  <div className="card-text-two text-end p-lg-2">
                    click Here
                    <HiArrowSmRight className="text-color" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </>
  );
}
export default Career;
