import { BsWhatsapp } from "react-icons/bs";
import { TiTick } from "react-icons/ti";
import { RiTwitterXFill } from 'react-icons/ri';
import { AiOutlineInstagram, AiFillLinkedin, AiFillYoutube } from 'react-icons/ai';
import { BiLogoFacebookCircle } from 'react-icons/bi';

function Thankyou(){
    return(
        <>
        <section className="thankyou-section">
          <div className="container-fluid thankyou-banner">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-8 justify-content-center">
                <div className="card py-5">
                  <div className="card-body d-flex justify-content-center">
                    <div className="text-center">
                    <TiTick className="thankyou-tick-icon"/>
                    <h2 className="p-3 thank-text">Thank You !</h2>
                    <p className="pb-lg-4">Cheers to taking that initial leap towards your vision.  Our team is on it! Expect a quick response from us as we work to meet your needs.  Your satisfaction is our priority, and we're excited to assist you</p>
                     <div className="row justify-content-center thank-sub-card gap-3 gap-lg-0">
                      <div className="col-lg-5 col-md-5"> 
                       <div className="card p-4">
                         <h4>connect with us</h4>
                         <div className='d-flex justify-content-evenly'>
                    <a href="https://twitter.com/upstrox" className='social-icons'style={{background:"black"}} target="_blank"> <RiTwitterXFill /></a>
                    <a href="/" className='social-icons'style={{background:"rgb(89,81,199)"}} target="_blank"> <AiOutlineInstagram /></a>
                    <a href="https://www.facebook.com/Upstrox" className='social-icons'style={{background:"rgb(8,102,255)"}} target="_blank"> <BiLogoFacebookCircle /></a>
                    <a href="https://www.linkedin.com/company/upstrox-techno-services/?viewAsMember=true" className='social-icons'style={{background:"rgb(0,119,181)"}} target="_blank"> <AiFillLinkedin /></a>
                    <a href="https://api.whatsapp.com/send?phone=919442430551&text=*Hi%20there!%20I%20am%20looking%20for%20your%20Services,%20Shall%20we%20Start%20the%20discussion*%20Lead%20From%20https://www.upstrox.com/" className='social-icons' style={{background:"green"}} target="_blank"> <BsWhatsapp /></a>
                  </div>
                       </div>
                      </div>
                      <div className="col-lg-5 col-md-5">
                      <div className="card p-4">
                      <h4>visit our website</h4>
                      <a className=" visit-button mx-auto" href="/">visit website</a>
                      </div>
                      </div>
                     </div>
                    </div>
                  </div>
                 </div>
              </div>
            </div>
          </div>
        </section>
        </>
    )
}
export default Thankyou;