import { MdOutlineArrowForwardIos } from "react-icons/md";
import Dummybg from "../../Assets/images/servies-images/demo/dummy-2.webp"
import { BiRightArrowAlt } from "react-icons/bi";
import experience from "../../Assets/images/servies-images/Experience Design Service/images/last image copy.webp";

import dataimg from "../../Assets/images/servies-images/Experience Design Service/images/first section copy 2.webp";
import expicon1 from "../../Assets/images/servies-images/Experience Design Service/icon/User Research and Insights.webp"
import expicon2 from "../../Assets/images/servies-images/Experience Design Service/icon/User Experience (UX) Design.webp"
import expicon3 from "../../Assets/images/servies-images/Experience Design Service/icon/Customer Journey Mapping.webp"
import expicon4 from "../../Assets/images/servies-images/Experience Design Service/icon/Service Design.webp"

import expicon5 from "../../Assets/images/servies-images/Experience Design Service/icon 2/Accessibility and Inclusivity.webp"
import expicon6 from "../../Assets/images/servies-images/Experience Design Service/icon 2/Multichannel and Cross-Platform Experiences.webp"
import expicon7 from "../../Assets/images/servies-images/Experience Design Service/icon 2/Experience Testing and Optimization.webp"
import { useEffect } from "react";

function ExperienceDesignService() {
    
    return (
        <>
            <div className="experience-section">
                <section className=" top-banner-section-overral-bg-section experience-background back-posi-img">
                    <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid top-banner-section'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1 className="top-text-one"><span className="text-color">Experience Design Service</span> by Upstrox</h1>
                                    <div className="top-text-two">Upstrox offers Experience Design services that focus on creating exceptional and memorable experiences for your customers, employees, and stakeholders. We believe that delivering outstanding experiences is crucial for building strong relationships, driving customer loyalty, and achieving business success.</div>
                                </div>
                            </div>
                            <div className="pt-3">
                               <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="logistic-new-card-section pt-5">
                <div className="container-fluid col-lg-10">
                    <div className="row align-items-center">
                    <div className="col-lg-4 col-md-12">
                        <div>
                        <h3 className="aerospace-new-text-1">
                            {" "}
                            Experience Design Service{" "}
                        </h3>
                        <p className="aerospace-new-text-2">
                            {" "}
                            Our Experience Design service encompasses a holistic approach to design, combining creativity, user-centricity, and cutting-edge technology. Here's how Upstrox can help you enhance your experiences
                        </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="card card-one">
                        <div className="p-3">
                            {" "}
                            <img
                            src={expicon1}
                            className="logi-img-one"
                            alt="experience-section"
                            />
                        </div>
                        <div className="pt-lg-3">
                            <h4 className="card-text-one ps-3 ">
                            User Research and Insights
                            </h4>
                            <h5 className="card-text-two px-3">
                            We conduct in-depth user research to gain a deep understanding of your target audience, their needs, and pain points. Through interviews, surveys, and observations, we gather valuable insights that inform the experience design process and ensure that your solutions meet user expectations.
                            </h5>
                        </div>
                        </div>
                        <div className="card card-one ">
                        <div className="p-3">
                            {" "}
                            <img
                            src={expicon2}
                            className="logi-img-one"
                            alt="experience-section"
                            />
                        </div>
                        <div className="pt-lg-3">
                            <h4 className="card-text-one ps-3">
                            User Experience (UX) Design
                            </h4>
                            <h5 className="card-text-two px-3">
                            Our UX designers create intuitive and user-friendly interfaces across various digital platforms. We focus on crafting seamless and engaging experiences that align with your brand identity and provide meaningful interactions for your users. We utilize best practices in information architecture, interaction design, and visual design to optimize usability and satisfaction.
                            </h5>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="card card-one">
                        <div className="p-3">
                            {" "}
                            <img
                            src={expicon3}
                            className="logi-img-one"
                            alt="experience-section"
                            />
                        </div>
                        <div className="pt-lg-3">
                            <h4 className="card-text-one ps-3">
                            Customer Journey Mapping
                            </h4>
                            <h5 className="card-text-two px-3">
                            We map out the customer journey to identify touchpoints and interactions throughout the user's experience with your brand. By visualizing the customer's end-to-end journey, we uncover opportunities to enhance key moments and address pain points, ensuring a cohesive and delightful experience across all touchpoints.
                            </h5>
                        </div>
                        </div>
                        <div className="card card-one">
                        <div className="p-3">
                            {" "}
                            <img src={expicon4} className="logi-img-one" alt="experience-section" />
                        </div>
                        <div className="pt-lg-3">
                            <h4 className="card-text-one ps-3 ">
                            Service Design
                            </h4>
                            <h5 className="card-text-two px-3">
                            We apply service design principles to create holistic experiences that encompass all aspects of your customer's interaction with your organization. Our service designers analyze and optimize the entire service ecosystem, considering people, processes, technology, and physical environments to create seamless and memorable experiences.
                            </h5>
                        </div>
                        </div>
                    </div>
                    </div>
                    
                    <section className="datacard-section-one py-5">
                        <div className="container-fluid">
                            <div className="row pt-2 pt-lg-5 align-items-center">
                                <div className="col-lg-7 col-md-7">
                                    <div className="container">
                                        <div className="row pt-lg-3 py-2">
                                            <div className="col">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">User Interface (UI) Design</h4>
                                                    <h5 className="card-text-two text-ho">
                                                    Our UI designers create visually appealing and aesthetically pleasing interfaces that align with your brand guidelines. We pay attention to typography, color schemes, iconography, and visual elements to ensure consistency and visual harmony across all digital touchpoints.
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-lg-3 py-2">
                                            <div className="col">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Prototyping and Iteration</h4>
                                                    <h5 className="card-text-two text-ho">
                                                    We believe in an iterative design process that involves rapid prototyping and user testing. We create interactive prototypes that allow users to experience the proposed solutions, gather feedback, and refine the designs based on user insights. This iterative approach ensures that the final product meets user needs effectively.
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-lg-3 py-2">
                                            <div className="col">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Design System Development</h4>
                                                    <h5 className="card-text-two text-ho">
                                                    We assist in developing design systems that provide a unified and consistent experience across your digital products and platforms. A design system includes components, patterns, and guidelines that ensure visual and functional coherence, improve efficiency, and facilitate scalability in design implementation.
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5">
                                    <div className=""><img src={dataimg} alt="experience-section" className="datacard-img" /></div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="card mt-5">
                    <div className="row row-cols-lg-3 row-cols-1 row-cols-md-2 mt-lg-4 mx-3 text-center border-n-row px justify-content-md-center">
                        <div className="col">
                        <div className="card h-100 ">
                            <div className=" text-center icon-align">
                            <img
                                src={expicon5}
                                className="logi-img-two"
                                alt="experience-section"
                            />
                            </div>
                            <div className="p-lg-3">
                            <h4 className="card-text-one ps-3">
                            Accessibility and Inclusivity
                            </h4>
                            <h5 className="card-text-two px-lg-3">
                            We prioritize accessibility and inclusivity in our experience design process. Our designers adhere to WCAG guidelines, consider diverse user needs, and ensure that your digital experiences are accessible to all users, including those with disabilities.
                            </h5>
                            </div>
                        </div>
                        </div>
                        <div className="col">
                        <div className="card h-100">
                            <div className=" text-center icon-align">
                            {" "}
                            <img
                                src={expicon6}
                                className="logi-img-two"
                                alt="experience-section"
                            />
                            </div>
                            <div className="p-lg-3">
                            <h4 className="card-text-one ps-3">
                            Multichannel and Cross-Platform Experiences
                            </h4>
                            <h5 className="card-text-two px-lg-3">
                            We design experiences that seamlessly transition across multiple channels and platforms. Whether it's web, mobile, voice interfaces, or emerging technologies, we create consistent and optimized experiences that adapt to different devices and contexts, providing a unified user experience.
                            </h5>
                            </div>
                        </div>
                        </div>
                        <div className="col pt-md-5 pt-lg-0">
                        <div className="card h-100">
                            <div className="text-center icon-align">
                            <img
                                src={expicon7}
                                className="logi-img-two"
                                alt="experience-section"
                            />
                            </div>
                            <div className="p-lg-3">
                            <h4 className="card-text-one ps-3">
                            Experience Testing and Optimization
                            </h4>
                            <h5 className="card-text-two px-lg-3">
                            We conduct usability testing and gather feedback throughout the design and development process. We analyze user behavior, identify areas for improvement, and iterate on the experience to ensure continuous optimization and alignment with user expectations.
                            </h5>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>

                <section className="logistic-last-section my-lg-5">
                    <div className="container py-5">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-4 col-md-4">
                        <img src={ experience} alt="experience-section" />
                        </div>
                        <div className="col-lg-8 col-md-8">
                        <div className="pt-lg-5">
                            <p className="">
                            At Upstrox, we are dedicated to delivering exceptional experiences that drive customer engagement and loyalty. 
                            </p>
                        </div>
                        <div>
                            <a className="new-ups-button-2" href="/contact">
                            <span className="new-ups-button-icon-3">Read More</span>{" "}
                            <BiRightArrowAlt className="new-ups-button-icon-2" />
                            </a>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>

                <section className="contact-text-section py-5">
                    <div className="container">
                    <div className="row g-4 justify-content-center text-center">
                        <div className="col-lg-12">
                        <p className="contact-text-last mb-lg-4">
                        Contact us today to discuss your specific experience design needs and explore how Upstrox can help you create memorable and impactful experiences for your customers and stakeholders.
                        </p>
                        </div>
                    </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default ExperienceDesignService