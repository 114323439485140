
import Lifesciencebg from "../../Assets/images/servies-images/life-science.webp"
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { BiRightArrowAlt } from 'react-icons/bi';
import Icon1 from "../../Assets/images/icon/images.webp"
import IconIndu1 from "../../Assets/images/icon/life science/Laboratory Information Management Systems.webp"
import IconIndu2 from "../../Assets/images/icon/life science/Electronic Data Capture.webp"
import IconIndu3 from "../../Assets/images/icon/life science/Clinical Trial Management Systems.webp"
import IconIndu4 from "../../Assets/images/icon/life science/Regulatory Compliance and Validation.webp"
import IconIndu5 from "../../Assets/images/icon/life science/Data Analytics and Business Intelligence.webp"
import IconIndu6 from "../../Assets/images/icon/life science/Pharmacovigilance and Drug Safety Systems.webp"
import IconIndu7 from "../../Assets/images/icon/life science/Bioinformatics and Genomics.webp"
import IconIndu8 from "../../Assets/images/icon/life science/Data Integration and Interoperability.webp"
import IconIndu9 from "../../Assets/images/icon/life science/IT Infrastructure and Cloud Services.webp"
import IconIndu10 from "../../Assets/images/icon/life science/IT Consulting and Strategy.webp"
import contactnew from "../../Assets/images/icon/life science/life science image.webp"



function Lifescience() {
  return (
    <>
      <div className="lifescience-banner-section">
        <section className="top-banner-section top-banner-section-overral-bg-section back-posi-img" style={{ backgroundImage: "url(" + Lifesciencebg + ")" }}>
          <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one">
                    <span className="text-color"> Life Sciences </span>  IT Solutions by Upstrox
                  </h1>
                  <div className="top-text-two">
                    Upstrox is your trusted partner for comprehensive IT solutions tailored specifically for the life sciences industry. We understand the unique challenges and requirements of pharmaceuticals, biotechnology, healthcare research, and other life sciences organizations.
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
              </div>
            </div>
          </div>
        </section>

        <section className="industrialmanu-new-design-1 py-5">
          <div className="container-fluid col-lg-11 mx-auto py-lg-5">
            <div className="row">
              <div className="col-lg-4">
                <h2 className="aerospace-new-text-1 mb-lg-4 mb-3">Life Sciences IT Solutions</h2>
                <p className="aerospace-new-text-2">Our expertise in technology and domain knowledge enables us to deliver cutting-edge solutions that drive innovation, accelerate research, and ensure regulatory compliance. Here are the key IT services we offer</p>
              </div>
              <div className="col-lg-8">
                <div className="row row-cols-md-2 row-cols-1 row-cols-lg-2 g-4">
                  <div className="col mt-0 industrialmanu-top-new-card-1">
                    <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                      <div class="card-body">
                        <img className="industrialmanu-icon-1" src={IconIndu1} alt="lifescience" />
                        <h5 className="aerospace-new-text-3">Laboratory Information Management Systems (LIMS)</h5>
                        <p className="aerospace-new-text-4"> We provide robust LIMS solutions that streamline laboratory workflows, sample tracking, and data management. Our LIMS systems enable efficient data capture, analysis, and reporting, improving research efficiency and data integrity.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col mt-0 industrialmanu-top-new-card-2">
                    <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                      <div class="card-body">
                        <img className="industrialmanu-icon-1" src={IconIndu2} alt="lifescience" />
                        <h5 className="aerospace-new-text-3">Electronic Data Capture (EDC) Systems</h5>
                        <p className="aerospace-new-text-4"> We offer EDC systems that automate data collection for clinical trials and research studies. Our EDC solutions simplify data entry, enhance data accuracy, and facilitate real-time data monitoring, accelerating the research and development process.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col mt-0 industrialmanu-top-new-card-3">
                    <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                      <div class="card-body">
                        <img className="industrialmanu-icon-1" src={IconIndu3} alt="lifescience" />
                        <h5 className="aerospace-new-text-3">Clinical Trial Management Systems (CTMS)</h5>
                        <p className="aerospace-new-text-4">We provide CTMS solutions that centralize and automate the management of clinical trials. Our CTMS systems streamline study planning, participant recruitment, monitoring, and reporting, ensuring efficient trial management and compliance with regulatory requirements.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col mt-0 industrialmanu-top-new-card-4">
                    <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                      <div class="card-body">
                        <img className="industrialmanu-icon-1" src={IconIndu4} alt="lifescience" />
                        <h5 className="aerospace-new-text-3">Regulatory Compliance and Validation</h5>
                        <p className="aerospace-new-text-4">We assist life sciences organizations in achieving and maintaining regulatory compliance, including FDA and GxP regulations. Our services cover validation documentation, quality management systems, data integrity assessments, and compliance audits.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="industrialmanu-new-design-2 py-5">
          <div className="container-fluid col-lg-10 mx-auto">
            <div className="row row-cols-1 row-cols-lg-3 g-4 justify-content-center">
              <div className="col industrialmanu-new-card-1">
                <div class="card h-100">
                  <div class="card-body">
                    <img className="industrialmanu-icon-1" src={IconIndu5} alt="lifescience" />
                    <h5 class="automotive-new-text-1">Data Analytics and Business Intelligence</h5>
                    <p class="automotive-new-text-3"> Our data analytics and business intelligence solutions help life sciences companies gain valuable insights from their data. We assist in analyzing clinical data, patient outcomes, and research trends to drive informed decision-making, optimize processes, and support evidence-based medicine.</p>
                  </div>
                </div>
              </div>
              <div className="col industrialmanu-new-card-2">
                <div class="card h-100">
                  <div class="card-body">
                    <img className="industrialmanu-icon-1" src={IconIndu6} alt="lifescience" />
                    <h5 class="automotive-new-text-1">Pharmacovigilance and Drug Safety Systems</h5>
                    <p class="automotive-new-text-3">We offer pharmacovigilance and drug safety systems that enable efficient adverse event reporting, risk assessment, and compliance monitoring. Our solutions enhance patient safety, facilitate regulatory reporting, and support pharmacovigilance processes.</p>
                  </div>
                </div>
              </div>
              <div className="col industrialmanu-new-card-3">
                <div class="card h-100">
                  <div class="card-body">
                    <img className="industrialmanu-icon-1" src={IconIndu7} alt="lifescience" />
                    <h5 class="automotive-new-text-1">Bioinformatics and Genomics:</h5>
                    <p class="automotive-new-text-3">We provide bioinformatics and genomics solutions to support genomics research, data analysis, and personalized medicine initiatives. Our solutions encompass genome sequencing, data mining, variant analysis, and interpretation tools, enabling breakthrough discoveries and precision medicine advancements.</p>
                  </div>
                </div>
              </div>
              <div className="col industrialmanu-new-card-4 mt-0">
                <div class="card h-100">
                  <div class="card-body">
                    <img className="industrialmanu-icon-1" src={IconIndu8} alt="lifescience" />
                    <h5 class="automotive-new-text-1">Data Integration and Interoperability</h5>
                    <p class="automotive-new-text-3">We specialize in integrating disparate data sources and systems within the life sciences ecosystem. Our data integration solutions enable seamless data exchange, interoperability, and data harmonization, facilitating collaborative research and improving data accessibility.</p>
                  </div>
                </div>
              </div>
              <div className="col industrialmanu-new-card-5 mt-0">
                <div class="card h-100">
                  <div class="card-body">
                    <img className="industrialmanu-icon-1" src={IconIndu9} alt="lifescience" />
                    <h5 class="automotive-new-text-1">IT Infrastructure and Cloud Services</h5>
                    <p class="automotive-new-text-3">We offer design and implementation services for secure and scalable IT infrastructure for life sciences organizations. Our solutions include server deployment, network configuration, cloud integration, data storage, and disaster recovery planning, ensuring reliable and compliant IT infrastructure.</p>
                  </div>
                </div>
              </div>
              <div className="col industrialmanu-new-card-6 mt-0">
                <div class="card h-100">
                  <div class="card-body">
                    <img className="industrialmanu-icon-1" src={IconIndu10} alt="lifescience" />
                    <h5 class="automotive-new-text-1">IT Consulting and Strategy</h5>
                    <p class="automotive-new-text-3"> Our experienced consultants provide strategic guidance on technology roadmaps, digital transformation, and data management strategies. We help life sciences organizations leverage emerging technologies, optimize processes, and align technology investments with business goals.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 flex-lg-row-reverse align-items-center">
              <div className="col-lg-4">
                <img src={contactnew} alt="lifescience" />
              </div>
              <div className="col-lg-8">
                <p className="aerospace-new-text-5 mb-lg-4">At Upstrox, we are committed to supporting the advancement of life sciences by providing innovative and reliable IT solutions.</p>
                <a href='/contact' className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="aerospace-new-text-6 mb-lg-4">Contact us today to discuss your specific requirements and explore how Upstrox can empower your organization's research, development, and healthcare initiatives with our industry-leading IT services.</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
export default Lifescience;