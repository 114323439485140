import { MdOutlineArrowForwardIos } from "react-icons/md";
import HighTechnologybg from "../../Assets/images/servies-images/high-technology.webp"
import dataimg from "../../Assets/images/servies-images/Telecom Product Development webp/images/first section copy.webp";
import sustainemployee from "../../Assets/images/servies-images/sustainability/sustainemployee.webp";
import sustainabilityreporting from "../../Assets/images/servies-images/sustainability/sustainabilityreporting.webp";
import technologysolution from "../../Assets/images/servies-images/sustainability/technologysolution.webp";
import ContactNew from "../../Assets/images/servies-images/Telecom Product Development webp/images/last image copy.webp"
import { BiRightArrowAlt } from 'react-icons/bi';
import Dummybg from "../../Assets/images/servies-images/demo/dummy-3.webp";
import teleicon1 from "../../Assets/images/servies-images/Telecom Product Development webp/icon/Cloud and Virtualization Solutions.webp"
import teleicon2 from "../../Assets/images/servies-images/Telecom Product Development webp/icon/Billing and Revenue.webp"
import teleicon3 from "../../Assets/images/servies-images/Telecom Product Development webp/icon/Customer Experience.webp"
import teleicon4 from "../../Assets/images/servies-images/Telecom Product Development webp/icon/Quality Assurance.webp"
import MetaTags from "../../Metatag";
import { useEffect } from "react";

function TelecomProductDevelopment() {

    

    return (
        <>
               {/* <MetaTags
        top="Revolutionize Telecoms' with Upstrox's Product Development"
        title="Connecting Tomorrow: Upstrox's Telecom Product Development"
        description="With Upstrox's cutting-edge telecom product development,  learn about the latest ideas that make communication smoother and more efficient."/> */}
            <div className="TelecomProductDevelopment">
                <section className="top-banner-section top-banner-section-overral-bg-section telecom-background back-posi-img">
                    <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1 className="top-text-one"><span className="text-color">Telecom Product Development</span> by Upstrox.</h1>
                                    <div className="top-text-two">Upstrox offers comprehensive telecom product development services, helping organizations in the telecommunications industry create innovative and reliable products that meet the evolving needs of their customers.</div>
                                </div>
                            </div>
                            <div className="pt-3">
                                <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="datacard-section">
                    <section className="datacard-section-one py-5">
                        <div className="container">
                            <h5 className="text-one">Our team of telecom experts and engineers possess extensive experience in developing cutting-edge telecom solutions. Here's how Upstrox can assist you with telecom product development</h5>
                            <div className="row pt-2 pt-lg-5 align-items-center">
                                <div className="data-card-blur" style={{ background: "#00ffff75", opacity: "0.5" }}></div>
                                <div className="col-lg-7 col-md-7">
                                    <div className="container">
                                        <div className="row pt-lg-3 py-2">
                                            <div className="col">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Product Strategy and Roadmap</h4>
                                                    <h5 className="card-text-two text-ho">
                                                        Our telecom consultants work closely with your organization to develop a product strategy and roadmap aligned with your business goals and market demands. We assess market trends, identify customer needs, and define a clear product vision to guide the development process.
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-lg-3 py-2">
                                            <div className="col">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Network Infrastructure Solutions</h4>
                                                    <h5 className="card-text-two text-ho">
                                                        We design and develop network infrastructure solutions that enable efficient and reliable telecom services. Our experts specialize in areas such as network planning and optimization, radio frequency (RF) engineering, transmission systems, and network management systems (NMS).
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-lg-3 py-2">
                                            <div className="col">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Telecom Software Development</h4>
                                                    <h5 className="card-text-two text-ho">
                                                        We provide custom software development services tailored to the telecom industry. Our experts develop software solutions for network management, service provisioning, billing and charging, customer relationship management (CRM), and other telecom-specific applications.
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5">
                                    <div className=""><img src={dataimg} alt="TelecomProductDevelopment" className="datacard-img" /></div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>


                <section className="sustaincard-section">
                    <section className="sustaincard-section-two py-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 py-2">
                                    <div className="card h-100 p-4 top-border">

                                        <h4 className="card-text-one">Mobile Applications Development</h4>
                                        <h5 className="card-text-two">
                                        We specialize in developing mobile applications for telecom operators and service providers. Our experts create intuitive and feature-rich mobile apps for services such as self-service portals, mobile payments, loyalty programs, and mobile network monitoring.
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 py-2">
                                    <div className="card h-100 p-4" style={{ backgroundColor: "rgba(46, 46, 46, 0.199)" }}>

                                        <h4 className="card-text-one">Internet of Things (IoT) Solutions</h4>
                                        <h5 className="card-text-two">
                                        We assist organizations in leveraging IoT technology to enhance their telecom offerings. Our experts develop IoT solutions for smart cities, connected vehicles, industrial automation, and other IoT applications, enabling seamless connectivity and data management.
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 py-2">
                                    <div className="card h-100 p-4 top-border">

                                        <h4 className="card-text-one">Value-Added Services (VAS)</h4>
                                        <h5 className="card-text-two">
                                        We help telecom operators enhance their service offerings with value-added services. Our experts design and develop VAS solutions such as messaging services, mobile entertainment, mobile advertising, location-based services, and content delivery platforms.
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sustaincard-section-three py-3">
                        <div className="container">
                            <div className="row d-flex justify-content-center pt-lg-5">
                                <div className="col-lg-6 col-md-6">
                                    <div className="card h-100 p-3 text-center">
                                        <div className="pb-3"><img src={teleicon1} alt="TelecomProductDevelopment" className="sustain-card-img" /></div>
                                        <h4 className="card-text-one">Cloud and Virtualization Solutions</h4>
                                        <h5 className="card-text-two">
                                        We enable telecom operators to embrace cloud computing and virtualization technologies for improved scalability, flexibility, and cost-efficiency. Our experts design and implement cloud-based solutions, virtualized network functions (VNFs), and software-defined networking (SDN) architectures.
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="card h-100 p-3 text-center">
                                        <div className="pb-3"><img src={teleicon2} alt="TelecomProductDevelopment" className="sustain-card-img" /></div>
                                        <h4 className="card-text-one">Billing and Revenue Management Systems</h4>
                                        <h5 className="card-text-two">
                                        We develop comprehensive billing and revenue management systems that support complex telecom billing processes, including rating, charging, invoicing, and revenue assurance. Our solutions enable accurate and timely billing and revenue management for telecom operators.
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center pb-lg-5">
                                <div className="col-lg-6 col-md-6">
                                    <div className="card h-100 p-3 text-center">
                                        <div className="pb-3"><img src={teleicon3} alt="TelecomProductDevelopment" className="sustain-card-img" /></div>
                                        <h4 className="card-text-one">Customer Experience Management</h4>
                                        <h5 className="card-text-two">
                                        We help telecom operators enhance their customer experience through advanced analytics, personalization, and self-service capabilities. Our experts develop customer experience management solutions that enable proactive customer engagement, personalized offers, and seamless support.
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="card h-100 p-3 text-center">
                                        <div className="pb-3"><img src={teleicon4} alt="TelecomProductDevelopment" className="sustain-card-img" /></div>
                                        <h4 className="card-text-one">Quality Assurance and Testing</h4>
                                        <h5 className="card-text-two">
                                        We provide thorough quality assurance and testing services to ensure the reliability and performance of telecom products. Our experts conduct functional testing, performance testing, interoperability testing, and user acceptance testing to deliver high-quality telecom solutions.
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

                <section className="aerospace-new-design-2 py-5">
                    <div className="container">
                        <div className="row g-4 flex-lg-row-reverse">
                            <div className="col-lg-4">
                                <img src={ContactNew} alt="TelecomProductDevelopment" />
                            </div>
                            <div className="col-lg-8">
                                <p className="aerospace-new-text-5 mb-lg-4">At Upstrox, we are committed to delivering innovative and reliable telecom products that help organizations stay competitive in the ever-evolving telecom industry.  </p>
                                <a href='/contact' className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="aerospace-new-design-2 py-5">
                    <div className="container">
                        <div className="row g-4 justify-content-center text-center">
                            <div className="col-lg-12">
                                <p className="aerospace-new-text-6 mb-lg-4">Contact us today to discuss your telecom product development requirements and explore how Upstrox can assist you in creating cutting-edge solutions to meet the needs of your customers.</p>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}
export default TelecomProductDevelopment;