import Insurancebg from "../../Assets/images/servies-images/insurance.webp"
import { TfiJoomla } from "react-icons/tfi";
import { SiAntdesign, SiChase } from "react-icons/si";
import { BiRightArrowAlt } from "react-icons/bi";
import { MdOutlineArrowForwardIos, MdOutlineDeveloperBoard } from "react-icons/md";
import img1 from "../../Assets/images/icon/Insurance IT Solutions/Policy Administration Systems.webp";
import img2 from "../../Assets/images/icon/Insurance IT Solutions/Claims Management Systems.webp";
import img3 from "../../Assets/images/icon/Insurance IT Solutions/Customer Relationship Management.webp";
import img4 from "../../Assets/images/icon/Insurance IT Solutions/Insurance Quoting and Underwriting Systems.webp";
import img5 from "../../Assets/images/icon/Insurance IT Solutions/Cybersecurity and Data Protection.webp";
import img6 from "../../Assets/images/icon/Insurance IT Solutions/IT Infrastructure Management.webp";
import img7 from "../../Assets/images/icon/Insurance IT Solutions/IT Consulting and Strategy.webp";
import img8 from "../../Assets/images/icon/Insurance IT Solutions/INSURANCE-image.webp";


function Insurance() {
  return (
    <>
      <div className="insurance-banner-section">
        <section className=" top-banner-section-overral-bg-section back-posi-img" style={{ backgroundImage: "url(" + Insurancebg + ")" }}>
          <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid top-banner-section'>
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one">Upstrox - Your Trusted Partner for
                    <span className="text-color">  Insurance</span> IT Solutions
                  </h1>
                  <div className="top-text-two">
                    In the dynamic and data-driven insurance industry, technology plays a vital role in streamlining operations, enhancing customer experiences, and managing risks effectively. At Upstrox, we specialize in providing comprehensive IT services tailored specifically for the insurance sector.
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
              </div>
            </div>
          </div>
        </section>

        <section className="logistic-new-card-section pt-5">
          <div className="container-fluid col-lg-10">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div>
                  <h3 className="aerospace-new-text-1">
                    {" "}
                    Insurance IT Solutions
                  </h3>
                  <p className="aerospace-new-text-2">
                    {" "}
                    Whether you are an insurance carrier, broker, or agency, we have the expertise to support your IT needs. Here are the key IT services we offer:
                  </p>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 g-1 sustaincard-section">
                  <div className="col">
                    <div className="card card-one h-100">
                      <div className="card-body">
                        <div className="p-3">
                          {" "}
                          <img src={img1} alt="insurance" className="sustain-card-img" />
                        </div>
                        <div className="pt-lg-3">
                          <h4 className="card-text-one ps-3 ">
                            Policy Administration Systems
                          </h4>
                          <h5 className="card-text-two px-3">
                            We provide robust policy administration systems that streamline policy management processes, including underwriting, policy issuance, endorsements, and renewals. Our solutions enable efficient data capture, policy document generation, and policyholder self-service.
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card card-one h-100 ">
                      <div className="card-body">
                        <div className="p-3">
                          {" "}
                          <img src={img2} alt="insurance" className="sustain-card-img" />
                        </div>
                        <div className="pt-lg-3">
                          <h4 className="card-text-one ps-3">
                            Claims Management Systems
                          </h4>
                          <h5 className="card-text-two px-3">
                            We offer claims management systems that optimize claims processing, from initial filing to settlement. Our solutions automate claims workflows, facilitate document management, and enable real-time communication with stakeholders, ensuring faster claims resolution and improved customer satisfaction.
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card card-one h-100">
                      <div className="card-body">
                        <div className="p-3">
                          {" "}
                          <img src={img3} alt="insurance" className="sustain-card-img" />
                        </div>
                        <div className="pt-lg-3">
                          <h4 className="card-text-one ps-3">
                            Customer Relationship Management (CRM)
                          </h4>
                          <h5 className="card-text-two px-3">
                            We provide CRM solutions tailored for the insurance industry. Our solutions help you manage customer interactions, track sales opportunities, and automate marketing campaigns. By leveraging our CRM system, you can enhance customer satisfaction, drive sales growth, and improve customer retention.
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card card-one h-100">
                      <div className="card-body">
                        <div className="p-3">
                          {" "}
                          <img src={img4} alt="insurance" className="sustain-card-img" />
                        </div>
                        <div className="pt-lg-3">
                          <h4 className="card-text-one ps-3 ">
                            Insurance Quoting and Underwriting Systems
                          </h4>
                          <h5 className="card-text-two px-3">
                            We assist in developing quoting and underwriting systems that enable efficient and accurate insurance policy pricing and risk assessment. Our solutions integrate data sources, automate underwriting rules, and facilitate quote generation, enhancing underwriting efficiency and decision-making.
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="sustaincard-section">

          <section className="sustaincard-section-two py-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-4 py-2">
                  <div className="card h-100 p-4 top-border">

                    <h4 className="card-text-one">Insurance Analytics and Business Intelligence</h4>
                    <h5 className="card-text-two">
                    Our insurance analytics and business intelligence solutions help you derive valuable insights from data. We assist in analyzing claims data, policy data, and market trends to drive informed decision-making, identify fraud patterns, optimize pricing, and enhance risk management.
                    </h5>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 py-2">
                  <div className="card h-100 p-4" style={{ backgroundColor: "rgba(46, 46, 46, 0.199)" }}>

                    <h4 className="card-text-one">Agency and Broker Management Systems</h4>
                    <h5 className="card-text-two">
                    We offer agency and broker management systems that streamline distribution channel management, commission tracking, and producer onboarding. Our solutions provide efficient collaboration between carriers and agents/brokers, enabling seamless information exchange and revenue tracking.
                    </h5>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 py-2">
                  <div className="card h-100 p-4 top-border">

                    <h4 className="card-text-one">Digital Transformation and Online Portals</h4>
                    <h5 className="card-text-two">
                    We assist in digital transformation initiatives, including the development of online portals for policyholders, agents, and brokers. Our solutions enable self-service functionality, policy document access, and real-time policy updates, enhancing convenience and customer engagement.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="sustaincard-section-three py-3">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-7">
                  <div className="card h-100 p-3 text-center">
                    <div className="pb-3"><img src={img5} alt="insurance" className="sustain-card-img" /></div>
                    <h4 className="card-text-one">Cybersecurity and Data Protection</h4>
                    <h5 className="card-text-two">
                    Protecting sensitive customer data and ensuring cybersecurity is paramount in the insurance industry. We provide comprehensive cybersecurity services, including network security, data encryption, access controls, and vulnerability assessments. Our solutions help safeguard customer information and protect against data breaches.
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center pb-lg-5">
                <div className="col-lg-6 col-md-6">
                  <div className="card h-100 p-3 text-center">
                    <div className="pb-3"><img src={img6} alt="insurance" className="sustain-card-img" /></div>
                    <h4 className="card-text-one">IT Infrastructure Management</h4>
                    <h5 className="card-text-two">
                    We offer end-to-end IT infrastructure management services, including network design, server management, data backup, and disaster recovery planning. Our solutions ensure secure and reliable IT infrastructure to support insurance operations and minimize downtime.
                    </h5>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="card h-100 p-3 text-center">
                    <div className="pb-3"><img src={img7} alt="insurance" className="sustain-card-img" /></div>
                    <h4 className="card-text-one">IT Consulting and Strategy</h4>
                    <h5 className="card-text-two">
                    Our experienced consultants offer IT consulting services to insurance organizations, providing guidance on technology strategy, digital transformation, and regulatory compliance. We help you align technology investments with business goals, optimize processes, and leverage emerging technologies.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>


        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 flex-lg-row-reverse align-items-center">
              <div className="col-lg-4">
                <img src={img8} alt="insurance" />
              </div>
              <div className="col-lg-8">
                <p className="aerospace-new-text-5 mb-lg-4">At Upstrox, we understand the unique challenges and opportunities in the insurance industry. Our comprehensive IT services empower organizations to drive operational efficiency, enhance customer experiences, and achieve sustainable growth </p>
                <a href='/contact' className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="aerospace-new-text-6 mb-lg-4">Contact us today to discuss your insurance IT needs and explore how we can support your organization's success.</p>
              </div>
            </div>
          </div>
        </section>

      </div>
    </>
  )
}
export default Insurance;