import {
  MdOutlineArrowForwardIos,
  MdOutlineDeveloperBoard,
} from "react-icons/md";
import transport from "../../Assets/images/icon/Oil And Gas IT Solutions/image.webp";
import { BiRightArrowAlt } from "react-icons/bi";
import img1 from "../../Assets/images/icon/Oil And Gas IT Solutions/Exploration and Production.webp";
import img2 from "../../Assets/images/icon/Oil And Gas IT Solutions/Production Data Management.webp";
import img3 from "../../Assets/images/icon/Oil And Gas IT Solutions/Asset Integrity Management.webp";
import img4 from "../../Assets/images/icon/Oil And Gas IT Solutions/Refinery and Plant Optimization.webp";
import img5 from "../../Assets/images/icon/Oil And Gas IT Solutions/Supply Chain Management.webp";
import img6 from "../../Assets/images/icon/Oil And Gas IT Solutions/Environmental Health and Safety (EHS) Management.webp";
import img7 from "../../Assets/images/icon/Oil And Gas IT Solutions/Data Analytics and Business Intelligence.webp";
import img8 from "../../Assets/images/icon/Oil And Gas IT Solutions/Cybersecurity and Data Protection.webp";
import img9 from "../../Assets/images/icon/Oil And Gas IT Solutions/IoT and Remote Monitoring.webp";
import img10 from "../../Assets/images/icon/Oil And Gas IT Solutions/IT Consulting and Strategy.webp";

function OilGas() {
  return (
    <>
      <div className="oilgas-banner-section">
        <section className=" oilgas-img back-posi-img">
          <div className="mobile-new-ups-bg-shade-inner mx-auto container-fluid top-banner-section">
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one">
                    <span className="text-color">Oil and Gas</span> IT Solutions
                    by Upstrox
                  </h1>
                  <div className="top-text-two">
                    Upstrox is your trusted partner for comprehensive IT
                    solutions tailored specifically for the oil and gas
                    industry. We understand the unique challenges and
                    requirements of the industry, including safety, operational
                    efficiency, and regulatory compliance.
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <a href="/contact">
                  <button className="top-button right-side">
                    Get More{" "}
                    <MdOutlineArrowForwardIos className="top-icon ms-lg-2" />
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="logistic-new-card-section pt-5">
          <div className="container-fluid col-lg-10">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div>
                  <h3 className="aerospace-new-text-1">
                    {" "}
                    Oil and Gas IT Solutions
                  </h3>
                  <p className="aerospace-new-text-2">
                    {" "}
                    Our expertise in technology and domain knowledge enables us
                    to deliver innovative solutions that enhance exploration,
                    production, refining, and distribution processes. Here are
                    the key IT services we offer
                  </p>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="row row-cols-1 row-cols-md-1 row-cols-lg-2 g-1">
                  <div className="col">
                    <div className="card card-one h-100">
                      <div className="card-body">
                        <div className="p-3">
                          {" "}
                          <img
                            src={img1}
                            className="automotive-new-text-2 oil-img"
                            alt="oilgas"
                          />
                        </div>
                        <div className="pt-lg-3">
                          <h4 className="card-text-one ps-3 ">
                            Exploration and Production (E&P) Systems
                          </h4>
                          <h5 className="card-text-two px-3">
                            We provide robust E&P systems that optimize the
                            entire oil and gas exploration and production
                            lifecycle. Our solutions cover reservoir modeling,
                            well planning, drilling optimization, production
                            monitoring, and asset management, improving
                            operational efficiency and maximizing hydrocarbon
                            recovery.
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card card-one h-100 ">
                      <div className="card-body">
                        <div className="p-3">
                          {" "}
                          <img
                            src={img2}
                            className="automotive-new-text-2 oil-img"
                            alt="oilgas"
                          />
                        </div>
                        <div className="pt-lg-3">
                          <h4 className="card-text-one ps-3">
                            Production Data Management
                          </h4>
                          <h5 className="card-text-two px-3">
                            We offer production data management solutions that
                            collect, analyze, and visualize production data from
                            various sources. Our systems enable real-time
                            monitoring, performance analysis, and reporting,
                            supporting informed decision-making and operational
                            optimization.
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card card-one h-100">
                      <div className="card-body">
                        <div className="p-3">
                          {" "}
                          <img
                            src={img3}
                            className="automotive-new-text-2 oil-img"
                            alt="oilgas"
                          />
                        </div>
                        <div className="pt-lg-3">
                          <h4 className="card-text-one ps-3">
                            Asset Integrity Management
                          </h4>
                          <h5 className="card-text-two px-3">
                            We provide asset integrity management solutions to
                            ensure the safety, reliability, and compliance of
                            oil and gas assets. Our solutions cover inspection
                            planning, risk assessment, maintenance management,
                            and regulatory compliance monitoring, minimizing
                            downtime and maximizing asset performance.
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card card-one h-100">
                      <div className="card-body">
                        <div className="p-3">
                          {" "}
                          <img
                            src={img4}
                            className="automotive-new-text-2 oil-img"
                            alt="oilgas"
                          />
                        </div>
                        <div className="pt-lg-3">
                          <h4 className="card-text-one ps-3 ">
                            Refinery and Plant Optimization
                          </h4>
                          <h5 className="card-text-two px-3">
                            We offer refinery and plant optimization solutions
                            to improve operational efficiency and reduce costs.
                            Our solutions include process monitoring, yield
                            optimization, energy management, and emissions
                            monitoring, enabling efficient refining operations
                            and compliance with environmental regulations.
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="consumer-new-cards-section pt-5">
          <div className="container mt-5 con-card-two">
            <div className="row row-cols-lg-3 row-cols-1 row-cols-md-2  pb-lg-4">
              <div className="col con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img src={img5} className="con-icon-top" alt="oilgas" />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">Supply Chain Management</h3>
                    <p className="card-text-two">
                      {" "}
                      We provide supply chain management solutions tailored for
                      the oil and gas industry. Our solutions optimize processes
                      such as procurement, inventory management, logistics, and
                      demand forecasting, ensuring timely delivery of resources
                      and minimizing supply chain disruptions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img src={img6} className="con-icon-top" alt="oilgas" />
                  </div>
                  <div className="pt-lg-4 ">
                    <h3 className="card-text-one">
                      Environmental Health and Safety (EHS) Management
                    </h3>
                    <p className="card-text-two">
                      We offer EHS management solutions to ensure compliance
                      with environmental regulations and enhance worker safety.
                      Our solutions include incident management, regulatory
                      compliance tracking, risk assessments, and safety training
                      management, promoting a safe and environmentally
                      responsible work environment.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img src={img7} className="con-icon-top" alt="oilgas" />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                      Data Analytics and Business Intelligence
                    </h3>
                    <p className="card-text-two">
                      Our data analytics and business intelligence solutions
                      help oil and gas companies derive valuable insights from
                      their data. We assist in analyzing production data,
                      operational metrics, market trends, and predictive
                      analytics to drive informed decision-making, optimize
                      processes, and identify cost-saving opportunities.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col con-top con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img src={img8} className="con-icon-top" alt="oilgas" />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                      Cybersecurity and Data Protection
                    </h3>
                    <p className="card-text-two">
                      Protecting sensitive oil and gas data, operational
                      systems, and infrastructure is crucial. We provide
                      comprehensive cybersecurity services, including network
                      security, access controls, data encryption, and
                      vulnerability assessments. Our solutions help safeguard
                      your valuable assets and protect against cyber threats.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img src={img9} className="con-icon-top" alt="oilgas" />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">IoT and Remote Monitoring</h3>
                    <p className="card-text-two">
                      We leverage IoT and remote monitoring technologies to
                      enable real-time monitoring of assets, equipment, and
                      remote locations. Our solutions provide predictive
                      maintenance, remote diagnostics, and asset performance
                      optimization, enhancing operational efficiency and
                      reducing downtime.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img src={img10} className="con-icon-top" alt="oilgas" />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                      IT Consulting and Strategy
                    </h3>
                    <p className="card-text-two">
                      Our experienced consultants provide IT consulting services
                      to oil and gas organizations, helping develop technology
                      strategies, optimize workflows, and implement digital
                      transformation initiatives. We assist in technology
                      selection, vendor management, and leveraging emerging
                      technologies to drive operational efficiency and
                      cost-effectiveness.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 flex-lg-row-reverse align-items-center">
              <div className="col-lg-4">
                <img src={transport} alt="oilgas" />
              </div>
              <div className="col-lg-8">
                <p className="aerospace-new-text-5 mb-lg-4">
                  At Upstrox, we are committed to supporting the oil and gas
                  industry by providing innovative and reliable IT solutions.{" "}
                </p>
                <a href="/contact" className="new-ups-button-2">
                  <span className="new-ups-button-icon-3">Read More</span>{" "}
                  <BiRightArrowAlt className="new-ups-button-icon-2" />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="aerospace-new-text-6 mb-lg-4">
                  Contact us today to discuss your specific requirements and
                  explore how Upstrox can empower your organization's
                  operations, safety, and profitability with our
                  industry-leading IT services.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default OilGas;
