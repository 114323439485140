import { MdOutlineSecurity } from "react-icons/md";
import { BsFillDatabaseFill } from "react-icons/bs";
import { GiDigitalTrace } from "react-icons/gi";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Dummybg from "../../Assets/images/icon/retail/retail landing banner.webp";
import retaillast from "../../Assets/imgs/re-banner.webp";
import logidis1ast from "../../Assets/imgs/re-last.webp";
import { BiRightArrowAlt } from "react-icons/bi";
import point from "../../Assets/imgs/pos.webp";
import ecom from "../../Assets/imgs/e-comerce.webp";
import inventory from "../../Assets/imgs/inventory management.webp";

function Retail() {
  return (
    <>
      <div className="retail-banner-section">
        <section
          className="top-banner-section top-banner-section-overral-bg-section retail-background-image back-posi-img"
          style={{ backgroundImage: "url(" + Dummybg + ")" }}
        >
          <div className="mobile-new-ups-bg-shade-inner mx-auto container-fluid">
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one">
                    <span className="text-color"> Retail </span> IT Solutions by
                    Upstrox
                  </h1>
                  <div className="top-text-two">
                    {" "}
                    Upstrox is your trusted partner for comprehensive IT
                    solutions tailored specifically for the retail industry. We
                    understand the unique challenges and requirements of retail
                    businesses, including customer engagement, omnichannel
                    operations, inventory management, and analytics.
                  </div>
                </div>
              </div>
              <div className="pt-3">
              <a href="/contact"><button className="top-button right-side">
                  Get More{" "}
                  <MdOutlineArrowForwardIos className="top-icon ms-lg-2" />
                </button></a>
              </div>
            </div>
          </div>
        </section>

        <section className="retail-banner-section-two pt-5">
          <div className="container">
            <div className="row">
              <div className="col">
                <h5 className="retail-tw-text">
                  Our expertise in technology and domain knowledge enables us to
                  deliver innovative solutions that enhance operational
                  efficiency, improve customer experiences, and drive business
                  growth. Here are the key IT services we offer
                </h5>
              </div>
            </div>
          </div>
        </section>

        <section className="retail-banner-section-three">
          <div className="container-fluid card-re-one col-lg-10">
            <div className="row row-cols-lg-2 row-cols-1 row-cols-md-2 justify-content-md-end">
              <div className="col pt-3 mt-lg-3 ">
                <div className="card-re-border-top"></div>
                <div
                  className=" card card-back-re1"
                  style={{ borderColor: "rgb(39 63 75)" }}
                >
                  <div
                    className="card-body d-lg-flex"
                    style={{ background: "#273f4b42" }}
                  >
                    <div className="re-icon-one">
                      {" "}
                      <img src={point} className="img-card-one" alt="retail" />
                    </div>
                    <div>
                      <h4 className="card-text-one">
                        Point of Sale (POS) Systems
                      </h4>
                      <h5 className="card-text-two">
                        We provide robust POS systems that streamline sales
                        transactions, inventory management, and customer
                        interactions. Our POS solutions enable smooth checkout
                        processes, inventory tracking, sales reporting, and
                        integrated payment processing, ensuring efficient and
                        accurate sales operations.
                      </h5>
                    </div>
                  </div>
                </div>
                <div
                  className="card-re-border-bottom"
                  style={{ borderColor: "rgb(39 63 75)" }}
                ></div>
              </div>
              <div className="col pt-3 pt-lg-0 retail-card-one">
                <div className="card-re-border-top"></div>
                <div
                  className=" card card-back-re2"
                  style={{ borderColor: "rgb(30 55 54)" }}
                >
                  <div
                    className="card-body d-lg-flex"
                    style={{
                      background: "rgb(30 55 54 / 25%)",
                      borderColor: "black",
                    }}
                  >
                    <div className="re-icon-one">
                      {" "}
                      <img src={ecom} alt="retail" className="img-card-one" />
                    </div>
                    <div>
                      <h4 className="card-text-one">
                        E-commerce and Omni-channel Solutions
                      </h4>
                      <h5 className="card-text-two">
                        We offer e-commerce and omni-channel solutions that
                        enable retailers to expand their reach and provide
                        seamless shopping experiences across multiple channels.
                        Our solutions encompass online store development, mobile
                        commerce, order management, and integration with
                        brick-and-mortar operations.
                      </h5>
                    </div>
                  </div>
                </div>
                <div
                  className="card-re-border-bottom"
                  style={{ borderColor: "rgb(30 55 54)" }}
                ></div>
              </div>
              <div className="col retail-card-one pt-md-5 pt-4">
                <div className="card-re-border-top"></div>
                <div
                  className=" card card-back-re3"
                  style={{ borderColor: "rgb(53 58 45)" }}
                >
                  <div
                    className="card-body d-lg-flex"
                    style={{ background: "rgb(53 58 45 / 25%)" }}
                  >
                    <div className="re-icon-one">
                      <img src={inventory} alt="retail" className="img-card-one"/>
                    </div>
                    <div>
                      <h4 className="card-text-one">
                        Inventory Management Systems
                      </h4>
                      <h5 className="card-text-two">
                        We provide inventory management systems that optimize
                        stock levels, replenishment, and supply chain
                        coordination. Our solutions automate inventory tracking,
                        order management, and demand forecasting, reducing
                        stockouts, minimizing excess inventory, and improving
                        overall inventory accuracy.
                      </h5>
                    </div>
                  </div>
                </div>
                <div
                  className="card-re-border-bottom"
                  style={{ borderColor: "rgb(53 58 45)" }}
                ></div>
              </div>
            </div>
          </div>

          <div className="container-fluid mt-5 re-two-card col-lg-10">
            <div className="row row-cols-lg-2 row-cols-md-2 row-cols-1 gap-3 gap-lg-0  gap-md-0 ">
              <div className="col pe-lg-0">
                <div className=" card h-100 py-lg-2">
                  <div className="card-body">
                    <div>
                      <h4 className="card-text-one">
                        Customer Relationship Management (CRM)
                      </h4>
                      <h5 className="card-text-two">
                        We offer CRM solutions tailored for the retail industry.
                        Our CRM systems help retailers manage customer data,
                        track purchase history, and personalize marketing
                        campaigns. With our CRM solutions, you can enhance
                        customer engagement, loyalty, and retention.
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col ">
                <div className=" card h-100">
                  <div className="card-body">
                    <div>
                      <h4 className="card-text-one">
                        Loyalty and Rewards Programs
                      </h4>
                      <h5 className="card-text-two">
                        We provide loyalty and rewards program solutions to help
                        retailers drive customer loyalty and repeat business.
                        Our solutions enable the design and management of
                        loyalty programs, customer segmentation, and
                        personalized rewards, fostering customer satisfaction
                        and brand advocacy.
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col pt-md-3">
                <div className=" card h-100 left-margin">
                  <div className="card-body ">
                    <div>
                      <h4 className="card-text-one">
                        Supply Chain and Logistics Management
                      </h4>
                      <h5 className="card-text-two">
                        We offer supply chain and logistics management solutions
                        that optimize the movement of goods from suppliers to
                        stores or customers. Our solutions cover demand
                        planning, transportation management, warehouse
                        optimization, and real-time visibility, ensuring
                        efficient and cost-effective supply chain operations.
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col pt-md-3">
                <div className=" card h-100 left-margin">
                  <div className="card-body">
                    <div>
                      <h4 className="card-text-one">
                        Analytics and Business Intelligence
                      </h4>
                      <h5 className="card-text-two">
                        Our analytics and business intelligence solutions help
                        retailers derive valuable insights from their data. We
                        assist in analyzing sales data, customer behavior,
                        market trends, and pricing strategies to drive
                        data-driven decision-making, optimize product
                        assortment, and enhance revenue growth.
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid mt-5 re-card-three col-lg-10">
            <div className="row flex-row-reverse">
              <div className="col-lg-5 col-md-4 d-flex align-items-center">
                <img src={retaillast} alt="retail" style={{height:"auto"}} />
              </div>
              <div className="col-lg-7 col-md-8">
                <div className=" card ">
                  <div className="card-body d-lg-flex d-md-flex">
                    <div className="">
                      {" "}
                      <BsFillDatabaseFill className="re-icon-three th-icon-one" />
                    </div>
                    <div className="px-lg-3 px-md-3">
                      <h4 className="card-text-one">
                        Store Operations and Workforce Management
                      </h4>
                      <h5 className="card-text-two">
                        We provide store operations and workforce management
                        solutions that optimize store-level operations and
                        employee scheduling. Our solutions cover task
                        management, employee performance tracking, time and
                        attendance, and labor optimization, improving store
                        efficiency and productivity.
                      </h5>
                    </div>
                  </div>
                </div>
                <div className=" card ">
                  <div className="card-body d-lg-flex d-md-flex">
                    <div className="">
                      {" "}
                      <GiDigitalTrace className="re-icon-three th-icon-two" />
                    </div>
                    <div className="px-lg-3 px-md-3">
                      <h4 className="card-text-one">
                        Digital Signage and In-store Technologies
                      </h4>
                      <h5 className="card-text-two">
                        We offer digital signage and in-store technologies to
                        enhance customer engagement and shopping experiences.
                        Our solutions include interactive displays, self-service
                        kiosks, mobile apps, and beacon technology, enabling
                        personalized promotions, product information, and
                        seamless in-store navigation.
                      </h5>
                    </div>
                  </div>
                </div>
                <div className=" card ">
                  <div className="card-body d-lg-flex d-md-flex">
                    <div className="">
                      {" "}
                      <MdOutlineSecurity className="re-icon-three th-icon-three" />
                    </div>
                    <div className="px-lg-3 px-md-3">
                      <h4 className="card-text-one">
                        Cybersecurity and Data Protection
                      </h4>
                      <h5 className="card-text-two">
                        Protecting customer data, payment information, and
                        retail operations is paramount. We provide comprehensive
                        cybersecurity services, including network security, data
                        encryption, access controls, and vulnerability
                        assessments. Our solutions help safeguard your valuable
                        assets and protect against data breaches.
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="logistic-last-section my-lg-5">
          <div className="container py-3">
            <div className="row flex-row-reverse">
              <div className="col-lg-4 col-md-4">
                <img src={logidis1ast} alt="retail" />
              </div>
              <div className="col-lg-8 col-md-8">
                <div className="pt-lg-5">
                  <p className="">
                    At Upstrox, we are committed to supporting retail businesses
                    with innovative and reliable IT solutions.
                  </p>
                </div>
                <div>
                  <a className="new-ups-button-2" href="/contact">
                    <span className="new-ups-button-icon-3">Read More</span>{" "}
                    <BiRightArrowAlt className="new-ups-button-icon-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-text-section py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="contact-text-last mb-lg-4">
                  Contact us today to discuss your specific requirements and
                  explore how Upstrox can empower your organization's
                  operations, customer experiences, and growth with our
                  industry-leading IT services.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Retail;
