import { FaArrowRightLong } from "react-icons/fa6";
import { FaArrowLeftLong } from "react-icons/fa6";
import { BiRightArrowAlt } from "react-icons/bi";
import { FaMapLocationDot } from "react-icons/fa6";
import { BsDatabaseFillCheck } from "react-icons/bs";
import { FaUserEdit } from "react-icons/fa";


import { MdOutlineArrowForwardIos } from "react-icons/md";
import Dummybg from "../../Assets/images/servies-images/demo/dummy-1.webp";
import customercardimg from "../../Assets/images/servies-images/customerexperience/customercardimg.webp";
import experience from "../../Assets/images/servies-images/experience.jpg";
import { useEffect } from "react";



function CustomerExperience() {
    
    return (
        <>
            <div className="customer-section">
                <section className="top-banner-section top-banner-section-overral-bg-section customer-background back-posi-img" >
                    <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1 className="top-text-one"><span className="text-color">Customer Experience</span> Solutions by Upstrox</h1>
                                    <div className="top-text-two">Upstrox offers comprehensive customer experience solutions designed to help businesses deliver exceptional experiences to their customers across various touchpoints.</div>
                                </div>
                            </div>
                            <div className="pt-3">
                                <a href="/Contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="customercard-section">
                    <section className="customercard-section-one py-5">
                        <div className="container-fluid col-lg-10">
                            <h5 className="text-one">We understand the importance of customer satisfaction, loyalty, and advocacy in driving business growth. Our customer experience solutions combine technology, data, and strategy to help you build meaningful connections with your customers. Here are the key customer experience services we offer</h5>
                            <div className="row pt-5 justify-content-between align-items-center g-4">
                                <div className="col-lg-4 col-md-4">
                                    <div className="card text-white">
                                        <div className=" px-3 pt-2"><FaMapLocationDot className="customer-icon" /></div>
                                        <div className="py-3 ">
                                            <h4 className="card-text-one  px-3">Customer Journey Mapping</h4>
                                            <h5 className="card-text-two px-3">
                                                We assist organizations in mapping and understanding the customer journey across multiple touchpoints. Our experts identify key customer interactions, pain points, and opportunities for improvement, enabling you to enhance the customer experience at every stage.
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div className="card text-white p-lg-4 p-md-4">
                                        <div className=" px-3 pt-2"><BsDatabaseFillCheck className="customer-icon" /></div>
                                        <div className="py-3 ">
                                            <h4 className="card-text-one  px-3">Customer Data Management</h4>
                                            <h5 className="card-text-two px-3">
                                                We provide solutions for effective customer data management, including data integration, cleansing, and segmentation. Our experts help you collect, organize, and analyze customer data to gain valuable insights and create personalized experiences.
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div className="card text-white">
                                        <div className=" px-3 pt-2"><FaUserEdit className="customer-icon" /></div>
                                        <div className="py-3 ">
                                            <h4 className="card-text-one  px-3">Omnichannel Experience Design</h4>
                                            <h5 className="card-text-two px-3">
                                                We specialize in designing seamless omnichannel experiences that enable customers to interact with your brand consistently across different channels and devices. Our experts ensure a unified and personalized experience, regardless of whether customers engage with you online, in-store, or through mobile applications.
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="customercard-section-two py-lg-5">
                        <div className="container-fluid col-lg-10">
                            <div className="row align-items-center">

                                <div className="col-lg-4 col-md-4">
                                    <div><img src={customercardimg} className="customer-card-img" /></div>
                                </div>
                                <div className="col-lg-8 col-md-8">
                                    <div className="container">
                                        <div className="row pt-lg-5 py-2">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Personalization and Customer Segmentation</h4>
                                                    <h5 className="card-text-two text-ho">
                                                        We help businesses deliver personalized experiences by leveraging customer segmentation and targeting strategies. Our solutions include customer profiling, segmentation analysis, and recommendation engines that enable you to tailor your offerings to individual customer preferences and needs.
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Voice of the Customer (VoC) Programs</h4>
                                                    <h5 className="card-text-two text-ho">
                                                        We assist organizations in implementing Voice of the Customer programs to capture customer feedback and sentiments. Our solutions include surveys, feedback management platforms, sentiment analysis, and customer satisfaction measurement tools, enabling you to understand customer preferences and make data-driven decisions.
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-lg-5 py-2">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Customer Service and Support Solutions</h4>
                                                    <h5 className="card-text-two text-ho">
                                                        We offer customer service and support solutions that enhance customer satisfaction and loyalty. Our solutions include self-service portals, chatbots, ticketing systems, and knowledge bases that enable customers to find information and resolve issues quickly and efficiently.
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Social Media Monitoring and Engagement</h4>
                                                    <h5 className="card-text-two text-ho">
                                                        We provide social media monitoring and engagement solutions that enable you to monitor brand mentions, track customer sentiments, and engage with customers on social media platforms. Our experts help you leverage social media to enhance the customer experience and build brand advocacy.
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="customercard-section-three py-5">
                        <div className="container">
                            <div className="row py-lg-5 pt-3 d-flex align-items-center">
                                <div className="col-lg-3 col-md-3">
                                    <h4 className="card-text-one customercard-heading">Customer Loyalty and Rewards Programs</h4></div>
                                <div className="col-lg-1 col-md-2 card-hide">
                                    <div className=""><FaArrowRightLong className="customer-arrow" /></div>
                                </div>
                                <div className="col">
                                    <h5 className="card-text-two text-ho">
                                        We assist businesses in designing and implementing customer loyalty and rewards programs. Our solutions enable you to incentivize customer loyalty, create personalized rewards, and track customer engagement, fostering long-term relationships and repeat business.
                                    </h5>
                                </div>
                            </div>
                            <div className="row py-lg-5 pt-3 d-flex align-items-center row-reverse">
                                <div className="col">
                                    <h5 className="card-text-two text-ho">
                                        We specialize in customer analytics and insights to uncover valuable information about your customers' behaviors, preferences, and needs. Our experts analyze customer data, conduct market research, and utilize predictive analytics to provide actionable insights for targeted marketing and improved customer experiences.
                                    </h5>
                                </div>
                                <div className="col-lg-1 col-md-2 card-hide">
                                    <div className=""><FaArrowLeftLong className="customer-arrow" /></div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <h4 className="card-text-one customercard-heading">Customer Analytics and Insights</h4></div>
                            </div>
                            <div className="row py-lg-5 pt-3 d-flex align-items-center">
                                <div className="col-lg-3 col-md-3">
                                    <h4 className="card-text-one customercard-heading">Continuous Improvement and Measurement</h4></div>
                                <div className="col-lg-1 col-md-2 card-hide">
                                    <div className=""><FaArrowRightLong className="customer-arrow" /></div>
                                </div>
                                <div className="col">
                                    <h5 className="card-text-two text-ho">
                                        We help organizations establish processes for continuous improvement of the customer experience. Our solutions include performance measurement frameworks, key performance indicators (KPIs), and customer feedback loops that enable you to monitor progress, identify areas for improvement, and iterate on your customer experience strategies.
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="customercard-section-four py-5 py-lg-5">
                        <div className="container">
                            <div className="row d-flex align-items-center g-4">
                                <div className="col-lg-8 col-md-8 "><p className="service-footer-text mb-4">At Upstrox, we are committed to delivering customer experience solutions that help you build strong relationships with your customers and drive business success.</p>
                                    <a href="contact" className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
                                </div>
                                <div className="col-lg-4 col-md-4"><img src={experience} alt="experience" className="service-footer-img"></img></div>
                            </div>
                        </div>
                    </section>
                    <section className="customercard-section-five pt-5">
                        <div className="container">
                            <div className="row g-4 justify-content-center text-center">
                                <div className="col-lg-12">
                                    <p className="contact-text mb-lg-4">Contact us today to discuss your specific requirements and explore how Upstrox can empower your organization with our industry-leading customer experience services.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        </>
    )
}
export default CustomerExperience;