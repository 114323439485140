import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {
  Pagination,
  Autoplay,
  Navigation,
  Mousewheel,
  FreeMode,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { BiRightArrowAlt } from "react-icons/bi";
import ContactNew from "../../Assets/images/icon/aerospacedefence/aerospace-defence-contact.webp";
import banner1 from "../../Assets/images/Blog/banner1.webp";
import { TbPointFilled } from "react-icons/tb";
import { TbMathGreater } from "react-icons/tb";
import banner2 from "../../Assets/images/Blog/small-banner1.webp";
import banner3 from "../../Assets/images/Blog/small-banner2.webp";
import banner4 from "../../Assets/images/Blog/small-banner3.webp";
import banner5 from "../../Assets/images/Blog/small-banner4.webp";
import ScrollSpy from "react-ui-scrollspy";
import inner1 from "../../Assets/images/Blog/bloginner-one.webp";
import inner2 from "../../Assets/images/Blog/inner-two.webp";


function Blog() {
  return (
    <>
      <div className="blog-section">
        <section className="blog-section-one ">
          <div className="container-fluid top-blog-section-one">
            <div className="row ">
              <div className=" d-flex justify-content-center mb-lg-3">
                <h3>Recent Blogs</h3>
              </div>
              <Swiper
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                // navigation={true}
                modules={[Pagination]}
                className="mySwiper"
              >
                <SwiperSlide className="">
                  <div className="">
                    <div className="container-fluid col-lg-10">
                      <div className="row flex-lg-row-reverse flex-md-row-reverse align-items-center top-new-ups-height justify-content-center">
                      <div className="col-lg-5 col-md-5">
                          <img src={banner2} style={{borderRadius:"11px"}} />
                        </div>
                        <div className="col-lg-5 col-md-5">
                          <h1 className="blog-text-one">
                            {" "}
                            A Tale of Success: How Aladdin Software Transformed
                            a Company
                          </h1>
                          <div className="blog-text-two mb-3">
                            Follow the story of Aladdin Software and how it
                            completely changed a company for the better. Learn
                            about the steps that led to this amazing success
                          </div>
                          <a href="/contact" className="blog-button-read">
                            Read More
                          </a>
                        </div>
                      
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="">
                  <div className="">
                    <div className="container-fluid col-lg-10">
                      <div className="row flex-lg-row-reverse flex-md-row-reverse align-items-center top-new-ups-height justify-content-center">
                      <div className="col-lg-5 col-md-5">
                          <img src={ContactNew} style={{borderRadius:"11px"}} />
                        </div>
                        <div className="col-lg-5 col-md-5">
                          <h1 className="blog-text-one">
                            {" "}
                            A Tale of Success: How Aladdin Software Transformed
                            a Company
                          </h1>
                          <div className="blog-text-two mb-3">
                            Follow the story of Aladdin Software and how it
                            completely changed a company for the better. Learn
                            about the steps that led to this amazing success
                          </div>
                          <a href="/contact" className="blog-button-read">
                            Read More
                          </a>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </section>

        <section className="blog-section-two py-5">
          <div className="container">
            <div className=" d-flex justify-content-center">
              <h3 className="">CATEGORIES</h3>
            </div>
            <div className="under-line-title "></div>
            <div className="row mt-lg-5 mt-3">
              <div className="col">
                <ul class="list-group list-group-horizontal-lg list-group-horizontal-md gap-3">
                  <li class="list-group-item">AI & ML</li>
                  <li class="list-group-item">Analytics </li>
                  <li class="list-group-item">App Development</li>
                  <li class="list-group-item">Blockchain & ML</li>
                  <li class="list-group-item">Data Engineering</li>
                  <li class="list-group-item">Devops</li>
                  <li class="list-group-item">Ecommerce & CMS</li>
                </ul>
                <ul class="list-group list-group-horizontal-lg gap-3 list-group-horizontal-md  mt-3 justify-content-center">
                  <li class="list-group-item">Industries</li>
                  <li class="list-group-item">On Demand Teams</li>
                  <li class="list-group-item">Software Engineering</li>
                  <li class="list-group-item">Solutions</li>
                  <li class="list-group-item">Technologies</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="blog-section-three py-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-8 ">
                <div className="pb-lg-3">
                  <img src={banner1} />
                </div>
                <h5 className="blog-text-3">
                  upstrox.com <TbMathGreater /> Blog{" "}
                </h5>
                <div className="py-3 d-flex align-items-center justify-content-between">
                  <button className="hire-button">Hire Developers</button>
                  <span className="blog-text-3">
                    Updated On September 1 2023
                  </span>
                </div>
                <div>
                  <div
                    class="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          Table of Content
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                        <div id="list-example" class="list-group">
                          <a
                            class="list-group-item list-group-item-action dropdown-item"
                            href="#list-item-1" data-to-scrollspy-id="list-item-1"
                          >
                            Challenges in Remote Developer Retention
                          </a>
                          <a
                            class="list-group-item list-group-item-action dropdown-item"
                            href="#list-item-2" data-to-scrollspy-id="list-item-2"
                          >
                            Effective Remote Developer Retention Strategies
                          </a>
                          <a
                            class="list-group-item list-group-item-action dropdown-item"
                            href="#list-item-3" data-to-scrollspy-id="list-item-3"
                          >
                            Why choose Your Team in India for hiring remote?
                          </a>
                          <a
                            class="list-group-item list-group-item-action dropdown-item"
                            href="#list-item-4" data-to-scrollspy-id="list-item-4"
                          >
                            Wrapping up
                          </a>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>

                      <ScrollSpy>
                      <div class="py-4">
                        <div
                          
                        >
                          <h4 id="list-item-1">
                            Challenges in Remote Developer Retention
                          </h4>
                          <p className="blog-text-3">
                            Hiring remote developers has become increasingly
                            popular in the digital era. In the long run, working
                            with remote employees and retaining them raises
                            challenges that can impact the retention rate and
                            job satisfaction to a greater extent.{" "}
                          </p>
                          <h4 id="list-item-2">
                            Effective Remote Developer Retention Strategies
                          </h4>
                          <div className="d-flex justify-content-center pb-lg-3">
                          <img src={inner1} className="inner-one"/>
                          </div>                   
                          <p className="blog-text-3">
                            Work culture plays a crucial role in the
                            productivity of employees. When you hire remote
                            developers, they will get the chance to interact
                            differently than regular employees. The
                            opportunities to socialize with colleagues and team
                            members are less. 
                             However, creating a strong work
                            culture can help feel connected with the
                            organization and engaged with the team members.{" "}
                          </p>
                        
                          <h4 id="list-item-3">
                            Why choose Your Team in India for hiring remote
                            developers?
                          </h4>
                          <p className="blog-text-3">
                            Your Team in India is one of the best IT companies
                            in India to provide outsourcing services to boost
                            the business development of start-ups and
                            small-scale to large-sized enterprises. We have a
                            strong force of over 200 certified developers with a
                            track record of 1000+ successfully delivered
                            projects{" "}<a className="link-text">IT Project Outsourcing.</a>
                          </p>
                          <h4 id="list-item-4">Wrapping up</h4>
                          <p className="blog-text-3">
                            When you hire remote employees, providing the best
                            environment and work policies is essential to keep
                            them engaged with your organization. By offering the
                            best growth opportunities, competitive benefits and
                            compensation, organizing virtual meetings, and
                            effective communication strategies, you can make
                            them feel connected and valued in the organization.
                          </p>
                          <img src={inner2} className="inner-two pb-lg-3"/>
                          <h4 id="list-item-4">What to look for when outsourcing software development?</h4>
                          <p className="blog-text-3">
                          Choosing the right company is crucial once you've determined the model and type of software development outsourcing that suits your needs. To do this, businesses should have specific evaluation criteria or a checklist to identify the best fit for their requirements.
                          When entering partnerships with outsourcing companies, they might make promises of delivering exceptional business benefits but may fall short when it comes to complex and challenging applications. Therefore, it's important to consider the following essential parameters during the selection process.
                          </p>
                        </div>
                      </div>
                      </ScrollSpy>
                    </div>
              </div>
              <div className="col-lg-4 col-md-4 sticky">
                <div className="trend-section">
                  <h5 className="blog-text-one d-flex justify-content-center">
                    Trending posts
                  </h5>
                  <div className="under-line-title"></div>
                  <div>
                    <ul class="list-group">
                      <li class="list-group-item">
                        <div>
                          <TbPointFilled className="point-group" />{" "}
                        </div>
                        <span className="">
                          A Tale of Success: How Aladdin Software Transformed a
                          Company{" "}
                        </span>
                      </li>
                      <li class="list-group-item">
                        <div>
                          <TbPointFilled className="point-group" />{" "}
                        </div>{" "}
                        The Genie in the Code - Aladdin Software Development
                        Explained
                      </li>
                      <li class="list-group-item">
                        <div>
                          <TbPointFilled className="point-group" />{" "}
                        </div>{" "}
                        Top 10 Marketing Strategies for Crypto Exchanges in 2024
                      </li>
                      <li class="list-group-item">
                        <div>
                          <TbPointFilled className="point-group" />{" "}
                        </div>{" "}
                        <p className="">
                          {" "}
                          Top 5 Benefits of Implementing Hybrid Crypto Exchange
                          Development for Your Business{" "}
                        </p>
                      </li>
                      <li class="list-group-item">
                        <div>
                          <TbPointFilled className="point-group" />{" "}
                        </div>{" "}
                        <p className="">
                          {" "}
                          Blockchain and AI integration: the technologies of the
                          future!{" "}
                        </p>
                      </li>
                      <li class="list-group-item">
                        <div>
                          <TbPointFilled className="point-group" />{" "}
                        </div>{" "}
                        <p className="">
                          {" "}
                          Top 5 Benefits of Implementing Hybrid Crypto Exchange
                          Development for Your Business{" "}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="blog-section-four pb-lg-4">
          <div className="container">
            <div className=" d-flex justify-content-center">
              <h3 className="">Related Posts</h3>
            </div>
            <div className="under-line-title"></div>
            <div className="row row-cols-lg-3 row-cols-md-2 py-3 ">
              <div className="col pt-lg-0 pt-3">
                <div class="card h-100">
                  <img src={banner1} class="card-img-top" alt="..." />
                  <div class="card-body">
                    <h5 class="card-title">
                      Top 10 Marketing Strategies for Crypto Exchanges in 2024
                    </h5>
                    <a href="#" class="custom-nav-hover">
                      Read More
                    </a>
                    <div className="read-line"></div>
                  </div>
                </div>
              </div>
              <div className="col pt-lg-0 pt-3">
                <div class="card h-100">
                  <img src={banner2} class="card-img-top" alt="..." />
                  <div class="card-body">
                    <h5 class="card-title">
                      The Genie in the Code - Aladdin Software Development
                    </h5>
                    <a href="#" class="">
                      Read More
                    </a>
                    <div className="read-line"></div>
                  </div>
                </div>
              </div>
              <div className="col pt-lg-0 pt-3">
                <div class="card h-100">
                  <img src={banner3} class="card-img-top" alt="..." />
                  <div class="card-body">
                    <h5 class="card-title">
                      Top 5 Benefits of Implementing Hybrid Crypto Exchange
                    </h5>
                    <a href="#" class="">
                      Read More
                    </a>
                    <div className="read-line"></div>
                  </div>
                </div>
              </div>
              <div className="col pt-3">
                <div class="card h-100">
                  <img src={banner4} class="card-img-top" alt="..." />
                  <div class="card-body">
                    <h5 class="card-title">
                      Blockchain and AI integration: the technologies of the
                      future!
                    </h5>
                    <a href="#" class="">
                      Read More
                    </a>
                    <div className="read-line"></div>
                  </div>
                </div>
              </div>
              <div className="col pt-3">
                <div class="card h-100">
                  <img src={banner5} class="card-img-top" alt="..." />
                  <div class="card-body">
                    <h5 class="card-title">
                      Top 7 Profitable AI Business ideas to start in 2024
                    </h5>
                    <a href="#" class="">
                      Read More
                    </a>
                    <div className="read-line"></div>
                  </div>
                </div>
              </div>
              <div className="col pt-3">
                <div class="card h-100">
                  <img src={banner1} class="card-img-top" alt="..." />
                  <div class="card-body">
                    <h5 class="card-title">
                      Top Profitable Cryptocurrency Business Ideas for 2024
                    </h5>
                    <a href="#" class="">
                      Read More
                    </a>
                    <div className="read-line"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav aria-label="Page navigation example ">
  <ul class="pagination d-flex justify-content-center mt-lg-3">
    <li class="page-item">
      <a class="page-link" href="#" aria-label="Previous">
        <span aria-hidden="true">&laquo;</span>
      </a>
    </li>
    <li class="page-item"><a class="page-link" href="#">1</a></li>
    <li class="page-item"><a class="page-link" href="#">2</a></li>
    <li class="page-item"><a class="page-link" href="#">3</a></li>
    <li class="page-item">
      <a class="page-link" href="#" aria-label="Next">
        <span aria-hidden="true">&raquo;</span>
      </a>
    </li>
  </ul>
</nav>
        </section>
     
      </div>
    </>
  );
}
export default Blog;
