import { MdOutlineArrowForwardIos } from "react-icons/md";
import Dummybg from "../../Assets/images/servies-images/Performance Engineering/images (4)/landing banner copy 2.webp"
import ContactNew from "../../Assets/images/servies-images/Performance Engineering/images (4)/last image copy.webp"
import { BiRightArrowAlt } from 'react-icons/bi';
import inteicon1 from "../../Assets/images/servies-images/Performance Engineering/icon/Performance Testing.webp";
import inteicon2 from "../../Assets/images/servies-images/Performance Engineering/icon/Load and Stress Testing.webp";
import inteicon3 from "../../Assets/images/servies-images/Performance Engineering/icon/Performance Monitoring and Analysis.webp";
import inteicon4 from "../../Assets/images/servies-images/Performance Engineering/icon/Scalability and Capacity Planning.webp";
import inteicon5 from "../../Assets/images/servies-images/Performance Engineering/icon 2/Performance Tuning and Optimization.webp";
import inteicon6 from "../../Assets/images/servies-images/Performance Engineering/icon 2/Cloud Performance Optimization.webp";
import inteicon7 from "../../Assets/images/servies-images/Performance Engineering/icon 2/Mobile Performance Testing.webp";
import inteicon8 from "../../Assets/images/servies-images/Performance Engineering/icon 3/Performance Engineering Consulting.webp";
import inteicon9 from "../../Assets/images/servies-images/Performance Engineering/icon 3/Performance Audits and Health Checks.webp";
import inteicon10 from "../../Assets/images/servies-images/Performance Engineering/icon 3/Performance Engineering Training.webp";
import { useEffect } from "react";


function PerformanceEngineering() {
   
    return (
        <>
            <div className="performance-section">
                <section className="top-banner-section top-banner-section-overral-bg-section back-posi-img performance-background" >
                    <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1 className="top-text-one"><span className="text-color">Performance Engineering</span> Services by Upstrox</h1>
                                    <div className="top-text-two">Upstrox offers comprehensive Performance Engineering services to help organizations optimize the performance and scalability of their software applications and IT infrastructure.</div>
                                </div>
                            </div>
                            <div className="pt-3">
                               <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="aerospace-new-design-1 py-5">
                <div className="container-fluid col-lg-11 mx-auto">
                    <div className="row">
                    <div className="col-lg-4">
                        <h2 className="aerospace-new-text-1 mb-lg-4 mb-3">Upstrox in Performance Engineering Services</h2>
                        <p className="aerospace-new-text-2">Our Performance Engineering experts employ industry best practices and cutting-edge tools to ensure that your applications deliver optimal performance, even under high loads and demanding conditions. Here are the key components of our Performance Engineering services</p>
                    </div>
                    <div className="col-lg-8">
                        <div className="row row-cols-md-1 row-cols-1 row-cols-lg-2 g-4">
                        <div className="col">
                            <img src={inteicon1} alt="performance" className="aerospace-new-image-1" />
                            <h5 className="aerospace-new-text-3">Performance Testing</h5>
                            <p className="aerospace-new-text-4">We conduct thorough performance testing of your applications to identify performance bottlenecks, measure response times, and evaluate system behavior under various load conditions. Our experts design and execute performance test scenarios to simulate real-world usage patterns, enabling you to assess application performance and scalability.</p>
                        </div>
                        <div className="col">
                            <img src={inteicon2} alt="performance" className="aerospace-new-image-1" />
                            <h5 className="aerospace-new-text-3">Load and Stress Testing</h5>
                            <p className="aerospace-new-text-4">We perform load and stress testing to evaluate how your applications perform under expected and peak loads. Our experts simulate high user traffic, heavy data volumes, and complex transactions to assess application performance, identify performance limitations, and ensure that your systems can handle anticipated workloads.</p>
                        </div>
                        <div className="col">
                            <img src={inteicon3} alt="performance" className="aerospace-new-image-1" />
                            <h5 className="aerospace-new-text-3">Performance Monitoring and Analysis</h5>
                            <p className="aerospace-new-text-4">We implement advanced performance monitoring tools to continuously monitor your applications and infrastructure in real-time. Our experts analyze performance metrics, identify performance bottlenecks, and provide actionable insights to optimize system performance, improve response times, and enhance user experiences.</p>
                        </div>
                        <div className="col">
                            <img src={inteicon4} alt="performance" className="aerospace-new-image-1" />
                            <h5 className="aerospace-new-text-3">Scalability and Capacity Planning</h5>
                            <p className="aerospace-new-text-4">We assist organizations in assessing and planning for scalability and capacity requirements. Our experts analyze your application architecture, user demand patterns, and growth projections to determine the optimal infrastructure scalability and capacity needed to support your future business needs.</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>

                <section className="aerospace-new-design-2 py-5">
                <div className="container-fluid col-lg-11 mx-auto">
                    <div className="row row-cols-md-1 row-cols-1 row-cols-lg-3 g-4 py-lg-5">
                    <div className="col aerospace-new-design-2-inner-1">
                        <img src={inteicon5} alt="performance" className="aerospace-new-image-2" />
                        <h5 className="aerospace-new-text-3">Performance Tuning and Optimization</h5>
                        <p className="aerospace-new-text-4">We optimize the performance of your applications by identifying and addressing performance bottlenecks. Our experts analyze system configurations, database queries, code structure, and network configurations to improve application response times, reduce resource utilization, and enhance overall system performance.</p>
                    </div>
                    <div className="col aerospace-new-design-2-inner-2">
                        <img src={inteicon6} alt="performance" className="aerospace-new-image-2" />
                        <h5 className="aerospace-new-text-3">Cloud Performance Optimization</h5>
                        <p className="aerospace-new-text-4">We help organizations optimize the performance of their applications deployed in cloud environments. Our experts assess cloud infrastructure configurations, application architectures, and resource allocations to ensure optimal performance and cost-effectiveness in cloud environments such as Amazon Web Services (AWS), Microsoft Azure, or Google Cloud Platform (GCP).</p>
                    </div>
                    <div className="col aerospace-new-design-2-inner-3">
                        <img src={inteicon7} alt="performance" className="aerospace-new-image-2" />
                        <h5 className="aerospace-new-text-3">Mobile Performance Testing</h5>
                        <p className="aerospace-new-text-4">We conduct mobile performance testing to assess the performance and responsiveness of your mobile applications across different devices, operating systems, and network conditions. Our experts evaluate factors such as app startup times, data synchronization, battery consumption, and network latency to optimize mobile app performance and enhance user satisfaction.</p>
                    </div>
                    </div>
                </div>
                </section>

                <section className="industrialmanu-new-design-2 py-5">
                <div className="container-fluid col-lg-10 mx-auto">
                    <div className="row row-cols-1 row-cols-lg-3 g-4 justify-content-center">
                    
                    <div className="col industrialmanu-new-card-4">
                        <div class="card h-100">
                        <div class="card-body">
                            <img className="industrialmanu-icon-1" src={inteicon8} alt="performance" />
                            <h5 class="automotive-new-text-1">Performance Engineering Consulting</h5>
                            <p class="automotive-new-text-3">We provide strategic consulting services to help organizations establish Performance Engineering practices within their software development lifecycle. Our experts assist in defining performance engineering strategies, establishing performance testing frameworks, and integrating performance engineering into the development process.</p>
                        </div>
                        </div>
                    </div>
                    <div className="col industrialmanu-new-card-5">
                        <div class="card h-100">
                        <div class="card-body">
                            <img className="industrialmanu-icon-1" src={inteicon9} alt="performance" />
                            <h5 class="automotive-new-text-1">Performance Audits and Health Checks</h5>
                            <p class="automotive-new-text-3">We perform comprehensive performance audits and health checks to assess the overall health and performance of your applications and infrastructure. Our experts conduct thorough assessments, identify performance gaps, and provide recommendations for improvement to ensure optimal system performance and reliability.</p>
                        </div>
                        </div>
                    </div>
                    <div className="col industrialmanu-new-card-6">
                        <div class="card h-100">
                        <div class="card-body">
                            <img className="industrialmanu-icon-1" src={inteicon10} alt="performance" />
                            <h5 class="automotive-new-text-1">Performance Engineering Training</h5>
                            <p class="automotive-new-text-3">We offer customized training programs to equip your development and operations teams with the necessary skills and knowledge in Performance Engineering. Our experts provide hands-on training on performance testing tools, methodologies, and best practices, enabling your teams to independently conduct performance testing and optimization activities.</p>
                        </div>
                        </div>
                    </div>

                    </div>
                </div>
                </section>

                <section className="aerospace-new-design-2 py-5">
                <div className="container">
                    <div className="row g-4 flex-lg-row-reverse">
                    <div className="col-lg-4">
                        <img src={ContactNew} alt="performance" />
                    </div>
                    <div className="col-lg-8">
                        <p className="aerospace-new-text-5 mb-lg-4">At Upstrox, we are committed to ensuring that your applications and infrastructure perform at their best, providing exceptional user experiences and meeting your performance objectives. </p>
                        <a href='/contact' className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
                    </div>
                    </div>
                </div>
                </section>

                <section className="aerospace-new-design-2 py-5">
                <div className="container">
                    <div className="row g-4 justify-content-center text-center">
                    <div className="col-lg-12">
                        <p className="aerospace-new-text-6 mb-lg-4">Contact us today to explore Contact us today to discuss your Performance Engineering requirements and explore how Upstrox can assist your organization in optimizing the performance and scalability of your software applications.</p>
                    </div>
                    </div>
                </div>
                </section>
            </div>
        </>
    )
}
export default PerformanceEngineering;