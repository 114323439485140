import { MdOutlineArrowForwardIos } from "react-icons/md";
import Dummybg from "../../Assets/images/servies-images/demo/dummy-1.webp"

import contactnew from "../../Assets/images/servies-images/sap solution/images/last image copy.webp"

import { BiRightArrowAlt } from 'react-icons/bi';
import sapicon1 from "../../Assets/images/servies-images/sap solution/icon/SAP Implementation.webp"
import sapicon2 from "../../Assets/images/servies-images/sap solution/icon/SAP S4HANA Migration.webp"
import sapicon3 from "../../Assets/images/servies-images/sap solution/icon/SAP Customization and Development.webp"
import sapicon4 from "../../Assets/images/servies-images/sap solution/icon/SAP Integration.webp"
import sapicon5 from "../../Assets/images/servies-images/sap solution/icon 2/SAP Support and Maintenance.webp"
import sapicon6 from "../../Assets/images/servies-images/sap solution/icon 2/SAP Business Intelligence and Analytics.webp"
import sapicon7 from "../../Assets/images/servies-images/sap solution/icon 2/SAP Cloud Solutions.webp"
import sapicon8 from "../../Assets/images/servies-images/sap solution/icon 2/SAP Fiori and User Experience (UX) Design.webp"
import sapicon9 from "../../Assets/images/servies-images/sap solution/icon 2/SAP Training and Change Management.webp"
import sapicon10 from "../../Assets/images/servies-images/sap solution/icon 2/SAP Upgrade and Enhancement.webp"
import { useEffect } from "react";


function SAP() {
    

    return (
        <>
            <div className="sap-section">
                <section className="top-banner-section top-banner-section-overral-bg-section sap-background back-posi-img">
                    <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1 className="top-text-one"><span className="text-color">SAP</span> Solutions by Upstrox</h1>
                                    <div className="top-text-two">Upstrox is a trusted provider of SAP solutions, offering a wide range of services to help organizations leverage the power of SAP's enterprise software. </div>
                                </div>
                            </div>
                            <div className="pt-3">
                                <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="industrialmanu-new-design-1 py-5">
                    <div className="container-fluid col-lg-11 mx-auto py-lg-5">
                        <div className="row">
                            <div className="col-lg-4">
                                <h2 className="aerospace-new-text-1 mb-lg-4 mb-3">Upstrox in SAP</h2>
                                <p className="aerospace-new-text-2">As an experienced SAP partner, we assist businesses in implementing, customizing, and optimizing SAP solutions to drive operational efficiency, improve business processes, and achieve digital transformation. Here are the key SAP services we offer</p>
                            </div>
                            <div className="col-lg-8">
                                <div className="row row-cols-md-1 row-cols-1 row-cols-lg-2 g-4">
                                    <div className="col mt-0 industrialmanu-top-new-card-1">
                                        <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                                            <div class="card-body">
                                                <img className="industrialmanu-icon-1" src={sapicon1} alt="sap" />
                                                <h5 className="aerospace-new-text-3">SAP Implementation</h5>
                                                <p className="aerospace-new-text-4">We provide end-to-end SAP implementation services, including system configuration, data migration, and integration with existing systems. Our experienced consultants work closely with your organization to tailor SAP solutions to your specific requirements, ensuring a successful implementation.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col mt-0 industrialmanu-top-new-card-2">
                                        <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                                            <div class="card-body">
                                                <img className="industrialmanu-icon-1" src={sapicon2} alt="sap" />
                                                <h5 className="aerospace-new-text-3">SAP S/4HANA Migration</h5>
                                                <p className="aerospace-new-text-4">We assist businesses in migrating from legacy systems to SAP S/4HANA, the next-generation intelligent ERP solution. Our experts guide you through the migration process, ensuring a seamless transition and enabling you to leverage the advanced capabilities of S/4HANA.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col mt-0 industrialmanu-top-new-card-3">
                                        <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                                            <div class="card-body">
                                                <img className="industrialmanu-icon-1" src={sapicon3} alt="sap" />
                                                <h5 className="aerospace-new-text-3">SAP Customization and Development</h5>
                                                <p className="aerospace-new-text-4">We offer SAP customization and development services to tailor SAP solutions to your unique business needs. Our team of developers and consultants leverage SAP's development tools and frameworks to extend functionality, create custom reports, and build bespoke applications.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col mt-0 industrialmanu-top-new-card-4">
                                        <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                                            <div class="card-body">
                                                <img className="industrialmanu-icon-1" src={sapicon4} alt="sap" />
                                                <h5 className="aerospace-new-text-3">SAP Integration</h5>
                                                <p className="aerospace-new-text-4">We specialize in integrating SAP solutions with other enterprise systems, such as CRM, MES, HR, and financial systems. Our integration services ensure smooth data flow and process synchronization between SAP and other systems, enabling seamless end-to-end business processes.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="industrialmanu-new-design-2 py-5">
                    <div className="container-fluid col-lg-10 mx-auto">
                        <div className="row row-cols-1  row-cols-md-2 row-cols-lg-3 g-4 justify-content-center">
                            <div className="col industrialmanu-new-card-1">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <img className="industrialmanu-icon-1" src={sapicon5} alt="sap" />
                                        <h5 class="automotive-new-text-1">SAP Support and Maintenance</h5>
                                        <p class="automotive-new-text-3">We provide comprehensive SAP support and maintenance services to ensure the smooth operation of your SAP landscape. Our dedicated support team offers incident management, system monitoring, performance tuning, and regular updates to keep your SAP environment optimized and secure.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col industrialmanu-new-card-2">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <img className="industrialmanu-icon-1" src={sapicon6} alt="sap" />
                                        <h5 class="automotive-new-text-1">SAP Business Intelligence and Analytics</h5>
                                        <p class="automotive-new-text-3">We assist organizations in harnessing the power of SAP's business intelligence and analytics tools. Our experts help you design and implement SAP BI solutions, enabling you to gain valuable insights from your data, create meaningful reports, and drive data-driven decision-making.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col industrialmanu-new-card-3">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <img className="industrialmanu-icon-1" src={sapicon7} alt="sap" />
                                        <h5 class="automotive-new-text-1">SAP Cloud Solutions</h5>
                                        <p class="automotive-new-text-3">We help businesses leverage SAP's cloud solutions, such as SAP SuccessFactors, SAP Ariba, and SAP Concur. Our experts guide you through the implementation and integration of these cloud solutions, enabling you to streamline HR processes, automate procurement, and manage travel and expenses effectively.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col industrialmanu-new-card-4 mt-0">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <img className="industrialmanu-icon-1" src={sapicon8} alt="sap" />
                                        <h5 class="automotive-new-text-1">SAP Fiori and User Experience (UX) Design</h5>
                                        <p class="automotive-new-text-3">We specialize in SAP Fiori and UX design, creating intuitive and user-friendly interfaces for SAP applications. Our UX experts design personalized, role-based Fiori apps that enhance user productivity, simplify tasks, and improve the overall user experience.
</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col industrialmanu-new-card-5 mt-0">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <img className="industrialmanu-icon-1" src={sapicon9} alt="sap" />
                                        <h5 class="automotive-new-text-1">SAP Training and Change Management</h5>
                                        <p class="automotive-new-text-3">We offer SAP training programs and change management services to ensure a smooth adoption of SAP solutions within your organization. Our training programs cater to end-users, administrators, and executives, empowering them to effectively use and maximize the benefits of SAP.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col industrialmanu-new-card-6 mt-0">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <img className="industrialmanu-icon-1" src={sapicon10} alt="sap" />
                                        <h5 class="automotive-new-text-1">SAP Upgrade and Enhancement</h5>
                                        <p class="automotive-new-text-3">We assist businesses in upgrading their existing SAP systems to newer versions and applying enhancement packs to leverage the latest features and functionalities. Our experts ensure a seamless upgrade process with minimal disruption to your business operations.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="aerospace-new-design-2 py-5">
                    <div className="container">
                        <div className="row g-4 flex-lg-row-reverse">
                            <div className="col-lg-4">
                                <img src={contactnew} alt="sap" />
                            </div>
                            <div className="col-lg-8">
                                <p className="aerospace-new-text-5 mb-lg-4">At Upstrox, we are committed to delivering SAP solutions that drive business success and enable digital transformation.</p>
                                <a href='/contact' className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="aerospace-new-design-2 py-5">
                    <div className="container">
                        <div className="row g-4 justify-content-center text-center">
                            <div className="col-lg-12">
                                <p className="aerospace-new-text-6 mb-lg-4">Contact us today to discuss your specific requirements and explore how Upstrox can empower your organization with our industry-leading SAP services.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default SAP;