import { FaCircle } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import {
  AiOutlineInstagram,
  AiFillLinkedin,
  AiFillYoutube,
} from "react-icons/ai";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Footerphone from "../Assets/images/footer-phone-2.gif";
import Config from "../Config";
import axios from "axios";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import ReactFlagsSelect from "react-flags-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useEffect, useState } from "react";
import "../App.css";
import logo from "../Assets/images/upstrox (1)/Asset 8@4x.webp";


function Footer() {
  const [countryCode, setCountryCodes] = useState("");
  const [countryDatas, setCountryDatas] = useState({});
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [selectedCountryName,setSelectedCountryName]= useState("")

  const fetchCountryData = async () => {
    try {
      const response = await fetch("https://ipapi.co/json");
      const data = await response.json();
      console.log(data.country_code.toLowerCase(),"daaaata")
      setCountryCodes(data.country_code.toLowerCase())
      setSelectedCountryCode(data.country_code)
      setSelectedCountryName(data.country_name)
    } catch (error) {
      console.error("Error fetching country data: ", error);
      setCountryDatas("us");
      setCountryDatas("United States");
    }
  };

  useEffect(() => {
    fetchCountryData();
  }, []);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      country: "",
      phoneno: "",
      requirement: "",
      socialMedia: "",
      message: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required!"),
      email: Yup.string()
        .email("Please enter valid email!")
        .required("E-mail is required!"),
      phoneno: Yup.number().required("Phone number  is required!"),
      requirement: Yup.string().required("Please select one field!"),
      socialMedia: Yup.string().required("Socialmedia id  is required!"),
      message: Yup.string().required("Please say required!"),
      country: Yup.string().required("Country is required!"),
    }),
    onSubmit: async (values) => {
      try {
        const payload = {
          name: values.name,
          email: values.email,
          phoneno: values.phoneno,
          requirement: values.requirement,
          socialMedia: values.socialMedia,
          message: values.message,
          country:values.country
        };
        await axios.post(`${Config.SERVER_URL}/register`, payload);
        // alert("User Registeration SuccessFully !!!")
        window.location.href = "/thankyou";
        resetForm("");
      } catch {
        console.log("error");
      }
    },
  });

  const handlePhoneChange = (value, country, e, formettedValue) => {
    handleChange(e);

    setValues((prevValues) => ({
      ...prevValues,
      phoneno: value,
      country:selectedCountryName
    }));
  };

  return (
    <div className="App footer-section">
      <section
        className="footer-new-ups-bottom-form-section py-lg-0 py-5"
        id="footer-section-hide"
      >
        <div className="container-fluid overflow-hidden">
          <div className="row align-items-center">
            <div className="col-lg-5 p-0">
              <div className="footer-new-bg-section-inner-2">
                <h5
                  class="floating-form-text-1 position-relative"
                  style={{ width: "fit-content" }}
                >
                  Your Ideas, Our Expertise!
                </h5>
                <div className="d-flex flex-row mt-lg-4 align-items-end">
                  <div className="d-lg-block d-none">
                    <div className="footer-phone-postioning">
                      <img src={Footerphone} alt="Footerphone" />
                    </div>
                  </div>
                  <div>
                    <p class="floating-form-text-2">
                      Share your vision, and our team of experts is eager to
                      schedule a private consultation to look into your project.
                      Your information is safe with us.
                    </p>
                    <p class="floating-form-text-2 mt-lg-4">
                      Reach us today, and let's begin on this exciting journey
                      together towards turning your project dreams into reality.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 footer-new-bg-section px-0">
              <form
                className="position-relative"
                onSubmit={handleSubmit}
              >
                <div className="footer-new-bg-section-inner-1">
                  <h2 className="mb-lg-4">Contact me</h2>
                  <div className="row g-lg-4 g-3">
                    <div className="col-lg-6">
                      <div class="form-floating mb-lg-3">
                        <input
                          type="text"
                          autoComplete="off"
                          id="floatingInput"
                          class="form-control"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                          placeholder="Enter Your Name"
                          invalid={touched.name && errors.name ? true : false}
                        />
                        <label for="floatingInput">Name</label>
                      </div>
                      {touched.name && errors.name && (
                        <small className="invalid-email error password-text-33">
                          {errors.name}
                        </small>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <div class="form-floating mb-lg-3">
                        <input
                          type="email"
                          autoComplete="off"
                          class="form-control"
                          id="floatingInput"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                          placeholder="Enter Your email"
                          invalid={touched.email && errors.email ? true : false}
                        />
                        <label for="floatingInput">Email</label>
                      </div>
                      {touched.email && errors.email && (
                        <small className="invalid-email error password-text-33">
                          {errors.email}
                        </small>
                      )}
                    </div>

                    {/* <div className="col-lg-6">
                      <div class="form-floating mb-lg-3">
                        <input
                          type=""
                          autoComplete="off"
                          class="form-control"
                          id="floatingInput"
                          name=" country"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                          placeholder="enter Your Contry"
                          invalid={touched.email && errors.email ? true : false}
                        />
                        <label for="floatingInput">Country</label>
                      </div>
                      {touched.country && errors.country && (
                        <small className="invalid-email error password-text-33">
                          {errors.country}
                        </small>
                      )}
                    </div> */}
                    <div class="col-lg-6 m-0">
                      <label
                        for="floatingInput"
                        class="form-label"
                        placeholder="enter Your Contry"
                      >
                      </label>
                    <ReactFlagsSelect className=" from-flag"
                    selected={selectedCountryCode}
                    onSelect={(country_code) => setSelectedCountryCode(country_code)}
                    id="floatingInput"
                    name="country"
                    />
                    </div>

                    <div className="col-lg-6">
                      <div class="form-floating mb-lg-3">
                        <PhoneInput
                          class="from-flag"
                          id="floatingInput"
                          name="phoneno"
                          autoComplete="off"
                          country={countryCode}
                          value={values.phoneno}
                          onChange={handlePhoneChange}
                          onBlur={handleBlur}
                          invalid={
                            touched.phoneno && errors.phoneno ? true : false
                          }
                        />
                      </div>
                      {touched.phoneno && errors.phoneno && (
                        <small className="invalid-email error password-text-33">
                          {errors.phoneno}
                        </small>
                      )}
                    </div>

                    <div className="col-lg-6">
                      <div class="form-floating">
                        <select
                          class="form-select"
                          autoComplete="off"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                          name="requirement"
                          value={values.requirement}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={
                            touched.requirement && errors.requirement
                              ? true
                              : false
                          }
                        >
                          <option value="" disabled="disabled">
                            Select your services
                          </option>
                          <option value="Automobile">Automobile</option>
                          <option value="Banking"> Banking</option>
                          <option value="Bio-Technology">Bio-Technology</option>
                          <option value="eCommerce">eCommerce</option>
                          <option value="DeFi Development">
                            DeFi Development
                          </option>
                          <option value="Education & Training">
                            Education & Training
                          </option>
                          <option value="Forming"> Forming</option>
                          <option value="Financial Service">
                            Financial Service
                          </option>
                          <option value="Health Care">Health Care</option>
                          <option value="Information Technology">
                            Information Technology
                          </option>
                          <option value="Insurance">Insurance</option>
                          <option value="Manufacturing"> Manufacturing</option>
                          <option value="Media & Entertainment">
                            Media & Entertainment
                          </option>
                          <option value="Oil & Gas">Oil & Gas</option>
                          <option value="Pharmaceutical">
                            {" "}
                            Pharmaceutical
                          </option>
                          <option value="Real Estate"> Real Estate</option>
                          <option value="Retail"> Retail</option>
                          <option value="Sports"> Sports</option>
                          <option value="TeleCommunication">
                            TeleCommunication
                          </option>
                          <option value="Textile"> Textile</option>
                          <option value="Tourism & Hospitality">
                            Tourism & Hospitality
                          </option>
                          <option value="Transportation">Transportation</option>

                          <option value="Others">Others</option>
                        </select>
                        {/* <label for="floatingSelect">Select Industry type</label> */}
                      </div>
                      {touched.requirement && errors.requirement && (
                        <small className="invalid-email error password-text-33">
                          {errors.requirement}
                        </small>
                      )}
                    </div>
                    <div className="col-lg-6 ">
                      <div class="form-floating mb-lg-3 place-size-hidden">
                        <input
                          type="text"
                          autoComplete="off"
                          class="form-control"
                          id="floatingInput"
                          name="socialMedia"
                          value={values.socialMedia}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control "
                          placeholder="Drop your WhatsApp/Telegram/Skype ID for smooth communication"
                          invalid={
                            touched.socialMedia && errors.socialMedia
                              ? true
                              : false
                          }
                        />
                        <label for="floatingInput " className="">
                          Whatsapp/Telegram/Skype ID
                        </label>
                      </div>
                      {touched.socialMedia && errors.socialMedia && (
                        <small className="invalid-email error password-text-33">
                          {errors.socialMedia}
                        </small>
                      )}
                    </div>
                    <div className="col-lg-12">
                      <div class="form-floating">
                        <textarea
                          class="form-control"
                          autoComplete="off"
                          placeholder="Write Something ..."
                          id="floatingTextarea2"
                          style={{ height: "120px" }}
                          name="message"
                          value={values.message}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={
                            touched.message && errors.message ? true : false
                          }
                        ></textarea>
                        <label for="floatingTextarea2">
                          Write Your Requirements...
                        </label>
                      </div>
                      {touched.message && errors.message && (
                        <small className="invalid-email error password-text-33">
                          {errors.message}
                        </small>
                      )}
                    </div>
                    <div className="col-lg-12 text-end mt-lg-5">
                      <button class="contact-new-ups-button-1">
                        SUBMIT
                        <MdOutlineKeyboardArrowRight className="contact-new-ups-button-2" />
                      </button>
                    </div>
                    <div>
                      <span className="mail-font">
                        Facing troubles? them simply mail us on{" "}
                        <a
                          className="mail-under"
                          href="https://mail.google.com/mail/u/0/?fs=1&to=contact@upstrox.com&su=SUBJECT&body=BODY&tf=cm"
                          target="_blank"
                        >
                          {" "}
                          contact@upstrox.com
                        </a>
                      </span>{" "}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-new-ups-bottom-section">
        <div className="container-fluid">
          <div className="row">
            <div class="col-lg-3 footer-new-ups-looping-section"></div>
            <div className="col-lg-9">
              <div className="row">
                <div class="col-lg-5">
                  <div className="footer-new-ups-bottom-inner-2">
                    <div class="list-group footer-typo-new  footer-typo-new-1">
                    <a class="navbar-brand" href="/"><img  src={logo} className='logo-img-upstrox' alt='logo'/></a>
                      <p className="mt-lg-2 mt-2">
                        Tell us about your goals, and we'll organize a
                        one-on-one consultation with our experts to discuss your
                        project. We value your privacy and security.
                      </p>
                      <div className="mt-lg-5 list-group">
                        <a href="/Aboutus">About us</a>
                        <a href="/">News</a>
                        <a href="/">Event</a>
                        <a href="/Career">Careers</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div className="footer-new-ups-bottom-inner-2 tab-view">
                    <div class="list-group footer-typo-new footer-typo-new-2">
                      <h4 className="mb-lg-4">
                        <FaCircle
                          className="footer-typo-new-2-icons"
                          style={{ visibility: "hidden" }}
                        />
                        Development Services
                      </h4>
                      <a href="/">
                        <FaCircle className="footer-typo-new-2-icons" />
                        Custom Software Development
                      </a>
                      <a href="/">
                        <FaCircle className="footer-typo-new-2-icons" />
                        Mobile App Development
                      </a>
                      <a href="/">
                        <FaCircle className="footer-typo-new-2-icons" />
                        Custom Web App Development
                      </a>
                      <a href="/">
                        <FaCircle className="footer-typo-new-2-icons" />
                        PHP Development
                      </a>
                      <a href="/">
                        <FaCircle className="footer-typo-new-2-icons" />
                        React Native Development
                      </a>
                      <a href="/">
                        <FaCircle className="footer-typo-new-2-icons" />
                        Cross Platform App Development
                      </a>
                    </div>
                    <div class="list-group footer-typo-new footer-typo-new-2 mt-lg-4 mt-md-0 mt-4">
                      <h4 className="mb-lg-4">
                        <FaCircle
                          className="footer-typo-new-2-icons"
                          style={{ visibility: "hidden" }}
                        />
                        IT Consulting
                      </h4>
                      <a href="/">
                        <FaCircle className="footer-typo-new-2-icons" />
                        Blockchain Development
                      </a>
                      <a href="/">
                        <FaCircle className="footer-typo-new-2-icons" />
                        AI Development
                      </a>
                      <a href="/">
                        <FaCircle className="footer-typo-new-2-icons" />
                        IoT Development
                      </a>
                      <a href="/">
                        <FaCircle className="footer-typo-new-2-icons" />
                        Cloud Computing Services
                      </a>
                      <a href="/">
                        <FaCircle className="footer-typo-new-2-icons" />
                        AI ML Development
                      </a>
                      <a href="/">
                        <FaCircle className="footer-typo-new-2-icons" />
                        Big Data Consulting Services
                      </a>
                      <a href="/">
                        <FaCircle className="footer-typo-new-2-icons" />
                        Database Migration Service
                      </a>
                      <a href="/">
                        <FaCircle className="footer-typo-new-2-icons" />
                        Offshore Software Development
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row border-top footer-new-ups-bottom-inner-3 align-items-center">
                <div class="col-lg-6 mt-md-3">
                  <div className="d-flex justify-content-lg-evenly gap-3">
                    <a
                      href="https://twitter.com/upstrox"
                      className="bottom-copy-right-text-2"
                      target="_blank"
                    >
                      {" "}
                      <RiTwitterXFill />
                    </a>
                    <a
                      href="/"
                      className="bottom-copy-right-text-2"
                      target="_blank"
                    >
                      {" "}
                      <AiOutlineInstagram />
                    </a>
                    <a
                      href="https://www.facebook.com/Upstrox"
                      className="bottom-copy-right-text-2"
                      target="_blank"
                    >
                      {" "}
                      <BiLogoFacebookCircle />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/upstrox-techno-services"
                      className="bottom-copy-right-text-2"
                      target="_blank"
                    >
                      {" "}
                      <AiFillLinkedin />
                    </a>
                    <a
                      href="/"
                      className="bottom-copy-right-text-2"
                      target="_blank"
                    >
                      {" "}
                      <AiFillYoutube />
                    </a>
                  </div>
                </div>
                <div class="col-lg-5 mt-md-3">
                  <p className="bottom-copy-right-text-1 mb-0">
                    Copyright © 2023 Upstrox Techno Services. All Rights
                    Reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
