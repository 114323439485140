import { BiRightArrowAlt } from "react-icons/bi";
import sustain from "../../Assets/images/icon/public sector/public-sector-image.webp";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import PublicSectorbg from "../../Assets/images/servies-images/public-sector.webp";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaArrowLeftLong } from "react-icons/fa6";
import publicicon1 from "../../Assets/images/icon/public sector/IT Consulting and Strategy.webp";
import publicicon2 from "../../Assets/images/icon/public sector/Application Development and Maintenance.webp";
import publicicon3 from "../../Assets/images/icon/public sector/IT Infrastructure Management.webp";
import publicicon4 from "../../Assets/images/icon/public sector/Data Analytics and Business Intelligence.webp";


function PublicSector() {
  return (
    <>
      <div className="publicsector-banner-section">
      <section className="top-banner-section top-banner-section-overral-bg-section" style={{ backgroundImage: "url(" + PublicSectorbg + ")" }}>
          <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
          <div className="container">
            <div className="row align-items-center">
              <div className="col">
                <h1 className="top-text-one">Upstrox - Your Trusted Partner for
                  <span className="text-color"> Public Sector </span> IT Services
                </h1>
                <div className="top-text-two"> The public sector plays a critical role in serving citizens and driving societal progress. At Upstrox, we understand the unique IT challenges faced by public sector organizations.
                </div>
              </div>
            </div>
            <div className="pt-3">
             <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
            </div>
          </div>
          </div>
        </section>

        <section>
          <div className="container-fluid logistic-new-card-section pt-5 col-lg-10">
          <div className="row">
              <div className="col-lg-4 col-md-12">
                <div>
                  <h3 className="aerospace-new-text-1">
                    {" "}
                    Public Sector
                  </h3>
                  <p className="aerospace-new-text-2">
                    {" "}
                    We are dedicated to providing comprehensive and reliable IT services tailored specifically for the public sector. Here are the key IT services we offer
                  </p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="row row-cols-md-1 row-cols-1 row-cols-lg-2 g-4">
                  <div className="col">
                    <img src={publicicon1} alt="publicsector" className="aerospace-new-image-1" />
                    <h5 className="aerospace-new-text-3"> IT Consulting and Strategy</h5>
                    <p className="aerospace-new-text-4"> We collaborate with public sector organizations to understand their IT needs and develop customized strategies. Our experienced consultants provide expert guidance on IT infrastructure, system modernization, digital transformation, and cybersecurity.</p>
                  </div>
                  <div className="col">
                    <img src={publicicon2} alt="publicsector" className="aerospace-new-image-1" />
                    <h5 className="aerospace-new-text-3"> IT Infrastructure Management</h5>
                    <p className="aerospace-new-text-4"> We offer end-to-end management of IT infrastructure, 
including servers, networks, storage, and data centers. Our services ensure reliable performance, scalability, and security to support the diverse needs of public sector organizations. </p>
                  </div>
                  <div className="col">
                    <img src={publicicon3} alt="publicsector" className="aerospace-new-image-1" />
                    <h5 className="aerospace-new-text-3">  Application Development and Maintenance</h5>
                    <p className="aerospace-new-text-4">We develop and maintain robust, scalable, and secure applications tailored to the unique requirements of the public sector. From citizen portals and e-government solutions to document management systems and enterprise resource planning, we provide efficient and user-friendly software solutions.</p>
                  </div>
                  <div className="col">
                    <img src={publicicon4} alt="publicsector" className="aerospace-new-image-1" />
                    <h5 className="aerospace-new-text-3">Data Analytics and Business Intelligence</h5>
                    <p className="aerospace-new-text-4">Our data analytics and business intelligence solutions enable public sector organizations to derive meaningful insights from their data. We help you make informed decisions, optimize operations, and improve service delivery based on data-driven analysis.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="customercard-section-three py-5">
                        <div className="container">
                            <div className="row py-lg-5 pt-3 d-flex align-items-center">
                                <div className="col-lg-3 col-md-3">
                                    <h4 className="card-text-one customercard-heading">Cybersecurity and Data Protection</h4></div>
                                <div className="col-lg-1 col-md-2 card-hide">
                                    <div className=""><FaArrowRightLong className="customer-arrow" /></div>
                                </div>
                                <div className="col">
                                    <h5 className="card-text-two text-ho">
                                    Protecting sensitive data and ensuring cybersecurity is paramount for public sector organizations. We offer comprehensive cybersecurity solutions, including risk assessments, network security, data encryption, threat detection, incident response, and compliance with data protection regulations.

                                    </h5>
                                </div>
                            </div>
                            <div className="row py-lg-5 pt-3 d-flex align-items-center row-reverse">
                                <div className="col">
                                    <h5 className="card-text-two text-ho">
                                    We assist public sector organizations in leveraging cloud technologies to enhance agility, scalability, and cost-efficiency. Our services cover cloud migration, infrastructure deployment, data storage, and disaster recovery, ensuring secure and seamless operations in the cloud.

                                    </h5>
                                </div>
                                <div className="col-lg-1 col-md-2 card-hide">
                                    <div className=""><FaArrowLeftLong className="customer-arrow" /></div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <h4 className="card-text-one customercard-heading">Cloud Services and Migration</h4></div>
                            </div>
                            <div className="row py-lg-5 pt-3 d-flex align-items-center">
                                <div className="col-lg-3 col-md-3">
                                    <h4 className="card-text-one customercard-heading">Digital Transformation</h4></div>
                                <div className="col-lg-1 col-md-2 card-hide">
                                    <div className=""><FaArrowRightLong className="customer-arrow" /></div>
                                </div>
                                <div className="col">
                                    <h5 className="card-text-two text-ho">
                                    We support public sector organizations in their digital transformation journey. From process automation and workflow optimization to citizen engagement platforms and digital service delivery, we help you harness the power of technology to improve efficiency and citizen satisfaction.

                                    </h5>
                                </div>
                            </div>
                            <div className="row py-lg-5 pt-3 d-flex align-items-center row-reverse">
                                <div className="col">
                                    <h5 className="card-text-two text-ho">
                                    Our skilled project managers ensure successful implementation and delivery of IT projects within the public sector. We follow best practices and methodologies to manage timelines, budgets, and resources effectively, ensuring project success and stakeholder satisfaction.

                                    </h5>
                                </div>
                                <div className="col-lg-1 col-md-2 card-hide">
                                    <div className=""><FaArrowLeftLong className="customer-arrow" /></div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <h4 className="card-text-one customercard-heading">IT Project Management</h4></div>
                            </div>
                        </div>
                    </section>
                    <section className="datacard-section-three pb-5">
                        <div className="container">
                            <div className="row py-2 g-4 pt-lg-4">
                                <div className="col-lg-6 col-md-6">
                                    <div className=" card text-white p-lg-4 h-100" style={{ background: "rgb(53 195 97 / 77%)" }}>
                                        <h4 className="card-text-one ps-3">
                                        IT Service Desk and Support
                                        </h4>
                                        <h5 className="card-text-two px-3 text-ho">
                                          We provide reliable IT service desk and support services to ensure uninterrupted operations and quick issue resolution. Our dedicated support team is available round-the-clock to address technical concerns and provide timely assistance to public sector employees and citizens.

                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className=" card text-white p-lg-4 h-100" style={{ background: "rgb(132 107 249)" }}>
                                        <h4 className="card-text-one ps-3">
                                        Compliance and Regulatory Support
                                        </h4>
                                        <h5 className="card-text-two px-3 text-ho">
                                        We assist public sector organizations in adhering to regulatory requirements and compliance standards. Our services include audits, risk assessments, policy development, and training to ensure that your IT systems meet the necessary compliance frameworks.

                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="logistic-last-section my-lg-5">
          <div className="container py-3">
            <div className="row flex-row-reverse">
              <div className="col-lg-4 col-md-4">
                <img src={sustain} alt="publicsector" />
              </div>
              <div className="col-lg-8 col-md-8">
                <div className="pt-lg-5">
                  <p className="">
                  At Upstrox, we are committed to delivering innovative and reliable IT solutions for the public sector. Our deep industry knowledge, technical expertise, and client-focused approach make us the ideal partner for public sector organizations. 

                  </p>
                </div>
                <div>
                  <a className="new-ups-button-2" href="/contact">
                    <span className="new-ups-button-icon-3">Read More</span>{" "}
                    <BiRightArrowAlt className="new-ups-button-icon-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-text-section py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="contact-text-last mb-lg-4">
                Contact us today to discuss your IT needs and explore how we can help you achieve your goals efficiently and effectively.

                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
export default PublicSector;