import { BiRightArrowAlt } from 'react-icons/bi';
import { MdOutlineArrowForwardIos } from "react-icons/md";

import HighTechnologybg from "../../Assets/images/servies-images/high-technology.webp";

import dataimg from "../../Assets/images/icon/high technology/high technology image 1.webp";
import ContactNew from "../../Assets/images/icon/high technology/high technology image 2.webp";
import iconhigh1 from "../../Assets/images/icon/high technology/Data Analytics and Business Intelligence.webp";
import iconhigh2 from "../../Assets/images/icon/high technology/Cybersecurity and Data Protection.webp";
import iconhigh3 from "../../Assets/images/icon/high technology/IT Project Management.webp";
import iconhigh4 from "../../Assets/images/icon/high technology/IT Consulting and Strategy.webp";


function HighTechnology() {
  return (
    <>
      <div className="hightechnology-banner-section">
        <section className="top-banner-section top-banner-section-overral-bg-section back-posi-img" style={{ backgroundImage: "url(" + HighTechnologybg + ")" }}>
          <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one">Upstrox - Your Trusted Partner for
                    <span className="text-color"> High Technology </span>  IT Services
                  </h1>
                  <div className="top-text-two">
                    In the rapidly evolving world of high technology, organizations need robust and cutting-edge IT solutions to stay competitive and drive innovation. At Upstrox, we specialize in providing comprehensive IT services tailored specifically for the high technology industry.
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
              </div>
            </div>
          </div>
        </section>

        <section className="datacard-section">
          <section className="datacard-section-one py-5">
            <div className="container">
              <h5 className="text-one">Our expertise spans various sectors, including electronics, semiconductors, telecommunications, and software development. Here are the key IT services we offer</h5>
              <div className="row pt-2 pt-lg-5 align-items-center">
                <div className="data-card-blur" style={{ background: "#00ffff75", opacity: "0.5" }}></div>
                <div className="col-lg-7 col-md-7">
                  <div className="container">
                    <div className="row pt-lg-3 py-2">
                      <div className="col">
                        <div className="card bg-transparent text-white">
                          <h4 className="card-text-one">Product Lifecycle Management (PLM)</h4>
                          <h5 className="card-text-two text-ho">
                            We provide PLM solutions to manage the entire lifecycle of high-tech products, from ideation and design to manufacturing, distribution, and end-of-life. Our PLM systems enable collaboration, version control, and efficient change management, ensuring accelerated time-to-market and improved product quality.
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-lg-3 py-2">
                      <div className="col">
                        <div className="card bg-transparent text-white">
                          <h4 className="card-text-one">Research and Development (R&D) Collaboration Platforms</h4>
                          <h5 className="card-text-two text-ho">
                            We offer R&D collaboration platforms that enable geographically dispersed teams to collaborate effectively, share knowledge, and manage intellectual property. Our solutions streamline idea generation, project tracking, and document management, fostering innovation and accelerating time-to-market.
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-lg-3 py-2">
                      <div className="col">
                        <div className="card bg-transparent text-white">
                          <h4 className="card-text-one">IT Infrastructure and Network Design</h4>
                          <h5 className="card-text-two text-ho">
                            We design and implement scalable and secure IT infrastructures for high-tech organizations. Our solutions cover network architecture, data centers, cloud integration, and cybersecurity, ensuring high availability, performance, and data protection.
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5">
                  <div className=""><img src={dataimg} alt="hightech" className="datacard-img" /></div>
                </div>
              </div>
            </div>
          </section>
        </section>


        <section className="sustaincard-section">
          <section className="sustaincard-section-two py-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-4 py-2">
                  <div className="card h-100 p-4 top-border">

                    <h4 className="card-text-one">Software Development and Engineering</h4>
                    <h5 className="card-text-two">
                    We provide software development and engineering services to high-tech companies, including custom software development, mobile app development, embedded systems, and firmware development. Our solutions are tailored to your specific needs, enabling you to create innovative and reliable software products.
                    </h5>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 py-2">
                  <div className="card h-100 p-4" style={{ backgroundColor: "rgba(46, 46, 46, 0.199)" }}>

                    <h4 className="card-text-one">Quality Assurance and Testing</h4>
                    <h5 className="card-text-two">
                    We offer comprehensive quality assurance and testing services to ensure the reliability and performance of high-tech products and software. Our solutions include functional testing, compatibility testing, security testing, and performance testing, helping you deliver high-quality products to market.
                    </h5>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 py-2">
                  <div className="card h-100 p-4 top-border">

                    <h4 className="card-text-one">Supply Chain Management (SCM)</h4>
                    <h5 className="card-text-two">
                    We provide SCM solutions tailored for high-tech companies, optimizing supply chain processes such as demand forecasting, procurement, inventory management, and logistics. Our solutions enhance visibility, collaboration, and efficiency, reducing costs and ensuring timely delivery of products.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="sustaincard-section-three py-3">
            <div className="container">
              <div className="row d-flex justify-content-center pt-lg-5">
                <div className="col-lg-6 col-md-6">
                  <div className="card h-100 p-3 text-center">
                    <div className="pb-3"><img src={iconhigh1} alt="hightech" className="sustain-card-img" /></div>
                    <h4 className="card-text-one">Data Analytics and Business Intelligence</h4>
                    <h5 className="card-text-two">
                    Our data analytics and business intelligence solutions help high-tech organizations derive actionable insights from their data. We assist in analyzing market trends, customer behavior, and operational data to drive informed decision-making, optimize processes, and identify new business opportunities.
                    </h5>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="card h-100 p-3 text-center">
                    <div className="pb-3"><img src={iconhigh2} alt="hightech" className="sustain-card-img" /></div>
                    <h4 className="card-text-one">Cybersecurity and Data Protection</h4>
                    <h5 className="card-text-two">
                    Protecting sensitive data and intellectual property is critical in the high-tech industry. We provide comprehensive cybersecurity services, including network security, data encryption, vulnerability assessments, and incident response. Our solutions help safeguard your valuable assets from cyber threats and ensure compliance with industry regulations.
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center pb-lg-5">
                <div className="col-lg-6 col-md-6">
                  <div className="card h-100 p-3 text-center">
                    <div className="pb-3"><img src={iconhigh3} alt="hightech" className="sustain-card-img" /></div>
                    <h4 className="card-text-one">IT Project Management</h4>
                    <h5 className="card-text-two">
                    Our skilled project managers ensure the successful implementation and delivery of IT projects for high-tech organizations. We follow industry best practices and methodologies to manage timelines, budgets, and resources effectively, ensuring project success and stakeholder satisfaction.
                    </h5>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="card h-100 p-3 text-center">
                    <div className="pb-3"><img src={iconhigh4} alt="hightech" className="sustain-card-img" /></div>
                    <h4 className="card-text-one">IT Consulting and Strategy</h4>
                    <h5 className="card-text-two">
                    Our experienced consultants offer IT consulting services to high-tech organizations, providing guidance on technology strategy, digital transformation, and innovation initiatives. We help you align technology investments with business goals, optimize processes, and leverage emerging technologies.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 flex-lg-row-reverse align-items-center">
              <div className="col-lg-4">
                <img src={ContactNew} alt="hightech" />
              </div>
              <div className="col-lg-8">
                <p className="aerospace-new-text-5 mb-lg-4">At Upstrox, we understand the unique challenges and opportunities in the high technology industry. Our comprehensive IT services empower high-tech organizations to drive innovation, streamline operations, and stay ahead of the competition. </p>
                <a href='/contact' className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="aerospace-new-text-6 mb-lg-4">Contact us today to discuss your high technology IT needs and explore how we can support your organization's growth and success.</p>
              </div>
            </div>
          </div>
        </section>

      </div>
    </>
  )
}
export default HighTechnology