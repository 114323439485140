import { BiRightArrowAlt } from "react-icons/bi";
import { IoMdAnalytics } from "react-icons/io";
import { AiOutlineAudit } from "react-icons/ai";
import { GiGreenhouse } from "react-icons/gi";
import IconAero5 from "../../Assets/images/icon/aerospacedefence/collaboration.webp"
import IconAero6 from "../../Assets/images/icon/aerospacedefence/supply-chain.webp"
import IconAero7 from "../../Assets/images/icon/aerospacedefence/compliance.webp"
import customercardimg from "../../Assets/images/servies-images/intelligent Automation Solutions webp/images/first section copy.webp";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Dummybg from "../../Assets/images/servies-images/demo/dummy-1.webp"
import dataimg from "../../Assets/images/servies-images/intelligent Automation Solutions webp/images/last image copy.webp";


import intelicon1 from "../../Assets/images/servies-images/intelligent Automation Solutions webp/icon 1/Machine Learning and Predictive Analytics.webp";
import intelicon2 from "../../Assets/images/servies-images/intelligent Automation Solutions webp/icon 1/Machine Learning and Predictive Analytics.webp";
import intelicon3 from "../../Assets/images/servies-images/intelligent Automation Solutions webp/icon 1/Machine Learning and Predictive Analytics.webp";
import intelicon4 from "../../Assets/images/servies-images/intelligent Automation Solutions webp/icon 2/Intelligent Data Analytics and Reporting.webp";
import intelicon5 from "../../Assets/images/servies-images/intelligent Automation Solutions webp/icon 2/Intelligent Integration and Middleware.webp";
import intelicon6 from "../../Assets/images/servies-images/intelligent Automation Solutions webp/icon 2/Intelligent Process Discovery and Optimization.webp";
import { useEffect } from "react";


function IntelligentAutomation() {
 
    return (
        <>
            <div className="intelligentautomation">
            <section className="top-banner-section top-banner-section-overral-bg-section back-posi-img intelligent-background">
                    <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col">
                                <h1 className="top-text-one"><span className="text-color">Intelligent Automation</span> Solutions by Upstrox.</h1>
                                <div className="top-text-two">Upstrox offers intelligent automation solutions that combine the power of artificial intelligence (AI) and automation technologies to streamline and optimize business processes. </div>
                            </div>
                        </div>
                        <div className="pt-3">
                           <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                        </div>
                    </div>
                    </div>
                </section>
                <section className="top-banner-section-two pt-5 py-2">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h5 className="text-one"> Intelligent automation enables organizations to automate repetitive tasks, improve operational efficiency, and enhance decision-making through intelligent data analysis. Here are the key intelligent automation solutions we offer</h5>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="customercard-section-two py-lg-5">
                        <div className="container-fluid col-lg-10">
                            <div className="row align-items-center">

                                <div className="col-lg-4 col-md-4">
                                    <div><img src={customercardimg} className="customer-card-img" /></div>
                                </div>
                                <div className="col-lg-8 col-md-8">
                                    <div className="container">
                                        <div className="row pt-lg-5 py-2">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Robotic Process Automation (RPA)</h4>
                                                    <h5 className="card-text-two text-ho">
                                                    We provide RPA services that automate rule-based and repetitive tasks using software robots. Our experts identify suitable processes for automation, design and develop robotic workflows, and implement RPA solutions that improve accuracy, speed, and productivity.

                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Cognitive Automation</h4>
                                                    <h5 className="card-text-two text-ho">
                                                    We leverage cognitive technologies, such as natural language processing (NLP), machine learning, and computer vision, to enable intelligent automation. Our solutions automate complex tasks that involve unstructured data, enable sentiment analysis, image recognition, and intelligent document processing, enhancing process efficiency and accuracy.

                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-lg-5 py-2">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Intelligent Chatbots and Virtual Assistants</h4>
                                                    <h5 className="card-text-two text-ho">
                                                    We develop intelligent chatbots and virtual assistants that leverage AI and natural language understanding to interact with users, provide support, and automate customer service tasks. Our chatbots enable self-service, enhance customer experience, and free up resources for more value-added activities.

                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Intelligent Data Processing</h4>
                                                    <h5 className="card-text-two text-ho">
                                                    We offer intelligent data processing solutions that automate data extraction, cleansing, and transformation tasks. Using AI algorithms, we extract structured and unstructured data from various sources, validate and enrich data, and prepare it for analysis, enabling accurate and efficient data processing.

                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="aerospace-new-design-2">
          <div className="container-fluid col-lg-11 mx-auto">
            <div className="row row-cols-md-1 row-cols-1 row-cols-lg-3 g-4 py-lg-5">
              <div className="col aerospace-new-design-2-inner-1">
                <img src={intelicon1} alt="intelligentautomation" className="aerospace-new-image-2" />
                <h5 className="aerospace-new-text-3">Machine Learning and Predictive Analytics</h5>
                <p className="aerospace-new-text-4">We utilize machine learning and predictive analytics techniques to uncover patterns, trends, and insights from your data. Our experts develop predictive models that enable proactive decision-making, identify anomalies, and optimize business processes based on data-driven insights.</p>
              </div>
              <div className="col aerospace-new-design-2-inner-2">
                <img src={intelicon2} alt="intelligentautomation" className="aerospace-new-image-2" />
                <h5 className="aerospace-new-text-3">Intelligent Document Processing</h5>
                <p className="aerospace-new-text-4">We provide intelligent document processing solutions that automate the extraction and analysis of information from documents. Using optical character recognition (OCR) and AI algorithms, we automate tasks such as invoice processing, contract management, and document classification, reducing manual effort and improving accuracy.</p>
              </div>
              <div className="col aerospace-new-design-2-inner-3">
                <img src={intelicon3} alt="intelligentautomation" className="aerospace-new-image-2" />
                <h5 className="aerospace-new-text-3">Intelligent Workflow Automation</h5>
                <p className="aerospace-new-text-4">TWe optimize workflows and automate end-to-end processes using intelligent workflow automation. Our experts analyze your business processes, identify bottlenecks, and implement workflow automation solutions that orchestrate tasks, streamline approvals, and ensure process compliance.
</p>
              </div>
            </div>
          </div>
        </section>
        <section className="sustaincard-section pb-5">
                <div className="container">
                <div className="row pt-lg-5 pt-3 d-flex align-items-center">
                    <div className="col-lg-1 col-md-2">
                    <div className=""><img src={intelicon4} className="intel-icon-img" alt="intelligentautomation"/></div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                    <h4 className="card-text-one">Intelligent Data Analytics and Reporting</h4></div>
                    <div className="col">
                    <h5 className="card-text-two">
                    We develop intelligent data analytics and reporting solutions that enable advanced data visualization, data exploration, and real-time insights. Our experts design and implement dashboards, data analytics platforms, and reporting tools that empower data-driven decision-making across your organization.

                    </h5>
                    </div>
                </div>
                <div className="row pt-lg-5 pt-3 d-flex align-items-center">
                    <div className="col-lg-1 col-md-2">
                    <div className=""><img src={intelicon5} className="intel-icon-img" alt="intelligentautomation"/></div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                    <h4 className="card-text-one">Intelligent Integration and Middleware</h4></div>
                    <div className="col">
                    <h5 className="card-text-two">
                    We assist in integrating disparate systems and applications using intelligent integration and middleware solutions. Our experts utilize technologies such as API management, microservices, and event-driven architectures to enable seamless data flow, process synchronization, and system interoperability.

                    </h5>
                    </div>
                </div>
                <div className="row pt-lg-5 pt-3 d-flex align-items-center">
                    <div className="col-lg-1 col-md-2">
                    <div className=""><img src={intelicon6} className="intel-icon-img" alt="intelligentautomation"/></div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                    <h4 className="card-text-one">Intelligent Process Discovery and Optimization</h4></div>
                    <div className="col">
                    <h5 className="card-text-two">
                    We employ process mining and intelligent process discovery techniques to analyze and optimize your business processes. Our experts identify process inefficiencies, bottlenecks, and areas for improvement, enabling you to optimize process performance, reduce costs, and enhance operational efficiency.

                    </h5>
                    </div>
                </div>
                </div>   
            </section>
            <section className="logistic-last-section my-lg-5">
          <div className="container py-3">
            <div className="row flex-row-reverse">
              <div className="col-lg-4 col-md-4">
                <img src={dataimg} alt="intelligentautomation" />
              </div>
              <div className="col-lg-8 col-md-8">
                <div className="pt-lg-5">
                  <p className="">
                  At Upstrox, we are committed to helping organizations leverage intelligent automation technologies to drive digital transformation and achieve operational excellence. 
                  </p>
                </div>
                <div>
                  <a className="new-ups-button-2" href="/contact">
                    <span className="new-ups-button-icon-3">Read More</span>{" "}
                    <BiRightArrowAlt className="new-ups-button-icon-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-text-section py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="contact-text-last mb-lg-4">
                Contact us today to discuss your intelligent automation requirements and explore how Upstrox can empower your organization with our industry-leading intelligent automation solutions.
                </p>
              </div>
            </div>
          </div>
        </section>
            </div>
        </>
    )
}
export default IntelligentAutomation;
