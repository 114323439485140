
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Dummybg from "../../Assets/images/servies-images/demo/dummy-1.webp";
import dataimg from "../../Assets/images/servies-images/Enterprise Of The Future Services webp/images2/first image copy.webp";
import { BiRightArrowAlt } from "react-icons/bi";
import consumerlast from "../../Assets/images/servies-images/Enterprise Of The Future Services webp/images2/last image copy.webp";

import entericon1 from "../../Assets/images/servies-images/Enterprise Of The Future Services webp/images1/Process Optimization and.webp";
import entericon2 from "../../Assets/images/servies-images/Enterprise Of The Future Services webp/images1/Innovation and Ideation Workshops.webp";
import entericon3 from "../../Assets/images/servies-images/Enterprise Of The Future Services webp/images1/Sustainability and Social Responsibility Consulting.webp";
import { useEffect } from "react";


function EnterpriseFutureServices() {

  

    return (
        <>
            <div className="enterprise">
                <section className="top-banner-section top-banner-section-overral-bg-section enterprise-background back-posi-img" >
                    <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1 className="top-text-one"><span className="text-color">Enterprise of the Future Services</span> by Upstrox.</h1>
                                    <div className="top-text-two">Upstrox is at the forefront of enabling organizations to embrace the vision of the Enterprise of the Future.</div>
                                </div>
                            </div>
                            <div className="pt-3">
                               <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="top-banner-section-two pt-5 py-2">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h5 className="text-one">  We offer a wide range of services that empower businesses to navigate the evolving landscape and unlock their full potential. Here are some key services provided by Upstrox</h5>
                            </div>
                        </div>
                    </div>
                </section>
               
               <section>
               <div className="container-fluid mt-5 re-two-card col-lg-10">
            <div className="row row-cols-lg-2 row-cols-md-2 row-cols-1 gap-3 gap-lg-0  gap-md-0 ">
              <div className="col pe-lg-0">
                <div className=" card h-100 py-lg-2">
                  <div className="card-body">
                    <div>
                      <h4 className="card-text-one">
                      Digital Transformation Consulting
                      </h4>
                      <h5 className="card-text-two">
                      Our experts provide strategic guidance and consulting services to help organizations embark on their digital transformation journey. We assess your current state, define a comprehensive roadmap, and assist in implementing digital technologies and solutions that drive innovation and growth.

                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col ">
                <div className=" card h-100">
                  <div className="card-body">
                    <div>
                      <h4 className="card-text-one">
                      Technology Implementation and Integration
                      </h4>
                      <h5 className="card-text-two">
                      We specialize in implementing and integrating cutting-edge technologies into your existing infrastructure. Whether it's cloud computing, artificial intelligence, IoT, or blockchain, our team ensures seamless integration and optimized performance, enabling you to leverage the full potential of these technologies.

                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col pt-md-3">
                <div className=" card h-100 left-margin">
                  <div className="card-body ">
                    <div>
                      <h4 className="card-text-one">
                      Data Analytics and Insights
                      </h4>
                      <h5 className="card-text-two">
                      We help organizations harness the power of data through advanced analytics and insights. Our experts leverage data mining, machine learning, and predictive modeling techniques to extract valuable insights from your data, enabling you to make data-driven decisions, optimize processes, and identify growth opportunities.

                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col pt-md-3">
                <div className=" card h-100 left-margin">
                  <div className="card-body">
                    <div>
                      <h4 className="card-text-one">
                      Customer Experience Optimization
                      </h4>
                      <h5 className="card-text-two">
                      We assist organizations in enhancing their customer experiences across all touchpoints. Our services include customer journey mapping, user experience design, personalization strategies, and customer analytics. We help you deliver seamless, personalized, and engaging experiences that drive customer satisfaction and loyalty.

                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
               </section>
               <section className="datacard-section-one py-5">
                        <div className="container">
                            <div className="row pt-2 pt-lg-5 align-items-center">
                                <div className="col-lg-7 col-md-7">
                                    <div className="container">
                                        <div className="row pt-lg-3 py-2">
                                            <div className="col">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Agile and DevOps Transformation</h4>
                                                    <h5 className="card-text-two text-ho">
                                                    We facilitate the adoption of agile methodologies and DevOps practices within your organization. Our experts help you implement agile frameworks, establish cross-functional teams, and streamline your software development and delivery processes. This enables faster time-to-market, improved collaboration, and increased product quality.
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-lg-3 py-2">
                                            <div className="col">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Cybersecurity and Risk Management</h4>
                                                    <h5 className="card-text-two text-ho">
                                                    We provide comprehensive cybersecurity services to protect your organization's digital assets and ensure regulatory compliance. Our experts conduct risk assessments, implement robust security measures, develop incident response plans, and provide ongoing monitoring and threat intelligence services.

                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-lg-3 py-2">
                                            <div className="col">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Change Management and Employee Enablement</h4>
                                                    <h5 className="card-text-two text-ho">
                                                    We recognize that successful transformation requires effective change management and empowered employees. Our change management experts help you navigate the organizational and cultural shifts, ensuring smooth transitions and driving employee engagement. We provide training and upskilling programs to equip your workforce with the necessary digital skills.

                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5">
                                    <div className=""><img src={dataimg} alt="enterprise" className="datacard-img" /></div>
                                </div>
                            </div>
                        </div>
                </section>
                <section>
                <div className="container mt-5 con-card-two">
            <div className="row row-cols-lg-3 row-cols-1 row-cols-md-2  pb-lg-4 justify-content-md-center">
              <div className="col con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={entericon1}
                      className="con-icon-top"
                      alt="enterprise"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                    Process Optimization and Automation
                    </h3>
                    <p className="card-text-two">
                      {" "}
We streamline and optimize your business processes through process reengineering and automation. Our experts identify areas of improvement, eliminate bottlenecks, and implement robotic process automation (RPA) and intelligent automation solutions to drive operational efficiency and reduce manual effort.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={entericon2}
                      className="con-icon-top"
                      alt="enterprise"
                    />
                  </div>
                  <div className="pt-lg-4 ">
                    <h3 className="card-text-one">
                    Innovation and Ideation Workshops
                    </h3>
                    <p className="card-text-two">
                    We facilitate innovation workshops and ideation sessions to foster a culture of creativity and entrepreneurship within your organization. Our experts employ design thinking methodologies and collaborative approaches to generate and refine innovative ideas that can transform your business.

                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={entericon3}
                      className="con-icon-top"
                      alt="enterprise"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                    Sustainability and Social Responsibility Consulting
                    </h3>
                    <p className="card-text-two">
                    We help organizations integrate sustainability and social responsibility practices into their operations and strategies. Our consultants assist in defining sustainability goals, developing environmental initiatives, and implementing responsible business practices that align with your organization's values and contribute to a better future.
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
                </section>
                <section className="consumer-last-section my-lg-5">
          <div className="container py-5">
            <div className="row flex-row-reverse">
              <div className="col-lg-4 col-md-4">
                <img src={consumerlast} alt="enterprise" />
              </div>
              <div className="col-lg-8 col-md-8">
                <div className="pt-lg-5 pt-md-5">
                  <p className="">
                  At Upstrox, we are committed to empowering organizations to embrace the Enterprise of the Future. Our services enable businesses to drive digital transformation, enhance customer experiences, optimize processes, and navigate the complexities of the evolving business landscape. 
                  </p>
                </div>
                <div>
                  <a className="new-ups-button-2" href="/contact">
                    <span className="new-ups-button-icon-3">Read More</span>{" "}
                    <BiRightArrowAlt className="new-ups-button-icon-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-text-section py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="contact-text-last mb-lg-4">
                Contact us today to explore how Upstrox can be your partner in shaping your enterprise for future success.
                </p>
              </div>
            </div>
          </div>
        </section>
      

            </div>
        </>
    )
}
export default EnterpriseFutureServices;