import { MdOutlineArrowForwardIos } from "react-icons/md";
import Dummybg from "../../Assets/images/servies-images/demo/dummy-1.webp"

import { SiAntdesign,SiChase } from 'react-icons/si';
import { MdOutlineDeveloperBoard } from 'react-icons/md';
import { TfiJoomla } from 'react-icons/tfi';
import { PiVirtualRealityBold } from 'react-icons/pi';
import { MdPrecisionManufacturing,MdOutlineDeveloperMode } from "react-icons/md";
import { BsFillPinMapFill } from "react-icons/bs";
import { MdDraw } from "react-icons/md";
import { TbPhotoSensor2 } from "react-icons/tb"
import ContactNew from "../../Assets/images/servies-images/DApp And DeFi Development webp/images/last image copy.webp";
import { BiRightArrowAlt } from 'react-icons/bi';
import dappicon1 from "../../Assets/images/servies-images/DApp And DeFi Development webp/icons/DApp Development.webp";
import dappicon2 from "../../Assets/images/servies-images/DApp And DeFi Development webp/icons/icon copy.webp";
import dappicon3 from "../../Assets/images/servies-images/DApp And DeFi Development webp/icons/Tokenization and Token Development.webp";
import dappicon4 from "../../Assets/images/servies-images/DApp And DeFi Development webp/icons/Decentralized Exchange (DEX) Development.webp";
import dappicon5 from "../../Assets/images/servies-images/DApp And DeFi Development webp/icons/Liquidity Pool Development.webp";
import dappicon6 from "../../Assets/images/servies-images/DApp And DeFi Development webp/icons/Yield Farming and Staking Solutions.webp";
import dappicon7 from "../../Assets/images/servies-images/DApp And DeFi Development webp/icons/Wallet Integration.webp";
import dappicon8 from "../../Assets/images/servies-images/DApp And DeFi Development webp/icons/Audit and Security.webp";
import dappicon9 from "../../Assets/images/servies-images/DApp And DeFi Development webp/icons/Regulatory Compliance.webp";
import dappicon10 from "../../Assets/images/servies-images/DApp And DeFi Development webp/icons/DApp and DeFi Consulting.webp";
import { useEffect } from "react";


function DAppandDeFi() {
    
    return (
        <>
            <div className="DAppandDeFi">
            <section className="top-banner-section top-banner-section-overral-bg-section dapp-background back-posi-img">
                    <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col">
                                <h1 className="top-text-one"><span className="text-color">DApp and DeFi</span> Development by Upstrox.</h1>
                                <div className="top-text-two">Upstrox specializes in DApp (Decentralized Application) and DeFi (Decentralized Finance) development, offering innovative solutions that leverage blockchain technology to create decentralized applications and financial systems.</div>
                            </div>
                        </div>
                        <div className="pt-3">
                           <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                        </div>
                    </div>
                    </div>
            </section>
            
            <section className="top-banner-section-two pb-4 pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h5 className="text-one">DApps are applications that run on blockchain networks, enabling transparent, secure, and peer-to-peer interactions, while DeFi refers to the use of blockchain and cryptocurrencies to recreate traditional financial systems in a decentralized manner. Here's how Upstrox can assist you with DApp and DeFi development</h5>
                            </div>
                        </div>
                    </div>
            </section>

            <section className="automotive-new-design-2 pb-4 pt-5">
                <div className="container-fluid col-lg-10 mx-auto">
                    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 justify-content-center">
                        <div class="col">
                            <div class="card h-100">
                                <div class="card-body">
                                    {/* <SiAntdesign className="automotive-new-text-2" /> */}
                                    <img src={dappicon1} className="dapp-icon-img automotive-new-text-2" alt="DAppandDeFi"/>
                                    <h5 class="automotive-new-text-1">DApp Development</h5>
                                    <p class="automotive-new-text-3">We provide end-to-end DApp development services, leveraging blockchain platforms such as Ethereum, Binance Smart Chain, and others. Our experts design and develop DApps that offer decentralized functionality, smart contract integration, and immutability, ensuring transparency and security.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100">
                                <div class="card-body">
                                <img src={dappicon2} className="dapp-icon-img automotive-new-text-2" alt="DAppandDeFi"/>
                                    <h5 class="automotive-new-text-1">Smart Contract Development</h5>
                                    <p class="automotive-new-text-3">We specialize in developing secure and auditable smart contracts that power DApps. Our experts utilize programming languages like Solidity to write and deploy smart contracts on blockchain networks, enabling automated and trustless execution of predefined rules and agreements.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100">
                                <div class="card-body">
                                <img src={dappicon3} className="dapp-icon-img automotive-new-text-2" alt="DAppandDeFi"/>
                                    <h5 class="automotive-new-text-1">Tokenization and Token Development</h5>
                                    <p class="automotive-new-text-3">We assist organizations in tokenization and token development for their DApps and DeFi projects. Whether you need utility tokens, security tokens, or governance tokens, our experts guide you through the token creation process and help you comply with relevant regulatory frameworks.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100">
                                <div class="card-body">
                                <img src={dappicon4} className="dapp-icon-img automotive-new-text-2" alt="DAppandDeFi"/>
                                    <h5 class="automotive-new-text-1">Decentralized Exchange (DEX) Development</h5>
                                    <p class="automotive-new-text-3">We offer DEX development services, enabling peer-to-peer token exchanges within decentralized ecosystems. Our experts develop intuitive and secure DEX platforms that facilitate seamless token swaps and liquidity provision while ensuring user privacy and control over funds.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100">
                                <div class="card-body">
                                <img src={dappicon5} className="dapp-icon-img automotive-new-text-2" alt="DAppandDeFi"/>
                                    <h5 class="automotive-new-text-1">Liquidity Pool Development</h5>
                                    <p class="automotive-new-text-3">We assist in building liquidity pool solutions for DeFi platforms. Our experts design and develop automated market makers (AMMs) that allow users to provide liquidity to trading pairs and earn yield through decentralized lending and borrowing protocols.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100">
                                <div class="card-body">
                                <img src={dappicon6} className="dapp-icon-img automotive-new-text-2" alt="DAppandDeFi"/>
                                    <h5 class="automotive-new-text-1">Yield Farming and Staking Solutions</h5>
                                    <p class="automotive-new-text-3">We help organizations implement yield farming and staking mechanisms within their DeFi projects. Our experts develop yield farming protocols that incentivize users to provide liquidity and stake their tokens, earning rewards in the form of additional tokens or fees.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100">
                                <div class="card-body">
                                <img src={dappicon7} className="dapp-icon-img automotive-new-text-2" alt="DAppandDeFi"/>
                                    <h5 class="automotive-new-text-1">Wallet Integration</h5>
                                    <p class="automotive-new-text-3">We facilitate wallet integration within DApps and DeFi platforms, enabling seamless and secure interaction with blockchain networks. Our experts integrate popular wallets such as MetaMask, Trust Wallet, and others, allowing users to manage their tokens, sign transactions, and interact with DApps easily.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100">
                                <div class="card-body">
                                <img src={dappicon8} className="dapp-icon-img automotive-new-text-2" alt="DAppandDeFi"/>
                                    <h5 class="automotive-new-text-1">Audit and Security</h5>
                                    <p class="automotive-new-text-3">We prioritize the security of DApps and DeFi projects. Our experts conduct thorough code audits, identify vulnerabilities, and recommend security best practices to mitigate risks associated with smart contracts, token systems, and platform architecture.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100">
                                <div class="card-body">
                                <img src={dappicon9} className="dapp-icon-img automotive-new-text-2" alt="DAppandDeFi"/>
                                    <h5 class="automotive-new-text-1">Regulatory Compliance</h5>
                                    <p class="automotive-new-text-3">We assist organizations in navigating regulatory frameworks and ensuring compliance within the DApp and DeFi space. Our experts provide guidance on compliance requirements, token sales, know-your-customer (KYC) procedures, and anti-money laundering (AML) regulations.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100">
                                <div class="card-body">
                                <img src={dappicon10} className="dapp-icon-img automotive-new-text-2" alt="DAppandDeFi"/>
                                    <h5 class="automotive-new-text-1">DApp and DeFi Consulting</h5>
                                    <p class="automotive-new-text-3">We offer comprehensive consulting services to help organizations understand the potential of DApps and DeFi, develop business strategies, and identify suitable use cases. Our experts provide insights into market trends, competitive analysis, and feasibility assessments to drive successful DApp and DeFi implementations.</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>

            <section className="aerospace-new-design-2 py-5">
                <div className="container">
                    <div className="row g-4 flex-lg-row-reverse">
                        <div className="col-lg-4">
                            <img src={ContactNew} alt="DAppandDeFi" />
                        </div>
                        <div className="col-lg-8">
                            <p className="aerospace-new-text-5 mb-lg-4">At Upstrox, we are committed to pushing the boundaries of decentralized technology and assisting organizations in leveraging the benefits of DApps and DeFi.</p>
                            <a href='/contact' className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="aerospace-new-design-2 py-5">
                <div className="container">
                    <div className="row g-4 justify-content-center text-center">
                        <div className="col-lg-12">
                            <p className="aerospace-new-text-6 mb-lg-4">Contact us today to discuss your DApp and DeFi development requirements and explore how Upstrox can empower your organization with our industry-leading services.</p>
                        </div>
                    </div>
                </div>
            </section>
            </div>
        </>
    )
}
export default DAppandDeFi;