import { MdOutlineArrowForwardIos } from "react-icons/md";
import Dummybg from "../../Assets/images/icon/logistics and distribution/logistics-and-distribution landing banner.webp";
import { TiTick } from "react-icons/ti";
import { BiRightArrowAlt } from "react-icons/bi";
import logi2 from "../../Assets/imgs/Group 1.webp";
import data from "../../Assets/imgs/data analysis.webp";
import inventory from "../../Assets/imgs/inventory-management.webp";
import warhouse from "../../Assets/imgs/warehouse management.webp";
import order from "../../Assets/imgs/order management.webp";
import transport from "../../Assets/imgs/transport.webp";
import transportation from "../../Assets/imgs/transportaion management.webp";
import supply from "../../Assets/imgs/supply-chain-management.webp";
import vendor from "../../Assets/imgs/vendor management.webp";

function LogisticsDistribution() {
  return (
    <>
      <div className="logistic-banner-section">
        <section
          className="top-banner-section top-banner-section-overral-bg-section logistic-background-image back-posi-img"
          style={{ backgroundImage: "url(" + Dummybg + ")" }}
        >
          <div className="mobile-new-ups-bg-shade-inner mx-auto container-fluid">
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one">
                    Upstrox - Your Trusted Partner for
                    <span className="text-color">
                      {" "}
                      Logistics & Distribution{" "}
                    </span>{" "}
                    IT Solutions
                  </h1>
                  <div className="top-text-two">
                    {" "}
                    In the fast-paced world of logistics and distribution,
                    technology plays a crucial role in optimizing supply chain
                    operations, enhancing visibility, and improving customer
                    satisfaction. At Upstrox, we specialize in providing
                    comprehensive IT services tailored specifically for the
                    logistics and distribution industry.
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <a href="/contact">
                  <button className="top-button right-side">
                    Get More{" "}
                    <MdOutlineArrowForwardIos className="top-icon ms-lg-2" />
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="logistic-new-card-section pt-5">
          <div className="container-fluid col-lg-10">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div>
                  <h3 className="aerospace-new-text-1">
                    {" "}
                    Logistics & Distribution{" "}
                  </h3>
                  <p className="aerospace-new-text-2">
                    {" "}
                    Whether you are a freight forwarder, warehouse operator, or
                    transportation company, we have the expertise to support
                    your IT needs. Here are the key IT services we offer
                  </p>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="row row-cols-1 row-cols-md-1 row-cols-lg-2 g-1">
                <div className="col">
                  <div className="card card-one h-100">
                    <div className="card-body">
                      <div className="p-3">
                        {" "}
                        <img
                          src={data}
                          className="logi-img-one"
                          alt="logisticdistribution"
                        />
                      </div>
                      <div className="pt-lg-3">
                        <h4 className="card-text-one ps-3 ">
                          Data Analytics and Business Intelligence
                        </h4>
                        <h5 className="card-text-two px-3">
                          Our data analytics and business intelligence solutions
                          help logistics and distribution companies gain valuable
                          insights from their data. We assist in analyzing supply
                          chain performance, demand patterns, and operational
                          metrics to drive informed decision-making, optimize
                          processes, and identify cost-saving opportunities.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card card-one h-100 ">
                    <div className="card-body">
                      <div className="p-3">
                        {" "}
                        <img
                          src={inventory}
                          className="logi-img-one"
                          alt="logisticdistribution"
                        />
                      </div>
                      <div className="pt-lg-3">
                        <h4 className="card-text-one ps-3">
                          Inventory Management Systems
                        </h4>
                        <h5 className="card-text-two px-3">
                          We provide inventory management systems that enable
                          accurate and real-time tracking of inventory levels, stock
                          movements, and stock replenishment. Our solutions help
                          optimize inventory levels, minimize stockouts, and improve
                          order fulfillment efficiency.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card card-one h-100">
                    <div className="card-body">
                      <div className="p-3">
                        {" "}
                        <img
                          src={warhouse}
                          className="logi-img-one"
                          alt="logisticdistribution"
                        />
                      </div>
                      <div className="pt-lg-3">
                        <h4 className="card-text-one ps-3">
                          Warehouse Management Systems (WMS)
                        </h4>
                        <h5 className="card-text-two px-3">
                          We offer WMS solutions that optimize warehouse operations,
                          including inventory management, order fulfillment, and
                          labor tracking. Our WMS systems enable efficient
                          receiving, put-away, picking, packing, and shipping,
                          improving accuracy and productivity.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card card-one h-100">
                    <div className="card-body">
                      <div className="p-3">
                        {" "}
                        <img src={order} className="logi-img-one"  alt="logisticdistribution"/>
                      </div>
                      <div className="pt-lg-3">
                        <h4 className="card-text-one ps-3 ">
                          Order Management Systems (OMS)
                        </h4>
                        <h5 className="card-text-two px-3">
                          We provide OMS solutions that centralize and automate
                          order processing, from order capture to fulfillment. Our
                          OMS systems integrate sales channels, inventory, and
                          fulfillment systems, enabling efficient order routing,
                          allocation, and status tracking.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-end mt-5 align-items-center ">
              {/* <div className="logi-blur"></div> */}

              <div className="col-lg-4 col-md-2">
                <img
                  className="logi-img"
                  src={logi2}
                  alt="logisticdistribution"
                ></img>
              </div>
              <div className="col-lg-8 col-md-10">
                <div className="col-lg-10 col-md-7 col-12 ms-auto">
                  <div className="d-flex">
                    <div className="">
                      <TiTick className="logi-icon-tick" />
                    </div>
                    <div>
                      <h4 className="card-text-one ">
                        {" "}
                        Cybersecurity and Data Protection
                      </h4>
                      <h5 className="card-text-two px-3">
                        Protecting sensitive shipment data, customer
                        information, and logistics operations is crucial in the
                        logistics and distribution industry. We provide
                        comprehensive cybersecurity services, including network
                        security, data encryption, access controls, and
                        vulnerability assessments. Our solutions help safeguard
                        your valuable assets and protect against data breaches.
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-11 col-md-8 col-12 ms-auto">
                  <div className="d-flex">
                    <div>
                      <TiTick className="logi-icon-tick" />
                    </div>
                    <div>
                      <h4 className="card-text-one">
                        Last-Mile Delivery Solutions
                      </h4>
                      <h5 className="card-text-two px-3">
                        We assist in developing last-mile delivery solutions
                        that optimize route planning, driver dispatch, and
                        proof-of-delivery. Our solutions enable real-time driver
                        tracking, customer communication, and electronic
                        proof-of-delivery, improving delivery accuracy and
                        customer experience.
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-9 col-12 ms-auto">
                  <div className="d-flex">
                    <div>
                      <TiTick className="logi-icon-tick" />
                    </div>
                    <div>
                      <h4 className="card-text-one">
                        Cross-Border Trade Solutions
                      </h4>
                      <h5 className="card-text-two px-3">
                        We provide cross-border trade solutions that facilitate
                        efficient customs clearance, compliance, and
                        documentation management. Our solutions help navigate
                        complex international trade regulations, ensuring smooth
                        cross-border operations.
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-5">
              <div className="row row-cols-lg-3 row-cols-1 row-cols-md-2 mt-lg-4 mx-3 text-center border-n-row px justify-content-md-center">
                <div className="col">
                  <div className="card h-100 ">
                    <div className=" text-center icon-align">
                      <img
                        src={transportation}
                        className="logi-img-two"
                        alt="logisticdistribution"
                      />
                    </div>
                    <div className="p-lg-3">
                      <h4 className="card-text-one ps-3">
                        Transportation Management Systems (TMS)
                      </h4>
                      <h5 className="card-text-two px-lg-3">
                        We provide robust TMS solutions that streamline
                        transportation planning, execution, and optimization.
                        Our TMS systems enable route optimization, carrier
                        selection, freight tracking, and performance monitoring,
                        ensuring efficient and cost-effective transportation
                        operations.
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100">
                    <div className=" text-center icon-align">
                      {" "}
                      <img
                        src={supply}
                        className="logi-img-two"
                        alt="logisticdistribution"
                      />
                    </div>
                    <div className="p-lg-3">
                      <h4 className="card-text-one ps-3">
                        Supply Chain Visibility Solutions
                      </h4>
                      <h5 className="card-text-two px-lg-3">
                        We offer supply chain visibility solutions that provide
                        real-time tracking and monitoring of shipments and
                        inventory throughout the supply chain. Our solutions
                        enable end-to-end visibility, event notifications, and
                        exception management, enhancing supply chain
                        responsiveness and customer satisfaction.
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col pt-md-5 pt-lg-0">
                  <div className="card h-100">
                    <div className="text-center icon-align">
                      <img
                        src={vendor}
                        className="logi-img-two"
                        alt="logisticdistribution"
                      />
                    </div>
                    <div className="p-lg-3">
                      <h4 className="card-text-one ps-3">
                        Vendor Management Systems (VMS)
                      </h4>
                      <h5 className="card-text-two px-lg-3">
                        We offer VMS solutions that streamline the management of
                        third-party vendors, including carriers, freight
                        forwarders, and service providers. Our VMS systems
                        enable efficient vendor onboarding, rate management, and
                        performance tracking, ensuring effective vendor
                        management and cost control.
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="logistic-last-section my-lg-5">
          <div className="container py-5">
            <div className="row flex-row-reverse">
              <div className="col-lg-4 col-md-4">
                <img src={transport} alt="logisticdistribution" />
              </div>
              <div className="col-lg-8 col-md-8">
                <div className="pt-lg-5">
                  <p className="">
                    At Upstrox, we understand the unique challenges and
                    opportunities in the logistics and distribution industry.
                    Our comprehensive IT services empower organizations to drive
                    operational efficiency, enhance supply chain visibility, and
                    deliver exceptional customer experiences.
                  </p>
                </div>
                <div>
                  <a className="new-ups-button-2" href="/contact">
                    <span className="new-ups-button-icon-3">Read More</span>{" "}
                    <BiRightArrowAlt className="new-ups-button-icon-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-text-section py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="contact-text-last mb-lg-4">
                  Contact us today to discuss your logistics and distribution IT
                  needs and explore how we can support your organization's
                  success.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default LogisticsDistribution;
