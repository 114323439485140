import { MdOutlineArrowForwardIos } from "react-icons/md";
import { BiRightArrowAlt } from "react-icons/bi";
import ContactNew from "../../Assets/images/servies-images/Network Services webp/last image copy.webp";
import neticon1 from "../../Assets/images/servies-images/Network Services webp/icon/Network Design and Architecture.webp";
import neticon2 from "../../Assets/images/servies-images/Network Services webp/icon/Network Infrastructure Implementation.webp";
import neticon3 from "../../Assets/images/servies-images/Network Services webp/icon/Network Security Solutions.webp";
import neticon4 from "../../Assets/images/servies-images/Network Services webp/icon/Wireless Network Solutions.webp";
import neticon5 from "../../Assets/images/servies-images/Network Services webp/icon/Network Monitoring and Management.webp";
import neticon6 from "../../Assets/images/servies-images/Network Services webp/icon/Network Optimization and Performance Tuning.webp";
import neticon7 from "../../Assets/images/servies-images/Network Services webp/icon/Software-Defined Networking (SDN).webp";
import neticon8 from "../../Assets/images/servies-images/Network Services webp/icon/Cloud Networking Solutions.webp";
import neticon9 from "../../Assets/images/servies-images/Network Services webp/icon/Network Virtualization.webp";
import neticon10 from "../../Assets/images/servies-images/Network Services webp/icon/Network Consulting and Assessment.webp";
import MetaTags from "../../Metatag";
import { useEffect } from "react";

function Networkservices() {
  
  return (

    <>
             {/* <MetaTags
        top="Upstrox: Your Partner for Enhanced Network Connectivity"
        title="Amplify Your Business with Upstrox's Network Services"
        description="Find out how Upstrox's reliable network services can make your business run more smoothly. Improve your connectivity now! "
      /> */}
      <div className="networkservices-banner-section">
        <section className="top-banner-section top-banner-section-overral-bg-section network-background back-posi-img">
          <div className="mobile-new-ups-bg-shade-inner mx-auto container-fluid">
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one">
                    <span className="text-color">Network Services</span> by
                    Upstrox.
                  </h1>
                  <div className="top-text-two">
                    Upstrox provides comprehensive network services designed to
                    optimize connectivity, enhance performance, and ensure the
                    reliability of your organization's network infrastructure.
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <a href="/contact">
                  <button className="top-button right-side">
                    Get More{" "}
                    <MdOutlineArrowForwardIos className="top-icon ms-lg-2" />
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="top-banner-section-two pb-4 pt-5">
          <div className="container">
            <div className="row">
              <div className="col">
                <h5 className="text-one">
                  Our solutions cover everything from concept design to
                  manufacturing and assembly, ensuring a seamless and efficient
                  process. Additionally, we provide software development
                  services for various automotive-related applications. Read on
                  to explore our wide range of offerings.
                </h5>
              </div>
            </div>
          </div>
        </section>

        <section className="automotive-new-design-2 pb-4 pt-5">
          <div className="container-fluid col-lg-10 mx-auto">
            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 justify-content-center">
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <img
                      src={neticon1}
                      className="automotive-new-text-2 net-icon-img"
                      alt="networkservice"
                    />
                    <h5 class="automotive-new-text-1">
                      Network Design and Architecture
                    </h5>
                    <p class="automotive-new-text-3">
                      Our experienced network architects work closely with your
                      organization to design and implement scalable and secure
                      network architectures. We assess your requirements, plan
                      network topologies, select appropriate technologies, and
                      design resilient and efficient networks tailored to your
                      business needs.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <img
                      src={neticon2}
                      className="automotive-new-text-2 net-icon-img"
                      alt="networkservice"
                    />
                    <h5 class="automotive-new-text-1">
                      Network Infrastructure Implementation
                    </h5>
                    <p class="automotive-new-text-3">
                      We provide end-to-end network infrastructure
                      implementation services, including installation,
                      configuration, and deployment of network equipment.
                      Whether you need to set up a local area network (LAN),
                      wide area network (WAN), or data center network, our
                      experts ensure seamless implementation and optimal
                      performance.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <img
                      src={neticon3}
                      className="automotive-new-text-2 net-icon-img"
                      alt="networkservice"
                    />
                    <h5 class="automotive-new-text-1">
                      Network Security Solutions
                    </h5>
                    <p class="automotive-new-text-3">
                      We prioritize the security of your network infrastructure.
                      Our network security solutions include firewall
                      deployment, intrusion detection and prevention systems,
                      virtual private networks (VPNs), secure access controls,
                      and security audits. We help protect your network from
                      unauthorized access, data breaches, and cyber threats.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <img
                      src={neticon4}
                      className="automotive-new-text-2 net-icon-img"
                      alt="networkservice"
                    />
                    <h5 class="automotive-new-text-1">
                      Wireless Network Solutions
                    </h5>
                    <p class="automotive-new-text-3">
                      We specialize in designing and implementing robust
                      wireless network solutions. Our experts ensure seamless
                      wireless connectivity, coverage optimization, and secure
                      access. We implement wireless LANs, outdoor wireless
                      networks, and guest Wi-Fi solutions to meet the demands of
                      your organization.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <img
                      src={neticon5}
                      className="automotive-new-text-2 net-icon-img"
                      alt="networkservice"
                    />
                    <h5 class="automotive-new-text-1">
                      Network Monitoring and Management
                    </h5>
                    <p class="automotive-new-text-3">
                      {" "}
                      We offer proactive network monitoring and management
                      services to ensure optimal network performance and uptime.
                      Our solutions include real-time monitoring, fault
                      detection, performance analysis, and proactive
                      troubleshooting. We provide network performance reports,
                      alerts, and 24/7 network support.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <img
                      src={neticon6}
                      className="automotive-new-text-2 net-icon-img"
                      alt="networkservice"
                    />
                    <h5 class="automotive-new-text-1">
                      Network Optimization and Performance Tuning
                    </h5>
                    <p class="automotive-new-text-3">
                      Our network optimization services help improve the
                      performance and efficiency of your network infrastructure.
                      We analyze network traffic patterns, optimize network
                      configurations, and fine-tune performance parameters to
                      ensure smooth and reliable network operations.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <img
                      src={neticon7}
                      className="automotive-new-text-2 net-icon-img"
                      alt="networkservice"
                    />
                    <h5 class="automotive-new-text-1">
                      Software-Defined Networking (SDN){" "}
                    </h5>
                    <p class="automotive-new-text-3">
                      We provide SDN solutions that enable centralized network
                      management and automation. SDN allows you to dynamically
                      control and configure your network infrastructure,
                      improving agility, scalability, and efficiency. Our
                      experts help you leverage SDN to simplify network
                      operations and reduce costs.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <img
                      src={neticon8}
                      className="automotive-new-text-2 net-icon-img"
                      alt="networkservice"
                    />
                    <h5 class="automotive-new-text-1">
                      Cloud Networking Solutions
                    </h5>
                    <p class="automotive-new-text-3">
                      We specialize in designing and implementing cloud
                      networking solutions that seamlessly connect your
                      on-premises network with cloud environments. Whether you
                      are adopting public, private, or hybrid cloud solutions,
                      we ensure secure and reliable connectivity to your cloud
                      resources.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <img
                      src={neticon9}
                      className="automotive-new-text-2 net-icon-img"
                      alt="networkservice"
                    />
                    <h5 class="automotive-new-text-1">
                      Network Virtualization
                    </h5>
                    <p class="automotive-new-text-3">
                      We offer network virtualization solutions that enable the
                      consolidation and management of multiple virtual networks.
                      Virtualized networks improve resource utilization, enhance
                      flexibility, and simplify network administration. Our
                      experts help you leverage network virtualization to
                      optimize your infrastructure.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <img
                      src={neticon10}
                      className="automotive-new-text-2 net-icon-img"
                      alt="networkservice"
                    />
                    <h5 class="automotive-new-text-1">
                      Network Consulting and Assessment
                    </h5>
                    <p class="automotive-new-text-3">
                      Our network consultants provide comprehensive network
                      consulting services, including network assessments,
                      audits, and technology recommendations. We help you
                      identify network vulnerabilities, assess performance
                      bottlenecks, and develop strategies for network
                      optimization and future growth.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 flex-lg-row-reverse">
              <div className="col-lg-4">
                <img src={ContactNew} alt="networkservice" />
              </div>
              <div className="col-lg-8">
                <p className="aerospace-new-text-5 mb-lg-4">
                  At Upstrox, we are committed to delivering reliable and
                  cutting-edge network services to help organizations optimize
                  their network infrastructure.{" "}
                </p>
                <a href="/contact" className="new-ups-button-2">
                  <span className="new-ups-button-icon-3">Read More</span>{" "}
                  <BiRightArrowAlt className="new-ups-button-icon-2" />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="aerospace-new-text-6 mb-lg-4">
                  Contact us today to discuss your specific requirements and
                  explore how Upstrox can empower your organization's network
                  connectivity, performance, and security with our
                  industry-leading IT services.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Networkservices;
