import { MdOutlineArrowForwardIos } from "react-icons/md";
import Dummybg from "../../Assets/images/servies-images/demo/dummy-2.webp"

import retaillast from "../../Assets/images/servies-images/testing services/images/first section copy.webp";
import logidis1ast from "../../Assets/images/servies-images/testing services/images/last image copy.webp"
import { BiRightArrowAlt } from "react-icons/bi";
import testicon1 from "../../Assets/images/servies-images/testing services/icon/Functional Testing.webp";
import testicon2 from "../../Assets/images/servies-images/testing services/icon/Performance Testing.webp";
import testicon3 from "../../Assets/images/servies-images/testing services/icon/Load Testing.webp";
import testicon4 from "../../Assets/images/servies-images/testing services/icon 2/Stress Testing.webp";
import testicon5 from "../../Assets/images/servies-images/testing services/icon 2/Security Testing.webp";
import testicon6 from "../../Assets/images/servies-images/testing services/icon 2/Usability Testing.webp";
import { useEffect } from "react";

function TestingServices() {
    
    return (
        <>
            <div className="testing-services">
                <section className="top-banner-section top-banner-section-overral-bg-section testing-background back-posi-img">
                    <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1 className="top-text-one"><span className="text-color">Testing Services</span> by Upstrox</h1>
                                    <div className="top-text-two">Upstrox offers comprehensive testing services to ensure the quality, reliability, and performance of your software applications and systems. Our testing services encompass the entire software development lifecycle, from requirements gathering to post-production support.</div>
                                </div>
                            </div>
                            <div className="pt-3">
                               <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="retail-banner-section-two pt-5">
                <div className="container">
                    <div className="row">
                    <div className="col">
                        <h5 className="retail-tw-text">
                        We leverage industry best practices, methodologies, and cutting-edge tools to deliver efficient and effective testing solutions. Here are the key testing services we offer
                        </h5>
                    </div>
                    </div>
                </div>
                </section>

                <section className="retail-banner-section-three">
                <div className="container-fluid card-re-one col-lg-10">
                    <div className="row row-cols-lg-2 row-cols-1 row-cols-md-2 justify-content-md-end">
                    <div className="col pt-3 mt-lg-3 ">
                        <div className="card-re-border-top"></div>
                        <div
                        className=" card card-back-re1"
                        style={{ borderColor: "rgb(39 63 75)" }}
                        >
                        <div
                            className="card-body d-lg-flex"
                            style={{ background: "#273f4b42" }}
                        >
                            <div className="re-icon-one">
                            {" "}
                            <img src={testicon1} className="img-card-one" alt="testingservices" />
                            </div>
                            <div>
                            <h4 className="card-text-one">
                            Functional Testing
                            </h4>
                            <h5 className="card-text-two">
                            We conduct thorough functional testing to verify that your software application meets the specified requirements and functions as intended. Our experts design test cases, execute tests, and report defects to ensure the functionality and usability of your software.
                            </h5>
                            </div>
                        </div>
                        </div>
                        <div
                        className="card-re-border-bottom"
                        style={{ borderColor: "rgb(39 63 75)" }}
                        ></div>
                    </div>
                    <div className="col pt-3 pt-lg-0 retail-card-one">
                        <div className="card-re-border-top"></div>
                        <div
                        className=" card card-back-re2"
                        style={{ borderColor: "rgb(30 55 54)" }}
                        >
                        <div
                            className="card-body d-lg-flex"
                            style={{
                            background: "rgb(30 55 54 / 25%)",
                            borderColor: "black",
                            }}
                        >
                            <div className="re-icon-one">
                            {" "}
                            <img src={testicon2} alt="testingservices" className="img-card-one" />
                            </div>
                            <div>
                            <h4 className="card-text-one">
                            Performance Testing
                            </h4>
                            <h5 className="card-text-two">
                            We perform performance testing to assess the responsiveness, scalability, and stability of your software application under varying workloads. Our experts simulate real-world scenarios, measure system performance, and identify performance bottlenecks, enabling you to optimize application performance.
                            </h5>
                            </div>
                        </div>
                        </div>
                        <div
                        className="card-re-border-bottom"
                        style={{ borderColor: "rgb(30 55 54)" }}
                        ></div>
                    </div>
                    <div className="col retail-card-one pt-md-5 pt-4">
                        <div className="card-re-border-top"></div>
                        <div
                        className=" card card-back-re3"
                        style={{ borderColor: "rgb(53 58 45)" }}
                        >
                        <div
                            className="card-body d-lg-flex"
                            style={{ background: "rgb(53 58 45 / 25%)" }}
                        >
                            <div className="re-icon-one">
                            <img src={testicon3} alt="testingservices" className="img-card-one"/>
                            </div>
                            <div>
                            <h4 className="card-text-one">
                            Load Testing
                            </h4>
                            <h5 className="card-text-two">
                            We conduct load testing to evaluate the performance of your application under expected user loads. Our experts simulate concurrent user interactions, measure response times, and assess system behavior to ensure that your application can handle the anticipated user demand.
                            </h5>
                            </div>
                        </div>
                        </div>
                        <div
                        className="card-re-border-bottom"
                        style={{ borderColor: "rgb(53 58 45)" }}
                        ></div>
                    </div>
                    </div>
                </div>

                <div className="container-fluid mt-5 re-card-three col-lg-10">
                    <div className="row flex-row-reverse">
                    <div className="col-lg-5 col-md-4 d-flex align-items-center">
                        <img src={retaillast} alt="testingservices" style={{height:"auto"}} />
                    </div>
                    <div className="col-lg-7 col-md-8">
                        <div className=" card ">
                        <div className="card-body d-lg-flex d-md-flex">
                            <div className="">
                            {" "}
                            {/* <BsFillDatabaseFill className="re-icon-three th-icon-one" /> */}
                            <img src={testicon4} className="test-icon-img" alt="testingservices"/>
                            </div>
                            <div className="px-lg-3 px-md-3">
                            <h4 className="card-text-one">
                            Stress Testing
                            </h4>
                            <h5 className="card-text-two">
                            We perform stress testing to assess the reliability and stability of your application under extreme conditions. Our experts subject your application to high loads, peak usage, and resource limitations to identify potential vulnerabilities and ensure system resilience.
                            </h5>
                            </div>
                        </div>
                        </div>
                        <div className=" card ">
                        <div className="card-body d-lg-flex d-md-flex">
                            <div className="">
                            {" "}
                            <img src={testicon5} className="test-icon-img" alt="testingservices" />
                            </div>
                            <div className="px-lg-3 px-md-3">
                            <h4 className="card-text-one">
                            Security Testing
                            </h4>
                            <h5 className="card-text-two">
                            We provide security testing services to identify vulnerabilities and assess the security posture of your software applications and systems. Our experts conduct penetration testing, vulnerability assessments, and security code reviews to uncover potential security risks and ensure data protection.
                            </h5>
                            </div>
                        </div>
                        </div>
                        <div className=" card ">
                        <div className="card-body d-lg-flex d-md-flex">
                            <div className="">
                            {" "}
                            <img src={testicon6} className="test-icon-img" alt="testingservices"/>
                            </div>
                            <div className="px-lg-3 px-md-3">
                            <h4 className="card-text-one">
                            Usability Testing
                            </h4>
                            <h5 className="card-text-two">
                            We conduct usability testing to evaluate the user experience and user interface design of your software application. Our experts assess the application's intuitiveness, navigation, and user satisfaction to identify areas for improvement and optimize the user experience.
                            </h5>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="container-fluid mt-5 re-two-card col-lg-10">
                    <div className="row row-cols-lg-2 row-cols-md-2 row-cols-1 gap-3 gap-lg-0  gap-md-0 ">
                    <div className="col pe-lg-0">
                        <div className=" card h-100 py-lg-2">
                        <div className="card-body">
                            <div>
                            <h4 className="card-text-one">
                            Compatibility Testing
                            </h4>
                            <h5 className="card-text-two">
                            We perform compatibility testing to ensure that your application functions correctly across different platforms, browsers, and devices. Our experts test your application's compatibility with various operating systems, hardware configurations, and software dependencies to ensure seamless user experiences.
                            </h5>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col ">
                        <div className=" card h-100">
                        <div className="card-body">
                            <div>
                            <h4 className="card-text-one">
                            Regression Testing
                            </h4>
                            <h5 className="card-text-two">
                            We conduct regression testing to ensure that new features, enhancements, or bug fixes do not introduce new issues or break existing functionality. Our experts design comprehensive test suites and execute regression tests to maintain the integrity and stability of your software.
                            </h5>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col pt-md-3">
                        <div className=" card h-100 left-margin">
                        <div className="card-body ">
                            <div>
                            <h4 className="card-text-one">
                            Test Automation
                            </h4>
                            <h5 className="card-text-two">
                            We offer test automation services to streamline and accelerate the testing process. Our experts utilize industry-leading test automation frameworks and tools to design and execute automated test scripts, enabling faster test cycles and improved test coverage.
                            </h5>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col pt-md-3">
                        <div className=" card h-100 left-margin">
                        <div className="card-body">
                            <div>
                            <h4 className="card-text-one">
                            Mobile App Testing
                            </h4>
                            <h5 className="card-text-two">
                            We specialize in mobile app testing to ensure the quality and functionality of your mobile applications across different devices and platforms. Our experts test your mobile app's compatibility, performance, usability, and security, providing a seamless user experience.
                            </h5>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>

                
                </section>

                <section className="logistic-last-section my-lg-5">
                <div className="container py-3">
                    <div className="row flex-row-reverse">
                    <div className="col-lg-4 col-md-4">
                        <img src={logidis1ast} alt="testingservices" />
                    </div>
                    <div className="col-lg-8 col-md-8">
                        <div className="pt-lg-5">
                        <p className="">
                        At Upstrox, we are dedicated to delivering high-quality testing services to ensure the reliability and performance of your software applications and systems.
                        </p>
                        </div>
                        <div>
                        <a className="new-ups-button-2" href="/contact">
                            <span className="new-ups-button-icon-3">Read More</span>{" "}
                            <BiRightArrowAlt className="new-ups-button-icon-2" />
                        </a>
                        </div>
                    </div>
                    </div>
                </div>
                </section>

                <section className="contact-text-section py-5">
                <div className="container">
                    <div className="row g-4 justify-content-center text-center">
                    <div className="col-lg-12">
                        <p className="contact-text-last mb-lg-4">
                        Contact us today to discuss your testing requirements and explore how Upstrox can help you achieve software excellence through our industry-leading testing services.
                        </p>
                    </div>
                    </div>
                </div>
                </section>
            </div>
        </>
    )
}
export default TestingServices;