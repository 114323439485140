import { MdOutlineArrowForwardIos } from "react-icons/md";
import { BiRightArrowAlt } from 'react-icons/bi';
import IconEngi1 from "../../Assets/images/icon/Engineering Procurement - Construction/Project Management Software.webp";
import IconEngi2 from "../../Assets/images/icon/Engineering Procurement - Construction/Document Management Systems.webp";
import IconEngi3 from "../../Assets/images/icon/Engineering Procurement - Construction/Building Information Modeling.webp";
import IconEngi4 from "../../Assets/images/icon/Engineering Procurement - Construction/Enterprise Resource Planning_.webp";
import IconEngi5 from "../../Assets/images/icon/Engineering Procurement - Construction/Collaboration and Communication Tools.webp";
import IconEngi6 from "../../Assets/images/icon/Engineering Procurement - Construction/Virtual Reality (VR) and Augmented Reality (AR).webp";
import IconEngi7 from "../../Assets/images/icon/Engineering Procurement - Construction/Supply Chain Management.webp";
import IconEngi8 from "../../Assets/images/icon/Engineering Procurement - Construction/Data Analytics and Business Intelligence.webp";
import IconEngi9 from "../../Assets/images/icon/Engineering Procurement - Construction/Cybersecurity and Data Protection.webp";
import IconEngi10 from "../../Assets/images/icon/Engineering Procurement - Construction/IT Infrastructure and Cloud Services.webp";
import ContactNew from "../../Assets/images/icon/Engineering Procurement - Construction/Engineering Procurement & Construction image.webp"
import EngineeringProcurementbg from "../../Assets/images/servies-images/engineering-construction.webp"

function EngineeringProcurement() {
  return (
    <>
      <div className="engineeringprocurement-banner-section">
        <section className="top-banner-section top-banner-section-overral-bg-section back-posi-img" style={{ backgroundImage: "url(" + EngineeringProcurementbg + ")" }}>
          <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one">Upstrox - Your Trusted Partner for
                    <span className="text-color">   Engineering Procurement & Construction (EPC) </span> IT Services
                  </h1>
                  <div className="top-text-two">
                    Engineering Procurement & Construction (EPC) projects require robust and efficient IT solutions to streamline complex workflows, enhance collaboration, and ensure project success.
                  </div>
                </div>
              </div>
              <div className="pt-3">
               <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-1 py-5">
          <div className="container-fluid col-lg-11 mx-auto">
            <div className="row">
              <div className="col-lg-4">
                <h2 className="aerospace-new-text-1 mb-lg-4 mb-3">Engineering Procurement & Construction</h2>
                <p className="aerospace-new-text-2"> At Upstrox, we specialize in providing comprehensive IT services tailored specifically for the EPC industry. Here are the key IT services we offer:</p>
              </div>
              <div className="col-lg-8">
                <div className="row row-cols-md-1 row-cols-1 row-cols-lg-2 g-4">
                  <div className="col">
                    <img src={IconEngi1} alt="engineering" className="eng-img" />
                    <h5 className="aerospace-new-text-3">Project Management Software</h5>
                    <p className="aerospace-new-text-4">We provide advanced project management software that enables efficient planning, scheduling, and tracking of EPC projects. Our solutions help manage resources, monitor progress, mitigate risks, and ensure timely project completion.</p>
                  </div>
                  <div className="col">
                    <img src={IconEngi2} alt="engineering" className="eng-img" />
                    <h5 className="aerospace-new-text-3">Document Management Systems</h5>
                    <p className="aerospace-new-text-4">We offer document management systems that facilitate efficient storage, retrieval, version control, and collaboration on project-related documents. Our solutions ensure seamless access to critical project documentation, enhancing communication and productivity.</p>
                  </div>
                  <div className="col">
                    <img src={IconEngi3} alt="engineering" className="eng-img" />
                    <h5 className="aerospace-new-text-3">Building Information Modeling (BIM)</h5>
                    <p className="aerospace-new-text-4">We assist EPC companies in implementing BIM technologies for effective project visualization, clash detection, and coordination among project stakeholders. Our BIM solutions enable 3D modeling, virtual walkthroughs, and accurate quantity takeoffs, improving design accuracy and reducing rework.</p>
                  </div>
                  <div className="col">
                    <img src={IconEngi4} alt="engineering" className="eng-img" />
                    <h5 className="aerospace-new-text-3">Enterprise Resource Planning (ERP)</h5>
                    <p className="aerospace-new-text-4">Our ERP solutions for EPC streamline project financials, procurement, inventory management, and resource allocation. We integrate various business functions, providing real-time visibility into project costs, schedules, and performance, optimizing project execution and profitability.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="aerospace-new-design-2 py-5">
          <div className="container-fluid col-lg-11 mx-auto">
            <div className="row row-cols-md-2 row-cols-1 row-cols-lg-3 g-4 py-lg-5">
              <div className="col aerospace-new-design-2-inner-1">
                <img src={IconEngi5} alt="engineering" className="eng-img" />
                <h5 className="aerospace-new-text-3">Collaboration and Communication Tools</h5>
                <p className="aerospace-new-text-4">We offer collaboration and communication tools that facilitate seamless information sharing and communication among project teams, subcontractors, and clients. Our solutions include project portals, virtual meeting platforms, and mobile applications, enabling effective collaboration in real-time.</p>
              </div>
              <div className="col aerospace-new-design-2-inner-2">
                <img src={IconEngi6} alt="engineering" className="eng-img" />
                <h5 className="aerospace-new-text-3">Virtual Reality (VR) and Augmented Reality (AR)</h5>
                <p className="aerospace-new-text-4">We leverage VR and AR technologies to enhance EPC project visualization, training, and safety programs. Our solutions enable stakeholders to experience immersive virtual environments, simulate scenarios, and identify potential design or construction issues before implementation.</p>
              </div>
              <div className="col aerospace-new-design-2-inner-3">
                <img src={IconEngi7} alt="engineering" className="eng-img" />
                <h5 className="aerospace-new-text-3">Supply Chain Management (SCM)</h5>
                <p className="aerospace-new-text-4">We provide SCM solutions tailored for the EPC industry, enabling efficient procurement, inventory management, and logistics. Our solutions optimize the supply chain, ensuring timely delivery of materials and reducing project delays.</p>
              </div>
              <div className="col aerospace-new-design-2-inner-1">
                <img src={IconEngi8} alt="engineering" className="eng-img" />
                <h5 className="aerospace-new-text-3">Data Analytics and Business Intelligence</h5>
                <p className="aerospace-new-text-4">Our data analytics and business intelligence solutions help EPC companies gain valuable insights from project data. We provide dashboards and reports that enable informed decision-making, performance tracking, and predictive analytics for improved project outcomes.</p>
              </div>
              <div className="col aerospace-new-design-2-inner-2">
                <img src={IconEngi9} alt="engineering" className="eng-img" />
                <h5 className="aerospace-new-text-3">Cybersecurity and Data Protection</h5>
                <p className="aerospace-new-text-4">We prioritize cybersecurity and data protection for EPC projects. Our solutions include network security, data encryption, access controls, and compliance with industry regulations. We help safeguard critical project data and protect against cyber threats.</p>
              </div>
              <div className="col aerospace-new-design-2-inner-3">
                <img src={IconEngi10} alt="engineering" className="eng-img" />
                <h5 className="aerospace-new-text-3">IT Infrastructure Design and Implementation</h5>
                <p className="aerospace-new-text-4">We offer design and implementation services for robust IT infrastructure tailored to EPC requirements. Our solutions include server deployment, network configuration, cloud integration, and disaster recovery planning, ensuring reliable and scalable IT infrastructure.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 flex-lg-row-reverse align-items-center">
              <div className="col-lg-4">
                <img src={ContactNew} alt="engineering" />
              </div>
              <div className="col-lg-8">
                <p className="aerospace-new-text-5 mb-lg-4">At Upstrox, we understand the intricacies of EPC projects and the importance of leveraging technology to drive efficiency and project success. Our IT services are designed to address the specific needs of EPC companies, enabling streamlined workflows, effective collaboration, and data-driven decision-making.</p>
                <a href='/contact' className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="aerospace-new-text-6 mb-lg-4">Contact us today to discuss your EPC IT requirements and explore how we can support your projects with our expertise.</p>
              </div>
            </div>
          </div>
        </section>
      </div>

    </>
  )
}
export default EngineeringProcurement;