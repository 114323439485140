import contactimg from "../../Assets/images/contact.png";
import { IoLocation } from "react-icons/io5";
import { HiPhone } from "react-icons/hi";
import { BiSolidPhoneCall } from "react-icons/bi";
import { AiFillMail } from "react-icons/ai";
import $ from "jquery";
import { useState, useEffect } from "react";
import axios from "axios";
import Config from "../../Config";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactFlagsSelect from "react-flags-select";
// import { Formik, useFormik, } from "formik";
// const onSubmit = (values,action) =>{
//   console.log("values",values,action);
// }
import { BsWhatsapp } from "react-icons/bs";
import { TiTick } from "react-icons/ti";
import { RiTwitterXFill } from 'react-icons/ri';
import { AiOutlineInstagram, AiFillLinkedin, AiFillYoutube } from 'react-icons/ai';
import { BiLogoFacebookCircle } from 'react-icons/bi';
import { FaTelegramPlane } from "react-icons/fa";
import { FaSkype } from "react-icons/fa";


function Contact() {

  const [countryCode, setCountryCodes] = useState("");
  const [countryDatas, setCountryDatas] = useState({});
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [selectedCountryName,setSelectedCountryName]= useState("")

  const fetchCountryData = async () => {
    try {
    const response = await fetch(`https://ipapi.co/json`);
    const data = await response.json();
    console.log("data======country",data);
    setCountryCodes(data.country_code.toLowerCase())
      setSelectedCountryCode(data.country_code)
    setSelectedCountryName(data.country_name)
    // if (data.country_name == undefined) {
    //     setCountryDatas("IN")
    // } else {
    //     setCountryDatas(data.country_name)
    // }
    } catch (error) {
    console.error("Error fetching country data: ", error);
    }
    
    };

    useEffect(()=>{
      fetchCountryData()
    },[])
  
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
    setValues
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      country: "",
      phoneno: "",
      requirement: "",
      socialMedia: "",
      message: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(" Name is required!"),
      email: Yup.string()
        .email("Please enter valid email!")
        .required("E-mail is required!"),
      phoneno: Yup.number().required("Phone number  is required!"),
      requirement: Yup.string().required("Please Select One Field!"),
      socialMedia: Yup.string().required("Socialmedia id  is required!"),
      message: Yup.string().required("Please say something!"),
      // country: Yup.string().required("Country is required!"),
    }),
    onSubmit : async(values) => {
      try {
        if(selectedCountryCode !== ""){
          const payload = {
            name: values.name,
            email: values.email,
            phoneno: values.phoneno,
            requirement: values.requirement,
            socialMedia: values.socialMedia,
            message: values.message,
            country:values.country
          };
          await axios.post(`${Config.SERVER_URL}/register`, payload); 
          // alert("User Registeration SuccessFully !!!")
          window.location.href="/thankyou"
          resetForm("")
        }
      } catch {
        console.log("error");
      }
     
    }
  });
  // console.log(values,"values")

  const handlePhoneChange = (value, country, e, formettedValue) => {
    handleChange(e);

    setValues((prevValues) => ({
      ...prevValues,
      phoneno: value,
      country:selectedCountryName
    }));
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   handleChange(e);
  //   console.log("vale",values,touched,errors);
  //  }

  // const handleSubmit = async(val)=>{
  //   console.log("val",val);
  // }
  // console.log("values------------",values);

  return (
    <>
      <div className="contact-banner-section">
        <section className="contact-section-one">
          <div className="container">
            <div className="row align-items-center">
              <div className="col d-flex justify-content-center">
                <h3 className="new-navbar-text-1 border-0"> Contact Us</h3>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-section-two pt-lg-5 pt-3">
          <div className="container">
            <div className="row row-cols-1 row-cols-lg-2 px-lg-4 px-2 pb-lg-5 top-row ">
                <div className="col box-column p-0">
                <div className="card-box p-lg-5 p-3 ">
                  <div className="pt-lg-5 pt-3 d-contents justify-content-center">
                    <div className="contact-text-one">
                      {" "}
                      We are always open for Business
                    </div>
                    <div className="card-text-two  pe-lg-5">
                      Know your requirement, our technical expert will schedule
                      a call and discuss your idea in detail. All information
                      will be kept confidential.{" "}
                    </div>
                    {/* <img src={contactimg} className=""/> */}
                  </div>
                  <div className="g-4 pt-lg-5 pt-3">
                    <div className="pt-lg-3">Our Contact us</div>
                    <div className="card-text-two d-flex pt-2">
                      <IoLocation />{" "}
                      <span>
                        <ul class="list-group">
                          <li class="list-group-item card-text-two">
                            13 B, 70 feet Road, Ellis Nagar,
                          </li>
                          <li class="list-group-item card-text-two">
                            Madurai, Tamil Nadu
                          </li>
                          <li class="list-group-item card-text-two"> India</li>
                        </ul>
                      </span>
                    </div>
                    <div className="pt-lg-2 pt-1 card-text-two">
                      <HiPhone />+91 9442430551
                    </div>
                    <div className="pt-lg-2 pt-1 card-text-two">
                      <AiFillMail /> Contact@upstrox.com
                    </div>
                    {/* <img src={contactimg} className="pt-lg-5 contactimg" /> */}
                    <div className="row pt-lg-5 ">
                    {/* <div className="col-lg-6 contact-text-one">
                     How to Reach us
                     </div> */}
                      <div className="col pt-5">
                        <div>
                          <span className="contact-text-one">Chat With Us</span>
                        </div>
                      <div className="d-flex align-items-center">
                      <a href="https://api.whatsapp.com/send?phone=919442430551&text=*Hi%20there!%20I%20am%20looking%20for%20your%20Services,%20Shall%20we%20Start%20the%20discussion*%20Lead%20From%20https://www.upstrox.com/" className='social-icons' style={{background:"green"}} target="_blank"> <BsWhatsapp /></a>
                       <a href="https://api.whatsapp.com/send?phone=919442430551&text=*Hi%20there!%20I%20am%20looking%20for%20your%20Services,%20Shall%20we%20Start%20the%20discussion*%20Lead%20From%20https://www.upstrox.com/" target="_blank"> <p className="contact-social-text"  >WhatsApp</p>   </a> 
                        </div>
                        <div className="d-flex align-items-center">
                      <a href="#" className='social-icons'style={{background:"rgb(8,102,255)"}} target="_blank"> <FaTelegramPlane /></a>
                      <a href="#" > <p className="contact-social-text">Telegram</p> </a>
                        </div>
                        <div className="d-flex align-items-center">
                      <a href="#" className='social-icons'style={{background:"rgb(0,119,181)"}} target="_blank"> <FaSkype /></a>
                      <a href="#" > <p className="contact-social-text">Skype</p> </a>
                        </div>
                  </div>
                 
                 
                  {/* <div className="col-lg-4">
                      <div className="social-contact-section">
                        <a href="https://twitter.com/upstrox" className='social-icons'style={{background:"black"}} target="_blank"> <RiTwitterXFill /></a>
                        <p>Twitter</p>
                        </div>
                  </div>
                  <div className="col-lg-4">
                      <div className="social-contact-section">
                      <a href="https://www.facebook.com/Upstrox" className='social-icons'style={{background:"rgb(8,102,255)"}} target="_blank"> <BiLogoFacebookCircle /></a>
                        <p>Facebook</p>
                        </div>
                  </div>
                  <div className="col-lg-6 pt-lg-3">
                      <div className="social-contact-section">
                      <a href="https://www.linkedin.com/company/upstrox-techno-services/?viewAsMember=true" className='social-icons'style={{background:"rgb(0,119,181)"}} target="_blank"> <AiFillLinkedin /></a>
                        <p>Linkedin</p>
                        </div>
                  </div>
                  <div className="col-lg-6 pt-lg-3">
                      <div className="social-contact-section">
                        <a href="https://twitter.com/upstrox" className='social-icons'style={{background:"#ff000045"}} target="_blank"> <AiFillMail /></a>
                        <p>Mail</p>
                        </div>
                  </div> */}
                </div>
                  </div>
                </div>
              </div>
              <div className="col  pt-3 top-column px-4 px-lg-4">
                  <div className="top-color pt-lg-5">
                <form
                  className="position-relative"
                   onSubmit={handleSubmit}
                >



                    <div class="mb-4">
                      <label for="exampleFormControlInput1" class="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        id="floatingInput"
                        class="form-control"
                        name="name"
                        autoComplete="off"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        placeholder="Enter Your Name"
                        invalid={touched.name && errors.name ? true : false}
                      />
                      {touched.name && errors.name && (
                        <small className="invalid-email error password-text-33">
                          {errors.name}
                        </small>
                      )}
                    </div>

                    <div class="mb-4">
                      <label for="exampleFormControlInput1" class="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        id="floatingInput"
                        name="email"
                        autoComplete="off"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        placeholder="Enter Your email"
                        invalid={touched.email && errors.email ? true : false}
                      />
                      {touched.email && errors.email && (
                        <small className="invalid-email error password-text-33">
                          {errors.email}
                        </small>
                      )}
                    </div>

                    <div class="mb-4">
                      <label
                        for="exampleFormControlInput1"
                        class="form-label"
                        placeholder="enter Your Contry"
                        
                      >
                        country
                      </label>

                    <ReactFlagsSelect className=" mb-4 form-control "
                    selected={selectedCountryCode}
                    onSelect={(country_code) => setSelectedCountryCode(country_code)}
                    id="floatingInput"
                    name="country"
                   
                    />
                    </div>


                    <div class="mb-4">
                      <label for="exampleFormControlInput1" class="form-label">
                        Phone Number
                      </label>
                      <PhoneInput
                        class="form-control"
                        id="floatingInput"
                        name="phoneno"
                        autoComplete="off"
                        country={countryCode}
                        value={values.phoneno}
                        onChange={handlePhoneChange}
                        onBlur={handleBlur}
                        invalid={
                          touched.phoneno && errors.phoneno ? true : false
                        }
                      />
                      {touched.phoneno && errors.phoneno && (
                        <small className="invalid-email error password-text-33">
                          {errors.phoneno}
                        </small>
                      )}
                    </div>

                    <div class="mb-4">
                      <label for="exampleFormControlInput1" class="form-label">
                        Select Industry type
                      </label>
                      <select
                        class="form-select"
                        id="floatingSelect"
                        aria-label="Floating label select example"
                        name="requirement"
                        autoComplete="off"
                        value={values.requirement}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={
                          touched.requirement && errors.requirement
                            ? true
                            : false
                        }
                      >
                        <option value="" disabled="disabled">
                          Select your services
                        </option>
                        <option value="Automobile">Automobile</option>
                        <option value="Banking"> Banking</option>
                        <option value="Bio-Technology">Bio-Technology</option>
                        <option value="eCommerce">eCommerce</option>
                        <option value="DeFi Development">
                          DeFi Development
                        </option>
                        <option value="Education & Training">
                          Education & Training
                        </option>
                        <option value="Forming"> Forming</option>
                        <option value="Financial Service">
                          Financial Service
                        </option>
                        <option value="Health Care">Health Care</option>
                        <option value="Information Technology">
                          Information Technology
                        </option>
                        <option value="Insurance">Insurance</option>
                        <option value="Manufacturing"> Manufacturing</option>
                        <option value="Media & Entertainment">
                          Media & Entertainment
                        </option>
                        <option value="Oil & Gas">Oil & Gas</option>
                        <option value="Pharmaceutical"> Pharmaceutical</option>
                        <option value="Real Estate"> Real Estate</option>
                        <option value="Retail"> Retail</option>
                        <option value="Sports"> Sports</option>
                        <option value="TeleCommunication">
                          TeleCommunication
                        </option>
                        <option value="Textile"> Textile</option>
                        <option value="Tourism & Hospitality">
                          Tourism & Hospitality
                        </option>
                        <option value="Transportation"> Transportation</option>

                        <option value="Others"> Others</option>
                      </select>
                      {touched.requirement && errors.requirement && (
                        <small className="invalid-email error password-text-33">
                          {errors.requirement}
                        </small>
                      )}
                    </div>

                    <div class="mb-4">
                      <label for="exampleFormControlInput1" class="form-label">
                        Whatsapp/Telegram/Skype ID
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="floatingInput"
                        name="socialMedia"
                        autoComplete="off"
                        value={values.socialMedia}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        placeholder="Drop your WhatsApp/Telegram/Skype ID for smooth communication"
                        invalid={
                          touched.socialMedia && errors.socialMedia
                            ? true
                            : false
                        }
                      />
                      {touched.socialMedia && errors.socialMedia && (
                        <small className="invalid-email error password-text-33">
                          {errors.socialMedia}
                        </small>
                      )}
                    </div>

                    <div class="mb-4">
                      <label
                        for="exampleFormControlTextarea1"
                        class="form-label"
                      >
                        Write Your Requirements...
                      </label>
                      <textarea
                        class="form-control"
                        placeholder="Write Something ..."
                        id="floatingTextarea2"
                        style={{ height: "120px" }}
                        name="message"
                        row="3"
                        autoComplete="off"
                        value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={
                          touched.message && errors.message ? true : false
                        }
                      ></textarea>
                      {touched.message && errors.message && (
                        <small className="invalid-email error password-text-33">
                          {errors.message}
                        </small>
                      )}
                    </div>

                    <div class="mb-4 mt-lg-5">
                      <button type='submit' className="contact-button">submit</button>
                  
                    </div>
                    <div>
                      <span className="mail-font">
                        Facing troubles? then simply mail us on{" "}
                        <a
                          className="mail-under"
                          href="https://mail.google.com/mail/u/0/?fs=1&to=contact@upstrox.com&su=SUBJECT&body=BODY&tf=cm"
                          target="_blank"
                       >
                          {" "}
                          contact@upstrox.com
                        </a>
                      </span>{" "}
                    </div>


                </form>
                  </div>
              </div>
            
              {/* <div className="col">
              <div className="card mx-lg-5">
                <div className="d-flex gap-3">
                  <div className="p-lg-3"> <HiPhone className="contact-icon"/> </div>
                  <div className=""><p className="card-text-two pt-lg-2">call us directly at </p> 
                  <p className="num-font">+91 867853428</p></div>
                </div>
              </div>
              <div className="card mx-lg-5 mt-lg-5">
                <div className="d-flex gap-3">
                  <div className="p-lg-3"> <AiFillMail className="contact-icon"/> </div>
                  <div className=""><p className="card-text-two pt-lg-2">Mail us directly at </p> 
                  <p className="num-font">Business@upstrox.com</p></div>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Contact;
