import {
  MdArrowDropDown,
  MdOutlineKeyboardDoubleArrowRight,
} from "react-icons/md";
import { HiOutlineBars2 } from "react-icons/hi2";
import Industry from "../Assets/images/nav-dropdown-images/industry.png";
import Medical from "../Assets/images/nav-dropdown-images/medical.png";
import Finance from "../Assets/images/nav-dropdown-images/finance.png";
import Cybersecurity from "../Assets/images/nav-dropdown-images/cyber-security.png";
import Businessservice from "../Assets/images/nav-dropdown-images/business-service.png";
import Agriculture from "../Assets/images/nav-dropdown-images/agriculture.webp";
import Blockchain from "../Assets/images/nav-dropdown-images/blockchain.webp";
import Technology from "../Assets/images/nav-dropdown-images/technology.webp";
import Pitchdeck from "../Assets/pitchdeck.pdf";
import { useEffect, useState } from "react";
import $ from "jquery";
import logo from "../Assets/images/upstrox (1)/Asset 6@4x-8.webp";
import axios from "axios";

function Navbar() {
  var baseURL = "";
  useEffect(() => {
    if (window.location.pathname == "/") {
      baseURL = `https://api.upstrox.com/pages/data/homepage`;
    } else {
      baseURL = `https://api.upstrox.com/pages/data${window.location.pathname}`;
    }

    console.log(baseURL, "baseurl");
    axios.get(baseURL).then((response) => {
      if (response.data != null) {
        console.log(response.data.pageimg, "baseurl");
        var ogurl = document.getElementById("og-image");
        ogurl.setAttribute("content", response.data.pageimg);
        document.getElementById("maintitle").innerHTML =
          response.data.page_meta_title;

        var keywords = document.getElementById("keywords");
        keywords.setAttribute("content", response.data.page_meta_keywords);

        var canonical = document.getElementById("canonical");
        canonical.setAttribute(
          "content",
          `https://www.upstrox.com/${response.data.page_link}`
        );

        var metatitle = document.getElementById("title");
        metatitle.setAttribute("content", response.data.page_meta_title);
        var metadescription = document.getElementById("description");
        metadescription.setAttribute(
          "content",
          response.data.page_meta_description
        );
        var ogimage = document.getElementById("og-image");
        ogimage.setAttribute("content", response.data.pageimg);
        var ogalt = document.getElementById("og-imagealt");
        ogalt.setAttribute("content", response.data.page_meta_title);
        var ogtitle = document.getElementById("og-title");
        ogtitle.setAttribute("content", response.data.page_meta_title);
        var ogdescription = document.getElementById("og-description");
        ogdescription.setAttribute(
          "content",
          response.data.page_meta_description
        );
        var twittertitle = document.getElementById("twitter-title");
        twittertitle.setAttribute("content", response.data.page_meta_title);
        var twitterdescription = document.getElementById("twitter-description");
        twitterdescription.setAttribute(
          "content",
          response.data.page_meta_description
        );
        var twitterimage = document.getElementById("twitter-image");
        twitterimage.setAttribute("content", response.data.pageimg);
      }
    });

    $(document).ready(function () {
      $(window).scroll(function () {
        //Method 1: Using addClass and removeClass
        if ($(document).scrollTop() > 50) {
          $(".navbar").addClass("navbar-shrink-new-ups");
        } else {
          $(".navbar").removeClass("navbar-shrink-new-ups");
        }

        if (window.location.pathname === "/contact") {
          $("#footer-section-hide").hide();
        }
        if (window.location.pathname === "/thankyou") {
          $("#footer-section-hide").hide();
        }
      });
    });

    $(document).ready(function () {
      $(".hsmenu").mouseenter(function () {
        $("body").css("overflow-y", "hidden");
        $("body").css("padding-right", "15px");
        $(".navbar").css("padding-right", "15px");
        $(".upstox-top-new-navbar .dropdown-menu").css("width", "calc(100vw - 15px)");
      });
      $(".hsmenu").mouseleave(function () {
        $("body").css("overflow-y", "scroll");
        $("body").css("padding-right", "0px");
        $(".navbar").css("padding-right", "0px");
        $(".upstox-top-new-navbar .dropdown-menu").css("width", "100%");
      });
    });
  });

  return (
    <div className="App">
      <section className="upstox-top-new-navbar fixed-top">
        <nav class="navbar navbar-expand-lg">
          <div class="container-fluid col-lg-11">
            <a class="navbar-brand" href="/">
              <img src={logo} className="logo-img-upstrox" alt="logo" />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon">
                <HiOutlineBars2 />
              </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                <li class="nav-item dropdown has-megamenu hsmenu">
                  <a class="nav-link " href="#" data-bs-toggle="dropdown">
                    <MdArrowDropDown className="new-navbar-icon-1" />
                    Industries{" "}
                  </a>
                  <div class="dropdown-menu megamenu" role="menu">
                    <h3 className="new-navbar-text-1">Industries</h3>
                    <p className="new-navbar-text-2 mb-lg-4">
                      As industries intersect and new sectors emerge, we are
                      reshaping our strategy, solutions, and platforms. Our
                      innovators and thought leaders are in ongoing discussions
                      with those who wield influence and contribute to the
                      evolution of various industries.
                    </p>
                    <div className="row  row-cols-1 row-cols-lg-5 g-lg-4">
                      <div className="col">
                        <img
                          className="new-mega-drop-images-1"
                          src={Industry}
                          alt="Industry"
                        />
                        <li className="dropdown-item-new-nav">
                          <a href="/aerospace-service-offering">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Aerospace & Defence
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/automotive-services-offering">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Automotive
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/industrial-manufacturing">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Industrial Manufacturing
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/chemical-manufacturing">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Chemical Manufacturing
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/mining">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Mining
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/oil-and-gas">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Oil and Gas
                          </a>
                        </li>
                      </div>
                      <div className="col">
                        <img
                          className="new-mega-drop-images-1"
                          src={Medical}
                          alt="Medical"
                        />
                        <li className="dropdown-item-new-nav">
                          <a href="/healthcare">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Healthcare
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/insurance">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Insurance
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/life-science">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Life Sciences
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/travel-hospitality">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Travel and Hospitality
                          </a>
                        </li>
                      </div>
                      <div className="col">
                        <img
                          className="new-mega-drop-images-1"
                          src={Technology}
                          alt="Technology"
                        />
                        <li className="dropdown-item-new-nav">
                          <a href="/information-service">
                            <div className="d-flex">
                              <div>
                                <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                              </div>
                              <div>Information Services & Publishing</div>
                            </div>
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/engineering-procurement">
                            <div className="d-flex">
                              <div>
                                <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                              </div>
                              <div>Engineering Procurement & Construction</div>
                            </div>
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/communication-service">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Communication Services
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/advanced-driver-assistance-systems">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            ADAS - Technology
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/high-technology">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            High Technology
                          </a>
                        </li>
                      </div>
                      <div className="col">
                        <img
                          className="new-mega-drop-images-1"
                          src={Agriculture}
                          alt="Agriculture"
                        />
                        <li className="dropdown-item-new-nav">
                          <a href="/agriculture">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Agriculture
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/public-sector">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Public Sector
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/education">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Education
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/waste-management">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Waste Management
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/media-and-entertainment">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Media and Entertainment
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/utilities">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Utilities
                          </a>
                        </li>
                      </div>
                      <div className="col">
                        <img
                          className="new-mega-drop-images-1"
                          src={Finance}
                          alt="Finance"
                        />
                        <li className="dropdown-item-new-nav">
                          <a href="/financial-service">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Financial Services
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/private-equity">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Private Equity
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/professional-services">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Professional Services
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/retail">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Retail
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/consumer-packaged">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Consumer Packaged Goods
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/logistics-distribution">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Logistics & Distribution
                          </a>
                        </li>
                      </div>
                    </div>
                  </div>
                </li>

                <li class="nav-item dropdown has-megamenu ms-lg-4 hsmenu">
                  <a class="nav-link " href="#" data-bs-toggle="dropdown">
                    {" "}
                    <MdArrowDropDown className="new-navbar-icon-1" />
                    Services{" "}
                  </a>
                  <div class="dropdown-menu megamenu" role="menu">
                    <h3 className="new-navbar-text-1">Services</h3>
                    <p className="new-navbar-text-2 mb-lg-4">
                      Our service offerings are tailored to meet the evolving
                      needs of our customers. Our array of services spans from
                      strategic design to tangible impact delivery.
                    </p>
                    <div className="row  row-cols-1 row-cols-lg-4 g-lg-4">
                      <div className="col">
                        <img
                          className="new-mega-drop-images-1"
                          src={Cybersecurity}
                          alt="Cybersecurity"
                        />
                        <li className="dropdown-item-new-nav">
                          <a href="/infrastructure-and-cloud-services">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Infrastructure and cloud services
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/network-services">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Network services
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/telecom-product-development">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Telecom product engineering
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/cyber-security">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Cyber Security
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/enterprise-future-services">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Enterprise of future
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/intelligent-automation">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Intelligent Automation
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/oracle-cloud-services">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Oracle
                          </a>
                        </li>
                      </div>
                      <div className="col">
                        <img
                          className="new-mega-drop-images-1"
                          src={Blockchain}
                          alt="Blockchain"
                        />
                        <li className="dropdown-item-new-nav">
                          <a href="/metaverse-development-services">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Metaverse Development
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/dapp-and-defi">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            dApp and DeFi
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/smart-contract-development">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Smart Contract Development
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/blockchain-development-services">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Blockchain & Blockchain Application
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/ai-development-companies">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Artificial intelligence
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/qa-and-software-testing-company">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Testing service
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/integrated-engineering-solutions">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Integrated engineering solutions
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/performance-engineering">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Performance engineering
                          </a>
                        </li>
                      </div>
                      <div className="col">
                        <img
                          className="new-mega-drop-images-1"
                          src={Businessservice}
                          alt="Businessservice"
                        />
                        <li className="dropdown-item-new-nav">
                          <a href="/digital-supply-chain">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Digital supply chain
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/business-process-services">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Business Process Services
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/business-excellence-services">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Business Excellence services
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/experience-design-service">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Experience Design service
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/sap">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            SAP
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/data-analytics-companies">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Data Analystics
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/customer-experience">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Customer experience
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/sustainability-as-a-service">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Sustainability as a service
                          </a>
                        </li>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="nav-item ms-lg-4">
                  <a class="nav-link" href="/blog">
                    <MdArrowDropDown
                      className="new-navbar-icon-1 "
                      style={{ visibility: "hidden" }}
                    />
                    Insights
                  </a>
                </li>

                <li class="nav-item dropdown has-megamenu ms-lg-4 hsmenu" >
                  <a class="nav-link " href="#" data-bs-toggle="dropdown">
                    {" "}
                    <MdArrowDropDown className="new-navbar-icon-1" />
                    About{" "}
                  </a>
                  <div
                    class="dropdown-menu megamenu"
                    role="menu"
                    style={{ minHeight: "auto" }}
                  >
                    <h3 className="new-navbar-text-1">About</h3>
                    <p className="new-navbar-text-2 mb-lg-4">
                      Our service offerings are tailored to meet the evolving
                      needs of our customers. Our array of services spans from
                      strategic design to tangible impact delivery.
                    </p>
                    <div className="row  row-cols-1 row-cols-lg-4 g-lg-4">
                      <div className="col">
                        <li className="dropdown-item-new-nav">
                          <a href="/aboutus">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            About Us
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/sustainability">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Sustainability{" "}
                          </a>
                        </li>
                      </div>
                      <div className="col">
                        <li className="dropdown-item-new-nav">
                          <a href="/">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            News
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href="/">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Infrastructure{" "}
                          </a>
                        </li>
                      </div>
                      <div className="col">
                        <li className="dropdown-item-new-nav">
                          <a href="/">
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Events
                          </a>
                        </li>
                        <li className="dropdown-item-new-nav">
                          <a href={Pitchdeck}>
                            <MdOutlineKeyboardDoubleArrowRight className="new-navbar-icon-2" />
                            Pitchdeck
                          </a>
                        </li>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="nav-item ms-lg-4">
                  <a class="nav-link" href="/career">
                    <MdArrowDropDown
                      className="new-navbar-icon-1 "
                      style={{ visibility: "hidden" }}
                    />
                    Careers
                  </a>
                </li>
                <li class="nav-item ms-lg-4">
                  <a class="nav-link" href="/contact">
                    <MdArrowDropDown
                      className="new-navbar-icon-1 "
                      style={{ visibility: "hidden" }}
                    />
                    Contact us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>
    </div>
  );
}

export default Navbar;
