import { HiArrowUpRight } from "react-icons/hi2";
import infrastructure from "../../Assets/images/servies-images/Infrastructure And Cloud Services webp/images/landing banner copy.webp";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { BiRightArrowAlt } from "react-icons/bi";
import contactnew from "../../Assets/images/servies-images/Infrastructure And Cloud Services webp/images/last image copy.webp";
import infraicon1 from "../../Assets/images/servies-images/Infrastructure And Cloud Services webp/icon 1/Cloud Strategy and Consulting.webp";
import infraicon2 from "../../Assets/images/servies-images/Infrastructure And Cloud Services webp/icon 1/Infrastructure as a Service (IaaS).webp";
import infraicon3 from "../../Assets/images/servies-images/Infrastructure And Cloud Services webp/icon 1/Platform as a Service (PaaS).webp";
import infraicon4 from "../../Assets/images/servies-images/Infrastructure And Cloud Services webp/icon 1/Software as a Service.webp";
import infraicon5 from "../../Assets/images/servies-images/Infrastructure And Cloud Services webp/icon 2/Hybrid Cloud.webp";
import infraicon6 from "../../Assets/images/servies-images/Infrastructure And Cloud Services webp/icon 2/Cloud Migration Services.webp";
import infraicon7 from "../../Assets/images/servies-images/Infrastructure And Cloud Services webp/icon 2/Cloud Backup and Disaster Recovery.webp";
import infraicon8 from "../../Assets/images/servies-images/Infrastructure And Cloud Services webp/icon 2/Cloud Security and Compliance.webp";
import infraicon9 from "../../Assets/images/servies-images/Infrastructure And Cloud Services webp/icon 2/Cloud Monitoring.webp";
import infraicon10 from "../../Assets/images/servies-images/Infrastructure And Cloud Services webp/icon 2/Managed Cloud.webp";
import MetaTags from "../../Metatag";
import { useEffect } from "react";

function InfrastructureandCloudServices() {
  return (
    <>
      {/* <MetaTags
        top="Fueling Growth with Upstrox's Infrastructure & Cloud Services"
        title="Boost Your Business: Upstrox's Infrastructure & Cloud Services "
        description="Upstrox brings you top-notch Infrastructure and Cloud Services for smoother business operations. Upgrade your business now!"
      /> */}
      <div className="Infrastructure-banner-section">
        <section
          className="top-banner-section top-banner-section-overral-bg-section infrastructure-background back-posi-img"
          style={{ backgroundImage: "url(" + infrastructure + ")" }}
        >
          <div className="mobile-new-ups-bg-shade-inner mx-auto container-fluid">
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one">
                    <span className="text-color">
                      Infrastructure and Cloud Services
                    </span>{" "}
                    by Upstrox.
                  </h1>
                  <div className="top-text-two">
                    Upstrox offers comprehensive infrastructure and cloud
                    services designed to meet the evolving needs of businesses.
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <a href="/contact">
                  <button className="top-button right-side">
                    Get More{" "}
                    <MdOutlineArrowForwardIos className="top-icon ms-lg-2" />
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="industrialmanu-new-design-1 py-5">
          <div className="container-fluid col-lg-11 mx-auto py-lg-5">
            <div className="row">
              <div className="col-lg-4">
                <h2 className="aerospace-new-text-1 mb-lg-4 mb-3">
                  Infrastructure and Cloud Services
                </h2>
                <p className="aerospace-new-text-2">
                  We provide robust and scalable solutions that leverage the
                  power of cloud computing, enabling organizations to optimize
                  their IT infrastructure, enhance performance, and drive
                  innovation. Here are the key infrastructure and cloud services
                  we offer
                </p>
              </div>
              <div className="col-lg-8">
                <div className="row row-cols-md-2 row-cols-1 row-cols-lg-2 g-4">
                  <div className="col mt-0 industrialmanu-top-new-card-1">
                    <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                      <div class="card-body">
                        <img
                          className="industrialmanu-icon-1 infra-icon-img"
                          src={infraicon1}
                          alt="Infrastructure"
                        />
                        <h5 className="aerospace-new-text-3">
                          Cloud Strategy and Consulting
                        </h5>
                        <p className="aerospace-new-text-4">
                          Our experienced consultants work closely with your
                          organization to develop a cloud strategy aligned with
                          your business objectives. We assess your existing
                          infrastructure, identify workloads suitable for the
                          cloud, and create a roadmap for a seamless migration
                          to the cloud.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col mt-0 industrialmanu-top-new-card-2">
                    <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                      <div class="card-body">
                        <img
                          className="industrialmanu-icon-1 infra-icon-img"
                          src={infraicon2}
                          alt="Infrastructure"
                        />
                        <h5 className="aerospace-new-text-3">
                          Infrastructure as a Service (IaaS)
                        </h5>
                        <p className="aerospace-new-text-4">
                          We offer IaaS solutions that provide virtualized
                          computing resources, storage, and networking
                          infrastructure on-demand. Our IaaS solutions allow you
                          to scale resources up or down based on your needs,
                          providing flexibility and cost-efficiency while
                          reducing the burden of infrastructure management.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col mt-0 industrialmanu-top-new-card-3">
                    <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                      <div class="card-body">
                        <img
                          className="industrialmanu-icon-1 infra-icon-img"
                          src={infraicon3}
                          alt="Infrastructure"
                        />
                        <h5 className="aerospace-new-text-3">
                          Platform as a Service (PaaS):
                        </h5>
                        <p className="aerospace-new-text-4">
                          Our PaaS solutions provide a complete development and
                          deployment environment in the cloud. You can build,
                          test, and deploy applications quickly without worrying
                          about the underlying infrastructure. Our PaaS
                          offerings streamline the application development
                          lifecycle and promote collaboration among development
                          teams.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col mt-0 industrialmanu-top-new-card-4">
                    <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                      <div class="card-body">
                        <img
                          className="industrialmanu-icon-1 infra-icon-img"
                          src={infraicon4}
                          alt="Infrastructure"
                        />
                        <h5 className="aerospace-new-text-3">
                          Software as a Service (SaaS)
                        </h5>
                        <p className="aerospace-new-text-4">
                          {" "}
                          We provide a range of SaaS solutions that deliver
                          software applications over the internet. With our SaaS
                          offerings, you can access and use applications without
                          the need for installation or maintenance. We offer a
                          diverse portfolio of industry-leading SaaS
                          applications to enhance productivity and efficiency
                          across various business functions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="industrialmanu-new-design-2 py-5">
          <div className="container-fluid col-lg-10 mx-auto">
            <div className="row row-cols-1 row-cols-lg-3 g-4 justify-content-center">
              <div className="col industrialmanu-new-card-1">
                <div class="card h-100">
                  <div class="card-body">
                    <img
                      className="industrialmanu-icon-1 infra-icon-img"
                      src={infraicon5}
                      alt="Infrastructure"
                    />
                    <h5 class="automotive-new-text-1">
                      Hybrid Cloud Solutions
                    </h5>
                    <p class="automotive-new-text-3">
                      {" "}
                      We specialize in designing and implementing hybrid cloud
                      solutions that seamlessly integrate on-premises
                      infrastructure with public and private clouds. Our hybrid
                      cloud solutions enable you to leverage the benefits of
                      both environments, ensuring data security, compliance, and
                      scalability.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col industrialmanu-new-card-2">
                <div class="card h-100">
                  <div class="card-body">
                    <img
                      className="industrialmanu-icon-1 infra-icon-img"
                      src={infraicon6}
                      alt="Infrastructure"
                    />
                    <h5 class="automotive-new-text-1">
                      Cloud Migration Services
                    </h5>
                    <p class="automotive-new-text-3">
                      {" "}
                      Our cloud migration services facilitate a smooth
                      transition of your existing applications and
                      infrastructure to the cloud. We follow best practices to
                      minimize downtime, ensure data integrity, and optimize
                      performance during the migration process. Our experts
                      handle the complexities of migration, allowing you to
                      focus on your core business.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col industrialmanu-new-card-3">
                <div class="card h-100">
                  <div class="card-body">
                    <img
                      className="industrialmanu-icon-1 infra-icon-img"
                      src={infraicon7}
                      alt="Infrastructure"
                    />
                    <h5 class="automotive-new-text-1">
                      Cloud Backup and Disaster Recovery
                    </h5>
                    <p class="automotive-new-text-3">
                      We offer robust cloud backup and disaster recovery
                      solutions to protect your critical data and ensure
                      business continuity. Our solutions provide automated
                      backups, off-site storage, and rapid recovery
                      capabilities, minimizing the impact of data loss or system
                      failures.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col industrialmanu-new-card-4 mt-0">
                <div class="card h-100">
                  <div class="card-body">
                    <img
                      className="industrialmanu-icon-1 infra-icon-img"
                      src={infraicon8}
                      alt="Infrastructure"
                    />
                    <h5 class="automotive-new-text-1">
                      Cloud Security and Compliance
                    </h5>
                    <p class="automotive-new-text-3">
                      {" "}
                      We prioritize the security of your cloud environment and
                      data. Our cloud security solutions include identity and
                      access management, encryption, threat detection, and
                      vulnerability assessments. We ensure compliance with
                      industry-specific regulations and standards, safeguarding
                      your sensitive information.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col industrialmanu-new-card-5 mt-0">
                <div class="card h-100">
                  <div class="card-body">
                    <img
                      className="industrialmanu-icon-1 infra-icon-img"
                      src={infraicon9}
                      alt="Infrastructure"
                    />
                    <h5 class="automotive-new-text-1">
                      Cloud Monitoring and Performance Optimization
                    </h5>
                    <p class="automotive-new-text-3">
                      Our cloud monitoring and performance optimization services
                      enable proactive monitoring, alerting, and fine-tuning of
                      your cloud infrastructure. We provide real-time insights
                      into system performance, resource utilization, and cost
                      optimization, ensuring optimal efficiency and
                      cost-effectiveness.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col industrialmanu-new-card-6 mt-0">
                <div class="card h-100">
                  <div class="card-body">
                    <img
                      className="industrialmanu-icon-1 infra-icon-img"
                      src={infraicon10}
                      alt="Infrastructure"
                    />
                    <h5 class="automotive-new-text-1">
                      Managed Cloud Services
                    </h5>
                    <p class="automotive-new-text-3">
                      We offer comprehensive managed cloud services, taking care
                      of the day-to-day management and maintenance of your cloud
                      infrastructure. Our experts handle routine tasks, monitor
                      performance, and address any issues, allowing you to focus
                      on your core business objectives.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 flex-lg-row-reverse">
              <div className="col-lg-4">
                <img src={contactnew} alt="Infrastructure" />
              </div>
              <div className="col-lg-8">
                <p className="aerospace-new-text-5 mb-lg-4">
                  At Upstrox, we are committed to delivering reliable and
                  cutting-edge infrastructure and cloud services to help
                  organizations harness the power of cloud computing.{" "}
                </p>
                <a href="/contact" className="new-ups-button-2">
                  <span className="new-ups-button-icon-3">Read More</span>{" "}
                  <BiRightArrowAlt className="new-ups-button-icon-2" />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="aerospace-new-text-6 mb-lg-4">
                  Contact us today to discuss your specific requirements and
                  explore how Upstrox can empower your organization's digital
                  transformation and drive business success with our
                  industry-leading IT services.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default InfrastructureandCloudServices;
