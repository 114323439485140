import { FaArrowRightLong } from "react-icons/fa6";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Dummybg from "../../Assets/images/icon/private equity/private equity landing banner.webp";
import customercardimg from "../../Assets/images/icon/private equity/private equity image 1.webp";
import dataimg from "../../Assets/images/icon/private equity/private equity image 2.webp";
import { BiRightArrowAlt } from 'react-icons/bi';
import privateicon1 from "../../Assets/images/icon/private equity/Deal Flow Management.webp";
import privateicon2 from "../../Assets/images/icon/private equity/Due Diligence and Data Room Solutions.webp";
import privateicon3 from "../../Assets/images/icon/private equity/Portfolio Management Systems.webp";
import privateicon4 from "../../Assets/images/icon/private equity/Document Management and Workflow Automation.webp";
import privateicon5 from "../../Assets/images/icon/private equity/CRM and Investor Management.webp";
import privateicon6 from "../../Assets/images/icon/private equity/IT Consulting and Strategy.webp";


function PrivateEquity() {
  return (
    <>
      <div className="privateequity-banner-section">
        <section
          className="top-banner-section top-banner-section-overral-bg-section private-equality-background back-posi-img"
          style={{ backgroundImage: "url(" + Dummybg + ")" }}
        >
          <div className="mobile-new-ups-bg-shade-inner mx-auto container-fluid">
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one">
                    <span className="text-color"> Private Equity </span> IT
                    Solutions by Upstrox
                  </h1>
                  <div className="top-text-two">
                    Upstrox is your trusted partner for comprehensive IT
                    solutions tailored specifically for the private equity
                    industry. We understand the unique challenges and
                    requirements of private equity firms, including deal
                    sourcing, due diligence, portfolio management, and investor
                    relations.
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <a href="/contact">
                  <button className="top-button right-side">
                    Get More{" "}
                    <MdOutlineArrowForwardIos className="top-icon ms-lg-2" />
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="customercard-section pt-5">
          <div className="container">
            <div className="row py-lg-5 pt-3 d-flex align-items-center">
              <div className="col-lg-3 col-md-3">
                <h4 className="card-text-one customercard-heading">
                  Private Equity
                </h4>
              </div>
              <div className="col-lg-1 col-md-2 card-hide">
                <div className="">
                  <FaArrowRightLong className="customer-arrow" />
                </div>
              </div>
              <div className="col">
                <h5 className="card-text-two text-ho">
                  Our expertise in technology and domain knowledge enables us to
                  deliver innovative solutions that enhance operational
                  efficiency, data management, and decision-making. Here are the
                  key IT services we offer
                </h5>
              </div>
            </div>
          </div>
        </section>

        <section className="datacard-section-two py-3">
          <div className="container">
            <div className="row align-items-end g-4">
              <div className="col-lg-4 col-md-4">
                <div className="card px-lg-3 py-lg-1 p-md-1 h-100">
                  <div className="text-center">
                    <img
                      src={privateicon1}
                      alt="privateequity"
                      className="datacard-img"
                    />
                  </div>
                  <h4 className="card-text-one">
                  Deal Flow Management
                  </h4>
                  <h5 className="card-text-two text-ho">
                  We provide deal flow management solutions that streamline the deal sourcing and evaluation process. Our solutions enable efficient deal tracking, document management, collaboration, and workflow automation, improving the speed and accuracy of deal evaluations
                  </h5>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="card px-lg-3 py-lg-3 p-md-2 h-100">
                  <div className="text-center">
                    <img
                      src={privateicon2}
                      alt="privateequity"
                      className="datacard-img"
                    />
                  </div>
                  <h4 className="card-text-one">
                  Due Diligence and Data Room Solutions
                  </h4>
                  <h5 className="card-text-two text-ho">
                  We offer due diligence and data room solutions that facilitate secure sharing and management of confidential documents and data during the due diligence process. Our solutions provide controlled access, version control, and audit trails, ensuring compliance and data security.
                  </h5>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="card px-lg-3 py-lg-5 p-md-4 h-100">
                  <div className="text-center">
                    <img
                      src={privateicon3}
                      alt="privateequity"
                      className="datacard-img"
                    />
                  </div>
                  <h4 className="card-text-one">
                  Portfolio Management Systems
                  </h4>
                  <h5 className="card-text-two text-ho">
                  We provide portfolio management systems that centralize and optimize the management of private equity portfolios. Our systems enable performance tracking, financial analysis, valuation modeling, and reporting, empowering portfolio managers with actionable insights for decision-making.

                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="customercard-section-two py-lg-5 py-md-5">
          <div className="container-fluid col-lg-10">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-4">
                <div>
                  <img src={customercardimg} className="customer-card-img" alt="privateequity"/>
                </div>
              </div>
              <div className="col-lg-8 col-md-8">
                <div className="container">
                  <div className="row pt-lg-5 py-2">
                    <div className="col-lg-6 col-md-6">
                      <div className="card bg-transparent text-white">
                        <h4 className="card-text-one">
                        Investor Relations Platforms
                        </h4>
                        <h5 className="card-text-two text-ho">
                        We offer investor relations platforms that enhance communication and transparency between private equity firms and investors. Our platforms enable investor reporting, document sharing, secure communication, and performance analytics, enhancing investor satisfaction and trust.
                        </h5>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="card bg-transparent text-white">
                        <h4 className="card-text-one">
                        Fund Administration Systems
                        </h4>
                        <h5 className="card-text-two text-ho">
                        We provide fund administration systems that automate and streamline fund accounting, investor allocations, and financial reporting. Our systems integrate with various financial systems, enabling accurate and timely reporting to investors and regulatory compliance.

                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-lg-5 py-2">
                    <div className="col-lg-6 col-md-6">
                      <div className="card bg-transparent text-white">
                        <h4 className="card-text-one">
                        Data Analytics and Business Intelligence
                        </h4>
                        <h5 className="card-text-two text-ho">
                        Our data analytics and business intelligence solutions help private equity firms derive valuable insights from their data. We assist in analyzing investment performance, portfolio trends, market data, and benchmarking, enabling data-driven decision-making and proactive portfolio management.

                        </h5>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="card bg-transparent text-white">
                        <h4 className="card-text-one">
                        Cybersecurity and Data Protection
                        </h4>
                        <h5 className="card-text-two text-ho">
                        Protecting sensitive financial data, investor information, and deal-related data is crucial in the private equity industry. We provide comprehensive cybersecurity services, including network security, access controls, data encryption, and vulnerability assessments. Our solutions help safeguard your valuable assets and protect against cyber threats.

                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="aerospace-new-design-2">
          <div className="container-fluid col-lg-11 mx-auto">
            <div className="row row-cols-md-1 row-cols-1 row-cols-lg-3 g-4 py-lg-5">
              <div className="col aerospace-new-design-2-inner-1">
                <img
                  src={privateicon4}
                  alt="privateequity"
                  className="aerospace-new-image-2"
                />
                <h5 className="aerospace-new-text-3">
                Document Management and Workflow Automation
                </h5>
                <p className="aerospace-new-text-4">
                We offer document management and workflow automation solutions to streamline document-intensive processes, such as deal execution, compliance, and reporting. Our solutions automate document generation, approval workflows, and compliance checks, improving efficiency and reducing manual errors.

                </p>
              </div>
              <div className="col aerospace-new-design-2-inner-2">
                <img
                  src={privateicon5}
                  alt="privateequity"
                  className="aerospace-new-image-2"
                />
                <h5 className="aerospace-new-text-3">
                CRM and Investor Management
                </h5>
                <p className="aerospace-new-text-4">
                We provide CRM and investor management solutions that help private equity firms manage relationships with investors, track fundraising efforts, and monitor investor communications. Our solutions enable centralized investor data, email campaign management, and investor portal access, enhancing investor engagement and satisfaction.

                </p>
              </div>
              <div className="col aerospace-new-design-2-inner-3">
                <img
                  src={privateicon6}
                  alt="privateequity"
                  className="aerospace-new-image-2"
                />
                <h5 className="aerospace-new-text-3">
                IT Consulting and Strategy
                </h5>
                <p className="aerospace-new-text-4">
                Our experienced consultants provide IT consulting services to private equity firms, helping develop technology strategies, optimize workflows, and implement digital transformation initiatives. We assist in technology selection, vendor management, and leveraging emerging technologies to drive operational efficiency and growth.

                </p>
              </div>
            </div>
          </div>
        </section>

       
        <section className="logistic-last-section my-lg-5">
          <div className="container py-3">
            <div className="row flex-row-reverse">
              <div className="col-lg-4 col-md-4">
                <img src={dataimg} alt="privateequity" />
              </div>
              <div className="col-lg-8 col-md-8">
                <div className="pt-lg-5">
                  <p className="">
                  At Upstrox, we are committed to supporting private equity firms with innovative and reliable IT solutions. 
                  </p>
                </div>
                <div>
                  <a className="new-ups-button-2" href="/contact">
                    <span className="new-ups-button-icon-3">Read More</span>{" "}
                    <BiRightArrowAlt className="new-ups-button-icon-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="aerospace-new-text-6 mb-lg-4">
                Contact us today to discuss your specific requirements and explore how Upstrox can empower your organization's operations, investor relations, and growth with our industry-leading IT services.

                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default PrivateEquity;
