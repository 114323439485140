import iconadas1 from "../../Assets/images/icon/Advanced Driver Assistance Systems ADAS/ADAS Integration and Development.webp";
import iconadas2 from "../../Assets/images/icon/Advanced Driver Assistance Systems ADAS/Sensor Calibration and Testing.webp";
import iconadas3 from "../../Assets/images/icon/Advanced Driver Assistance Systems ADAS/ADAS Software Development.webp";
import iconadas4 from "../../Assets/images/icon/Advanced Driver Assistance Systems ADAS/ADAS Validation and Verification.webp";
import iconadas5 from "../../Assets/images/icon/Advanced Driver Assistance Systems ADAS/Data Analytics and Machine Learning.webp";
import iconadas6 from "../../Assets/images/icon/Advanced Driver Assistance Systems ADAS/ADAS Maintenance and Support.webp";
import iconadas7 from "../../Assets/images/icon/Advanced Driver Assistance Systems ADAS/ADAS Training and Education.webp";
import iconadas8 from "../../Assets/images/icon/Advanced Driver Assistance Systems ADAS/ADAS Consulting and Strategy.webp";
import iconadas9 from "../../Assets/images/icon/Advanced Driver Assistance Systems ADAS/Cybersecurity and Data Protection.webp";
import iconadas10 from "../../Assets/images/icon/Advanced Driver Assistance Systems ADAS/Industry Collaboration and Partnerships.webp";


import { MdOutlineArrowForwardIos } from "react-icons/md";
import Adasbg from "../../Assets/images/servies-images/adas.webp"
import ContactNew from "../../Assets/images/icon/Advanced Driver Assistance Systems ADAS/adas image.webp"
import { BiRightArrowAlt } from "react-icons/bi";
import MetaTags from "../../Metatag";
import { useEffect } from "react";

function AdvancedDriverAssistanceSystems() {

  return (
    <>
      <div className="adas-banner-section">
      <MetaTags
        title="advance"
        description="Description of advance"
        // Add more props for additional meta tags
      />
        <section className="top-banner-section top-banner-section-overral-bg-section back-posi-img" style={{ backgroundImage: "url(" + Adasbg + ")" }}>
          <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one">Advanced Driver Assistance Systems
                    <span className="text-color"> (ADAS) </span>  Development Services
                  </h1>
                  <div className="top-text-two">
                    Upstrox is proud to offer comprehensive services for Advanced Driver Assistance Systems (ADAS) to enhance safety and efficiency on the roads. ADAS technologies are revolutionizing the automotive industry by providing intelligent features that assist drivers and mitigate potential risks.
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
              </div>
            </div>
          </div>
        </section>

        <section className="top-banner-section-two py-5">
          <div className="container">
            <div className="row">
              <div className="col">
                <h5 className="text-one">
                  Our ADAS services cover a wide range of solutions to support vehicle manufacturers, suppliers, and fleet operators. Here's how Upstrox can help
                </h5>
              </div>
            </div>
          </div>
        </section>

        <section className="adas-card-section">
          <div className="container-fluid col-lg-10">
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4  pt-lg-4 justify-content-center">
              <div className="col pt-3 pt-lg-0">
                <div className=" card h-100 ">
                  <div className="card-body bg-green-box container-card">
                    <div className="px-lg-3 pb-lg-3"> <img src={iconadas1} alt="adas" className="adas-icon mb-lg-0 mb-3 " style={{ "background": "linear-gradient(71deg, #0d1212, #3da077, #0d1212)", "border": "1px solid #3da077" }} /> </div>
                    <h4 className="card-text-one px-lg-3">
                      ADAS Integration and Development
                    </h4>
                    <h5 className="card-text-two px-lg-3">
                      We specialize in integrating ADAS technologies into vehicles, including sensors, cameras, radar systems, and control units. Our skilled engineers and developers work closely with manufacturers and suppliers to ensure seamless integration of ADAS features, enhancing vehicle safety and performance.
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col pt-3 pt-lg-0">
                <div className="card h-100 topcard ">
                  <div className="  card-body bg-white-box container-card">
                    <div className="px-lg-3 pb-lg-3"> <img src={iconadas2} alt="adas" className="adas-icon mb-lg-0 mb-3 " style={{ "background": "linear-gradient(71deg, #121013, #b0afb0, #121013)", "border": "1px solid #b0afb0" }} /> </div>
                    <h4 className="card-text-one px-lg-3">
                      Sensor Calibration and Testing
                    </h4>
                    <h5 className="card-text-two px-lg-3">
                      We provide sensor calibration and testing services to ensure accurate and reliable operation of ADAS systems. Our experts use advanced calibration equipment and techniques to align sensors properly, validate their functionality, and optimize their performance according to industry standards.
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col pt-3 pt-lg-0">
                <div className=" card h-100">
                  <div className=" card-body bg-yellow-box container-card ">
                    <div className="px-lg-3 pb-lg-3"> <img src={iconadas3} alt="adas" className="adas-icon mb-lg-0 mb-3 " style={{ "background": "linear-gradient(71deg, #110e0e, #afa220, #110e0e)", "border": "1px solid #afa220" }} /> </div>
                    <h4 className="card-text-one px-lg-3">
                      ADAS Software Development
                    </h4>
                    <h5 className="card-text-two px-lg-3">
                      Our software development team creates custom ADAS software solutions tailored to specific requirements. We develop algorithms, implement machine learning models, and optimize software performance to deliver advanced functionalities such as lane departure warning, adaptive cruise control, collision avoidance, and pedestrian detection.
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col pt-3 pt-lg-0">
                <div className=" card h-100">
                  <div className="card-body bg-blue-box container-card">
                    <div className="px-lg-3 pb-lg-3"> <img src={iconadas4} alt="adas" className="adas-icon mb-lg-0 mb-3 " style={{ "background": "linear-gradient(71deg, #0c0a0e, #5f6fad, #0c0a0e)", "border": "1px solid #5f6fad" }} /> </div>
                    <h4 className="card-text-one px-lg-3">
                      ADAS Validation and Verification
                    </h4>
                    <h5 className="card-text-two px-lg-3">
                      We conduct rigorous validation and verification tests to ensure the effectiveness and safety of ADAS features. Our comprehensive testing processes include real-world scenarios, simulation environments, and data analysis to validate system behavior, accuracy, and response in various driving conditions.
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col pt-3 pt-lg-0">
                <div className=" card h-100 ">
                  <div className=" card-body bg-green-box container-card">
                    <div className="px-lg-3 pb-lg-3"> <img src={iconadas5} alt="adas" className="adas-icon mb-lg-0 mb-3 " style={{ "background": "linear-gradient(71deg, #0d1212, #3da077, #0d1212)", "border": "1px solid #3da077" }} /> </div>
                    <h4 className="card-text-one px-lg-3">
                      Data Analytics and Machine Learning
                    </h4>
                    <h5 className="card-text-two px-lg-3">
                      Our data analytics and machine learning expertise enable us to extract meaningful insights from ADAS data. We help manufacturers and fleet operators analyze large datasets to improve system performance, identify usage patterns, and make data-driven decisions for continuous improvement.
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col pt-3 pt-lg-0">
                <div className=" card h-100">
                  <div className="  card-body bg-white-box container-card">
                    <div className="px-lg-3 pb-lg-3"> <img src={iconadas6} alt="adas" className="adas-icon mb-lg-0 mb-3 " style={{ "background": "linear-gradient(71deg, #121013, #b0afb0, #121013)", "border": "1px solid #b0afb0" }} /> </div>
                    <h4 className="card-text-one px-lg-3">
                      ADAS Maintenance and Support
                    </h4>
                    <h5 className="card-text-two px-lg-3">
                      We offer ongoing maintenance and support services for ADAS systems. Our team provides timely updates, troubleshooting assistance, and system upgrades to ensure optimal performance, reliability, and compliance with evolving industry standards.
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col pt-3 pt-lg-0">
                <div className=" card h-100 ">
                  <div className=" card-body bg-yellow-box container-card">
                    <div className="px-lg-3 pb-lg-3"> <img src={iconadas7} alt="adas" className="adas-icon mb-lg-0 mb-3 " style={{ "background": "linear-gradient(71deg, #110e0e, #afa220, #110e0e)", "border": "1px solid #afa220" }} /> </div>
                    <h4 className="card-text-one px-lg-3">
                      ADAS Training and Education
                    </h4>
                    <h5 className="card-text-two px-lg-3">
                      We provide training and educational programs to drivers, fleet operators, and technicians to familiarize them with ADAS technologies. Our programs cover system operation, maintenance best practices, and safety guidelines, empowering individuals to make the most of ADAS features.

                    </h5>
                  </div>
                </div>
              </div>
              <div className="col pt-3 pt-lg-0">
                <div className=" card h-100 ">
                  <div className=" card-body bg-blue-box container-card">
                    <div className="px-lg-3 pb-lg-3"> <img src={iconadas8} alt="adas" className="adas-icon mb-lg-0 mb-3 " style={{ "background": "linear-gradient(71deg, #0c0a0e, #5f6fad, #0c0a0e)", "border": "1px solid #5f6fad" }} /> </div>
                    <h4 className="card-text-one px-lg-3">
                      ADAS Consulting and Strategy
                    </h4>
                    <h5 className="card-text-two px-lg-3">
                      Our experienced consultants offer ADAS consulting services, assisting organizations in developing ADAS strategies, roadmaps, and implementation plans. We provide guidance on technology selection, regulatory compliance, and integration with existing automotive systems.
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col pt-3 pt-lg-0">
                <div className=" card h-100 ">
                  <div className="card-body bg-green-box container-card">
                    <div className="px-lg-3 pb-lg-3"> <img src={iconadas9} alt="adas" className="adas-icon mb-lg-0 mb-3 " style={{ "background": "linear-gradient(71deg, #0d1212, #3da077, #0d1212)", "border": "1px solid #3da077" }} /> </div>
                    <h4 className="card-text-one px-lg-3">
                      Cybersecurity and Data Protection
                    </h4>
                    <h5 className="card-text-two px-lg-3">
                      We prioritize cybersecurity and data protection for ADAS systems. Our comprehensive cybersecurity services include network security, intrusion detection, secure communication protocols, and data encryption, safeguarding ADAS systems from potential threats and vulnerabilities.
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col pt-3 pt-lg-0">
                <div className=" card h-100">
                  <div className=" card-body container-card bg-white-box">
                    <div className="px-lg-3 pb-lg-3"> <img src={iconadas10} alt="adas" className="adas-icon mb-lg-0 mb-3 " style={{ "background": "linear-gradient(71deg, #121013, #b0afb0, #121013)", "border": "1px solid #b0afb0" }} /> </div>
                    <h4 className="card-text-one px-lg-3">
                      Industry Collaboration and Partnerships
                    </h4>
                    <h5 className="card-text-two px-lg-3">
                      Upstrox actively collaborates with industry partners, research institutions, and regulatory bodies to stay at the forefront of ADAS technology advancements. By forging partnerships, we ensure that our clients benefit from the latest developments and adhere to industry best practices.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 flex-lg-row-reverse align-items-center">
              <div className="col-lg-4">
                <img src={ContactNew} alt="Adas" />
              </div>
              <div className="col-lg-8">
                <p className="aerospace-new-text-5 mb-lg-4">At Upstrox, we are committed to advancing ADAS technology to improve road safety and transform the driving experience. Our ADAS services cater to the unique needs of vehicle manufacturers, suppliers, and fleet operators, helping them harness the power of intelligent automotive technologies. </p>
                <a href='/contact' className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="aerospace-new-text-6 mb-lg-4">Contact us today to discuss your ADAS requirements and explore how Upstrox can support your organization's goals efficiently and effectively.</p>
              </div>
            </div>
          </div>
        </section>

      </div>
    </>
  )
}
export default AdvancedDriverAssistanceSystems;