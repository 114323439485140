import { MdOutlineArrowForwardIos } from "react-icons/md";
import sustain from "../../Assets/images/servies-images/Business Process Services/images/last image copy.webp";
import { BiRightArrowAlt } from "react-icons/bi";

import busiicoon1 from "../../Assets/images/servies-images/Business Process Services/icon/icon copy.webp";
import busiicoon2 from "../../Assets/images/servies-images/Business Process Services/icon/robotic-process-automation (1).webp";
import busiicoon3 from "../../Assets/images/servies-images/Business Process Services/icon/Data Management and Analytics.webp";
import busiicoon4 from "../../Assets/images/servies-images/Business Process Services/icon/Document Management.webp";
import busiicoon5 from "../../Assets/images/servies-images/Business Process Services/icon 2/Procurement and Vendor Management.webp";
import busiicoon6 from "../../Assets/images/servies-images/Business Process Services/icon 2/IT Helpdesk and Technical Support.webp";
import busiicoon7 from "../../Assets/images/servies-images/Business Process Services/icon 2/Quality Assurance and Compliance.webp";
import { useEffect } from "react";

function BusinessProcessServices() {
    
    return (
        <>
            <div className="business-process">
                <section className=" top-banner-section-overral-bg-section businessprocess-background back-posi-img">
                    <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid top-banner-section'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1 className="top-text-one"><span className="text-color">Business Process Services</span> by Upstrox</h1>
                                    <div className="top-text-two">Upstrox offers comprehensive Business Process Services (BPS) designed to streamline and optimize your organization's business processes, drive operational efficiency, and enhance overall productivity.</div>
                                </div>
                            </div>
                            <div className="pt-3">
                                <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="sustaincard-section">
                    <section className="sustaincard-section-one py-5">
                        <div className="container">
                            <h5 className="text-one">Our BPS solutions are tailored to meet the unique needs of your industry and can be customized to align with your specific business objectives. Here are some key Business Process Services we provide</h5>
                            <div className="row pt-lg-5 pt-3 d-flex align-items-center">
                                <div className="col-lg-1 col-md-2">
                                    <div className=""><img src={busiicoon1} className="busi-icon-img sustain-icon" alt="business-process"/></div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <h4 className="card-text-one">Process Consulting and Transformation</h4></div>
                                <div className="col">
                                    <h5 className="card-text-two">
                                    Our experts assess your existing business processes, identify areas for improvement, and develop strategies for process optimization and transformation. We work closely with your organization to design efficient and streamlined processes that align with industry best practices and drive operational excellence.
                                    </h5>
                                </div>
                            </div>
                            <div className="row pt-lg-5 pt-3 d-flex align-items-center">
                                <div className="col-lg-1 col-md-2">
                                    <div className=""><img src={busiicoon2} className="busi-icon-img sustain-icon" alt="business-process"/></div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <h4 className="card-text-one">Process Automation</h4></div>
                                <div className="col">
                                    <h5 className="card-text-two">
                                    We help organizations automate repetitive and manual tasks through Robotic Process Automation (RPA) and intelligent automation solutions. Our experts identify automation opportunities, develop automation strategies, and implement automation technologies to improve process efficiency, accuracy, and speed.
                                    </h5>
                                </div>
                            </div>
                            <div className="row pt-lg-5 pt-3 d-flex align-items-center">
                                <div className="col-lg-1 col-md-2">
                                    <div className=""><img src={busiicoon3} className="busi-icon-img sustain-icon" alt="business-process"/></div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <h4 className="card-text-one">Data Management and Analytics</h4></div>
                                <div className="col">
                                    <h5 className="card-text-two">
                                    We assist organizations in managing and analyzing large volumes of data to derive valuable insights and support data-driven decision-making. Our experts provide data integration, cleansing, and visualization services, enabling you to make informed business decisions and optimize your processes based on data-driven insights.
                                    </h5>
                                </div>
                            </div>
                            <div className="row pt-lg-5 pt-3 d-flex align-items-center">
                                <div className="col-lg-1 col-md-2">
                                    <div className=""><img src={busiicoon4} className="busi-icon-img sustain-icon" alt="business-process"/></div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <h4 className="card-text-one">Document Management</h4></div>
                                <div className="col">
                                    <h5 className="card-text-two">
                                    We help organizations streamline their document management processes through digitization, indexing, and document workflow automation. Our experts implement document management systems, enabling secure storage, retrieval, and sharing of documents, while ensuring compliance with regulatory requirements.
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sustaincard-section-two py-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 py-2">
                                    <div className="card h-100 p-4">

                                        <h4 className="card-text-one">Customer Support and Contact Center Services</h4>
                                        <h5 className="card-text-two">
                                        We provide customer support and contact center services to help organizations deliver exceptional customer experiences. Our dedicated teams handle customer inquiries, provide technical support, manage customer relationships, and ensure prompt and efficient issue resolution.
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 py-2">
                                    <div className="card h-100 p-4" style={{ backgroundColor: "rgba(46, 46, 46, 0.199)" }}>

                                        <h4 className="card-text-one">Finance and Accounting Services</h4>
                                        <h5 className="card-text-two">
                                        We offer end-to-end finance and accounting services to help organizations streamline their financial operations. Our services include accounts payable and receivable management, financial reporting, payroll processing, tax preparation, and compliance management.
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 py-2">
                                    <div className="card h-100 p-4">

                                        <h4 className="card-text-one">Human Resources Outsourcing</h4>
                                        <h5 className="card-text-two">
                                        We provide comprehensive human resources outsourcing services, including employee onboarding, payroll administration, benefits administration, performance management, and HR compliance. Our experts ensure accurate and efficient HR processes, allowing you to focus on strategic HR initiatives.
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sustaincard-section-three py-3">
                        <div className="container">
                            <div className="row d-flex justify-content-center pt-lg-5">
                                <div className="col">
                                    <div className="card h-100 p-3 text-center">
                                        <div className="pb-3"><img src={busiicoon5} alt="business-process" className="sustain-card-img " /></div>
                                        <h4 className="card-text-one">Procurement and Vendor Management</h4>
                                        <h5 className="card-text-two">
                                        We assist organizations in optimizing their procurement and vendor management processes. Our services include supplier selection, contract management, purchase order processing, invoice management, and vendor performance evaluation, enabling cost savings and improved supplier relationships.
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center pb-lg-5">
                                <div className="col-lg-6 col-md-6">
                                    <div className="card h-100 p-3 text-center">
                                        <div className="pb-3"><img src={busiicoon6} alt="business-process" className="sustain-card-img " /></div>
                                        <h4 className="card-text-one">IT Helpdesk and Technical Support</h4>
                                        <h5 className="card-text-two">
                                        We offer IT helpdesk and technical support services to ensure smooth and efficient IT operations within your organization. Our skilled professionals provide remote assistance, troubleshooting, and issue resolution for IT infrastructure, software applications, and hardware devices.
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="card h-100 p-3 text-center">
                                        <div className="pb-3"><img src={busiicoon7} alt="business-process" className="sustain-card-img" /></div>
                                        <h4 className="card-text-one">Quality Assurance and Compliance</h4>
                                        <h5 className="card-text-two">
                                        We help organizations implement quality assurance and compliance programs to ensure adherence to industry regulations and standards. Our experts perform audits, develop quality control frameworks, and monitor compliance to help you achieve and maintain high-quality standards in your processes.
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sustaincard-section-four py-3 py-lg-5">
                        <div className="container">
                            <div className="row d-flex align-items-center g-4">
                                <div className="col-lg-8 col-md-8"><p className="service-footer-text mb-4">At Upstrox, we are committed to optimizing your business processes, driving efficiency, and enhancing your organization's overall performance.</p>
                                    <a href="contact" className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
                                </div>
                                <div className="col-lg-4 col-md-4"><img src={sustain} alt="business-process" className="service-footer-img"></img></div>
                            </div>
                        </div>
                    </section>
                    <section className="sustaincard-section-five pt-5">
                        <div className="container">
                            <div className="row g-4 justify-content-center text-center">
                                <div className="col-lg-12">
                                    <p className="contact-text mb-lg-4">Contact us today to discuss your Business Process Services requirements and explore how Upstrox can assist your organization in achieving operational excellence and delivering superior business outcomes.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        </>
    )
}
export default BusinessProcessServices;