import { MdOutlineArrowForwardIos } from "react-icons/md";
import Dummybg from "../../Assets/images/servies-images/demo/dummy-3.webp"

import datavisualization from "../../Assets/images/servies-images/dataanalytics/datavisualization.webp";
import analysis from "../../Assets/images/servies-images/dataanalytics/analysis.webp";
import textanalysis from "../../Assets/images/servies-images/dataanalytics/textanalysis.webp";
import dataimg from "../../Assets/images/servies-images/Integrated Engineering Solutions/images/first section copy.webp";
import datatwo from "../../Assets/images/servies-images/Integrated Engineering Solutions/images/last image copy.webp";
import { BiRightArrowAlt } from "react-icons/bi";
import inteicon1 from "../../Assets/images/servies-images/Integrated Engineering Solutions/icon/Sustainability and Green Engineering.webp";
import inteicon2 from "../../Assets/images/servies-images/Integrated Engineering Solutions/icon/Reverse Engineering.webp";
import inteicon3 from "../../Assets/images/servies-images/Integrated Engineering Solutions/icon/Engineering Consulting.webp";
import { useEffect } from "react";


function IntegratedEngineeringSolutions() {
    
    return (
        <>
            <div className="integrated-section">
                <section className=" top-banner-section-overral-bg-section integrated-background back-posi-img">
                    <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid top-banner-section'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1 className="top-text-one"><span className="text-color">Integrated Engineering Solutions</span> by Upstrox</h1>
                                    <div className="top-text-two">Upstrox offers comprehensive integrated engineering solutions that cater to the diverse needs of engineering organizations across various industries.</div>
                                </div>
                            </div>
                            <div className="pt-3">
                               <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="datacard-section">
                    <section className="datacard-section-one py-5">
                        <div className="container">
                        <h5 className="text-one">Our integrated engineering services combine expertise in engineering disciplines with cutting-edge technologies, enabling seamless collaboration, optimized workflows, and efficient project delivery. Here are the key integrated engineering solutions we offer</h5>
                            <div className="row pt-2 pt-lg-5 align-items-center">
                                <div className="data-card-blur" style={{ background: "#00ffff75", opacity: "0.5" }}></div>
                                <div className="col-lg-7 col-md-7">
                                    <div className="container">
                                        <div className="row pt-lg-3 py-2">
                                            <div className="col">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Engineering Design and Drafting</h4>
                                                    <h5 className="card-text-two text-ho">
                                                    We provide engineering design and drafting services that cover a wide range of disciplines, including mechanical, electrical, civil, and architectural. Our experienced engineers and drafters use industry-standard software to create precise and detailed engineering drawings, models, and schematics.
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-lg-3 py-2">
                                            <div className="col">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Product Development and Prototyping</h4>
                                                    <h5 className="card-text-two text-ho">
                                                    We offer end-to-end product development services, from concept design to prototyping and testing. Our engineers work closely with your team to develop innovative and functional product designs, leveraging 3D modeling, simulation, and rapid prototyping technologies.
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-lg-3 py-2">
                                            <div className="col">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Computer-Aided Engineering (CAE)</h4>
                                                    <h5 className="card-text-two text-ho">
                                                    Our CAE services enable virtual product testing, analysis, and optimization. We utilize advanced CAE tools to perform structural analysis, thermal analysis, fluid dynamics simulations, and other engineering analyses to validate designs and optimize performance.
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5">
                                    <div className=""><img src={dataimg} alt="integrated" className="datacard-img" /></div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="datacard-section-three py-5">
                        <div className="container">
                            <div className="row py-2 g-4 pt-lg-4">
                                <div className="col-lg-6 col-md-6">
                                    <div className=" card text-white p-lg-4 h-100" style={{ background: "rgba(46, 46, 46, 0.199)" }}>
                                        <h4 className="card-text-one ps-3">
                                        Building Information Modeling (BIM)
                                        </h4>
                                        <h5 className="card-text-two px-3 text-ho">
                                        We specialize in BIM services that facilitate the efficient planning, design, construction, and operation of buildings and infrastructure projects. Our BIM experts create intelligent 3D models that capture project information, enabling collaborative design, clash detection, and improved project coordination.
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className=" card text-white p-lg-4 h-100" style={{ background: "rgba(46, 46, 46, 0.199)" }}>
                                        <h4 className="card-text-one ps-3">
                                        Simulation and Modeling
                                        </h4>
                                        <h5 className="card-text-two px-3 text-ho">
                                        We provide simulation and modeling services that enable virtual testing and analysis of engineering systems. Our simulation solutions cover structural analysis, flow simulations, electromagnetics, optimization, and other engineering disciplines, assisting in product development and performance optimization.
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="row py-2 align-items-center row-reverse g-4">
                                <div className="col-lg-6 col-md-6">
                                    <div className=" card text-white p-lg-4 h-100" style={{ background: "rgba(46, 46, 46, 0.199)" }}>
                                        <h4 className="card-text-one ps-3">
                                        Engineering Project Management
                                        </h4>
                                        <h5 className="card-text-two px-3 text-ho">
                                        Our engineering project management services help streamline project execution, ensuring timely delivery, cost control, and quality management. We assist in project planning, scheduling, resource allocation, risk management, and stakeholder communication, ensuring project success.
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className=" card text-white p-lg-4 h-100" style={{ background: "rgba(46, 46, 46, 0.199)" }}>
                                        <h4 className="card-text-one ps-3">
                                        Industrial Automation and Control Systems
                                        </h4>
                                        <h5 className="card-text-two px-3 text-ho">
                                        We offer industrial automation and control system solutions to enhance operational efficiency and productivity. Our experts design and implement control systems, programmable logic controllers (PLCs), human-machine interfaces (HMIs), and supervisory control and data acquisition (SCADA) systems.
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="datacard-section-two py-3">
                        <div className="container">
                            <div className="row align-items-end g-4">
                                <div className="col-lg-4 col-md-4">
                                    <div className="card px-lg-3 h-100">
                                        <div className="text-center"><img src={inteicon1} alt="integrated" className="datacard-img" /></div>
                                        <h4 className="card-text-one">
                                        Sustainability and Green Engineering
                                        </h4>
                                        <h5 className="card-text-two text-ho">
                                        We provide sustainability and green engineering solutions to help organizations adopt environmentally friendly practices and technologies. Our services cover energy efficiency analysis, renewable energy system design, waste management optimization, and sustainable building design.
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div className="card px-lg-3 h-100">
                                        <div className="text-center"><img src={inteicon2} alt="integrated" className="datacard-img" /></div>
                                        <h4 className="card-text-one">
                                        Reverse Engineering and 3D Scanning
                                        </h4>
                                        <h5 className="card-text-two text-ho">
                                        We offer reverse engineering services that enable the digitization and reconstruction of existing physical objects. Our 3D scanning technologies capture precise measurements and data, facilitating design modifications, product replication, and quality control.
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div className="card px-lg-3 h-100">
                                        <div className="text-center"><img src={inteicon3} alt="integrated" className="datacard-img" /></div>
                                        <h4 className="card-text-one">
                                        Engineering Consulting and Advisory
                                        </h4>
                                        <h5 className="card-text-two text-ho">
                                        Our experienced engineering consultants provide expert guidance and advisory services to help organizations overcome engineering challenges and make informed decisions. We offer technology assessments, process optimization recommendations, and strategic engineering planning.
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="datacard-section-four py-3 py-lg-5">
                        <div className="container">
                            <div className="row d-flex align-items-center g-4">
                                <div className="col-lg-8 col-md-8 "><p className="service-footer-text mb-4">At Upstrox, we are committed to delivering integrated engineering solutions that drive innovation, improve efficiency, and optimize project outcomes. </p>
                                    <a href="contact" className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
                                </div>
                                <div className="col-lg-4 col-md-4"><img src={datatwo} alt="integrated" className="service-footer-img"></img></div>
                            </div>
                        </div>
                    </section>
                    <section className="datacard-section-five pt-5">
                        <div className="container">
                            <div className="row g-4 justify-content-center text-center">
                                <div className="col-lg-12">
                                    <p className="contact-text mb-lg-4">Contact us today to discuss your specific requirements and explore how Upstrox can empower your organization's engineering capabilities and success with our industry-leading integrated engineering services.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        </>
    )
}
export default IntegratedEngineeringSolutions;