import { } from "react-icons/bi";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Dummybg from "../../Assets/images/servies-images/demo/dummy-1.webp"

import { BiRightArrowAlt } from 'react-icons/bi';
import ContactNew from "../../Assets/images/servies-images/Digital Supply Chain/images/lsdt image copy.webp";
import digiicon1 from "../../Assets/images/servies-images/Digital Supply Chain/icon/Supply Chain Visibility.webp";
import digiicon2 from "../../Assets/images/servies-images/Digital Supply Chain/icon/Demand Forecasting and Planning.webp";
import digiicon3 from "../../Assets/images/servies-images/Digital Supply Chain/icon/Inventory Optimization.webp";
import digiicon4 from "../../Assets/images/servies-images/Digital Supply Chain/icon/Supplier Collaboration.webp";
import digiicon5 from "../../Assets/images/servies-images/Digital Supply Chain/icon/Warehouse and Distribution Management.webp";
import digiicon6 from "../../Assets/images/servies-images/Digital Supply Chain/icon/Transportation and Logistics Optimization.webp";
import digiicon7 from "../../Assets/images/servies-images/Digital Supply Chain/icon/Digital Procurement and Supplier Management.webp";
import digiicon8 from "../../Assets/images/servies-images/Digital Supply Chain/icon/Blockchain-enabled Supply Chain.webp";
import digiicon9 from "../../Assets/images/servies-images/Digital Supply Chain/icon/Analytics and Insights.webp";
import digiicon10 from "../../Assets/images/servies-images/Digital Supply Chain/icon/icon copy.webp";
import { useEffect } from "react";


function DigitalSupplyChain() {
   
    return (
        <>
            <div className="digitalsupplychain">
                <section className="top-banner-section top-banner-section-overral-bg-section digitalsuply-background back-posi-img">
                    <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1 className="top-text-one"><span className="text-color">Digital Supply Chain</span> Solutions by Upstrox</h1>
                                    <div className="top-text-two">Upstrox offers comprehensive Digital Supply Chain solutions to help organizations transform their supply chain operations, optimize processes, and enhance visibility and collaboration across the supply chain ecosystem.</div>
                                </div>
                            </div>
                            <div className="pt-3">
                               <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="top-banner-section-two pb-4 pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h5 className="text-one">Our digital supply chain solutions leverage advanced technologies such as IoT, AI, data analytics, and blockchain to enable organizations to adapt to rapidly changing market dynamics and drive operational excellence. Here are some key components of our Digital Supply Chain solutions</h5>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="automotive-new-design-2 pb-4 pt-5">
                    <div className="container-fluid col-lg-10 mx-auto">
                        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 justify-content-center">
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                        {/* <SiAntdesign className="automotive-new-text-2" /> */}
                                        <img src={digiicon1} className="digi-icon-img automotive-new-text-2" alt="digitalsupply" />
                                        <h5 class="automotive-new-text-1">Supply Chain Visibility</h5>
                                        <p class="automotive-new-text-3">We provide real-time visibility into the end-to-end supply chain, enabling organizations to track inventory levels, monitor shipments, and gain insights into demand and supply fluctuations. Our solutions leverage IoT sensors, RFID technology, and data analytics to provide actionable insights for effective decision-making.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                    <img src={digiicon2} className="digi-icon-img automotive-new-text-2" alt="digitalsupply" />
                                        <h5 class="automotive-new-text-1">Demand Forecasting and Planning</h5>
                                        <p class="automotive-new-text-3">We help organizations leverage data analytics and machine learning algorithms to improve demand forecasting accuracy. Our solutions analyze historical data, market trends, and external factors to generate accurate demand forecasts, enabling organizations to optimize inventory levels and minimize stock-outs.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                    <img src={digiicon3} className="digi-icon-img automotive-new-text-2" alt="digitalsupply" />
                                        <h5 class="automotive-new-text-1">Inventory Optimization</h5>
                                        <p class="automotive-new-text-3">We assist organizations in optimizing inventory levels and improving inventory management processes. Our solutions utilize demand forecasting, predictive analytics, and optimization algorithms to determine optimal inventory levels, reduce carrying costs, and ensure product availability while minimizing stock obsolescence.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                    <img src={digiicon4} className="digi-icon-img automotive-new-text-2" alt="digitalsupply" />
                                        <h5 class="automotive-new-text-1">Supplier Collaboration</h5>
                                        <p class="automotive-new-text-3">We enable organizations to enhance collaboration with suppliers through digital platforms and tools. Our solutions facilitate real-time information sharing, supplier performance monitoring, and streamlined communication, fostering transparency, and enabling more efficient supplier relationships.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                    <img src={digiicon5} className="digi-icon-img automotive-new-text-2" alt="digitalsupply" />
                                        <h5 class="automotive-new-text-1">Warehouse and Distribution Management</h5>
                                        <p class="automotive-new-text-3">We help organizations optimize warehouse and distribution operations through digital technologies. Our solutions utilize automation, robotics, and IoT devices to improve warehouse efficiency, track inventory movements, and optimize order fulfillment processes, reducing errors and enhancing customer satisfaction.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                    <img src={digiicon6} className="digi-icon-img automotive-new-text-2" alt="digitalsupply" />
                                        <h5 class="automotive-new-text-1">Transportation and Logistics Optimization</h5>
                                        <p class="automotive-new-text-3">We assist organizations in optimizing transportation and logistics operations. Our solutions leverage data analytics and optimization algorithms to optimize route planning, carrier selection, load consolidation, and delivery scheduling, enabling organizations to reduce costs and improve delivery performance.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                    <img src={digiicon7} className="digi-icon-img automotive-new-text-2" alt="digitalsupply" />
                                        <h5 class="automotive-new-text-1">Digital Procurement and Supplier Management</h5>
                                        <p class="automotive-new-text-3">We provide digital procurement solutions that streamline procurement processes and enhance supplier management. Our solutions utilize e-procurement platforms, AI-powered supplier selection algorithms, and contract management tools to drive efficiency, reduce procurement cycle times, and ensure compliance.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                    <img src={digiicon8} className="digi-icon-img automotive-new-text-2" alt="digitalsupply" />
                                        <h5 class="automotive-new-text-1">Blockchain-enabled Supply Chain</h5>
                                        <p class="automotive-new-text-3">We leverage blockchain technology to enhance supply chain transparency, traceability, and trust. Our solutions utilize blockchain for secure and immutable record-keeping, smart contracts for automating contractual agreements, and supply chain traceability for ensuring product authenticity and ethical sourcing.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                    <img src={digiicon9} className="digi-icon-img automotive-new-text-2" alt="digitalsupply" />
                                        <h5 class="automotive-new-text-1">Analytics and Insights</h5>
                                        <p class="automotive-new-text-3">We help organizations gain actionable insights from supply chain data through advanced analytics. Our solutions provide real-time analytics, data visualization, and predictive analytics capabilities to identify patterns, detect anomalies, and optimize supply chain operations.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body">
                                    <img src={digiicon10} className="digi-icon-img automotive-new-text-2" alt="digitalsupply" />
                                        <h5 class="automotive-new-text-1">Digital Supply Chain Strategy and Consulting</h5>
                                        <p class="automotive-new-text-3">We provide strategic consulting services to help organizations develop and implement a digital supply chain strategy aligned with their business goals. Our experts assess your current supply chain, identify improvement opportunities, and develop a roadmap for digital transformation, ensuring a successful and seamless transition.</p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>

                <section className="aerospace-new-design-2 py-5">
                    <div className="container">
                        <div className="row g-4 flex-lg-row-reverse">
                            <div className="col-lg-4">
                                <img src={ContactNew} alt="digitalsupply" />
                            </div>
                            <div className="col-lg-8">
                                <p className="aerospace-new-text-5 mb-lg-4">At Upstrox, we are committed to helping organizations harness the power of digital technologies to transform their supply chain operations.</p>
                                <a href='/contact' className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="aerospace-new-design-2 py-5">
                    <div className="container">
                        <div className="row g-4 justify-content-center text-center">
                            <div className="col-lg-12">
                                <p className="aerospace-new-text-6 mb-lg-4">Contact us today to discuss your Digital Supply Chain requirements and explore how Upstrox can assist your organization in driving operational excellence, improving customer satisfaction, and gaining a competitive edge through our digital supply chain solutions.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default DigitalSupplyChain;