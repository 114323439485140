import { MdOutlineArrowForwardIos } from "react-icons/md";
import { BiRightArrowAlt } from "react-icons/bi";
import consumerlast from "../../Assets/images/servies-images/Business Excellence Services/images/last image copy.webp";

import busiicon1 from "../../Assets/images/servies-images/Business Excellence Services/icon/Business Process Optimization.webp";
import busiicon2 from "../../Assets/images/servies-images/Business Excellence Services/icon/Quality Management Systems (QMS).webp";
import busiicon3 from "../../Assets/images/servies-images/Business Excellence Services/icon/Performance Measurement and KPIs.webp";
import busiicon4 from "../../Assets/images/servies-images/Business Excellence Services/icon/Customer Experience Management.webp";
import busiicon5 from "../../Assets/images/servies-images/Business Excellence Services/icon 2/Continuous Improvement Programs.webp";
import busiicon6 from "../../Assets/images/servies-images/Business Excellence Services/icon 2/Change Management.webp";
import busiicon7 from "../../Assets/images/servies-images/Business Excellence Services/icon 2/Employee Engagement and Empowerment.webp";
import busiicon8 from "../../Assets/images/servies-images/Business Excellence Services/icon 2/Benchmarking and Best Practices.webp";
import busiicon9 from "../../Assets/images/servies-images/Business Excellence Services/icon 2/Strategy Deployment.webp";
import busiicon10 from "../../Assets/images/servies-images/Business Excellence Services/icon 2/Business Excellence Assessments and Awards.webp";
import { useEffect } from "react";


function BusinessExcellenceServices() {
  
  return (
    <>
      <div className="business-excellence">
        <section className="top-banner-section top-banner-section-overral-bg-section businessexcellence-img back-posi-img" >
          <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one"><span className="text-color">Business Excellence Services</span> by Upstrox</h1>
                  <div className="top-text-two">Upstrox offers comprehensive Business Excellence services to help organizations achieve and sustain high levels of performance, operational efficiency, and continuous improvement.</div>
                </div>
              </div>
              <div className="pt-3">
                <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
              </div>
            </div>
          </div>
        </section>
  
        <section className="consumer-new-cards-section pt-5">
          <div className="container-fluid col-lg-10 position-relative pb-lg-5">
            <div className="row">
              {/* <div className="consumer-blur"></div> */}
              <div className="col-lg-4">
                <h5 className="aerospace-new-text-1">Business Excellence Services by Upstrox</h5>
                <p className="aerospace-new-text-2">
                Our services are designed to enable organizations to optimize their processes, enhance their customer experiences, and achieve their strategic goals. Here are some key Business Excellence services we provide
                </p>
              </div>
              <div className="col-lg-8">
                <div className="d-lg-flex d-md-flex">
                  <div>
                    {" "}
                    <img
                      src={busiicon1}
                      className="busiex-icon-img"
                      alt="business-excellence"
                    />
                  </div>
                  <div className="">
                    <h3 className="card-text-one">
                    Business Process Optimization
                    </h3>
                    <p className="card-text-two">
                    We analyze your existing business processes, identify areas of improvement, and design optimized processes that align with industry best practices. Our experts employ methodologies such as Lean Six Sigma, process mapping, and value stream analysis to streamline operations, eliminate waste, and enhance efficiency.
                    </p>
                  </div>
                </div>
                <div className="d-lg-flex d-md-flex">
                  <div>
                    {" "}
                    <img
                      src={busiicon2}
                      className="busiex-icon-img"
                      alt="business-excellence"
                    />
                  </div>
                  <div className="">
                    <h3 className="card-text-one">
                    Quality Management Systems (QMS)
                    </h3>
                    <p className="card-text-two">
                    We assist organizations in implementing and maintaining robust Quality Management Systems based on internationally recognized standards such as ISO 9001. Our experts help you define quality objectives, develop quality policies and procedures, and establish a culture of continuous improvement and customer focus.
                    </p>
                  </div>
                </div>
                <div className="d-lg-flex d-md-flex">
                  <div>
                    {" "}
                    <img
                      src={busiicon3}
                      className="busiex-icon-img"
                      alt="business-excellence"
                    />
                  </div>
                  <div>
                    <h3 className="card-text-one">
                    Performance Measurement and KPIs
                    </h3>
                    <p className="card-text-two">
                    We help organizations define and implement performance measurement frameworks, including Key Performance Indicators (KPIs), to track and monitor progress towards strategic objectives. Our experts develop balanced scorecards, dashboards, and reporting mechanisms that provide meaningful insights into organizational performance.
                    </p>
                  </div>
                </div>
                <div className="d-lg-flex d-md-flex">
                  <div>
                    {" "}
                    <img
                      src={busiicon4}
                      className="busiex-icon-img"
                      alt="business-excellence"
                    />
                  </div>
                  <div>
                    <h3 className="card-text-one">
                    Customer Experience Management
                    </h3>
                    <p className="card-text-two">
                    We assist organizations in enhancing their customer experiences through customer journey mapping, voice of the customer analysis, and customer feedback mechanisms. Our experts help you develop customer-centric strategies, implement customer experience improvement initiatives, and drive customer loyalty and satisfaction.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-5 con-card-two">
            <div className="row row-cols-lg-3 row-cols-1 row-cols-md-2  pb-lg-4">
              <div className="col con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={busiicon5}
                      className="con-icon-top"
                      alt="business-excellence"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                    Continuous Improvement Programs
                    </h3>
                    <p className="card-text-two">
                      {" "}
                      We help organizations establish and implement continuous improvement programs such as Kaizen, Lean, and Six Sigma. Our experts facilitate improvement workshops, provide training and coaching, and support the implementation of improvement projects to drive efficiency, reduce costs, and increase customer value.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={busiicon6}
                      className="con-icon-top"
                      alt="business-excellence"
                    />
                  </div>
                  <div className="pt-lg-4 ">
                    <h3 className="card-text-one">
                    Change Management
                    </h3>
                    <p className="card-text-two">
                    We support organizations in managing change effectively to ensure successful implementation of improvement initiatives. Our experts develop change management strategies, conduct change impact assessments, and provide guidance and support to stakeholders during the change process.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={busiicon7}
                      className="con-icon-top"
                      alt="business-excellence"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                    Employee Engagement and Empowerment
                    </h3>
                    <p className="card-text-two">
                    We help organizations foster a culture of employee engagement and empowerment. Our experts develop employee engagement strategies, facilitate employee feedback mechanisms, and design recognition and rewards programs that promote employee motivation, productivity, and satisfaction.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col con-top con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={busiicon8}
                      className="con-icon-top"
                      alt="business-excellence"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                    Benchmarking and Best Practices
                    </h3>
                    <p className="card-text-two">
                    We assist organizations in benchmarking their performance against industry peers and identifying best practices. Our experts conduct benchmarking studies, facilitate knowledge sharing sessions, and provide insights and recommendations to help you achieve and surpass industry standards.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={busiicon9}
                      className="con-icon-top"
                      alt="business-excellence"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                    Strategy Deployment
                    </h3>
                    <p className="card-text-two">
                    We support organizations in effectively deploying their strategic goals and initiatives throughout the organization. Our experts assist in developing strategy maps, cascading objectives, and aligning performance measures to ensure that every level of the organization contributes to the overall strategic direction.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={busiicon10}
                      className="con-icon-top"
                      alt="business-excellence"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                    Business Excellence Assessments and Awards
                    </h3>
                    <p className="card-text-two">
                    We help organizations prepare for and participate in Business Excellence assessments and awards, such as the Malcolm Baldrige National Quality Award and European Foundation for Quality Management (EFQM) Excellence Model. Our experts provide guidance and support throughout the assessment process, helping you showcase your organization's excellence and drive continuous improvement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="consumer-last-section my-lg-5">
          <div className="container py-5">
            <div className="row flex-row-reverse">
              <div className="col-lg-4 col-md-4">
                <img src={consumerlast} alt="business-excellence" />
              </div>
              <div className="col-lg-8 col-md-8">
                <div className="pt-lg-5 pt-md-5">
                  <p className="">
                  At Upstrox, we are committed to helping organizations achieve Business Excellence by optimizing processes, enhancing customer experiences, and driving continuous improvement. 
                  </p>
                </div>
                <div>
                  <a className="new-ups-button-2" href="/contact">
                    <span className="new-ups-button-icon-3">Read More</span>{" "}
                    <BiRightArrowAlt className="new-ups-button-icon-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-text-section py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="contact-text-last mb-lg-4">
                Contact us today to discuss your Business Excellence requirements and explore how Upstrox can assist your organization in its journey towards excellence and sustained success.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
export default BusinessExcellenceServices;