import { MdOutlineArrowForwardIos } from "react-icons/md";
import MediaEntertainmentbg from "../../Assets/images/icon/media and entertainment/media-entertainment.webp";
import { BiRightArrowAlt } from "react-icons/bi";
import sustain from "../../Assets/images/icon/media and entertainment/media image.webp";
import mediaicon1 from "../../Assets/images/icon/media and entertainment/Content Management Systems.webp";
import mediaicon2 from "../../Assets/images/icon/media and entertainment/Digital Asset Management.webp";
import mediaicon3 from "../../Assets/images/icon/media and entertainment/Video Production and Post-Production Solutions.webp";
import mediaicon4 from "../../Assets/images/icon/media and entertainment/Over-the-Top (OTT) and Streaming Solutions.webp";
import mediaicon5 from "../../Assets/images/icon/media and entertainment/IT Consulting and Strategy.webp";
import mediaicon6 from "../../Assets/images/icon/media and entertainment/Cybersecurity and Data Protection.webp";


function MediaEntertainment() {
  return (
    <>
      <div className="mediaentertainment-banner-section">
        <section className="top-banner-section top-banner-section-overral-bg-section back-posi-img" style={{ backgroundImage: "url(" + MediaEntertainmentbg + ")" }}>
          <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one">
                    <span className="text-color"> Media and Entertainment </span> IT  Solutions by Upstrox
                  </h1>
                  <div className="top-text-two">Upstrox is your trusted partner for comprehensive IT solutions tailored specifically for the media and entertainment industry. We understand the unique challenges and demands of content creation, distribution, and audience engagement.
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5">
          <div className="container-fluid col-lg-11 mx-auto">
            <div className="row">
              <div className="col-lg-4">
                <h2 className="aerospace-new-text-1 mb-lg-4 mb-3">Media and Entertainment</h2>
                <p className="aerospace-new-text-2"> With our expertise in technology and industry knowledge, we provide innovative solutions to help you stay ahead in the rapidly evolving media landscape. Here are the key IT services we offer</p>
              </div>
              <div className="col-lg-8">
                <div className="row row-cols-md-2 row-cols-1 row-cols-lg-2 g-4">
                  <div className="col">
                    <img src={mediaicon1} alt="media" className="aerospace-new-image-1" />
                    <h5 className="aerospace-new-text-3">Content Management Systems (CMS)</h5>
                    <p className="aerospace-new-text-4">We provide robust CMS solutions that streamline content creation, organization, and distribution. Our CMS platforms enable efficient collaboration, version control, metadata management, and multi-channel publishing, ensuring seamless content delivery across various platforms.
                    </p>
                  </div>
                  <div className="col">
                    <img src={mediaicon2} alt="media" className="aerospace-new-image-1" />
                    <h5 className="aerospace-new-text-3">Digital Asset Management (DAM)</h5>
                    <p className="aerospace-new-text-4"> We offer DAM solutions that centralize and optimize the management of digital assets, including images, videos, and audio files. Our DAM systems facilitate easy search, retrieval, and distribution of assets, improving content production workflows and maximizing asset utilization.
                    </p>
                  </div>
                  <div className="col">
                    <img src={mediaicon3} alt="media" className="aerospace-new-image-1" />
                    <h5 className="aerospace-new-text-3">Video Production and Post-Production Solutions</h5>
                    <p className="aerospace-new-text-4">We provide video production and post-production solutions that enhance the creative process and improve efficiency. Our solutions cover video editing, visual effects, color grading, and audio mixing, enabling seamless collaboration and high-quality content delivery.
                    </p>
                  </div>
                  <div className="col">
                    <img src={mediaicon4} alt="media" className="aerospace-new-image-1" />
                    <h5 className="aerospace-new-text-3">Over-the-Top (OTT) and Streaming Solutions</h5>
                    <p className="aerospace-new-text-4"> We specialize in developing OTT and streaming solutions that enable content distribution across multiple platforms and devices. Our solutions include video encoding, adaptive streaming, DRM integration, and analytics, ensuring a seamless streaming experience for your audience.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container-fluid mt-5 re-two-card col-lg-10">
            <div className="row row-cols-lg-2 row-cols-md-2 row-cols-1 gap-3 gap-lg-0  gap-md-0 ">
              <div className="col pe-lg-0">
                <div className=" card h-100 py-lg-2">
                  <div className="card-body">
                    <div>
                      <h4 className="card-text-one">
                        Content Delivery Network (CDN) and Cloud Services
                      </h4>
                      <h5 className="card-text-two">
                        We offer CDN and cloud services that optimize content delivery, scalability, and reliability. Our solutions ensure fast and secure content distribution, global reach, and high-quality streaming performance, enhancing user experiences.

                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col ">
                <div className=" card h-100">
                  <div className="card-body">
                    <div>
                      <h4 className="card-text-one">
                        Audience Engagement and Personalization
                      </h4>
                      <h5 className="card-text-two">
                        We provide solutions to enhance audience engagement and deliver personalized experiences. Our services include content recommendation engines, user profiling, analytics, and marketing automation, enabling targeted content delivery and improved audience retention.

                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col pt-md-3">
                <div className=" card h-100 left-margin">
                  <div className="card-body ">
                    <div>
                      <h4 className="card-text-one">
                        Rights Management and Licensing
                      </h4>
                      <h5 className="card-text-two">
                        We offer rights management and licensing solutions that streamline the management of content rights, contracts, and royalties. Our solutions help you track and monetize content usage, manage licensing agreements, and ensure compliance with copyright regulations.

                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col pt-md-3">
                <div className=" card h-100 left-margin">
                  <div className="card-body">
                    <div>
                      <h4 className="card-text-one">
                        Data Analytics and Business Intelligence
                      </h4>
                      <h5 className="card-text-two">
                        Our data analytics and business intelligence solutions help you derive valuable insights from audience data, content performance, and market trends. We assist in analyzing viewer behavior, content engagement, and revenue metrics to drive data-driven decision-making and optimize content strategies.

                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className=" sustaincard-section sustaincard-section-three py-3 mt-5">
          <div className="container">
            <div className="row d-flex justify-content-center pb-lg-5">
              <div className="col-lg-6 col-md-6">
                <div className="card h-100 p-3 text-center">
                  <div className="pb-3"><img src={mediaicon5} alt="media" className="aerospace-new-image-1" /></div>
                  <h4 className="card-text-one">IT Consulting and Strategy</h4>
                  <h5 className="card-text-two">
                    Our experienced consultants offer IT consulting services to help you develop technology strategies, digital transformation roadmaps, and monetization models. We assist in technology selection, vendor management, and optimizing workflows for improved efficiency and growth.
                  </h5>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="card h-100 p-3 text-center">
                  <div className="pb-3"><img src={mediaicon6} alt="media" className="aerospace-new-image-1" /></div>
                  <h4 className="card-text-one">Cybersecurity and Content Protection</h4>
                  <h5 className="card-text-two">
                    We prioritize content protection and cybersecurity in the media and entertainment industry. We provide comprehensive cybersecurity services, including content watermarking, access controls, DRM integration, and piracy monitoring, safeguarding your valuable assets and ensuring content security.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sustaincard-section-four py-3 py-lg-1">
          <div className="container">
            <div className="row d-flex align-items-center g-4">
              <div className="col-lg-8 col-md-8"><p className="service-footer-text mb-4">At Upstrox, we are committed to helping media and entertainment organizations thrive in the digital age. </p>
                <a href="contact" className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
              </div>
              <div className="col-lg-4 col-md-4"><img src={sustain} alt="media" className="service-footer-img"></img></div>
            </div>
          </div>
        </section>
        <section className="contact-text-section py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="contact-text-last mb-lg-4">
                  Contact us today to discuss your specific requirements and explore how Upstrox can empower your organization's content creation, distribution, and audience engagement with our industry-leading IT services.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
export default MediaEntertainment;