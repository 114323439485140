import { MdOutlineArrowForwardIos } from "react-icons/md";
import Dummybg from "../../Assets/images/servies-images/demo/dummy-2.webp"


import { BiRightArrowAlt } from "react-icons/bi";

import data from "../../Assets/imgs/data analysis.webp";
import inventory from "../../Assets/imgs/inventory-management.webp";
import warhouse from "../../Assets/imgs/warehouse management.webp";
import order from "../../Assets/imgs/order management.webp";
import artilast from "../../Assets/images/servies-images/Artificial Intelligence/images/last image copy.webp";
import transportation from "../../Assets/imgs/transportaion management.webp";
import supply from "../../Assets/imgs/supply-chain-management.webp";
import vendor from "../../Assets/imgs/vendor management.webp";
import dataimg from "../../Assets/images/servies-images/Artificial Intelligence/images/first section.webp";
import artiicon1 from "../../Assets/images/servies-images/Artificial Intelligence/icon1/Machine Learning (ML).webp"
import artiicon2 from "../../Assets/images/servies-images/Artificial Intelligence/icon1/natural-language-processing.webp"
import artiicon3 from "../../Assets/images/servies-images/Artificial Intelligence/icon1/Computer Vision.webp"
import artiicon4 from "../../Assets/images/servies-images/Artificial Intelligence/icon1/Predictive Analytics.webp"

import artiicon5 from "../../Assets/images/servies-images/Artificial Intelligence/icon2/Robotic Process Automation (RPA).webp";
import artiicon6 from "../../Assets/images/servies-images/Artificial Intelligence/icon2/AI Model Deployment and Integration.webp";
import artiicon7 from "../../Assets/images/servies-images/Artificial Intelligence/icon2/AI Strategy and Consulting.webp";
import { useEffect } from "react";


function ArtificialIntelligence() {

   
    return (
        <>
            <div className="ArtificialIntelligence">
                <section className="top-banner-section top-banner-section-overral-bg-section artificial-background back-posi-img">
                    <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1 className="top-text-one"><span className="text-color">Artificial Intelligence</span>(AI) Solutions by Upstrox</h1>
                                    <div className="top-text-two">Upstrox offers cutting-edge Artificial Intelligence (AI) solutions designed to help organizations leverage the power of AI technologies and drive innovation in their operations.</div>
                                </div>
                            </div>
                            <div className="pt-3">
                               <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                            </div>
                            </div>
                        </div>
                </section>
                
                <section className="logistic-new-card-section pt-5">
                <div className="container-fluid col-lg-10">
                    <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <div>
                        <h3 className="aerospace-new-text-1">
                            {" "}
                            Artificial Intelligence (AI) Solutions{" "}
                        </h3>
                        <p className="aerospace-new-text-2">
                            {" "}
                            Our team of AI experts and data scientists possess extensive knowledge and experience in developing AI models and applications. Here are some key AI solutions we provide
                        </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="card card-one">
                        <div className="p-3">
                            {" "}
                            <img
                            src={artiicon1}
                            className="arti1-icon-img"
                            alt="ArtificialIntelligence"
                            />
                        </div>
                        <div className="pt-lg-3">
                            <h4 className="card-text-one ps-3 ">
                            Machine Learning (ML)
                            </h4>
                            <h5 className="card-text-two px-3">
                            We develop custom ML models that enable organizations to make accurate predictions, detect patterns, and gain valuable insights from large datasets. Our experts employ various ML techniques, including supervised learning, unsupervised learning, and reinforcement learning, to solve complex business problems.
                            </h5>
                        </div>
                        </div>
                        <div className="card card-one ">
                        <div className="p-3">
                            {" "}
                            <img
                            src={artiicon2}
                            className="arti1-icon-img"
                            alt="ArtificialIntelligence"
                            />
                        </div>
                        <div className="pt-lg-3">
                            <h4 className="card-text-one ps-3">
                            Natural Language Processing (NLP)
                            </h4>
                            <h5 className="card-text-two px-3">
                            We specialize in NLP, enabling organizations to extract meaning from unstructured data and understand human language. Our experts develop NLP models for tasks such as sentiment analysis, text classification, entity recognition, language translation, and chatbot development.
                            </h5>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="card card-one">
                        <div className="p-3">
                            {" "}
                            <img
                            src={artiicon3}
                            className="arti1-icon-img"
                            alt="ArtificialIntelligence"
                            />
                        </div>
                        <div className="pt-lg-3">
                            <h4 className="card-text-one ps-3">
                            Computer Vision
                            </h4>
                            <h5 className="card-text-two px-3">
                            We create computer vision solutions that enable machines to understand and interpret visual information. Our experts develop models for object detection, image recognition, facial recognition, video analysis, and other computer vision applications, enabling automation and intelligent decision-making based on visual data.
                            </h5>
                        </div>
                        </div>
                        <div className="card card-one">
                        <div className="p-3">
                            {" "}
                            <img src={artiicon4} className="arti1-icon-img" />
                        </div>
                        <div className="pt-lg-3">
                            <h4 className="card-text-one ps-3 ">
                            Predictive Analytics
                            </h4>
                            <h5 className="card-text-two px-3">
                            We assist organizations in harnessing the power of predictive analytics to forecast future outcomes and make data-driven decisions. Our experts develop predictive models that help optimize processes, anticipate customer behavior, predict equipment failures, and enable proactive decision-making.
                            </h5>
                        </div>
                        </div>
                    </div>
                    </div>
                    
                    <section className="datacard-section-one py-5">
                        <div className="container-fluid">
                            <div className="row pt-2 pt-lg-5 align-items-center">
                                <div className="col-lg-7 col-md-7">
                                    <div className="container">
                                        <div className="row pt-lg-3 py-2">
                                            <div className="col">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Anomaly Detection</h4>
                                                    <h5 className="card-text-two text-ho">
                                                    We develop AI models for anomaly detection, enabling organizations to identify unusual patterns or behaviors in their data. Our experts employ statistical techniques, machine learning algorithms, and anomaly scoring methods to detect anomalies and trigger timely alerts for potential issues or threats.
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-lg-3 py-2">
                                            <div className="col">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Recommendation Systems</h4>
                                                    <h5 className="card-text-two text-ho">
                                                    We help organizations implement recommendation systems that personalize and enhance user experiences. Our experts develop collaborative filtering and content-based recommendation algorithms that provide personalized product recommendations, content suggestions, and tailored marketing campaigns.
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-lg-3 py-2">
                                            <div className="col">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">AI-powered Chatbots and Virtual Assistants</h4>
                                                    <h5 className="card-text-two text-ho">
                                                    We design and develop AI-powered chatbots and virtual assistants that provide intelligent and conversational interactions with users. Our experts utilize NLP and dialogue management techniques to create chatbots that can understand user queries, provide information, and assist with tasks.
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5">
                                    <div className=""><img src={dataimg} alt="ArtificialIntelligence" className="datacard-img" /></div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="card mt-5">
                    <div className="row row-cols-lg-3 row-cols-1 row-cols-md-2 mt-lg-4 mx-3 text-center border-n-row px justify-content-md-center">
                        <div className="col">
                        <div className="card h-100 ">
                            <div className=" text-center icon-align">
                            <img
                                src={artiicon5}
                                className="logi-img-two"
                                alt="ArtificialIntelligence"
                            />
                            </div>
                            <div className="p-lg-3">
                            <h4 className="card-text-one ps-3">
                            Robotic Process Automation (RPA)
                            </h4>
                            <h5 className="card-text-two px-lg-3">
                            We combine AI with RPA to automate repetitive and rule-based tasks, enabling organizations to improve operational efficiency and reduce manual effort. Our experts develop intelligent automation solutions that utilize AI algorithms to perform data extraction, decision-making, and process automation.
                            </h5>
                            </div>
                        </div>
                        </div>
                        <div className="col">
                        <div className="card h-100">
                            <div className=" text-center icon-align">
                            {" "}
                            <img
                                src={artiicon6}
                                className="logi-img-two"
                                alt="ArtificialIntelligence"
                            />
                            </div>
                            <div className="p-lg-3">
                            <h4 className="card-text-one ps-3">
                            AI Model Deployment and Integration
                            </h4>
                            <h5 className="card-text-two px-lg-3">
                            We assist organizations in deploying AI models into production environments and integrating them with existing systems and workflows. Our experts ensure scalability, performance, and security while integrating AI capabilities into your organization's infrastructure.
                            </h5>
                            </div>
                        </div>
                        </div>
                        <div className="col pt-md-5 pt-lg-0">
                        <div className="card h-100">
                            <div className="text-center icon-align">
                            <img
                                src={artiicon7}
                                className="logi-img-two"
                                alt="ArtificialIntelligence"
                            />
                            </div>
                            <div className="p-lg-3">
                            <h4 className="card-text-one ps-3">
                            AI Strategy and Consulting
                            </h4>
                            <h5 className="card-text-two px-lg-3">
                            We provide strategic AI consulting services to help organizations define their AI vision, roadmap, and implementation strategies. Our experts assess your organization's readiness for AI adoption, identify use cases, and provide guidance on data management, infrastructure requirements, and talent acquisition for successful AI implementation.
                            </h5>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>

                <section className="logistic-last-section my-lg-5">
                    <div className="container py-5">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-4 col-md-4">
                        <img src={artilast} alt="ArtificialIntelligence" />
                        </div>
                        <div className="col-lg-8 col-md-8">
                        <div className="pt-lg-5">
                            <p className="">
                            At Upstrox, we are committed to helping organizations unlock the transformative potential of Artificial Intelligence.
                            </p>
                        </div>
                        <div>
                            <a className="new-ups-button-2" href="/contact">
                            <span className="new-ups-button-icon-3">Read More</span>{" "}
                            <BiRightArrowAlt className="new-ups-button-icon-2" />
                            </a>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>

                <section className="contact-text-section py-5">
                    <div className="container">
                    <div className="row g-4 justify-content-center text-center">
                        <div className="col-lg-12">
                        <p className="contact-text-last mb-lg-4">
                        Contact us today to discuss your AI requirements and explore how Upstrox can assist your organization in leveraging AI technologies to drive innovation, efficiency, and competitive advantage.
                        </p>
                        </div>
                    </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default ArtificialIntelligence;