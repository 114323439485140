
import { MdOutlineArrowForwardIos } from "react-icons/md";
import React, { useRef, useState, useEffect } from 'react';
import { Virtual, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import IconChem1 from "../../Assets/images/icon/Chemical-Manufacturing/Enterprise-Resource-Planning.webp"
import IconChem2 from "../../Assets/images/icon/Chemical-Manufacturing/Cheminformatics-Tools.webp"
import IconChem3 from "../../Assets/images/icon/Chemical-Manufacturing/Manufacturing-Execution-Systems.webp"
import IconChem4 from "../../Assets/images/icon/Chemical-Manufacturing/Laboratory-Information.webp"
import IconChem5 from "../../Assets/images/icon/Chemical-Manufacturing/Process-Simulation.webp"
import IconChem6 from "../../Assets/images/icon/Chemical-Manufacturing/Supply-Chain.webp"
import IconChem7 from "../../Assets/images/icon/Chemical-Manufacturing/Quality-Management.webp"
import IconChem8 from "../../Assets/images/icon/Chemical-Manufacturing/Health-and-Safety.webp"
import IconChem9 from "../../Assets/images/icon/Chemical-Manufacturing/Asset-Management.webp"
import IconChem10 from "../../Assets/images/icon/Chemical-Manufacturing/Business-Intelligence.webp"
import IconChem11 from "../../Assets/images/icon/Chemical-Manufacturing/Cybersecurity-Solutions.webp"
import IconChem12 from "../../Assets/images/icon/Chemical-Manufacturing/Mobile-Applications.webp"
import IconChem13 from "../../Assets/images/icon/Chemical-Manufacturing/Industrial-Automation.webp"
import IconChem14 from "../../Assets/images/icon/Chemical-Manufacturing/Artificial-Intelligence.webp"



// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import $ from "jquery"


function ChemicalManufacturing() {
    const [swiperRef, setSwiperRef] = useState(null);


    useEffect(() => {
        let totalTabsCount = 0;
        let activeTabIndex = 1;
        let tabChangeTimeout = 4000;

        totalTabsCount = $("#v-pills-tab .nav-link").length;

        function tabChangeHandler() {
            if (activeTabIndex == totalTabsCount) {
                activeTabIndex = 1;
            } else {
                activeTabIndex++;
            }
            console.log(activeTabIndex, "activeTabIndex")

            $("#v-pills-tab .nav-link")
                .eq(parseInt(activeTabIndex - 1))
                .trigger("click");
        }

        let AUTO_CHANGE_TIMER = setInterval(tabChangeHandler, tabChangeTimeout);

        $(".chemicalmanufacturing-new-design-2-inner-2 .card-body").click(function () {
            clearInterval(AUTO_CHANGE_TIMER);
        });

    });

    const slideTo = (index) => {
        swiperRef.slideTo(index - 1, 0);
    };
    return (
        <div className="ChemicalManufacturing-banner-section">

            <section className=" chemical-img back-posi-img">
                <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid top-banner-section'>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col">
                                <h1 className="top-text-one">
                                    Welcome to Upstrox, your trusted partner in delivering cutting-edge IT solutions for the
                                    <span className="text-color"> chemical manufacturing </span>
                                    industry.
                                </h1>
                                <div className="top-text-two">
                                    With our deep understanding of the industry's unique challenges and requirements, we offer a comprehensive suite of services to optimize your operations, streamline processes, and drive growth.
                                </div>
                            </div>
                        </div>
                        <div className="pt-3">
                           <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="top-banner-section-two pb-4 pt-5">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h5 className="text-one">
                                Our team of experienced professionals is committed to providing innovative solutions that empower your business to thrive in today's dynamic and competitive landscape. Explore our range of services and discover how Upstrox can revolutionize your chemical manufacturing operation
                            </h5>
                        </div>
                    </div>
                </div>
            </section>

            <section className="chemicalmanufacturing-new-design-1 py-5">
                <div className="container-fluid col-lg-11 mx-auto py-lg-5">
                    <div className="row row-cols-md-1 row-cols-1 row-cols-lg-2 g-4">
                        <div className="col mt-0 card h-100 chemicalmanufacturing-top-new-card-1">
                            <div class="card bg-transparent text-white border-0 p-lg-3">
                                <div class="card-body">
                                    <img src={IconChem1} alt="icon" className="aerospace-new-image-2" />
                                    <h5 className="aerospace-new-text-3">Enterprise Resource Planning (ERP) Systems</h5>
                                    <p className="aerospace-new-text-4 mb-2">Unlock the Power of Integration and Efficiency</p>
                                    <p className="aerospace-new-text-4 mb-2"> In the complex world of chemical manufacturing, efficient management of resources, processes, and information is crucial for success. Upstrox's Enterprise Resource Planning (ERP) systems provide a centralized platform that seamlessly integrates and streamlines your entire business operations, from procurement to production, inventory management to financials, and beyond.</p>
                                    <p className="aerospace-new-text-4">Our ERP solutions are tailor-made for the chemical manufacturing industry, enabling you to optimize resource allocation, improve productivity, and enhance decision-making. With real-time visibility into critical data and advanced reporting capabilities, you can proactively respond to market demands, mitigate risks, and seize opportunities for growth. Harness the power of ERP systems to drive operational excellence and transform your chemical manufacturing enterprise.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col mt-0 chemicalmanufacturing-top-new-card-2">
                            <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                                <div class="card-body">
                                    <img src={IconChem2} alt="icon" className="aerospace-new-image-2" />
                                    <h5 className="aerospace-new-text-3">Cheminformatics Tools</h5>
                                    <p className="aerospace-new-text-4 mb-2">Accelerate Innovation and Enhance Research</p>
                                    <p className="aerospace-new-text-4 mb-2"> In the fast-paced world of chemical research and development, staying ahead of the curve is paramount. Upstrox's Cheminformatics tools empower your scientists and researchers with advanced software solutions designed to accelerate innovation, improve efficiency, and drive successful outcomes.</p>
                                    <p className="aerospace-new-text-4">Our Cheminformatics tools encompass a wide range of capabilities, including chemical structure analysis, data visualization, molecular modeling, property prediction, and virtual screening. By leveraging these tools, your team can expedite the discovery process, optimize formulations, and make informed decisions that lead to breakthroughs.With Upstrox as your partner, unleash the potential of Cheminformatics tools and revolutionize your chemical research and development capabilities.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col mt-0 chemicalmanufacturing-top-new-card-3">
                            <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                                <div class="card-body">
                                    <img src={IconChem3} alt="icon" className="aerospace-new-image-2" />
                                    <h5 className="aerospace-new-text-3">Manufacturing Execution Systems (MES)</h5>
                                    <p className="aerospace-new-text-4 mb-2">Optimize Production and Enhance Efficiency</p>
                                    <p className="aerospace-new-text-4 mb-2">Efficiently managing the complexities of chemical manufacturing requires a robust Manufacturing Execution System (MES). Upstrox offers MES solutions tailored to the unique needs of the chemical industry, empowering you to optimize production processes, drive operational excellence, and achieve superior results</p>
                                    <p className="aerospace-new-text-4">Our MES solutions provide real-time visibility and control over your manufacturing operations, enabling accurate scheduling, tracking, and monitoring of production activities. With comprehensive data capture, analysis, and reporting capabilities, you gain actionable insights into performance metrics, quality control, and resource utilization. Unlock the full potential of MES systems to streamline your operations, maximize productivity, and deliver exceptional products to the market.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col mt-0 chemicalmanufacturing-top-new-card-4">
                            <div class="card h-100 bg-transparent text-white border-0 p-lg-3">
                                <div class="card-body">
                                    <img src={IconChem4} alt="icon" className="aerospace-new-image-2" />
                                    <h5 className="aerospace-new-text-3">Laboratory Information Management Systems (LIMS)</h5>
                                    <p className="aerospace-new-text-4 mb-2">Streamline Lab Operations and Improve Compliance</p>
                                    <p className="aerospace-new-text-4 mb-2">In the realm of chemical manufacturing, efficient management of laboratory data is critical for quality control, compliance, and innovation. Upstrox's Laboratory Information Management Systems (LIMS) provide a comprehensive solution for streamlining laboratory operations, data management, and regulatory compliance.</p>
                                    <p className="aerospace-new-text-4">Our LIMS solutions offer seamless integration with various lab instruments, automated data capture, sample tracking, and result analysis. With robust data security and audit trail features, you can ensure data integrity, meet regulatory requirements, and drive continuous improvement. Partner with Upstrox to harness the power of LIMS and unlock the full potential of your laboratory operations.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="chemicalmanufacturing-new-design-2 py-5 d-lg-none d-block">
                <div className="container-fluid col-lg-11 mx-auto py-lg-5">

                    <div className="row">
                        <div className="col-lg-12 ">
                            <Swiper
                                loop={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                onSwiper={setSwiperRef}
                                keyboard={true}
                                breakpoints={{
                                    920: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 40,
                                    },
                                    1024: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                    },
                                }}

                                modules={[Autoplay]}
                                className="mySwiper"
                            >
                                <SwiperSlide className="swiper-slide-card swiper-slide-card-1">
                                    <div className="card h-100 p-2">
                                        <div className="card-body">
                                            <img src={IconChem5} alt="icon" className="chemicalmanu-new-image-2" />
                                            <h1 className="aerospace-new-text-3">Process Simulation and Modeling</h1>
                                            <p className="aerospace-new-text-4 mb-1">Optimize Efficiency and Drive Innovation.</p>
                                            <p className="aerospace-new-text-4 ">In the competitive landscape of chemical manufacturing, optimizing processes and product quality is essential for success. Upstrox's Process Simulation and Modeling solutions empower you to achieve these objectives by providing advanced tools for designing, analyzing, and optimizing chemical manufacturing processes.Our software solutions enable you to create virtual process models, simulate various scenarios, and analyze key performance indicators. By accurately predicting outcomes and optimizing process parameters, you can reduce costs, minimize waste, improve energy efficiency, and enhance product quality.Harness the power of Process Simulation and Modeling to drive efficiency, innovation, and sustainable growth in your chemical manufacturing operations.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiper-slide-card swiper-slide-card-2">
                                    <div className="card h-100 p-2">
                                        <div className="card-body">
                                            <img src={IconChem6} alt="icon" className="chemicalmanu-new-image-2" />
                                            <h1 className="aerospace-new-text-3">Supply Chain Management (SCM) Systems</h1>
                                            <p className="aerospace-new-text-4 mb-1">Optimize Your Supply Chain for Success.</p>
                                            <p className="aerospace-new-text-4 ">In the fast-paced and complex world of chemical manufacturing, efficient supply chain management is crucial for maintaining a competitive edge. Upstrox's Supply Chain Management (SCM) systems offer comprehensive solutions that enable you to streamline your supply chain, enhance visibility, and drive operational efficiency.Our SCM systems provide end-to-end visibility into your supply chain, from procurement to distribution. With features such as demand forecasting, inventory management, supplier relationship management, and logistics optimization, you can reduce costs, minimize stockouts, and ensure timely delivery of products to customers.Partner with Upstrox and unlock the power of SCM systems to optimize your supply chain, improve customer satisfaction, and gain a significant advantage in the market.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiper-slide-card swiper-slide-card-3">
                                    <div className="card h-100 p-2">
                                        <div className="card-body">
                                            <img src={IconChem7} alt="icon" className="chemicalmanu-new-image-2" />
                                            <h1 className="aerospace-new-text-3">Quality Management Systems (QMS)</h1>
                                            <p className="aerospace-new-text-4 mb-1">Deliver Excellence Through Quality.</p>
                                            <p className="aerospace-new-text-4 ">In the chemical manufacturing industry, ensuring consistent product quality, adhering to regulatory standards, and meeting customer expectations are critical for success. Upstrox's Quality Management Systems (QMS) offer comprehensive solutions to help you achieve these objectives.Our QMS solutions provide robust quality control processes, document management, deviation tracking, and corrective action management. With advanced analytics and reporting capabilities, you can monitor key quality metrics, identify trends, and proactively address potential issues.Partner with Upstrox and unlock the power of QMS to drive excellence in quality management, enhance customer satisfaction, and maintain a strong reputation in the chemical manufacturing industry.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiper-slide-card swiper-slide-card-4">
                                    <div className="card h-100 p-2">
                                        <div className="card-body">
                                            <img src={IconChem8} alt="icon" className="chemicalmanu-new-image-2" />
                                            <h1 className="aerospace-new-text-3">Environmental, Health, and Safety (EHS) Management Systems</h1>
                                            <p className="aerospace-new-text-4 mb-1">Prioritize Safety and Sustainability.</p>
                                            <p className="aerospace-new-text-4 ">Safety and sustainability are paramount in the chemical manufacturing industry. Upstrox's Environmental, Health, and Safety (EHS) Management Systems provide comprehensive solutions to help you effectively manage environmental impacts, occupational health, and safety risks.Our EHS solutions enable you to track and monitor compliance with regulations, implement safety protocols, conduct risk assessments, and manage incidents and audits. With real-time visibility into EHS performance and actionable insights, you can minimize risks, ensure regulatory compliance, and foster a culture of safety and sustainability.Unlock the power of EHS Management Systems to prioritize safety, sustainability, and responsible practices in your chemical manufacturing operations.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiper-slide-card swiper-slide-card-5">
                                    <div className="card h-100 p-2">
                                        <div className="card-body">
                                            <img src={IconChem9} alt="icon" className="chemicalmanu-new-image-2" />
                                            <h1 className="aerospace-new-text-3">Asset Management Systems</h1>
                                            <p className="aerospace-new-text-4 mb-1">Maximize the Value of Your Assets.</p>
                                            <p className="aerospace-new-text-4 ">In the asset-intensive world of chemical manufacturing, effective management and maintenance of equipment and facilities are essential for maximizing operational efficiency and minimizing downtime. Upstrox's Asset Management Systems offer comprehensive solutions to help you optimize the lifecycle management of your assets.Our asset management solutions provide features such as asset tracking, preventive maintenance scheduling, condition monitoring, and performance analysis. With real-time insights into asset health and utilization, you can make data-driven decisions, reduce maintenance costs, and extend the lifespan of your assets.Partner with Upstrox and unlock the power of Asset Management Systems to optimize your asset performance, minimize downtime, and maximize the value of your investments.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiper-slide-card swiper-slide-card-6">
                                    <div className="card h-100 p-2">
                                        <div className="card-body">
                                            <img src={IconChem10} alt="icon" className="chemicalmanu-new-image-2" />
                                            <h1 className="aerospace-new-text-3">Data Analytics and Business Intelligence (BI) Tools</h1>
                                            <p className="aerospace-new-text-4 mb-1">Harness the Power of Data for Success.</p>
                                            <p className="aerospace-new-text-4 ">In the era of digital transformation, leveraging data to gain actionable insights is essential for staying competitive in the chemical manufacturing industry. Upstrox's Data Analytics and Business Intelligence (BI) Tools provide advanced solutions to help you harness the power of data for informed decision-making and business growth.Our data analytics and BI tools enable you to collect, analyze, and visualize data from various sources, allowing you to uncover trends, identify opportunities, and optimize operations. With intuitive dashboards and reports, you can monitor key performance indicators, track market trends, and make data-driven decisions with confidence.Unlock the power of Data Analytics and Business Intelligence Tools to drive innovation, optimize performance, and achieve sustainable growth in the chemical manufacturing industry.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiper-slide-card swiper-slide-card-7">
                                    <div className="card h-100 p-2">
                                        <div className="card-body">
                                            <img src={IconChem11} alt="icon" className="chemicalmanu-new-image-2" />
                                            <h1 className="aerospace-new-text-3">Cybersecurity Solutions</h1>
                                            <p className="aerospace-new-text-4 mb-1">Protect Your Business from Cyber Threats.</p>
                                            <p className="aerospace-new-text-4 ">In the digital age, protecting your critical data and systems from cyber threats is paramount. Upstrox's Cybersecurity Solutions offer comprehensive strategies and technologies to safeguard your chemical manufacturing operations from potential cyber attacks.Our cybersecurity solutions encompass robust firewall systems, intrusion detection and prevention systems, data encryption, access controls, and employee awareness training. By implementing industry-leading security measures, you can mitigate risks, protect sensitive data, ensure regulatory compliance, and maintain the trust of your customers.Partner with Upstrox and gain peace of mind knowing that your chemical manufacturing operations are protected by cutting-edge cybersecurity solutions.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiper-slide-card swiper-slide-card-8">
                                    <div className="card h-100 p-2">
                                        <div className="card-body">
                                            <img src={IconChem12} alt="icon" className="chemicalmanu-new-image-2" />
                                            <h1 className="aerospace-new-text-3">Mobile Applications</h1>
                                            <p className="aerospace-new-text-4 mb-1">Empower Your Workforce On the Go.</p>
                                            <p className="aerospace-new-text-4 ">In today's mobile-centric world, empowering your workforce with real-time information and collaboration tools is essential for optimizing productivity and efficiency. Upstrox's Mobile Applications provide intuitive and user-friendly solutions designed to enhance communication and streamline operations in the chemical manufacturing industry.Our mobile applications enable remote access to critical information, facilitate real-time collaboration, and provide on-the-go capabilities for tasks such as inventory management, field inspections, and reporting. By empowering your workforce with mobility, you can improve response times, reduce errors, and enhance overall operational efficiency.Partner with Upstrox and harness the power of Mobile Applications to transform your chemical manufacturing operations and drive productivity and collaboration wherever your employees are.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiper-slide-card swiper-slide-card-9">
                                    <div className="card h-100 p-2">
                                        <div className="card-body">
                                            <img src={IconChem13} alt="icon" className="chemicalmanu-new-image-2" />
                                            <h1 className="aerospace-new-text-3">Internet of Things (IoT) and Industrial Automation</h1>
                                            <p className="aerospace-new-text-4 mb-1">Embrace the Future of Manufacturing.</p>
                                            <p className="aerospace-new-text-4 ">The Internet of Things (IoT) and Industrial Automation technologies are revolutionizing the chemical manufacturing industry by enabling real-time monitoring, predictive maintenance, and enhanced operational efficiency. Upstrox offers comprehensive solutions that leverage IoT and Industrial Automation to drive innovation and competitiveness.Our IoT and Industrial Automation solutions enable the integration of sensors and smart devices to collect real-time data, monitor equipment health, and optimize production processes. By leveraging data-driven insights and automation, you can reduce downtime, improve asset utilization, and enhance product quality.Partner with Upstrox and embrace the future of manufacturing by harnessing the power of IoT and Industrial Automation to drive innovation and achieve sustainable growth in the chemical manufacturing industry.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiper-slide-card swiper-slide-card-10">
                                    <div className="card h-100 p-2">
                                        <div className="card-body">
                                            <img src={IconChem14} alt="icon" className="chemicalmanu-new-image-2" />
                                            <h1 className="aerospace-new-text-3">Artificial Intelligence (AI)</h1>
                                            <p className="aerospace-new-text-4 mb-1">Unlock the Power of Intelligent Solutions.</p>
                                            <p className="aerospace-new-text-4 ">Artificial Intelligence (AI) is transforming the chemical manufacturing industry by enabling advanced data analytics, predictive modeling, and intelligent decision-making. Upstrox offers AI solutions that empower you to unlock the full potential of your data and gain a competitive advantage.Our AI solutions encompass machine learning algorithms, predictive analytics, and cognitive computing capabilities. By analyzing vast amounts of data, our AI solutions can provide valuable insights into production optimization, demand forecasting, predictive maintenance, and quality control.Partner with Upstrox and harness the power of AI to unlock new levels of efficiency, innovation, and competitiveness in the chemical manufacturing industry.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>



                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>

            <section className="chemicalmanufacturing-new-design-2 py-5 d-lg-block d-none">
                <div className="container-fluid col-lg-11 mx-auto py-lg-5">

                    <div className="row">
                        <div className="col-lg-5 ">


                            <div class="tab-content swiper-slide-card" id="v-pills-tabContent">
                                <div class="tab-pane fade show active" id="v-manu-tabs-1" role="tabpanel" aria-labelledby="v-manu-tabs-1-tab">
                                    <div className="card h-100 p-2">
                                        <div className="card-body">
                                            <img src={IconChem5} alt="icon" className="chemicalmanu-new-image-2-1" />
                                            <h1 className="aerospace-new-text-3">Process Simulation and Modeling</h1>
                                            <p className="aerospace-new-text-4 mb-1">Optimize Efficiency and Drive Innovation.</p>
                                            <p className="aerospace-new-text-4 ">In the competitive landscape of chemical manufacturing, optimizing processes and product quality is essential for success. Upstrox's Process Simulation and Modeling solutions empower you to achieve these objectives by providing advanced tools for designing, analyzing, and optimizing chemical manufacturing processes.Our software solutions enable you to create virtual process models, simulate various scenarios, and analyze key performance indicators. By accurately predicting outcomes and optimizing process parameters, you can reduce costs, minimize waste, improve energy efficiency, and enhance product quality.Harness the power of Process Simulation and Modeling to drive efficiency, innovation, and sustainable growth in your chemical manufacturing operations.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="v-manu-tabs-2" role="tabpanel" aria-labelledby="v-manu-tabs-2-tab">
                                    <div className="card h-100 p-2">
                                        <div className="card-body">
                                            <img src={IconChem6} alt="icon" className="chemicalmanu-new-image-2-1" />
                                            <h1 className="aerospace-new-text-3">Supply Chain Management (SCM) Systems</h1>
                                            <p className="aerospace-new-text-4 mb-1">Optimize Your Supply Chain for Success.</p>
                                            <p className="aerospace-new-text-4 ">In the fast-paced and complex world of chemical manufacturing, efficient supply chain management is crucial for maintaining a competitive edge. Upstrox's Supply Chain Management (SCM) systems offer comprehensive solutions that enable you to streamline your supply chain, enhance visibility, and drive operational efficiency.Our SCM systems provide end-to-end visibility into your supply chain, from procurement to distribution. With features such as demand forecasting, inventory management, supplier relationship management, and logistics optimization, you can reduce costs, minimize stockouts, and ensure timely delivery of products to customers.Partner with Upstrox and unlock the power of SCM systems to optimize your supply chain, improve customer satisfaction, and gain a significant advantage in the market.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="v-manu-tabs-3" role="tabpanel" aria-labelledby="v-manu-tabs-3-tab">
                                    <div className="card h-100 p-2">
                                        <div className="card-body">
                                            <img src={IconChem7} alt="icon" className="chemicalmanu-new-image-2-1" />
                                            <h1 className="aerospace-new-text-3">Quality Management Systems (QMS)</h1>
                                            <p className="aerospace-new-text-4 mb-1">Deliver Excellence Through Quality.</p>
                                            <p className="aerospace-new-text-4 ">In the chemical manufacturing industry, ensuring consistent product quality, adhering to regulatory standards, and meeting customer expectations are critical for success. Upstrox's Quality Management Systems (QMS) offer comprehensive solutions to help you achieve these objectives.Our QMS solutions provide robust quality control processes, document management, deviation tracking, and corrective action management. With advanced analytics and reporting capabilities, you can monitor key quality metrics, identify trends, and proactively address potential issues.Partner with Upstrox and unlock the power of QMS to drive excellence in quality management, enhance customer satisfaction, and maintain a strong reputation in the chemical manufacturing industry.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="v-manu-tabs-4" role="tabpanel" aria-labelledby="v-manu-tabs-4-tab">
                                    <div className="card h-100 p-2">
                                        <div className="card-body">
                                            <img src={IconChem8} alt="icon" className="chemicalmanu-new-image-2-1" />
                                            <h1 className="aerospace-new-text-3">Environmental, Health, and Safety (EHS) Management Systems</h1>
                                            <p className="aerospace-new-text-4 mb-1">Prioritize Safety and Sustainability.</p>
                                            <p className="aerospace-new-text-4 ">Safety and sustainability are paramount in the chemical manufacturing industry. Upstrox's Environmental, Health, and Safety (EHS) Management Systems provide comprehensive solutions to help you effectively manage environmental impacts, occupational health, and safety risks.Our EHS solutions enable you to track and monitor compliance with regulations, implement safety protocols, conduct risk assessments, and manage incidents and audits. With real-time visibility into EHS performance and actionable insights, you can minimize risks, ensure regulatory compliance, and foster a culture of safety and sustainability.Unlock the power of EHS Management Systems to prioritize safety, sustainability, and responsible practices in your chemical manufacturing operations.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="v-manu-tabs-5" role="tabpanel" aria-labelledby="v-manu-tabs-5-tab">
                                    <div className="card h-100 p-2">
                                        <div className="card-body">
                                            <img src={IconChem9} alt="icon" className="chemicalmanu-new-image-2-1" />
                                            <h1 className="aerospace-new-text-3">Asset Management Systems</h1>
                                            <p className="aerospace-new-text-4 mb-1">Maximize the Value of Your Assets.</p>
                                            <p className="aerospace-new-text-4 ">In the asset-intensive world of chemical manufacturing, effective management and maintenance of equipment and facilities are essential for maximizing operational efficiency and minimizing downtime. Upstrox's Asset Management Systems offer comprehensive solutions to help you optimize the lifecycle management of your assets.Our asset management solutions provide features such as asset tracking, preventive maintenance scheduling, condition monitoring, and performance analysis. With real-time insights into asset health and utilization, you can make data-driven decisions, reduce maintenance costs, and extend the lifespan of your assets.Partner with Upstrox and unlock the power of Asset Management Systems to optimize your asset performance, minimize downtime, and maximize the value of your investments.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="v-manu-tabs-6" role="tabpanel" aria-labelledby="v-manu-tabs-6-tab">
                                    <div className="card h-100 p-2">
                                        <div className="card-body">
                                            <img src={IconChem10} alt="icon" className="chemicalmanu-new-image-2-1" />
                                            <h1 className="aerospace-new-text-3">Data Analytics and Business Intelligence (BI) Tools</h1>
                                            <p className="aerospace-new-text-4 mb-1">Harness the Power of Data for Success.</p>
                                            <p className="aerospace-new-text-4 ">In the era of digital transformation, leveraging data to gain actionable insights is essential for staying competitive in the chemical manufacturing industry. Upstrox's Data Analytics and Business Intelligence (BI) Tools provide advanced solutions to help you harness the power of data for informed decision-making and business growth.Our data analytics and BI tools enable you to collect, analyze, and visualize data from various sources, allowing you to uncover trends, identify opportunities, and optimize operations. With intuitive dashboards and reports, you can monitor key performance indicators, track market trends, and make data-driven decisions with confidence.Unlock the power of Data Analytics and Business Intelligence Tools to drive innovation, optimize performance, and achieve sustainable growth in the chemical manufacturing industry.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="v-manu-tabs-7" role="tabpanel" aria-labelledby="v-manu-tabs-7-tab">
                                    <div className="card h-100 p-2">
                                        <div className="card-body">
                                            <img src={IconChem11} alt="icon" className="chemicalmanu-new-image-2-1" />
                                            <h1 className="aerospace-new-text-3">Cybersecurity Solutions</h1>
                                            <p className="aerospace-new-text-4 mb-1">Protect Your Business from Cyber Threats.</p>
                                            <p className="aerospace-new-text-4 ">In the digital age, protecting your critical data and systems from cyber threats is paramount. Upstrox's Cybersecurity Solutions offer comprehensive strategies and technologies to safeguard your chemical manufacturing operations from potential cyber attacks.Our cybersecurity solutions encompass robust firewall systems, intrusion detection and prevention systems, data encryption, access controls, and employee awareness training. By implementing industry-leading security measures, you can mitigate risks, protect sensitive data, ensure regulatory compliance, and maintain the trust of your customers.Partner with Upstrox and gain peace of mind knowing that your chemical manufacturing operations are protected by cutting-edge cybersecurity solutions.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="v-manu-tabs-8" role="tabpanel" aria-labelledby="v-manu-tabs-8-tab">
                                    <div className="card h-100 p-2">
                                        <div className="card-body">
                                            <img src={IconChem12} alt="icon" className="chemicalmanu-new-image-2-1" />
                                            <h1 className="aerospace-new-text-3">Mobile Applications</h1>
                                            <p className="aerospace-new-text-4 mb-1">Empower Your Workforce On the Go.</p>
                                            <p className="aerospace-new-text-4 ">In today's mobile-centric world, empowering your workforce with real-time information and collaboration tools is essential for optimizing productivity and efficiency. Upstrox's Mobile Applications provide intuitive and user-friendly solutions designed to enhance communication and streamline operations in the chemical manufacturing industry.Our mobile applications enable remote access to critical information, facilitate real-time collaboration, and provide on-the-go capabilities for tasks such as inventory management, field inspections, and reporting. By empowering your workforce with mobility, you can improve response times, reduce errors, and enhance overall operational efficiency.Partner with Upstrox and harness the power of Mobile Applications to transform your chemical manufacturing operations and drive productivity and collaboration wherever your employees are.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="v-manu-tabs-9" role="tabpanel" aria-labelledby="v-manu-tabs-9-tab">
                                    <div className="card h-100 p-2">
                                        <div className="card-body">
                                            <img src={IconChem13} alt="icon" className="chemicalmanu-new-image-2-1" />
                                            <h1 className="aerospace-new-text-3">Internet of Things (IoT) and Industrial Automation</h1>
                                            <p className="aerospace-new-text-4 mb-1">Embrace the Future of Manufacturing.</p>
                                            <p className="aerospace-new-text-4 ">The Internet of Things (IoT) and Industrial Automation technologies are revolutionizing the chemical manufacturing industry by enabling real-time monitoring, predictive maintenance, and enhanced operational efficiency. Upstrox offers comprehensive solutions that leverage IoT and Industrial Automation to drive innovation and competitiveness.Our IoT and Industrial Automation solutions enable the integration of sensors and smart devices to collect real-time data, monitor equipment health, and optimize production processes. By leveraging data-driven insights and automation, you can reduce downtime, improve asset utilization, and enhance product quality.Partner with Upstrox and embrace the future of manufacturing by harnessing the power of IoT and Industrial Automation to drive innovation and achieve sustainable growth in the chemical manufacturing industry.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="v-manu-tabs-10" role="tabpanel" aria-labelledby="v-manu-tabs-10-tab">
                                    <div className="card h-100 p-2">
                                        <div className="card-body">
                                            <img src={IconChem14} alt="icon" className="chemicalmanu-new-image-2-1" />
                                            <h1 className="aerospace-new-text-3">Artificial Intelligence (AI)</h1>
                                            <p className="aerospace-new-text-4 mb-1">Unlock the Power of Intelligent Solutions.</p>
                                            <p className="aerospace-new-text-4 ">Artificial Intelligence (AI) is transforming the chemical manufacturing industry by enabling advanced data analytics, predictive modeling, and intelligent decision-making. Upstrox offers AI solutions that empower you to unlock the full potential of your data and gain a competitive advantage.Our AI solutions encompass machine learning algorithms, predictive analytics, and cognitive computing capabilities. By analyzing vast amounts of data, our AI solutions can provide valuable insights into production optimization, demand forecasting, predictive maintenance, and quality control.Partner with Upstrox and harness the power of AI to unlock new levels of efficiency, innovation, and competitiveness in the chemical manufacturing industry.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div class="nav nav-pills row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 chemicalmanufacturing-new-design-2-inner-2" id="v-pills-tab" role="tablist">
                                <div className="col">
                                    <div class="card h-100 nav-link active" id="v-manu-tabs-1-tab" data-bs-toggle="pill" data-bs-target="#v-manu-tabs-1" type="button" role="tab" aria-controls="v-manu-tabs-1" aria-selected="true">
                                        <div class="card-body">
                                            <img src={IconChem5} alt="icon" className="chemicalmanu-new-image-2" />
                                            <h5 class="chemicalmanu-new-text-1">Process Simulation and Modeling</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div class="nav-link card h-100" id="v-manu-tabs-2-tab" data-bs-toggle="pill" data-bs-target="#v-manu-tabs-2" type="button" role="tab" aria-controls="v-manu-tabs-2" aria-selected="false">
                                        <div class="card-body">
                                            <img src={IconChem6} alt="icon" className="chemicalmanu-new-image-2" />
                                            <h5 class="chemicalmanu-new-text-1">Supply Chain Management (SCM) Systems</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div class="nav-link card h-100" id="v-manu-tabs-3-tab" data-bs-toggle="pill" data-bs-target="#v-manu-tabs-3" type="button" role="tab" aria-controls="v-manu-tabs-3" aria-selected="false">
                                        <div class="card-body">
                                            <img src={IconChem7} alt="icon" className="chemicalmanu-new-image-2" />
                                            <h5 class="chemicalmanu-new-text-1">Quality Management Systems (QMS)</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div class="nav-link card h-100" id="v-manu-tabs-4-tab" data-bs-toggle="pill" data-bs-target="#v-manu-tabs-4" type="button" role="tab" aria-controls="v-manu-tabs-4" aria-selected="false">
                                        <div class="card-body">
                                            <img src={IconChem8} alt="icon" className="chemicalmanu-new-image-2" />
                                            <h5 class="chemicalmanu-new-text-1">Environmental, Health, and Safety (EHS) Management Systems</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div class="nav-link card h-100" id="v-manu-tabs-5-tab" data-bs-toggle="pill" data-bs-target="#v-manu-tabs-5" type="button" role="tab" aria-controls="v-manu-tabs-5" aria-selected="false">
                                        <div class="card-body">
                                            <img src={IconChem9} alt="icon" className="chemicalmanu-new-image-2" />
                                            <h5 class="chemicalmanu-new-text-1">Asset Management Systems</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div class="nav-link card h-100" id="v-manu-tabs-6-tab" data-bs-toggle="pill" data-bs-target="#v-manu-tabs-6" type="button" role="tab" aria-controls="v-manu-tabs-6" aria-selected="false">
                                        <div class="card-body">
                                            <img src={IconChem10} alt="icon" className="chemicalmanu-new-image-2" />
                                            <h5 class="chemicalmanu-new-text-1">Data Analytics and Business Intelligence (BI) Tools</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div class="nav-link card h-100" id="v-manu-tabs-7-tab" data-bs-toggle="pill" data-bs-target="#v-manu-tabs-7" type="button" role="tab" aria-controls="v-manu-tabs-7" aria-selected="false">
                                        <div class="card-body">
                                            <img src={IconChem11} alt="icon" className="chemicalmanu-new-image-2" />
                                            <h5 class="chemicalmanu-new-text-1">Cybersecurity Solutions</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div class="nav-link card h-100" id="v-manu-tabs-8-tab" data-bs-toggle="pill" data-bs-target="#v-manu-tabs-8" type="button" role="tab" aria-controls="v-manu-tabs-8" aria-selected="false">
                                        <div class="card-body">
                                            <img src={IconChem12} alt="icon" className="chemicalmanu-new-image-2" />
                                            <h5 class="chemicalmanu-new-text-1">Mobile Applications</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div class="nav-link card h-100" id="v-manu-tabs-9-tab" data-bs-toggle="pill" data-bs-target="#v-manu-tabs-9" type="button" role="tab" aria-controls="v-manu-tabs-9" aria-selected="false">
                                        <div class="card-body">
                                            <img src={IconChem13} alt="icon" className="chemicalmanu-new-image-2" />
                                            <h5 class="chemicalmanu-new-text-1">Internet of Things (IoT) and Industrial Automation</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div class="nav-link card h-100" id="v-manu-tabs-10-tab" data-bs-toggle="pill" data-bs-target="#v-manu-tabs-10" type="button" role="tab" aria-controls="v-manu-tabs-10" aria-selected="false">
                                        <div class="card-body">
                                            <img src={IconChem14} alt="icon" className="chemicalmanu-new-image-2" />
                                            <h5 class="chemicalmanu-new-text-1">Artificial Intelligence (AI)</h5>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="aerospace-new-design-2 py-5">
                <div className="container">
                    <div className="row g-4 justify-content-center text-center">
                        <div className="col-lg-12">
                            <p className="aerospace-new-text-6 mb-lg-4">Contact us today to explore how Upstrox can transform your Chemical Manufacturing business.</p>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    );
}

export default ChemicalManufacturing;