
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { BiRightArrowAlt } from 'react-icons/bi';

import  iconinfo1  from "../../Assets/images/icon/Information Services - Publishing/content-management-system.webp"
import iconinfo2 from "../../Assets/images/icon/Information Services - Publishing/Digital Asset Management.webp"
import iconinfo3 from "../../Assets/images/icon/Information Services - Publishing/Web Development and Design.webp"
import iconinfo4 from "../../Assets/images/icon/Information Services - Publishing/Subscription Management Systems.webp"
import iconinfo5 from "../../Assets/images/icon/Information Services - Publishing/Digital Publishing Platforms.webp";
import iconinfo6 from "../../Assets/images/icon/Information Services - Publishing/Data Analytics and Business Intelligence.webp";
import iconinfo7 from "../../Assets/images/icon/Information Services - Publishing/E-commerce and Monetization Solutions.webp";
import iconinfo8 from "../../Assets/images/icon/Information Services - Publishing/Mobile App Development.webp";
import iconinfo9 from "../../Assets/images/icon/Information Services - Publishing/Cybersecurity and Data Protection.webp";
import iconinfo10 from "../../Assets/images/icon/Information Services - Publishing/IT Consulting and Strategy.webp";



import ContactNew from "../../Assets/images/icon/Information Services - Publishing/information services image.webp"
import Informationservicebg from "../../Assets/images/servies-images/information-services-and-publishing.webp"


function Informationservice() {
  return (
    <>
    <div className="informationservice">
      <section className="top-banner-section top-banner-section-overral-bg-section back-posi-img" style={{ backgroundImage: "url(" + Informationservicebg + ")" }}>
        <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
          <div className="container">
            <div className="row align-items-center">
              <div className="col">
                <h1 className="top-text-one">Upstrox - Your Trusted Partner for
                  <span className="text-color"> Information Services & Publishing</span> IT Solutions
                </h1>
                <div className="top-text-two">
                  In the dynamic and data-driven insurance industry, technology plays a vital role in streamlining operations, enhancing customer experiences, and managing risks effectively. At Upstrox, we specialize in providing comprehensive IT services tailored specifically for the insurance sector.
                </div>
              </div>
            </div>
            <div className="pt-3">
              <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
            </div>
          </div>
        </div>
      </section>

      <section className="top-banner-section-two pb-4 pt-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <h5 className="text-one">Whether you are a media company, content provider, or publishing house, we have the expertise to support your IT needs. Here are the key IT services we offer:</h5>
            </div>
          </div>
        </div>
      </section>



      <section className="automotive-new-design-2 pb-4 pt-5">
        <div className="container-fluid col-lg-10 mx-auto">
          <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 justify-content-center">
            <div class="col">
              <div class="card h-100">
                <div class="card-body">
                  <img src={iconinfo1} alt="informationservice" className="automotive-new-text-2 info-img" />
                  <h5 class="automotive-new-text-1">Content Management Systems (CMS)</h5>
                  <p class="automotive-new-text-3">We provide robust CMS solutions that enable efficient content creation, editing, and publishing. Our CMS platforms offer intuitive interfaces, workflow management, and content version control, ensuring streamlined content production processes.</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <div class="card-body">
                  <img src={iconinfo2} alt="informationservice" className="automotive-new-text-2 info-img" />
                  <h5 class="automotive-new-text-1">Digital Asset Management (DAM)</h5>
                  <p class="automotive-new-text-3">We offer DAM solutions that centralize and organize digital assets, including images, videos, and documents. Our DAM systems facilitate easy search, retrieval, and distribution of assets, enhancing content creation and distribution workflows.</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <div class="card-body">
                <img src={iconinfo3} alt="informationservice" className="automotive-new-text-2 info-img" />
                  <h5 class="automotive-new-text-1">Web Development and Design</h5>
                  <p class="automotive-new-text-3">We provide custom web development and design services tailored to the information services and publishing industry. From responsive websites and user-friendly interfaces to interactive multimedia content, we help you create engaging digital experiences for your audience.</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <div class="card-body">
                <img src={iconinfo4} alt="informationservice" className="automotive-new-text-2 info-img" />
                  <h5 class="automotive-new-text-1">Subscription Management Systems</h5>
                  <p class="automotive-new-text-3">We offer subscription management systems that streamline the management of digital subscriptions, including user registration, billing, and access control. Our solutions enable you to monetize content, manage subscriber data, and enhance customer experiences.</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <div class="card-body">
                <img src={iconinfo5} alt="informationservice" className="automotive-new-text-2 info-img" />
                  <h5 class="automotive-new-text-1">Digital Publishing Platforms</h5>
                  <p class="automotive-new-text-3">We assist in developing and implementing digital publishing platforms for e-books, magazines, and other digital publications. Our solutions cover content formatting, distribution, and monetization, ensuring seamless access to digital content across devices.</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <div class="card-body">
                <img src={iconinfo6} alt="informationservice" className="automotive-new-text-2 info-img" />
                  <h5 class="automotive-new-text-1">Data Analytics and Business Intelligence</h5>
                  <p class="automotive-new-text-3">Our data analytics and business intelligence solutions help information services and publishing companies derive valuable insights from data. We assist in analyzing user behavior, content consumption patterns, and market trends to drive informed decision-making and optimize content strategies.</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <div class="card-body">
                <img src={iconinfo7} alt="informationservice" className="automotive-new-text-2 info-img" />
                  <h5 class="automotive-new-text-1">E-commerce and Monetization Solutions</h5>
                  <p class="automotive-new-text-3">We provide e-commerce and monetization solutions that enable you to sell digital content, implement paywalls, and manage subscriptions. Our solutions integrate secure payment gateways, order management, and customer support, ensuring a seamless digital revenue generation process.</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <div class="card-body">
                <img src={iconinfo8} alt="informationservice" className="automotive-new-text-2 info-img" />
                  <h5 class="automotive-new-text-1">Mobile App Development</h5>
                  <p class="automotive-new-text-3">We offer mobile app development services for information services and publishing companies. Whether it's a content delivery app, news aggregator, or interactive learning platform, we create engaging mobile experiences that cater to your target audience.</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <div class="card-body">
                <img src={iconinfo9} alt="informationservice" className="automotive-new-text-2 info-img" />
                  <h5 class="automotive-new-text-1">Cybersecurity and Data Protection</h5>
                  <p class="automotive-new-text-3">Protecting intellectual property, user data, and content distribution channels is crucial in the information services and publishing industry. We provide comprehensive cybersecurity services, including content security, access controls, data encryption, and vulnerability assessments, ensuring data protection and compliance.</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <div class="card-body">
                <img src={iconinfo10} alt="informationservice" className="automotive-new-text-2 info-img" />
                  <h5 class="automotive-new-text-1">IT Consulting and Strategy</h5>
                  <p class="automotive-new-text-3">Our experienced consultants offer IT consulting services to information services and publishing organizations, providing guidance on technology strategy, digital transformation, and content monetization. We help you leverage emerging technologies and optimize your IT infrastructure to meet your business goals.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="aerospace-new-design-2 py-5">
        <div className="container">
          <div className="row g-4 flex-lg-row-reverse align-items-center">
            <div className="col-lg-4">
              <img src={ContactNew} alt="informationservice" />
            </div>
            <div className="col-lg-8">
              <p className="aerospace-new-text-5 mb-lg-4">At Upstrox, we understand the unique challenges and opportunities in the information services and publishing industry. Our comprehensive IT services empower organizations to deliver compelling content, engage their audience, and drive revenue growth. </p>
              <a href='/contact' className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
            </div>
          </div>
        </div>
      </section>

      <section className="aerospace-new-design-2 py-5">
        <div className="container">
          <div className="row g-4 justify-content-center text-center">
            <div className="col-lg-12">
              <p className="aerospace-new-text-6 mb-lg-4">Contact us today to discuss your information services and publishing IT needs and explore how we can support your organization's success.</p>
            </div>
          </div>
        </div>
      </section>
      </div>


    </>
  )
}
export default Informationservice;