import { MdOutlineArrowForwardIos } from "react-icons/md";
import { BiRightArrowAlt } from "react-icons/bi";
import iconcom1 from "../../Assets/images/icon/communication services/Network Infrastructure Services.webp";
import iconcom2 from "../../Assets/images/icon/communication services/Telecommunications Systems.webp";
import iconcom3 from "../../Assets/images/icon/communication services/Data Center Services.webp";
import iconcom4 from "../../Assets/images/icon/communication services/Cloud Services.webp";
import iconcom5 from "../../Assets/images/icon/communication services/Network Security.webp";
import iconcom6 from "../../Assets/images/icon/communication services/Mobile and Wireless Solutions.webp";
import iconcom7 from "../../Assets/images/icon/communication services/Customer Relationship Management.webp";
import iconcom8 from "../../Assets/images/icon/communication services/Billing and Revenue Management Systems.webp";
import iconcom9 from "../../Assets/images/icon/communication services/Content Management Systems.webp";
import iconcom10 from "../../Assets/images/icon/communication services/Data Analytics and Business Intelligence.webp";
import iconcom11 from "../../Assets/images/icon/communication services/Internet of Things (IoT).webp";
import iconcom12 from "../../Assets/images/icon/communication services/Digital Transformation Services.webp";
import iconcom13 from "../../Assets/images/icon/communication services/IT Consulting and Professional Services.webp";
import iconcom14 from "../../Assets/images/icon/communication services/IT Support and Helpdesk Services.webp";
import iconcom15 from "../../Assets/images/icon/communication services/Regulatory Compliance Solutions.webp";
import consumerlast from "../../Assets/images/icon/communication services/communication services image.webp"
import Communicationservicebg from "../../Assets/images/servies-images/communication-services.webp"

function Communicationservice() {
  return (
    <>
      <div className="communication-banner-section">
        <section className="top-banner-section top-banner-section-overral-bg-section back-posi-img" style={{ backgroundImage: "url(" + Communicationservicebg + ")" }}>
          <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="top-text-one">Welcome to Upstrox: Your IT Partner in
                    <span className="text-color"> Communication Services</span>
                  </h1>
                  <div className="top-text-two">
                    At Upstrox, we understand the critical role that effective communication plays in the success of businesses across various industries. In today's interconnected world, having reliable and efficient communication services is essential for seamless operations, enhanced productivity, and superior customer experiences.
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <a href="/contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
              </div>
            </div>
          </div>
        </section>

        <section className="consumer-new-cards-section pt-5">
          <div className="container-fluid col-lg-10 position-relative pb-lg-5">
            <div className="row">
              {/* <div className="consumer-blur"></div> */}
              <div className="col-lg-4">
                <h5 className="aerospace-new-text-1">Communication Services</h5>
                <p className="aerospace-new-text-2">
                  As a trusted provider of comprehensive IT solutions, we specialize in delivering cutting-edge communication services tailored to meet the unique needs of our clients.
                </p>
              </div>
              <div className="col-lg-8">
                <div className="d-lg-flex d-md-flex">
                  <div>
                    {" "}
                    <img
                      src={iconcom1}
                      className="con-img-one"
                      alt="communication"
                    />
                  </div>
                  <div className="">
                    <h3 className="card-text-one">
                      Network Infrastructure Services:
                    </h3>
                    <p className="card-text-two">
                      A robust and scalable network infrastructure forms the backbone of any communication service. At Upstrox, we offer network infrastructure services that encompass designing, implementing, and managing advanced wired and wireless networks. Our experts ensure your network infrastructure is optimized for speed, reliability, and security, enabling uninterrupted communication and data transfer.
                    </p>
                  </div>
                </div>
                <div className="d-lg-flex d-md-flex">
                  <div>
                    {" "}
                    <img
                      src={iconcom2}
                      className="con-img-one"
                      alt="communication"
                    />
                  </div>
                  <div className="">
                    <h3 className="card-text-one">
                      Telecommunications Systems
                    </h3>
                    <p className="card-text-two">
                      In the fast-paced world of communication, staying connected is crucial. Upstrox provides state-of-the-art telecommunications systems to empower your business with efficient voice and data communication capabilities. Our solutions include VoIP, PBX, unified communications, video conferencing, and collaboration tools. With our telecommunications systems, you can foster seamless communication and collaboration across teams and locations.
                    </p>
                  </div>
                </div>
                <div className="d-lg-flex d-md-flex">
                  <div>
                    {" "}
                    <img
                      src={iconcom3}
                      className="con-img-one"
                      alt="communication"
                    />
                  </div>
                  <div>
                    <h3 className="card-text-one">
                      Data Center Services
                    </h3>
                    <p className="card-text-two">
                      Data centers are the nerve centers that house critical infrastructure and support the communication industry's operations. Upstrox offers comprehensive data center services, including design, implementation, and management of secure and efficient data centers. We ensure your data center is equipped with the latest technologies, such as server virtualization and disaster recovery solutions, to safeguard your valuable data and ensure business continuity.
                    </p>
                  </div>
                </div>
                <div className="d-lg-flex d-md-flex">
                  <div>
                    {" "}
                    <img
                      src={iconcom4}
                      className="con-img-one"
                      alt="communication"
                    />
                  </div>
                  <div>
                    <h3 className="card-text-one">
                      Cloud Services
                    </h3>
                    <p className="card-text-two">
                      Cloud computing has revolutionized the communication industry by providing scalability, flexibility, and cost-effectiveness. Upstrox offers a range of cloud services, including Infrastructure as a Service (IaaS), Platform as a Service (PaaS), and Software as a Service (SaaS). Our cloud solutions enable you to seamlessly scale your resources, optimize costs, and enhance collaboration and productivity.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-5 con-card-two">
            <div className="row row-cols-lg-3 row-cols-1 row-cols-md-2  pb-lg-4">
              <div className="col con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={iconcom5}
                      className="con-icon-top"
                      alt="communication"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                      Network Security
                    </h3>
                    <p className="card-text-two">
                      {" "}
                      In an era of increasing cyber threats, ensuring network security is paramount for communication service providers. Upstrox specializes in implementing robust network security measures to protect your infrastructure, data, and communication systems. We employ advanced firewall systems, intrusion detection and prevention systems, data encryption, and secure remote access solutions to safeguard your network from malicious activities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={iconcom6}
                      className="con-icon-top"
                      alt="communication"
                    />
                  </div>
                  <div className="pt-lg-4 ">
                    <h3 className="card-text-one">
                      Mobile and Wireless Solutions
                    </h3>
                    <p className="card-text-two">
                      In today's mobile-driven world, having mobile and wireless solutions is essential for communication service providers. Upstrox offers mobile application development, mobile device management, and wireless network solutions to enable seamless connectivity and mobility. Our solutions empower your workforce to stay connected and productive while on the go, ensuring efficient communication and collaboration.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={iconcom7}
                      className="con-icon-top"
                      alt="communication"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                      Customer Relationship Management (CRM) Systems
                    </h3>
                    <p className="card-text-two">
                      Building and maintaining strong customer relationships is vital in the communication industry. Upstrox provides CRM systems designed specifically for communication service providers. Our CRM solutions enable effective management of customer interactions, sales processes, service requests, and customer analytics. By leveraging our CRM systems, you can deliver personalized experiences, enhance customer satisfaction, and drive customer loyalty.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col con-top con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={iconcom8}
                      className="con-icon-top"
                      alt="communication"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                      Billing and Revenue Management Systems
                    </h3>
                    <p className="card-text-two">
                      Effective billing and revenue management are crucial for communication service providers. Upstrox offers comprehensive billing and revenue management systems that accurately capture, process, and manage customer billing, payments, and revenue streams. Our solutions ensure efficient and transparent financial operations, enabling you to maximize revenue and optimize business processes.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={iconcom9}
                      className="con-icon-top"
                      alt="communication"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                      Content Management Systems
                    </h3>
                    <p className="card-text-two">
                      Managing and delivering digital content is a key aspect of the communication industry. Upstrox provides content management systems that enable efficient storage, organization, and delivery of digital content. Whether it's managing websites, media files, or documents, our content management solutions empower you to streamline content creation, editing, publishing, and distribution.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={iconcom10}
                      className="con-icon-top"
                      alt="communication"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                      Data Analytics and Business Intelligence
                    </h3>
                    <p className="card-text-two">
                      Data analytics and business intelligence have become indispensable for communication service providers. Upstrox offers advanced data analytics and business intelligence solutions that help you gain valuable insights into customer behavior, market trends, and operational performance. Our solutions enable you to make informed decisions, drive innovation, and optimize your communication services.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row row-cols-lg-3 row-cols-1 row-cols-md-2  pb-lg-4 mt-lg-5">
              <div className="col con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={iconcom11}
                      className="con-icon-top"
                      alt="communication"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                      Internet of Things (IoT)
                    </h3>
                    <p className="card-text-two">
                      {" "}
                      The Internet of Things (IoT) has transformed the communication industry by connecting devices and enabling smart solutions. Upstrox specializes in leveraging IoT technologies to enhance communication services. We offer IoT solutions that connect and manage devices, enabling smart homes, smart cities, and industrial applications. With our IoT solutions, you can unlock new opportunities, improve efficiency, and deliver innovative services.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={iconcom12}
                      className="con-icon-top"
                      alt="communication"
                    />
                  </div>
                  <div className="pt-lg-4 ">
                    <h3 className="card-text-one">
                      Digital Transformation Services
                    </h3>
                    <p className="card-text-two">
                      In the era of digital transformation, embracing new technologies and optimizing business processes are critical for communication service providers. Upstrox provides digital transformation services to help you stay ahead of the curve. Our experts assist you in formulating digital strategies, adopting new technologies, and optimizing operations for improved efficiency and competitiveness.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={iconcom13}
                      className="con-icon-top"
                      alt="communication"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                      IT Consulting and Professional Services
                    </h3>
                    <p className="card-text-two">
                      Navigating the complex landscape of the communication industry requires expert guidance. Upstrox offers IT consulting and professional services to assist communication service providers in leveraging technology for business growth and success. Our team of experienced professionals provides strategic advice, project management, and technical expertise to address your specific needs and challenges.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col con-top con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={iconcom14}
                      className="con-icon-top"
                      alt="communication"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                      IT Support and Helpdesk Services
                    </h3>
                    <p className="card-text-two">
                      Smooth operations and timely issue resolution are essential for communication service providers. Upstrox offers comprehensive IT support and helpdesk services to ensure uninterrupted service delivery. Our dedicated support team is available round the clock to address your technical queries, troubleshoot issues, and provide prompt assistance, enabling you to focus on your core business activities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col con-top con-top-mobile">
                <div className="card h-100 px-3">
                  <div className="ps-3">
                    <img
                      src={iconcom15}
                      className="con-icon-top"
                      alt="communication"
                    />
                  </div>
                  <div className="pt-lg-4">
                    <h3 className="card-text-one">
                      Regulatory Compliance Solutions
                    </h3>
                    <p className="card-text-two">
                      Compliance with industry regulations and data privacy laws is of utmost importance in the communication industry. Upstrox specializes in providing regulatory compliance solutions tailored for communication service providers. We assist you in ensuring compliance, implementing data protection measures, and adhering to security standards to safeguard sensitive information and maintain trust with your customers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 flex-lg-row-reverse align-items-center">
              <div className="col-lg-4">
                <img src={consumerlast} alt="communication" />
              </div>
              <div className="col-lg-8">
                <h5 className="mb-4">Partner with Upstrox and Transform Your Communication Services</h5>
                <p className="aerospace-new-text-5 mb-lg-4">At Upstrox, we are committed to helping communication service providers thrive in an ever-evolving industry. With our comprehensive range of communication services, we empower you to deliver superior experiences, optimize operations, and stay ahead of the competition. Partner with Upstrox and unlock the full potential of your communication services in the digital age.</p>
                <a href='/contact' className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
              </div>
            </div>
          </div>
        </section>

        <section className="aerospace-new-design-2 py-5">
          <div className="container">
            <div className="row g-4 justify-content-center text-center">
              <div className="col-lg-12">
                <p className="aerospace-new-text-6 mb-lg-4">Contact us today to Transform your Communication service Business.</p>
              </div>
            </div>
          </div>
        </section>


      </div>
    </>
  )
}
export default Communicationservice;