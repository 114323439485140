import { BsBook } from "react-icons/bs";
import { BiBuildings } from "react-icons/bi";
import { MdOutlinePermDeviceInformation } from "react-icons/md";
import { RiVidiconLine } from "react-icons/ri";
import { MdOutlineBookOnline } from "react-icons/md";
import { SiLibrariesdotio } from "react-icons/si";
import { ImDatabase } from "react-icons/im";
import { GiCyberEye } from "react-icons/gi";
import { BiSolidHelpCircle } from "react-icons/bi";
import { TbCloudDollar } from "react-icons/tb";
import { FaAward } from "react-icons/fa";
import { BsDatabaseLock } from "react-icons/bs";
import { RiUser3Fill } from "react-icons/ri";

function Sustainability(){
    return(
        <>
       <div className="sustainability-banner-section">
        <section className="sustainability-banner-section-one">
          <div className="container">
            <div className="row align-items-center">
            <div className="col">
                <div className="sub-text-one text-center">
                At Upstrox, we are deeply committed to sustainability and recognize the importance of integrating environmental and social responsibility into our business practices. We strive to create a positive impact on the planet and society as a whole. Here's how sustainability is embedded in our operations
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sustainability-card-section py-lg-5">
        <div className="container">
         <div className="row">
         <div className="container">
    <div className="row row-cols-1 row-cols-lg-3 g-4 text-center card-container px-lg-3 pb-lg-5">
        <div className="col pt-3 pt-lg-0">
            <div className=" card h-100 ">
                <div className="card-body ">
                    <div className=" pb-lg-3"> <BiBuildings className="sub-icon " style={{"background":"rgb(0 250 255 / 93%)"}}/> </div>
                    <h4 className="card-text-one">
                    Green IT Solutions
                    </h4>
                    <h5 className="card-text-two">
                    We actively promote and implement green IT practices to minimize the environmental impact of technology. By leveraging energy-efficient infrastructure and optimizing resource usage, we help our clients reduce their carbon footprint and achieve greater sustainability in their IT operations.
                    </h5>
                </div>
            </div>
        </div>
        <div className="col pt-3 pt-lg-0">
            <div className=" card h-100 top-card">
                <div className="card-body ">
                    <div className=" pb-lg-3"> <TbCloudDollar className="sub-icon " style={{"background":"rgb(225 86 199)"}}/> </div>
                    <h4 className="card-text-one">
                    Cloud-based Solutions
                    </h4>
                    <h5 className="card-text-two">
                    Our focus on cloud services allows organizations to optimize their infrastructure utilization, reducing the need for physical hardware and energy consumption. This not only enhances operational efficiency but also contributes to a lower overall environmental impact.
                    </h5>
                </div>
            </div>
        </div>
        <div className="col pt-3 pt-lg-0">
            <div className=" card h-100 ">
                <div className="card-body ">
                    <div className=" pb-lg-3"> <FaAward className="sub-icon " style={{"background":"rgb(153 97 46 / 93%)"}}/> </div>
                    <h4 className="card-text-one">
                    Sustainable Partnerships
                    </h4>
                    <h5 className="card-text-two">
                    We actively seek partnerships with suppliers and vendors who share our commitment to sustainability. By collaborating with like-minded organizations, we aim to foster a supply chain that prioritizes ethical and sustainable practices.
                    </h5>
                </div>
            </div>
        </div>
        <div className="col pt-3 pt-lg-0">
            <div className=" card h-100 ">
                <div className="card-body ">
                    <div className=" pb-lg-3"> <BsDatabaseLock className="sub-icon "style={{"background":"rgb(237 56 94 / 93%)"}} /> </div>
                    <h4 className="card-text-one">
                    Data Center Efficiency
                    </h4>
                    <h5 className="card-text-two">
                    We prioritize energy-efficient design and management practices in our data centers, ensuring that our infrastructure operates at peak efficiency with minimal energy waste. By employing advanced cooling technologies, renewable energy sources, and efficient power distribution, we reduce the carbon footprint associated with data center operations.

                    </h5>
                </div>
            </div>
        </div>

        <div className="col pt-3 pt-lg-0">
            <div className=" card h-100 top-card">
                <div className="card-body ">
                    <div className=" pb-lg-3"> <RiUser3Fill className="sub-icon "style={{"background":"rgb(132 81 207 / 93%)"}} /> </div>
                    <h4 className="card-text-one">
                    Employee Engagement
                    </h4>
                    <h5 className="card-text-two">
                    We believe that sustainability starts from within. We promote awareness and education among our employees, encouraging responsible practices such as waste reduction, recycling, and energy conservation. By fostering a culture of sustainability, we empower our team members to make a positive impact both at work and in their communities.
                    </h5>
                </div>
            </div>
        </div>
        <div className="col pt-3 pt-lg-0">
            <div className=" card h-100 ">
                <div className="card-body ">
                    <div className=" pb-lg-3"> <SiLibrariesdotio className="sub-icon "style={{"background":"rgb(59 189 107 / 93%)"}} /> </div>
                    <h4 className="card-text-one">
                    Continuous Improvement
                    </h4>
                    <h5 className="card-text-two">
                    We understand that sustainability is an ongoing journey. We regularly evaluate our practices, policies, and technologies to identify areas for improvement and implement innovative solutions. By staying at the forefront of sustainable advancements, we strive to provide our clients with the most environmentally responsible solutions.
                    </h5>
                </div>
            </div>
        </div>
    </div>
    </div>
         </div>
        </div>
        </section>

        </div>
        </>
    )
}
export default Sustainability;