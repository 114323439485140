import { BiRightArrowAlt } from "react-icons/bi";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Dummybg from "../../Assets/images/servies-images/demo/dummy-3.webp";

import datatwo from "../../Assets/images/servies-images/datatwo.webp";

import datavisualization from "../../Assets/images/servies-images/dataanalytics/datavisualization.webp";
import analysis from "../../Assets/images/servies-images/dataanalytics/analysis.webp";
import textanalysis from "../../Assets/images/servies-images/dataanalytics/textanalysis.webp";
import dataimg from "../../Assets/images/servies-images/dataanalytics/dataimg.webp";
import { useEffect } from "react";

function DataAnalytics() {
    
    return (
        <>
            <div className="dataanalytics-section">
                <section className="top-banner-section top-banner-section-overral-bg-section back-posi-img dataanalytics-background" >
                    <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1 className="top-text-one"><span className="text-color">Data Analytics</span> Solutions by Upstrox</h1>
                                    <div className="top-text-two">Upstrox provides comprehensive data analytics solutions designed to help organizations derive valuable insights from their data, make informed decisions, and drive business growth.</div>
                                </div>
                            </div>
                            <div className="pt-3">
                                <a href="/Contact"><button className="top-button right-side">Get More <MdOutlineArrowForwardIos className="top-icon ms-lg-2" /></button></a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="datacard-section">
                    <section className="datacard-section-one py-5">
                        <div className="container">
                        <h5 className="text-one">Our expertise in data analytics, combined with advanced technologies and industry knowledge, enables us to deliver innovative solutions tailored to your specific needs. Here are the key data analytics services we offer</h5>
                            <div className="row pt-2 pt-lg-5 align-items-center">
                                <div className="data-card-blur" style={{ background: "#00ffff75", opacity: "0.5" }}></div>
                                <div className="col-lg-7 col-md-7">
                                    <div className="container">
                                        <div className="row pt-lg-3 py-2">
                                            <div className="col">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Data Strategy and Consulting</h4>
                                                    <h5 className="card-text-two text-ho">
                                                        Our experienced data consultants work closely with your organization to develop a data strategy aligned with your business objectives. We assess your data landscape, identify data sources, define data governance frameworks, and create roadmaps for data-driven transformation.
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-lg-3 py-2">
                                            <div className="col">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Data Warehousing and Data Lake Solutions</h4>
                                                    <h5 className="card-text-two text-ho">
                                                        We offer data warehousing and data lake solutions that centralize and integrate data from various sources. Our solutions provide a unified view of your data, ensuring data quality, data security, and efficient data processing for analytics purposes.
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-lg-3 py-2">
                                            <div className="col">
                                                <div className="card bg-transparent text-white">
                                                    <h4 className="card-text-one">Data Integration and ETL</h4>
                                                    <h5 className="card-text-two text-ho">
                                                        We provide data integration and extract, transform, load (ETL) services to efficiently extract, transform, and load data from disparate sources into a unified data repository. Our experts utilize industry-leading ETL tools and techniques to ensure data accuracy and reliability.
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5">
                                    <div className=""><img src={dataimg} alt="datacardimg" className="datacard-img" /></div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="datacard-section-two py-3">
                        <div className="container">
                            <div className="row align-items-end g-4">
                                <div className="col-lg-4 col-md-4">
                                    <div className="card px-lg-3 py-lg-1 p-md-1 h-100">
                                        <div className="text-center"><img src={datavisualization} alt="datavisualization" className="datacard-img" /></div>
                                        <h4 className="card-text-one">
                                            Data Visualization and Reporting
                                        </h4>
                                        <h5 className="card-text-two text-ho">
                                            We offer data visualization and reporting solutions that enable you to gain insights from your data through interactive and visually appealing dashboards and reports. Our solutions leverage leading visualization tools to present data in a meaningful and intuitive manner.
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div className="card px-lg-3 py-lg-3 p-md-2 h-100">
                                        <div className="text-center"><img src={analysis} alt="analysis" className="datacard-img" /></div>
                                        <h4 className="card-text-one">
                                            Advanced Analytics and Predictive Modeling
                                        </h4>
                                        <h5 className="card-text-two text-ho">
                                            We specialize in advanced analytics and predictive modeling to uncover hidden patterns, trends, and relationships in your data. Our experts utilize statistical modeling, machine learning, and AI techniques to develop predictive models that help you make accurate forecasts and data-driven decisions.
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div className="card px-lg-3 py-lg-5 p-md-4 h-100">
                                        <div className="text-center"><img src={textanalysis} alt="textanalysis" className="datacard-img" /></div>
                                        <h4 className="card-text-one">
                                            Text Analytics and Natural Language Processing
                                        </h4>
                                        <h5 className="card-text-two text-ho">
                                            We provide text analytics and natural language processing (NLP) solutions to extract insights from unstructured data sources such as customer feedback, social media posts, and documents. Our solutions enable sentiment analysis, topic modeling, and entity recognition, helping you understand customer preferences and market trends.
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="datacard-section-three py-5">
                        <div className="container">
                            <div className="row py-2 g-4 pt-lg-4">
                                <div className="col-lg-6 col-md-6">
                                    <div className=" card text-white p-lg-4 h-100" style={{ background: "rgba(46, 46, 46, 0.199)" }}>
                                        <h4 className="card-text-one ps-3">
                                            Data Governance and Compliance
                                        </h4>
                                        <h5 className="card-text-two px-3 text-ho">
                                            We assist organizations in implementing data governance frameworks and ensuring compliance with data privacy regulations. Our solutions cover data classification, access controls, data lineage, and data protection measures, helping you maintain data integrity and meet regulatory requirements.
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className=" card text-white p-lg-4 h-100" style={{ background: "rgb(132 107 249)" }}>
                                        <h4 className="card-text-one ps-3">
                                            Big Data Analytics
                                        </h4>
                                        <h5 className="card-text-two px-3 text-ho">
                                            We specialize in big data analytics, helping organizations process and analyze large volumes of structured and unstructured data. Our experts leverage big data technologies such as Hadoop, Spark, and NoSQL databases to extract insights from diverse data sources and uncover valuable business intelligence.
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="row py-2 align-items-center row-reverse g-4">
                                <div className="col-lg-6 col-md-6">
                                    <div className=" card text-white p-lg-4 h-100" style={{ background: "rgb(53 195 97 / 77%)" }}>
                                        <h4 className="card-text-one ps-3">
                                            Data Quality Management
                                        </h4>
                                        <h5 className="card-text-two px-3 text-ho">
                                            We offer data quality management solutions to ensure the accuracy, completeness, and consistency of your data. Our solutions include data profiling, data cleansing, and data validation techniques to improve data quality and reliability for analytics purposes.
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className=" card text-white p-lg-4 h-100" style={{ background: "rgba(46, 46, 46, 0.199)" }}>
                                        <h4 className="card-text-one ps-3">
                                            Data Science and AI Solutions
                                        </h4>
                                        <h5 className="card-text-two px-3 text-ho">
                                            We provide data science and AI solutions that leverage machine learning, deep learning, and AI algorithms to solve complex business problems. Our data scientists collaborate with your team to develop custom AI models and solutions that drive innovation and optimize business processes.
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="datacard-section-four py-3 py-lg-5">
                        <div className="container">
                            <div className="row d-flex align-items-center g-4">
                                <div className="col-lg-8 col-md-8 "><p className="service-footer-text mb-4">At Upstrox, we are committed to delivering data analytics solutions that enable organizations to unlock the full potential of their data.</p>
                                    <a href="contact" className='new-ups-button-2'><span className='new-ups-button-icon-3'>Read More</span> <BiRightArrowAlt className='new-ups-button-icon-2' /></a>
                                </div>
                                <div className="col-lg-4 col-md-4"><img src={datatwo} alt="datatwo" className="service-footer-img"></img></div>
                            </div>
                        </div>
                    </section>
                    <section className="datacard-section-five pt-5">
                        <div className="container">
                            <div className="row g-4 justify-content-center text-center">
                                <div className="col-lg-12">
                                    <p className="contact-text mb-lg-4">Contact us today to discuss your specific requirements and explore how Upstrox can empower your organization with our industry-leading data analytics services.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        </>
    )
}
export default DataAnalytics;