import { GoDotFill } from "react-icons/go";
import { FaHandshake } from "react-icons/fa";
import { IoMdContact } from "react-icons/io";
import Dummybg from "../../Assets/images/servies-images/demo/dummy-3.webp"
import Aboutbanner1 from "../../Assets/images/about/about-business-banner-1.webp"
import Aboutbanner2 from "../../Assets/images/about/about-business-banner-2.webp"


function AboutUs() {
  return (
    <>
      <div className="aboutus-banner-section">
        <section className="top-banner-section top-banner-section-overral-bg-section" style={{ backgroundImage: "url(" + Dummybg + ")" }}>
          <div className='mobile-new-ups-bg-shade-inner mx-auto container-fluid'>
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="career-text-one">
                    <span className="text-color"> About </span>  Upstrox
                  </h1>
                  <div className="top-text-two">
                    At Upstrox, we are a leading technology solutions provider dedicated to empowering businesses and organizations with cutting-edge IT services. With our expertise in infrastructure and cloud solutions, we help our clients unlock the full potential of their digital transformation journey.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="aboutus-section-two py-4">
          <div className="container ">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-4">
                <img src={Aboutbanner1} alt="Mission" style={{borderRadius:"20px"}} />
              </div>
              <div className="col-lg-4 ">
                <div className="card-text-one pb-2">
                  Our Mission
                </div>
                <div className="card-text-two ">
                  Our mission is to drive innovation and enable digital agility for businesses across various industries. We strive to deliver robust and scalable technology solutions that enhance operational efficiency, streamline processes, and accelerate business growth.
                </div>
              </div>
            </div>
            <div className="row justify-content-center pt-3 align-items-center">
              <div className="col-lg-4">
                <div className="card-text-one pb-2">
                  Industry Expertise
                </div>
                <div className="card-text-two">
                With a diverse portfolio of clients, Upstrox has extensive experience working with a wide range of industries. From healthcare and finance to retail and manufacturing, we understand the unique challenges and requirements of different sectors. This expertise allows us to tailor our solutions to meet the specific needs of each industry, delivering optimal results and tangible value.

                </div>
              </div>
              <div className="col-lg-4">
              <img src={Aboutbanner2} alt="Mission" style={{borderRadius:"20px"}} />

              </div>
            </div>
          </div>
        </section>

        <section className="abotus-section-three my-lg-5">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-lg-4 p-2">
                <h3 className="card-text-one text-color">
                  Comprehensive Solutions
                </h3>
                <div className="card-text-two">
                  At Upstrox, we offer a comprehensive suite of services to address every aspect of our clients' technology needs. Our core offerings include
                </div>
              </div>
              <div className="col-lg-4 p-2">
                <div className="">
                  <h5 className="about-text-one"><GoDotFill className="text-color" />Infrastructure as a Service (IaaS)</h5>
                  <p className="card-text-two">We provide flexible and scalable infrastructure solutions that leverage the power of the cloud, enabling organizations to optimize resource allocation, enhance security, and reduce costs.</p>
                </div>
                <div className="">
                  <h5 className="about-text-one"><GoDotFill className="text-color" />Data Security and Compliance</h5>
                  <p className="card-text-two"> Our team of experts helps organizations safeguard their critical data and maintain regulatory compliance through robust security measures, risk assessments, and data protection strategies.</p>
                </div>

              </div>
              <div className="col-lg-4 p-2">
                <div className="">
                  <h5 className="about-text-one"><GoDotFill className="text-color" />Cloud Migration and Management</h5>
                  <p className="card-text-two">We assist businesses in smoothly transitioning their applications and data to the cloud, ensuring seamless integration and efficient management of cloud-based environments.</p>
                </div>
                <div className="">
                  <h5 className="about-text-one"><GoDotFill className="text-color" />IT Consulting and Support</h5>
                  <p className="card-text-two">We offer strategic IT consulting services to help businesses align their technology investments with their long-term objectives. Our dedicated support team ensures prompt assistance and reliable technical guidance.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="aboutus-section-four pb-lg-5">
          <div className="container">
            <div className="row row-cols-lg-3 row-cols-1 row-cols-md-2  justify-content-center text-center g-3">
              <div className="col">
                <div className="card h-100 p-lg-4 p-2">
                  <div className="pb-3"><FaHandshake className="career-icon" /></div>
                  <h3 className="card-text-one pb-2">Partnerships and Commitment</h3>
                  <p className="card-text-two ">At Upstrox, we believe in fostering strong partnerships with our clients. We strive to understand their unique business requirements, challenges, and goals, and work collaboratively to achieve tangible results. Our commitment to excellence, continuous innovation, and customer satisfaction sets us apart as a trusted technology partner.</p>
                </div>
              </div>
              <div className="col">
                <div className="card h-100 p-lg-4 p-2">
                  <div className="pb-3"><IoMdContact className="career-icon" /></div>
                  <h3 className="card-text-one pb-2">Contact Us</h3>
                  <p className="card-text-two ">Discover how Upstrox can accelerate your digital transformation journey. Reach out to our team today to discuss your technology needs and explore the solutions we offer. Together, let's unlock the power of technology for your business</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
export default AboutUs;